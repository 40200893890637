/**
 * @file_purpose  page for showing receiver details list
 * @author Sarmistha Mondal
 * @Date_Created 28/06/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import Vlogo from "../../../assets/images/TataMotors.png";
import SmallTruck from "../../../assets/images/sm_truck.png";
import VehicleDoc from "../../../assets/images/document.png";


export default function ReceiverDetails() {
    const [_filteredData, _setFilteredData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [Id, setId] = useState();
    const [deliverdData, setDeliverdData] = useState([]);

    useEffect(() => {
        allReceiverDetailsGetList()
    }, []);

    /**
   * @author Sarmistha Mondal
   * @Date_Created 28/06/2024
   * @Date_Modified 
   * @function async
   * @functionName allReceiverDetailsGetList
   * @functionPurpose this function for receiver list.
   *
   * @functionParam {}
   *
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
    const allReceiverDetailsGetList = () => {
        Service.apiPostTokenCallRequest(RouteURL.shipperAllReceivers, {}).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let details = response.data;
                setFilteredData(details)
                _setFilteredData(details);
                if (details.length > 0) {
                    setId(details[0]?.id)
                    orderByReceiver(details[0]?.id)
                }

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });
    }

    /**
        * @author Sarmistha Mondal
        * @Date_Created 28/06/2024
        * @Date_Modified
        * @function async
        * @functionName orderByReceiver
        * @functionPurpose this function gets the order by receiver.
        *
        * @functionParam {payload object:}
        *
        * @functionSuccess Success status and message.
        *
        * @functionError {Boolean} error is there.
        * @functionError {String} message  Description message.
        */

    const orderByReceiver = (receiver_id) => {
        setDeliverdData([])
        Service.apiPostTokenCallRequest(RouteURL.receiverAllOrder, { receiver_id: receiver_id })
            .then((res) => {
                if (res.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let _res = res.data.all_orders;
                    if (_res.length > 0) {
                        setDeliverdData(_res);
                    }
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
    }

    const ToggleButton = (openIndex, i, item) => {
        if (openIndex) {
            setId(item.id);
            orderByReceiver(item.id)
        }
    };

    return (
        <>

            <div className="sideright_menu">
                <div className="shipment_menu_rt">

                    <div className="godown_upper_hed">
                        <div className="row">
                            <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-12 col-md-12">
                                <div className="godown_upper_hed_lt">
                                    <h4>Receiver Details</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="godwn_dwn">
                        {filteredData.length>0?
                        <div className="row">
                            <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12 ">
                                <div className='vehicle_track_sec'>
                                    <div className='godown_office_main' style={{ overflowX: 'auto', height: filteredData.length > 6 ? 1000 : 'auto' }}>
                                        {filteredData.map((item, key) => {
                                            return (<>
                                                <div className={`${Id == item.id ? `godown_office_box office_box_select` : `godown_office_box `}`} onClick={() => { ToggleButton(item.id, key, item); }}>
                                                    <div className="vehicle_number_lt">
                                                        <span className="godown_office_box_upper_img">
                                                            <img src={item?.vehicle_image != null ? item?.vehicle_image[0] : Vlogo} alt="" />

                                                        </span>
                                                        <div className="godown_office_box_upper">
                                                            <h6 style={{ color: Id == item.id ? ' #FBAA2A' : '#000' }}>{item.name}</h6>
                                                            <span >
                                                                <i class="fa-solid fa-phone" style={{ fontSize: 10 }}></i>
                                                                {item?.contact_no}</span>
                                                            <p><span><i class="fa-solid fa-phone" style={{ fontSize: 10 }}></i>{item.contact_no2}</span><br /> {item.email}</p>
                                                        </div>
                                                    </div>
                                                    <div className="godown_office_box_dwn">
                                                        <p>Received Materials</p>
                                                        <p className="mt-3"><br />
                                                        </p>
                                                        <div className="godwn_arrow">
                                                            {
                                                                Id == item.id ?
                                                                    <i className="fa-solid fa-angle-left" style={{
                                                                        color:
                                                                            Id == item.id ? ' #FBAA2A' : '#000'
                                                                    }} /> :
                                                                    <i className="fa-solid fa-angle-right" />}

                                                        </div>
                                                    </div>

                                                </div>
                                            </>)
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 cl-xxl-8 col-xl-8 col-lg-8 col-md-12 ">
                                <div className="office_address_lt vehicle_gap">
                                    <div className="row">
                                        <div className="col-6"><h6>Received Materials <span style={{ color: '#FBAA2A' }}> </span></h6>

                                            <div className="form-check form-switch vehicle_switch" >
                                                <label className="form-check-label" ></label>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="office_address_rt">
                                        {deliverdData.length > 0 ? deliverdData?.map((item) =>
                                            item?.material_type.map((data, key) => {
                                                return (
                                                    <>
                                                        <div className="vehicle_rt_box">
                                                            <div className="vehicle_txt">

                                                                <div className="row" style={{}}>
                                                                    <div className="col-4">
                                                                        <div className="productmaterial_inn">
                                                                            <h6 style={{ display: 'block' }}><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.017 6">
                                                                                <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                            </svg> Dimensions (L x W x H)
                                                                            </h6>
                                                                            <p className="productmaterial_inn_color">{data.product_dimension}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-4">
                                                                        <div className="productmaterial_inn">
                                                                            <h6 style={{ display: 'block' }}><svg xmlns="http://www.w3.org/2000/svg" width="10.141" height="10" viewBox="0 0 5.141 6">
                                                                                <g id="scale" transform="translate(-36.645)">
                                                                                    <g id="Group_8799" data-name="Group 8799" transform="translate(36.645)">
                                                                                        <g id="Group_8798" data-name="Group 8798">
                                                                                            <path id="Path_9414" data-name="Path 9414" d="M40.915,1.059A2.128,2.128,0,0,0,41.761.27.179.179,0,0,0,41.608,0H36.824a.179.179,0,0,0-.154.27,2.127,2.127,0,0,0,.847.789,2.531,2.531,0,0,0,1.018.282V1.8H37.69a.627.627,0,0,0-.621.55L36.708,5.3a.626.626,0,0,0,.621.7H41.1a.626.626,0,0,0,.621-.7L41.363,2.35a.627.627,0,0,0-.621-.55H39.9V1.341A2.532,2.532,0,0,0,40.915,1.059ZM40.681,3.9a1.465,1.465,0,1,1-1.465-1.465A1.466,1.466,0,0,1,40.681,3.9Z" transform="translate(-36.645)" fill="#928fa9" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g id="Group_8801" data-name="Group 8801" transform="translate(38.1 2.781)">
                                                                                        <g id="Group_8800" data-name="Group 8800">
                                                                                            <path id="Path_9415" data-name="Path 9415" d="M162.628,238.308a1.115,1.115,0,1,0,1.115,1.115A1.117,1.117,0,0,0,162.628,238.308Zm.127,1.243-.433.433a.18.18,0,1,1-.255-.255l.433-.433a.18.18,0,0,1,.255.255Z" transform="translate(-161.513 -238.308)" fill="#928fa9" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </svg> Weight
                                                                            </h6>
                                                                            <p className="productmaterial_inn_color">{data.package_weight}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="productmaterial_inn">
                                                                            <h6 style={{ display: 'block' }}><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                            </svg> No of boxes
                                                                            </h6>
                                                                            <p className="productmaterial_inn_color">{data.no_of_units}</p>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-4">
                                                                        <div className="productmaterial_image">
                                                                            <h6>Product Image</h6>
                                                                            <div className="productmaterial_image_box">

                                                                                <div className="p_box">
                                                                                    {data?.images.length > 0 && data?.images.map((img) => {
                                                                                        return (
                                                                                            <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                                        )
                                                                                    })}

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <div className="productmaterial_image">
                                                                            <h6>Shipper Doc</h6>
                                                                            <div className="productmaterial_image_box">
                                                                                <div className="productmaterial_inn_color">
                                                                                    {(item.document_details?.shipper_document.length != 0 ?
                                                                                        <div className="tran_doc_img">
                                                                                            <a href={`${item.document_details?.shipper_document[0]}`} target="_blank" ><img src={VehicleDoc} alt="" height={40} width={40} /></a></div> : '--')}                                                                                    

                                                                                </div>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                    <div className="col-sm-4">

                                                                        <div className="productmaterial_image">
                                                                            <h6>Transporter Docs</h6>
                                                                            <div className="productmaterial_image_box">
                                                                                <div className="productmaterial_inn_color">
                                                                                    {(item.document_details?.transporter_document.length != 0 ? <div className="tran_doc_img">   {item.document_details?.transporter_document.map((item, key) => {
                                                                                        return (
                                                                                            <a href={`${item}`} target="_blank" key={key}>
                                                                                                <img src={VehicleDoc} alt="" height={40} width={40} />
                                                                                            </a>
                                                                                        )
                                                                                    })}</div> : '--')}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="productmaterial_inn">
                                                                        <h6 style={{ display: 'block' }}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 5.737 6">
                                                                            <g id="Group_29516" data-name="Group 29516" transform="translate(-803 -366)">
                                                                                <path id="Path_9494" data-name="Path 9494" d="M26.667,1.6,29.308.463,28.214.014a.19.19,0,0,0-.144,0L25.444,1.093Z" transform="translate(777.726 366)" fill="#928fa9" />
                                                                                <path id="Path_9495" data-name="Path 9495" d="M172.654,56.125l-2.641,1.132.984.4,2.7-1.108Z" transform="translate(634.871 310.54)" fill="#928fa9" />
                                                                                <path id="Path_9496" data-name="Path 9496" d="M13.76,122.008l-1.069-.439v.831a.19.19,0,0,1-.379,0v-.987l-1.231-.506v3.339a.19.19,0,0,0,.118.175l2.561,1.052Z" transform="translate(791.919 246.525)" fill="#928fa9" />
                                                                                <path id="Path_9497" data-name="Path 9497" d="M269.069,122.008v3.466l2.561-1.052a.19.19,0,0,0,.118-.175v-3.339Z" transform="translate(536.989 246.525)" fill="#928fa9" />
                                                                            </g>
                                                                        </svg> Material
                                                                        </h6>
                                                                        <p className="productmaterial_inn_color">{data.material_type
                                                                        }</p>
                                                                    </div>
                                                                </div>
                                                            </div >
                                                        </div>
                                                    </>
                                                )
                                            })

                                        ) : <div className="" style={{}}>No data found ...</div>}
                                    </div>
                                </div>
                            </div>
                            </div> : <div className="" style={{}}>No data found ...</div>}
                    </div>
                </div>
            </div>


        </>
    );
}
