/**
 * @file_purpose  page for showing Upload e way bill and payment
 * @author Sarmistha Mondal
 * @Date_Created 24/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    isFormValid,
    leftTrim,
} from "../../../Utility/Helper";
import Modal from "react-bootstrap/Modal";
import TataBg from "../../../assets/images/tat_bg.png"

export default function UploadEBillModal(props) {
    const modalRef = useRef(null);
    let formData = new FormData();
    let order_id = props._allData.order_id;
    let type = props._allData.type;
    let price = props._allData.price;
    const [fileData, setFileData] = useState({
        e_way_bill: [], e_way_bill_img: [],
    });
    const [errorValue, setErrorValue] = useState({ e_way_bill: "", payment_amount: '', payment_method: '' });
    const selectedFIles = [];
    const [submitDisable, setSubmitDisable] = useState(false);
    const [formValue, setFormValue] = useState({ payment_amount: price, payment_method: '' })
    const [Type, setType] = useState(type)


    // focus within a specific area
    useEffect(() => {
        if (props.show) {
            const modalElement = modalRef.current;
            //add any focusable HTML element you want to include to this string
            const focusableElements = modalElement.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event) => {
                if (event.key === "Tab") {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event) => {
                if (event.key === "Escape") {
                    props.onHide();
                }
            };

            modalElement.addEventListener("keydown", handleTabKeyPress);
            modalElement.addEventListener("keydown", handleEscapeKeyPress);

            return () => {
                modalElement.removeEventListener("keydown", handleTabKeyPress);
                modalElement.removeEventListener("keydown", handleEscapeKeyPress);
            };
        }
    }, [props.show]);


    /* upload e bill */
    const handleMultipleLR = (e, i) => {
        let files = e.target.files[0];
        const newArray = [...fileData.e_way_bill];
        newArray.push(files)
        setFileData((prevFormValue) => {
            return { ...prevFormValue, e_way_bill: e.target.files };
        })
        if (fileData?.e_way_bill?.length <= 1) {
            const targetFilesObject = [...newArray]
            targetFilesObject.map((file) => {
                return selectedFIles.push(URL.createObjectURL(file))
            })

        }
        
        setFileData((prevFormValue) => {
            return { ...prevFormValue, e_way_bill_img: selectedFIles };
        })

    }

    /* on change input function */
    const formChange = (event) => {
        const { name, value, checked } = event.target;
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
    };

    /* Validation Checking */
    const validationBill = (e) => {
        e.preventDefault();
        if (Type == 'bill') {
            if (fileData.e_way_bill.length == 0) {
                setErrorValue((prevFormValue) => {
                    return { ...prevFormValue, e_way_bill: 'Please upload e-way bill' };
                });

            } else {
                setErrorValue((prevFormValue) => {
                    return { ...prevFormValue, e_way_bill: '' };
                });
            }
        }


        if (fileData.e_way_bill.length > 0 && (Type == 'bill')) {
            _submitBill()
        }
    }

    /* Validation Checking */
    const validationPay = (e) => {
        e.preventDefault();

        if (Type == 'pay') {
            if (!formValue.payment_method) {
                setErrorValue((prevFormValue) => {
                    return { ...prevFormValue, payment_method: 'Please enter payment method' };
                });

            } else {
                setErrorValue((prevFormValue) => {
                    return { ...prevFormValue, payment_method: '' };
                });

            }
            if (!formValue.payment_amount) {
                setErrorValue((prevFormValue) => {
                    return { ...prevFormValue, payment_amount: 'Please enter payment amount' };
                });

            } else {
                setErrorValue((prevFormValue) => {
                    return { ...prevFormValue, payment_amount: '' };
                });

            }
        }
       
        if (isFormValid(formValue)) {
            _submitPayment()
        }

    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 30/04/2024
      * @Date_Modified 
      * @function async
      * @functionName _submitBill
      * @functionPurpose this function for e way bill submition.
      *
      * @functionParam {order_id,e_way_bill}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _submitBill = () => {
        formData.append(`order_id`, order_id);
        formData.append(`e_way_bill`, fileData.e_way_bill[0]);
       
        setSubmitDisable(true)
        Service.apiPostCallFormDataRequest(RouteURL.uploadDeliveryDocument, formData).then((response) => {
           
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setType('pay') 
                setSubmitDisable(false)
            } else {
               
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        }).catch((error) => {
            setSubmitDisable(false)

        });
    }


    /**
      * @author Sarmistha Mondal
      * @Date_Created 01/05/2024
      * @Date_Modified 
      * @function async
      * @functionName _submitPayment
      * @functionPurpose this function for payment submition.      
      * @functionParam {order_id}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _submitPayment = () => {
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.shipperPayment, { order_id: order_id }).then((response) => {         
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {                
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        }).catch((error) => {
            setSubmitDisable(false)

        });
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec" ref={modalRef}>
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt"> {Type == 'bill' ? 'Upload E-Way Bill' : 'Payment Details'}</h6>
                    </Modal.Header>

                    <section className="reject_inner">
                        {/* {type == 'bill' &&  */}
                        <p>Please submit below documents for transporter to start deliver</p>
                        <div className="row">
                            {/* {type == 'bill' && */}
                            <div className="col-sm-12">
                                {/* <div className="office_address_rt"> */}
                                <div className="office_field">
                                    <div className="vehicle_rt_box lorry_upload">
                                        <div className="vehicle_txt">
                                            <h6>e-Way Bill / GST bill and other documents<span></span></h6>
                                        </div>
                                        <div className="row">
                                            {fileData?.e_way_bill_img?.map((url) => {
                                                return (<div className="col-sm-3">
                                                    <div className="tata_moto_sec">
                                                        <img src={url} alt="" />
                                                    </div>
                                                </div>)
                                            })}
                                            {fileData?.e_way_bill?.length < 1 &&
                                                <div className="col-sm-2 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                                                    <div className="tata_moto_sec">
                                                        <input type="file" accept=".jpg,.jpeg,.png"
                                                            name="e_way_bill"
                                                            onChange={(e) => handleMultipleLR(e)}
                                                            error={errorValue.e_way_bill ? "true" : "false"}
                                                        />
                                                        <img src={TataBg} alt="" />
                                                    </div>
                                                </div>
                                            }

                                        </div>

                                        {fileData?.e_way_bill.length == 0 && errorValue.e_way_bill && (
                                            <span className="error">
                                                {errorValue.e_way_bill}
                                            </span>
                                        )}
                                    </div>

                                </div>
                                <div className="col-sm-6 offset-sm-3">
                                    <div className="login_btn">
                                        <button type="" value="get Started"
                                            disabled={submitDisable ? (Type == 'bill' ? true : false) : false}
                                            style={{ background: (Type == 'bill' ? '#000' : '#C5C4D2') }}
                                            onClick={validationBill}
                                        > {Type == 'pay' ?'Uploaded':'Upload'}</button>
                                    </div>
                                </div>
                            </div>
                            {/* }
                            {type == 'pay' && */}
                            <>
                                <div className="col-sm-12">
                                    <div className="office_field">
                                        <label>Transporter Bill Amount</label>
                                        <input type="number" name="payment_amount"
                                            placeholder="Enter Bill Amount"
                                            onChange={formChange}
                                            value={formValue.payment_amount}
                                            error={errorValue.payment_amount ? "true" : "false"}
                                        />
                                        {errorValue.payment_amount && (
                                            <span className="error">
                                                {errorValue.payment_amount}
                                            </span>
                                        )}

                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="office_field">
                                        <label>Payment Method</label>
                                        <div className="office_radio">
                                            <div className="radio_sec">
                                                <input id="radio-1" className="radio-custom" type="radio" value="Bank" name="payment_method" checked={formValue.payment_method == 'Bank' ? true : false}
                                                    onChange={formChange}
                                                />
                                                <label for="radio-1" className="radio-custom-label" style={{ color: "#000" }}>Bank Details</label>
                                            </div>
                                            <div className="radio_sec">
                                                <input id="radio-2" className="radio-custom" name="payment_method" type="radio" value="upi" checked={formValue.payment_method == 'upi' ? true : false}
                                                    onChange={formChange}
                                                />
                                                <label for="radio-2" className="radio-custom-label">UPI</label>
                                            </div>
                                            <div className="radio_sec">
                                                <input id="radio-3" className="radio-custom" name="payment_method" type="radio" value="cash" checked={formValue.payment_method == 'cash' ? true : false}
                                                    onChange={formChange}
                                                />
                                                <label for="radio-3" className="radio-custom-label">Cash</label>
                                            </div>
                                        </div>
                                        {errorValue.payment_method && (
                                            <span className="error">
                                                {errorValue.payment_method}
                                            </span>
                                        )}

                                    </div>
                                </div>
                            </>
                            {/* } */}

                        </div>

                        <div className="col-sm-6 offset-sm-3">
                            <div className="login_btn">
                                <button type="" value="get Started"
                                    disabled={submitDisable ? (Type == 'pay' ? true : false) : false}
                                    style={{ background: (Type == 'pay' ? '#000' : '#C5C4D2') }}
                                    onClick={validationPay}
                                > Pay</button>
                            </div>
                        </div>
                    </section>


                </div>
            </Modal>
        </>
    )
}