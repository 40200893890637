/**
 * @file_purpose  page for showing assign vehicle
 * @author Sarmistha Mondal
 * @Date_Created 19/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim,
} from "../../../Utility/Helper";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Dimension from "../../../assets/images/square-measument.png";
import Select from 'react-select';
import SmallTruck from "../../../assets/images/sm_truck.png"


export default function AssignVehicleModal(props) {
    const modalRef = useRef(null);
    let shipment_booking_id = props._allData.shipmentBookingId;
    let order_id = props._allData.order_id
    const [type, setType] = useState('Pick Up');
    const [filteredData, setFilteredData] = useState({});
    const [productDetailsShow, setProductDetailsShow] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [formGstValue, setFormGstValue] = useState({
        gstno: '',
        additional_notes: '',
        vehicle_details: ''
    });
    const [tracking_device_iemi, setTracking_device_iemi] = useState();
    const [formValue, setFormValue] = useState({
        assigned_vehicle_id: '',
        assigned_driver_id: '',
        start_date: '',
    });
    const [formUniqueValue, setFormUniqueValue] = useState({
        unique_id: '',
        additional_notes: '',
        vehicle_details: ''
    })
    const [optionalFields, setoptionalFields] = useState({
        additional_notes: '',
        start_time: "",
        tracking_link: ''
    });
    const [formTouchValue, setFormTouchValue] = useState({
        assigned_vehicle_id: false,
        assigned_driver_id: false,
        start_date: false,
        gstno: false,
        unique_id: false,
    });
    const [errorValue, setErrorValue] = useState({
        assigned_vehicle_id: '',
        assigned_driver_id: '',
        start_date: '',
        gstno: '',
        unique_id: ''
    });
    const [vehicleUnavalableError, setVehicleUnavalableError] = useState('');
    const [vehicleList, setVehicleList] = useState([]);
    const [driverList, setDriverList] = useState([]);
    useEffect(() => {
        _shipmentRequestDetails();
        _transporterVehicleList();
        _transporterDriverList()      
        
    }, [])

    // focus within a specific area
    useEffect(() => {
        if (props.show) {
            const modalElement = modalRef.current;
            //add any focusable HTML element you want to include to this string
            const focusableElements = modalElement.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event) => {
                if (event.key === "Tab") {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event) => {
                if (event.key === "Escape") {
                    props.onHide();
                }
            };

            modalElement.addEventListener("keydown", handleTabKeyPress);
            modalElement.addEventListener("keydown", handleEscapeKeyPress);

            return () => {
                modalElement.removeEventListener("keydown", handleTabKeyPress);
                modalElement.removeEventListener("keydown", handleEscapeKeyPress);
            };
        }
    }, [props.show]);


    /**
    * @author Sarmistha Mondal
    * @Date_Created 18/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _shipmentRequestDetails
    * @functionPurpose this function for shipment request details.
    *
    * @functionParam {shipment_booking_id}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _shipmentRequestDetails = () => {
        Service.apiPostTokenCallRequest(RouteURL.shipmentRequestDetails, { shipment_booking_id: shipment_booking_id }).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setFilteredData(response.data.shipmentRequestDetails)

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /**
    * @author Sarmistha Mondal
    * @Date_Created 19/04/2024
    * @Date_Modified 
    * @function async
    * @functionName _transporterVehicleList
    * @functionPurpose this function for available vehicle list.
    *
    * @functionParam {shipment_booking_id}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _transporterVehicleList = () => {
        Service.apiPostTokenCallRequest(RouteURL.transporterAvailableVehicleList, { shipment_booking_id: shipment_booking_id }).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let res = response.data.vehicleList;
                var newArray = []
                res.map((item, index) => (
                    newArray.push({
                        value: item.id,
                        label: item.vehicle_model,
                        device_iemi: item.tracking_device_iemi,
                    })
                ))
                setVehicleList(newArray)

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };


    /**
   * @author Sarmistha Mondal
   * @Date_Created 19/04/2024
   * @Date_Modified 
   * @function async
   * @functionName _transporterDriverList
   * @functionPurpose this function for available driver list.
   *
   * @functionParam {}
   *
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
    const _transporterDriverList = () => {
        Service.apiPostTokenCallRequest(RouteURL.transporterAvailableDriverList, {}).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let res = response.data.driverList;
                var newArray = []
                res.map((item, index) => (
                    newArray.push({
                        value: item.id,
                        label: item.name
                    })
                ))
                setDriverList(newArray)

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /* type choose*/
    const _TypeChange = (event) => {
        const { name, value, checked } = event.target;
        setType(value)
        if (value == 'Pick Up') {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, gstno: '', unique_id: ''
                };
            });
            setFormGstValue({
                gstno: '',
                additional_notes: ''
            })
            setFormUniqueValue({ unique_id: '', additional_notes: '', })

        } else if (value == 'Send GST Number') {
            setErrorValue({
                assigned_vehicle_id: '',
                assigned_driver_id: '',
                start_date: '',
                unique_id: ''
            });
            setFormUniqueValue({ unique_id: '', additional_notes: '', })
            setFormValue({
                assigned_vehicle_id: '',
                assigned_driver_id: '',
                start_date: '',
            })
            setoptionalFields({
                additional_notes: '',
                start_time: "",
                tracking_link: ''
            })

        } else {
            setErrorValue({
                assigned_vehicle_id: '',
                assigned_driver_id: '',
                start_date: '',
                gstno: '',
            });
            setoptionalFields({
                additional_notes: '',
                start_time: "",
                tracking_link: ''
            })
        }

    }

    /* select vehicle */
    const setSelectedVehicleOption = (e) => {
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, 'assigned_vehicle_id': e.value };
        });
        setTracking_device_iemi(e.device_iemi)
    }

    /* on change input function */
    const formChange = (event) => {
        const { name, value, } = event.target;
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
    };

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }

    };

    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !formValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` : ""
                };
            });
        }
    };

    useEffect(() => {
        if (formValue) {
            if (vehicleList.length > 0) {
                checkError("assigned_vehicle_id");
                checkError("assigned_driver_id");
                checkError("start_date");
                setVehicleUnavalableError('')
            } //else setVehicleUnavalableError('Please add vehicle');
        }
    }, [formValue])

  
    useEffect(() => {
        if (!formGstValue.gstno) {
            checkError("gstno");
        }
    }, [formGstValue])


    useEffect(() => {
        if (!formUniqueValue.unique_id) {
            checkError("unique_id");
        }
    }, [formUniqueValue])

    /* Validation Checking */
    const validation = (e) => {
        e.preventDefault();
        if (type == 'Pick Up') {
            setFormTouchValue(markAllAsTouched(formTouchValue));

            if (vehicleList.length > 0) {
                checkError("assigned_vehicle_id");
                checkError("assigned_driver_id");
                checkError("start_date");
                setVehicleUnavalableError('')
            } else setVehicleUnavalableError('Please add vehicle');

            if (isFormValid(formValue)) {
                _submitPicupTypeVehicale()
            }
        }
        if (type == 'Send GST Number') {
            setFormTouchValue(markAllAsTouched(formTouchValue));
            if (!formGstValue.gstno) {
                checkError("gstno");

            } else if (formGstValue.gstno && !REGEX.gstno.test(formGstValue.gstno)) {
                setErrorValue((prevErrorValue) => {
                    return {
                        ...prevErrorValue, gstno: "Please provide a valid GST"
                    };
                });
            } else if (formGstValue.gstno && REGEX.gstno.test(formGstValue.gstno) && (formGstValue.vehicle_details == true)) {

                setErrorValue((prevErrorValue) => {
                    return {
                        ...prevErrorValue, gstno: ""
                    };
                });
                delete formGstValue.vehicle_details;

                _submitGstTypeVehicale();
            } else {
                setErrorValue((prevErrorValue) => {
                    return {
                        ...prevErrorValue, gstno: ""
                    };
                });
            }
        }
        if (type == 'Send Unique ID') {
            setFormTouchValue(markAllAsTouched(formTouchValue));
            if (!formUniqueValue.unique_id) {
                checkError("unique_id");

            } else if (formUniqueValue.unique_id && (formUniqueValue.vehicle_details == true)) {

                delete formUniqueValue.vehicle_details;
                _submitUniqueIdTypeVehicale();
            } else {
                setErrorValue((prevErrorValue) => {
                    return {
                        ...prevErrorValue, unique_id: ""
                    };
                });
            }
        }

    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 19/04/2024
      * @Date_Modified 
      * @function async
      * @functionName _submitPicupTypeVehicale
      * @functionPurpose this function for pick up vehicle.
      *
      * @functionParam {order_id,assigned_vehicle_id,assigned_driver_id,start_date,
      * additional_notes,start_time,}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */

    const _submitPicupTypeVehicale = () => {
        let params = JSON.stringify({
            order_id: order_id,
            assigned_vehicle_id: formValue.assigned_vehicle_id,
            assigned_driver_id: formValue.assigned_driver_id,
            start_date: formValue.start_date,
            additional_notes: optionalFields.additional_notes,
            start_time: optionalFields.start_time,
            tracking_link: ''
        });

        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterAssignVehicle, params).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }

    /* on change input function */
    const formGstChange = (event) => {
        const { name, value, checked } = event.target;
        if (name == 'vehicle_details') {
            setFormGstValue((prevValue) => {
                return { ...prevValue, ['vehicle_details']: checked };
            });
        } else {
            setFormGstValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };

    /* on change input function */
    const formUniqueChange = (event) => {
        const { name, value, checked } = event.target;
        if (name == 'vehicle_details') {
            setFormUniqueValue((prevValue) => {
                return { ...prevValue, ['vehicle_details']: checked };
            });
        } else {
            setFormUniqueValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };

    /**
      * @author Sarmistha Mondal
      * @Date_Created 19/04/2024
      * @Date_Modified 
      * @function async
      * @functionName _submitGstTypeVehicale
      * @functionPurpose this function for gst type vehicle.
      *
      * @functionParam {order_id,gst_number,additional_notes}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */


    const _submitGstTypeVehicale = () => {
        let params = JSON.stringify({
            order_id: order_id,
            gst_number: formGstValue.gstno,
            additional_notes: formGstValue.additional_notes
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterAssignVehicleSaveGst, params).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }

    /**
     * @author Sarmistha Mondal
     * @Date_Created 19/04/2024
     * @Date_Modified 
     * @function async
     * @functionName _submitUniqueIdTypeVehicale
     * @functionPurpose this function for unique id type vehicle.
     *
     * @functionParam {order_id,unique_id,additional_notes}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */

    const _submitUniqueIdTypeVehicale = () => {
        let params = JSON.stringify({
            order_id: order_id,
            unique_id: formUniqueValue.unique_id,
            additional_notes: formUniqueValue.additional_notes
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterAssignVehicleUniqueid, params).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }


    return (
        <>
            < Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec" ref={modalRef}>
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Assign Vehicle</h6>
                    </Modal.Header>

                    <section className="reject_inner">
                        <div className="reject_req_sec">
                            <div className="reject_hed"><h4>{filteredData?.shipment_details?.request_id} <br /><span style={{ color: "#FBAA2A" }}>{filteredData?.shipment_details?.shipper_code}</span></h4></div>
                            <div className="reject_person"><p>{filteredData?.shipment_details?.shipper_name}</p></div>
                        </div>
                        <div className="reject_track">
                            <div className="order-track-step">
                                <div className="order-track-status">
                                    <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                    <span className="order-track-status-line"></span>
                                </div>
                                <div className="order-track-text">
                                    <span className="order-track-text-sub">Booking Date - {filteredData?.location_details?.booking_date}</span>
                                    <p className="order-track-text-stat">{filteredData?.location_details?.from_location}</p>
                                    <span className="kilometer">{filteredData?.location_details?.distance}</span>
                                    <span className="kilometer">{filteredData?.location_details?.time_duration}</span>
                                </div>
                            </div>
                            <div className="order-track-step">
                                <div className="order-track-status">
                                    <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                    <span className="order-track-status-line"></span>
                                </div>
                                <div className="order-track-text">
                                    <span className="order-track-text-sub">Est. Delivery date - {filteredData.location_details?.estimated_delivery_date != null ? filteredData.location_details?.estimated_delivery_date : ' _____'}</span>
                                    <p className="order-track-text-stat">{filteredData?.location_details?.to_location}</p>
                                </div>
                            </div>

                        </div>
                        <div className="material_show_dot">
                            <span className="dot_bluclick">
                                <i className="fa-solid fa-ellipsis" onClick={() => setProductDetailsShow(!productDetailsShow)}>
                                    {productDetailsShow == true &&
                                        <div className="dot_bluclick_inner shipaccept">
                                            <span><i className="fa-solid fa-xmark" onClick={() => setProductDetailsShow(false)}></i></span>
                                            {filteredData?.material_type.length > 0 && filteredData?.material_type.map((item) => {
                                                return (
                                                    <div className="row">

                                                        <div className="col-sm-6">
                                                            <div className="material1_sec">
                                                                <p><img src={Dimension} alt="" /> Material</p>

                                                                <h6>{item.material_type}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="material1_sec">
                                                                <p><img src={Dimension} alt="" /> Weight</p>
                                                                <h6>{item.package_weight}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="material1_sec">
                                                                <p><img src={Dimension} alt="" /> Dimensions</p>
                                                                <h6>{item.product_dimension}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="material1_sec">
                                                                <p><img src={Dimension} alt="" /> Number of Boxes</p>
                                                                <h6>{item.no_of_units}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="material1_sec">
                                                                <p>Payment Mode</p>
                                                                <h6> {filteredData?.payment_mode}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="material1_sec">
                                                                <p>Loading Type</p>
                                                                <h6>{filteredData?.load_type} </h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="material1_sec">
                                                                <h6>Product Image</h6>
                                                                <div className="productmaterial_image_box">

                                                                    <div className="p_box"><img src={item.images[0] ? item.images[0] : SmallTruck} alt="" height={100} width={100} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    }
                                </i>


                            </span>
                        </div>
                        <div className="Reject_field">
                            <div className="office_radio">
                                <div className="radio_sec">
                                    <input id="radio-1" className="radio-custom" name="radio-group" type="radio" value="Pick Up" checked={type == 'Pick Up' ? true : false}
                                        onChange={_TypeChange} />
                                    <label for="radio-1" className="radio-custom-label" style={{ color: "#000" }}>Assign Vehicle</label>
                                </div>
                                <div className="radio_sec">
                                    <input id="radio-2" className="radio-custom" name="radio-group" type="radio" value="Send GST Number" checked={type == 'Send GST Number' ? true : false} onChange={_TypeChange} />
                                    <label for="radio-2" className="radio-custom-label">Send GST Number</label>
                                </div>
                                <div className="radio_sec">
                                    <input id="radio-3" className="radio-custom" name="radio-group" type="radio" value="Send Unique ID" checked={type == 'Send Unique ID' ? true : false} onChange={_TypeChange} />
                                    <label for="radio-3" className="radio-custom-label">Send Unique ID</label>
                                </div>
                            </div>
                            {type == 'Pick Up' &&
                                <>
                                    {formValue.assigned_vehicle_id && <p className="track_device_txt">Tracking Device IMEI <span> &nbsp; {tracking_device_iemi}</span></p>}
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="office_field">
                                                <label>Select Vehicle</label>
                                                <Select
                                                    placeholder="Select Option"
                                                    onChange={setSelectedVehicleOption}
                                                    options={vehicleList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />

                                            </div>

                                            {errorValue.assigned_vehicle_id && (
                                                <span className="error">
                                                    {errorValue.assigned_vehicle_id}
                                                </span>
                                            )}
                                            {(vehicleList.length == 0) && (
                                                <span className="error">
                                                    {vehicleUnavalableError}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="office_field">
                                                <label>Select Driver</label>
                                                <Select
                                                    placeholder="Select Option"
                                                    onChange={(e) =>
                                                        setFormValue((prevFormValue) => {
                                                            return { ...prevFormValue, 'assigned_driver_id': leftTrim(e.value) };
                                                        })}
                                                    options={driverList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />
                                            </div>
                                            {errorValue.assigned_driver_id && (
                                                <span className="error">
                                                    {errorValue.assigned_driver_id}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="office_field">
                                                <label>Loading Date</label>
                                                <input type="date"
                                                    placeholder="Select Date"
                                                    name="start_date" value={formValue.start_date}
                                                    min={moment(new Date()).format("YYYY-MM-DD")}
                                                    onChange={formChange}
                                                    // onBlur={updateFormTouchValue}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                />
                                                <span className="verify_sec"></span>
                                            </div>
                                            {errorValue.start_date && (
                                                <span className="error">
                                                    {errorValue.start_date}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="office_field">
                                                <label>Loading Time (Optional)</label>
                                                <input type="time" placeholder="Select Time" name="start_time"
                                                    value={optionalFields.start_time}
                                                    onChange={(e) => {
                                                        setoptionalFields((prevFormValue) => {
                                                            return { ...prevFormValue, 'start_time': e.target.value };
                                                        });

                                                    }} />
                                                <span className="verify_sec"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="office_field">
                                                <label>Additional Notes</label>
                                                <input type="text" placeholder="Enter Note" name="additional_notes" value={optionalFields.additional_notes} onChange={(e) => {
                                                    setoptionalFields((prevFormValue) => {
                                                        return { ...prevFormValue, 'additional_notes': e.target.value };
                                                    });

                                                }} />
                                            </div>
                                        </div>


                                        <div className="col-sm-6 offset-sm-3">
                                            <div className="login_btn">

                                                <button type="submit" value="get Started"
                                                    disabled={submitDisable ? true : false}
                                                    style={{ background: '#000' }} onClick={validation}>Confirm & Assign</button></div>
                                        </div>
                                    </div>
                                </>
                            }
                            {type == 'Send GST Number' &&
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="office_field">
                                            <label >GST Number</label>
                                            <input type="text" placeholder="Enter GST Number" name="gstno"
                                                maxLength={15}
                                                minLength={15}
                                                onChange={formGstChange}
                                                value={formGstValue.gstno ? formGstValue.gstno.toUpperCase() : ""}
                                                error={errorValue.gstno ? "true" : "false"}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                            />
                                        </div>
                                        {(errorValue.gstno) && (
                                            <span className="error">
                                                {errorValue.gstno}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="office_field">
                                            <label>Additional Notes</label>
                                            <input type="text" placeholder="Enter Note"
                                                name="additional_notes"
                                                onChange={formGstChange}
                                                value={formGstValue.additional_notes}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="create_sub">

                                            <label className="check_term" htmlFor="text-input">
                                                <input id="cb1" type="checkbox" name="vehicle_details"
                                                    onChange={formGstChange}
                                                    defaultChecked={formGstValue?.vehicle_details}
                                                /> Will add vehicles detail later</label>

                                        </div>
                                    </div>

                                    <div className="col-sm-6 offset-sm-3">
                                        <div className="login_btn">
                                            <button type="submit" value="get Started"
                                                style={{ background: '#000' }} onClick={validation}>Confirm & Assign</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {type == 'Send Unique ID' &&
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="office_field">
                                            <label >Unique ID </label>
                                            <input type="text" placeholder="Enter Unique ID" name="unique_id"
                                                onChange={formUniqueChange}
                                                value={formUniqueValue.unique_id ? formUniqueValue.unique_id.toUpperCase() : ""}
                                                error={errorValue.unique_id ? "true" : "false"}
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                            />
                                        </div>
                                        {(!formUniqueValue.unique_id && errorValue.unique_id) && (
                                            <span className="error">
                                                {errorValue.unique_id}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="office_field">
                                            <label>Additional Notes</label>
                                            <input type="text" placeholder="Enter Note"
                                                name="additional_notes"
                                                onChange={formUniqueChange}
                                                value={formUniqueValue.additional_notes}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="create_sub">

                                            <label className="check_term" htmlFor="text-input">
                                                <input id="cb1" type="checkbox" name="vehicle_details"
                                                    onChange={formUniqueChange}
                                                    checked={formUniqueValue?.vehicle_details}
                                                /> Will add vehicles detail later</label>

                                        </div>
                                    </div>

                                    <div className="col-sm-6 offset-sm-3">
                                        <div className="login_btn">
                                            <button type="submit" value="get Started"
                                                style={{ background: '#000' }} onClick={validation}>Confirm & Assign</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </section>


                </div>
            </Modal>
        </>
    )
}