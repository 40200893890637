/**
 * @file_purpose  page for showing landing page
 * @author Sarmistha Mondal
 * @Date_Created 18/03/2024
 * @Date_Modified 
 */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import how_bg from "../../assets/images/how_bg.png";
import abt_car from "../../assets/images/abt_car.png";
import Group_29511 from "../../assets/images/Group 29511.png";
import appImg from "../../assets/images/app.png";
import img1 from "../../assets/images/1.png";
import img2 from "../../assets/images/2.png";
import img3 from "../../assets/images/3.png";
import quoteImg from "../../assets/images/quote.png";
import logo from "../../assets/images/logo.png";
import display from "../../assets/images/display.png";
import bigImg from "../../assets/images/b1.png";
import bigImg1 from "../../assets/images/b2.png";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim, checkLength
} from "../../Utility/Helper";
import { Constants, ERROR_MESSAGE, REGEX } from "../../Utility/ConstantData";
import Service from "../../apis/Service";
import RouteURL from "../../apis/RouteURL";
import LandingPageFooter from "./landingPageFooter";


export default function LandingPage() {
    const navigate = useNavigate();
    const [stateUpdate, setstateUpdate] = useState(false)
    const [formValue, setFormValue] = useState({
        firstName: "",
        lastName: "", email: "", phone: "", msg: ""
    });
    const [errorValue, setErrorValue] = useState({
        firstName: "",
        lastName: "", email: "", phone: "", msg: ""
    });
    const [formTouchValue, setFormTouchValue] = useState({
        firstName: false,
        lastName: false, email: false, phone: false, msg: false
    });
    const formChange = (event) => {
        const { name, value } = event.target;
        // console.log(name, value);
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });


    };
    useEffect(() => { localStorage.clear(); },[]);
    
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !formValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (formValue[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)

                };
            });
        }
    };
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });


        }

    };
    useEffect(() => {
        if (formValue && stateUpdate == false) {
            // console.log('ok1');
            checkError("firstName");
            checkError("lastName");
            checkError("email");
            checkError("phone");
            checkError("msg");
        }

    }, [formValue]);
    const validation = (e) => {
        e.preventDefault();
        // console.log('ok');
        setFormTouchValue(markAllAsTouched(formTouchValue));
        setFormValue(leftTrimAllValue(formValue));
        checkError("firstName");
        checkError("lastName");
        checkError("email");
        checkError("phone");
        checkError("msg");
        if (isFormValid(formValue)) {
            _formSubmit();
        }
    }

    /**
   * @author Sarmistha Mondal
   * @Date_Created 18/03/2024
   * @Date_Modified 
   * @function async
   * @functionName _formSubmit
   * @functionPurpose this function for contact.
   *
   * @functionParam {}
   *
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
    const _formSubmit = () => {
        let params = JSON.stringify({
            first_name: formValue.firstName,
            last_name: formValue.lastName,
            contact_number: formValue.phone,
            email: formValue.email,
            message: formValue.msg,
        });
        Service.apiPostCallRequest(RouteURL.contactUs, params).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setFormValue({
                    firstName: "", lastName: "", email: "", phone: "", msg: ""
                });
                setstateUpdate(true)
                toast.success(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });
    }
    return (
        <>
            <ToastContainer />
            <header className="header_sec1">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="header_inn1">
                                <div className="row">
                                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-9">
                                        <div className="header_inn_lt">

                                            <div className="menu_sec"  >
                                                <nav className="navbar navbar-expand-lg navbar-light">
                                                    <div className="container-fluid">
                                                        <Link className="navbar-brand" to="/">
                                                            <img src={logo} alt="" /></Link>
                                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#navbarSupportedContent"
                                                            aria-controls="navbarSupportedContent" aria-expanded="false"
                                                            aria-label="Toggle navigation">
                                                            <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
                                                        </button>


                                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
                                                                <li className="nav-item">
                                                                    <Link className="nav-link active" aria-current="page"
                                                                        to={'/'}>Home</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="#about">About us</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="nav-link" to="/terms-and-conditions">Terms & Condition</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="#how">How it works</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="#contact">Contact us</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </nav>





                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 d-none d-md-block">
                                        <div className="header_inn_rt">
                                            <ul>
                                                <li><a href=""><i className="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-instagram"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-twitter"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="banner_sec">
                <div className="banner_main">
                    <div className="banner_inn">
                        <img className="w-100" src={bigImg} alt="" />
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="banner_txt">
                                        <h2>Shipgudds</h2>
                                        <p>Shipping made easy </p>
                                        <Link to="/type-of-registration">Join Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>

            <section className="about_sec" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1">
                            <div className="about_inn">
                                <h6>ABOUT US</h6>
                                <p>We offer one-stop commercial or industrial goods moving & shipping solutions across India.
                                </p>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="about_lt">
                                            <img src={abt_car} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="about_rt">
                                            <p>At ShipGudds, we are dedicated to facilitating the seamless and effective transportation of commercial and industrial goods across India. We prioritize excellence, timely deliveries, and utilize advanced tracking technology to ensure reliability. No matter where your shipments go, trust ShipGudds to be there every step of the way!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how_works" id="how"
                style={{ background: `url(${how_bg})` }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1">
                            <div className="how_work_inn">
                                <h6>HOW IT WORKS</h6>
                                <div className="row">
                                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                        <div className="how_lt">
                                            <p>Our app allows you to create a user profile where you can enlist essential information like types of goods, transportation requirements, location, weight, customer profile, travel area map etc and so on. Based on your details, we will arrange the appropriate transportation facility to ship your items to your desired location on time.
                                            </p>
                                            <div className="gplay">
                                                <div><a href=""><img src={Group_29511} alt="" /></a></div>
                                                <div><a href=""><img src={appImg} alt="" /></a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-xxl-7 col-xl-7 col-lg-7 col-md-12 ">
                                        <div className="how_rt">
                                            <img src={display} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feedback_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1">
                            <div className="feedback_inn">
                                <h6>CLIENT'S FEEDBACK</h6>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="feedback_box">
                                            <div className="feedback_upper">
                                                <img src={img1} alt="" />
                                            </div>
                                            <div className="feedback_dwn">
                                                <p>Looking forward to fun multiplayer games on GrandGaming.com </p>
                                                <p>Always wanted to play games along with my friends who are on different devices and locations. GrandGaming has solved that need.</p>
                                                <span>Tarun Kumar</span>
                                                <div className="quote">
                                                    <img src={quoteImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="feedback_box">
                                            <div className="feedback_upper">
                                                <img src={img2} alt="" />
                                            </div>
                                            <div className="feedback_dwn">
                                                <p>Looking forward to fun multiplayer games on GrandGaming.com </p>
                                                <p>Always wanted to play games along with my friends who are on different devices and locations. GrandGaming has solved that need.</p>
                                                <span>Jyoti</span>
                                                <div className="quote">
                                                    <img src={quoteImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="feedback_box">
                                            <div className="feedback_upper">
                                                <img src={img3} alt="" />
                                            </div>
                                            <div className="feedback_dwn">
                                                <p>Looking forward to fun multiplayer games on GrandGaming.com </p>
                                                <p>Always wanted to play games along with my friends who are on different devices and locations. GrandGaming has solved that need.</p>
                                                <span>Bhoomika</span>
                                                <div className="quote">
                                                    <img src={quoteImg} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact_sec" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1">
                            <div className="contact_inn">
                                <h6>GET IN TOUCH</h6>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="con_sec">
                                            <input type="text"
                                                placeholder="First name"
                                                name="firstName"
                                                value={formValue.firstName} onChange={formChange}
                                                onBlur={updateFormTouchValue}
                                                error={errorValue.firstName ? "true" : "false"}
                                            />
                                            {errorValue.firstName && (
                                                <span className="error">
                                                    {errorValue.firstName}
                                                </span>
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-sm-6">
                                        <div className="con_sec">
                                            <input type="text"
                                                placeholder="Last Name"
                                                name="lastName"
                                                value={formValue.lastName}
                                                onChange={formChange}
                                                onBlur={updateFormTouchValue}
                                                error={errorValue.lastName ? "true" : "false"} />
                                            {errorValue.lastName && (
                                                <span className="error">
                                                    {errorValue.lastName}
                                                </span>
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-sm-6">
                                        <div className="con_sec">
                                            <input type="tel"
                                                placeholder="Contact No."
                                                name="phone"
                                                maxLength={10}
                                                pattern="[0-9]*"
                                                onKeyDown={e => checkLength(e)}
                                                value={formValue.phone} onChange={formChange}
                                                onBlur={updateFormTouchValue}
                                                error={errorValue.phone ? "true" : "false"} />
                                            {errorValue.phone && (
                                                <span className="error">
                                                    {errorValue.phone}
                                                </span>
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-sm-6">
                                        <div className="con_sec">
                                            <input type="email"
                                                placeholder="Email"
                                                name="email"
                                                value={formValue.email}
                                                onChange={formChange}
                                                onBlur={updateFormTouchValue}
                                                error={errorValue.email ? "true" : "false"} />
                                            {errorValue.email && (
                                                <span className="error">
                                                    {errorValue.email}
                                                </span>
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-sm-12">
                                        <div className="con_sec">
                                            <textarea
                                                placeholder="Massage"
                                                name="msg"
                                                value={formValue.msg}
                                                onChange={formChange}
                                                onBlur={updateFormTouchValue}
                                                error={errorValue.msg ? "true" : "false"}></textarea>
                                            {errorValue.msg && (
                                                <span className="error">
                                                    {errorValue.msg}
                                                </span>
                                            )}
                                        </div>

                                    </div>
                                    <div className="col-sm-12">
                                        <div className="con_sec text-center mb-0">
                                            <button type="button" value="submit" onClick={validation}>Submit</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <LandingPageFooter/>
        </>
    )
}