/**
 * @file_purpose  page for payment methods
 * @author Sarmistha Mondal
 * @Date_Created 28/06/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim, checkLength
} from "../../../Utility/Helper";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-js-loader";
import Verified from "../../../assets/images/checkmark.png";

export default function Payment_Details(props) {
    const modalRef = useRef(null);
    let formData = new FormData();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(0);
    const [is_bank_account_number_verified, setIsBankAccountNumberVerified] = useState(0);
    const [is_upi_payment_enabled_verified, setIsUpiPaymentVerified] = useState(0);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [upadate, setUpdate] = useState(false);
    const [verifyDisable, setVerifyDisable] = useState(false);
    const [checkOcr, setCheckOcr] = useState();
    const [formValueForBank, setFormValueForBank] = useState({
        is_bank_payment_enabled: "",
        account_holder_name: "",
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        branch_name: ""
    });
    const [formValueForUpi, setFormValueForUpi] = useState({
        is_upi_payment_enabled: "",
        upi_id: "",
    });
    const [formValueForCash, setFormValueForCash] = useState({
        is_cash_payment_enabled: ""
    });
    const [errorValue, setErrorValue] = useState({
        chequeOCR: "",
        account_holder_name: "",
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        branch_name: ""
    });
    const [upiErrorValue, setUpiErrorValue] = useState({ upi_id: "", })
    const [formTouchValueForBank, setFormTouchValueForBank] = useState({
        chequeOCR: false,
        account_holder_name: false,
        account_number: false,
        ifsc_code: false,
        bank_name: false,
        branch_name: false
    });
    const [formTouchValueForUpi, setFormTouchValueForUpi] = useState({ upi_id: false })
    const [inputDisable, setInputDisable] = useState({
        account_holder_name: "",
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        branch_name: ""
    })

    // focus within a specific area
    useEffect(() => {

        const modalElement = modalRef.current;
        //add any focusable HTML element you want to include to this string
        const focusableElements = modalElement.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        const handleTabKeyPress = (event) => {
            if (event.key === "Tab") {
                if (event.shiftKey && document.activeElement === firstElement) {
                    event.preventDefault();
                    lastElement.focus();
                } else if (
                    !event.shiftKey &&
                    document.activeElement === lastElement
                ) {
                    event.preventDefault();
                    firstElement.focus();
                }
            }
        };

        const handleEscapeKeyPress = (event) => {
            if (event.key === "Escape") {
                // props.onHide();
            }
        };

        modalElement.addEventListener("keydown", handleTabKeyPress);
        modalElement.addEventListener("keydown", handleEscapeKeyPress);

        return () => {
            modalElement.removeEventListener("keydown", handleTabKeyPress);
            modalElement.removeEventListener("keydown", handleEscapeKeyPress);
        };

    }, []);

    useEffect(() => {
        if (str2bool(formValueForUpi.is_upi_payment_enabled) === true) {
            if (!formValueForUpi.upi_id) {
                return setUpiErrorValue({ upi_id: "UPI id is required" })
            } else {
                if (formValueForUpi.upi_id.match(REGEX['upi_id'])) setUpiErrorValue({ upi_id: "" })
                else return setUpiErrorValue({ upi_id: "Please enter a valid upi id" })
            }
        } else setUpiErrorValue('');       

    }, [formValueForBank, formValueForUpi]);

    /* on change input function */
    const formChange = (event) => {
        const { name, value, checked } = event.target;
        if (name == 'is_bank_payment_enabled') {
            setFormValueForBank((prevValue) => {
                return { ...prevValue, [name]: checked };
            });
        }
        else {
            setFormValueForBank((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };

    /* select payment type*/ 
    const formChangeForOthers = (event) => {
        const { name, value, checked } = event.target;
        if (name == 'is_upi_payment_enabled') {
            setFormValueForUpi((prevValue) => {
                return { ...prevValue, [name]: checked };
            });

        }
        else if (name == 'is_cash_payment_enabled') {
            setFormValueForCash((prevValue) => {
                return { ...prevValue, [name]: checked };
            });
        }
        else {
            setFormValueForUpi((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });

        }

    };
    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValueForBank[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: formValueForBank[targetName] == "" ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (formValueForBank[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)
                };
            });
        }
    };

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValueForBank[name]) {
            setFormTouchValueForBank((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormTouchValueForBank((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
        if (!formTouchValueForUpi[name]) {           
            setFormTouchValueForUpi((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormTouchValueForUpi((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };


    useEffect(() => {
        _onboardingPaymantMethod();
    }, []);


    /**
       * @author Sarmistha Mondal
       * @Date_Created 27/11/2023
       * @Date_Modified 
       * @function async
       * @functionName _onboardingPaymantMethod
       * @functionPurpose this function for onboarding Office Address List.
       *
       * @functionParam {}
       *
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _onboardingPaymantMethod = () => {
        Service.apiPostTokenCallRequest(RouteURL.userPaymentInfo, {}).then((response) => {
           
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data?.paymentMethods != undefined) {
                    setFormValueForBank((prevValue) => {
                        return {
                            ...prevValue,
                            is_bank_payment_enabled: response.data.paymentMethods.is_bank_payment_enabled == 1 ? true : false,
                            account_holder_name: response.data.paymentMethods.account_holder_name,
                            account_number: response.data.paymentMethods.account_number,
                            ifsc_code: response.data.paymentMethods.ifsc_code,
                            bank_name: response.data.paymentMethods.bank_name,
                        };
                    });
                    if (response.data.paymentMethods.is_bank_payment_enabled == 1) {
                        localStorage.setItem("account_number", response.data.paymentMethods.account_number); setIsBankAccountNumberVerified(1)
                    }
                    else setIsBankAccountNumberVerified(0)

                    setFormValueForUpi((prevValue) => {
                        return {
                            ...prevValue,
                            is_upi_payment_enabled: response.data.paymentMethods.is_upi_payment_enabled == 1 ? true : false,
                            upi_id: response.data.paymentMethods.upi_id,
                        };
                    });
                    if (response.data.paymentMethods.is_upi_payment_enabled == 1) {
                        localStorage.setItem("upiaccount_number", response.data.paymentMethods.upi_id);
                        setIsUpiPaymentVerified(1)
                    }
                    else setIsUpiPaymentVerified(0)
                    setFormValueForCash((prevValue) => {
                        return {
                            ...prevValue,
                            is_cash_payment_enabled: response.data.paymentMethods.is_cash_payment_enabled == 1 ? true : false
                        };
                    })
                }
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /**
     * @author Sarmistha Mondal
     * @Date_Created 27.11.2023
     * @Date_Modified 
     * @function async
     * @functionName _verifyBankAccountNumber
     * @functionPurpose this function for
     * verify bank a/c no.
     *
     *  @functionParam {payload object: account_number,ifsc_code}
     * 
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _verifyBankAccountNumber = () => {
        let params = JSON.stringify({
            account_number: formValueForBank.account_number,
            ifsc_code: formValueForBank.ifsc_code
        })
        setVerifyDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.verifyBankAccountnumber, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    localStorage.setItem("account_number", formValueForBank.account_number)
                    setIsBankAccountNumberVerified(1)

                    let res = response.data.ocrDetails;
                    localStorage.setItem("account_number", res.account_number)
                    setFormValueForBank((prevFormValue) => {
                        return {
                            ...prevFormValue,
                            account_holder_name: res.account_holder_name,
                            account_number: res.account_number,
                            ifsc_code: res.ifsc_code,
                            bank_name: res.bank_name,
                            branch_name: res.branch_name
                        };

                    });
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });

                } else {
                    if (response.statusCode == 406) {
                        navigate("/shipper-subscription");
                    }
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });
                }
            }).catch((error) => {
                setVerifyDisable(false)
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 27.11.2023
    * @Date_Modified 
    * @function async
    * @functionName _verifyverifyUPIId
    * @functionPurpose this function for
    * verify upi a/c.
    *
    *  @functionParam {payload object: upi_id}
    * 
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _verifyverifyUPIId = () => {
        setVerifyDisable(true)
        let params = JSON.stringify({
            upi_id: formValueForUpi.upi_id,
        })
        Service.apiPostTokenCallRequest(RouteURL.verifyUpid, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    localStorage.setItem("upiaccount_number", formValueForUpi.upi_id)
                    setIsUpiPaymentVerified(1)
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });

                }
            }).catch((error) => {
                setVerifyDisable(false)
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    const str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        } else {
            if (value === "true") return true;
            if (value === "false") return false;
        }
        return value;
    }


    const accountValidation = () => {
        setVerifyDisable(true)
        toast.error('Please verfy your a/c no. ', {
            position: toast.POSITION.BOTTOM_RIGHT,
            onClose: () => setVerifyDisable(false)
        });
    }
    const upiAccountValidation = () => {
        
        setVerifyDisable(true);
        toast.error('Please verfy your upi id. ', {
            position: toast.POSITION.BOTTOM_RIGHT,
            onClose: () => setVerifyDisable(false)
        });
    }

    const _onboardingPaymentSubmit = (e) => {
        e.preventDefault();
    
        setFormTouchValueForBank(markAllAsTouched(formTouchValueForBank));
        
        let formIsValid = true;
        if ((str2bool(!formValueForBank.is_bank_payment_enabled) || str2bool(formValueForBank.is_bank_payment_enabled) == '') && (str2bool(!formValueForUpi.is_upi_payment_enabled) || str2bool(formValueForUpi.is_upi_payment_enabled) == "") && (str2bool(!formValueForCash.is_cash_payment_enabled) || str2bool(formValueForCash.is_cash_payment_enabled) == '')) {
            setSubmitDisable(true)
           
            toast.error('Please select payment method! ', {
                position: toast.POSITION.BOTTOM_RIGHT,
                onClose: () => setSubmitDisable(false)
            });

        } else {

            if (str2bool(formValueForBank.is_bank_payment_enabled) == true && isFormValid(formValueForBank) && (str2bool(!formValueForUpi.is_upi_payment_enabled) || str2bool(formValueForUpi.is_upi_payment_enabled) == '') && (str2bool(!formValueForCash.is_cash_payment_enabled) || str2bool(formValueForCash.is_cash_payment_enabled) == '')) {
                formIsValid = false;
                checkError("account_holder_name")
                checkError("account_number")
                checkError("ifsc_code")
                checkError("bank_name")
               
                if (isFormValid(formValueForBank) && is_bank_account_number_verified == 1) {
                 
                    formIsValid = false;
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 1,
                        account_holder_name: formValueForBank.account_holder_name,
                        account_number: formValueForBank.account_number,
                        ifsc_code: formValueForBank.ifsc_code,
                        bank_name: formValueForBank.bank_name,
                        branch_name: formValueForBank.branch_name,
                        is_upi_payment_enabled: 0,
                        upi_id: "",
                        is_cash_payment_enabled: 0
                    });
                    _submitPayment(params);
                } else {
                    if (!checkOcr) {
                        setErrorValue((prevValue) => {
                            return { ...prevValue, chequeOCR: 'Please upload cheque ' };
                        })
                    } else accountValidation();
                  
                }

            } else if (str2bool(formValueForUpi.is_upi_payment_enabled) == true && isFormValid(formValueForUpi) && (str2bool(!formValueForBank.is_bank_payment_enabled) || str2bool(formValueForBank.is_bank_payment_enabled) == "") && (str2bool(!formValueForCash.is_cash_payment_enabled) || str2bool(formValueForCash.is_cash_payment_enabled) == '')) {

                if (!formValueForUpi.upi_id) {
                    return setUpiErrorValue({ upi_id: "UPI id is required" })
                } else if (!formValueForUpi.upi_id.match(REGEX['upi_id'])) {
                    setUpiErrorValue({ upi_id: "Please enter a valid upi id" })
                } else {

                    if (isFormValid(formValueForUpi) && is_upi_payment_enabled_verified == 1) {
                        formIsValid = false;
                        
                        let params = JSON.stringify({
                            is_bank_payment_enabled: 0,
                            account_holder_name: "",
                            account_number: "",
                            ifsc_code: "",
                            bank_name: "",
                            branch_name: "",
                            is_upi_payment_enabled: 1,
                            upi_id: formValueForUpi.upi_id,
                            is_cash_payment_enabled: 0
                        });
                        _submitPayment(params)
                    } else {
                        setVerifyDisable(true)
                        upiAccountValidation()
                    }
                }

            }
            else if (str2bool(formValueForCash.is_cash_payment_enabled) == true && (str2bool(!formValueForBank.is_bank_payment_enabled) || str2bool(formValueForBank.is_bank_payment_enabled) == '') && (str2bool(!formValueForUpi.is_upi_payment_enabled) || str2bool(formValueForUpi.is_upi_payment_enabled) == '')) {
               
                formIsValid = false;
                let params = JSON.stringify({
                    is_bank_payment_enabled: 0,
                    account_holder_name: "",
                    account_number: "",
                    ifsc_code: "",
                    bank_name: "",
                    branch_name: "",
                    is_upi_payment_enabled: 0,
                    upi_id: "",
                    is_cash_payment_enabled: 1
                });
                _submitPayment(params)
            } else if (str2bool(formValueForCash.is_cash_payment_enabled) == true && str2bool(formValueForBank.is_bank_payment_enabled) == true && str2bool(formValueForUpi.is_upi_payment_enabled) == true) {
                if (isFormValid(formValueForBank) && isFormValid(formValueForUpi) && is_bank_account_number_verified == 1 && is_upi_payment_enabled_verified == 1) {
                  
                    formIsValid = false;
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 1,
                        account_holder_name: formValueForBank.account_holder_name,
                        account_number: formValueForBank.account_number,
                        ifsc_code: formValueForBank.ifsc_code,
                        bank_name: formValueForBank.bank_name,
                        branch_name: formValueForBank.branch_name,
                        is_upi_payment_enabled: 1,
                        upi_id: formValueForUpi.upi_id,
                        is_cash_payment_enabled: 1
                    });
                    _submitPayment(params)
                } else {
                    if (is_bank_account_number_verified != 1) {
                        formIsValid = false;
                        if (!checkOcr) {
                            setErrorValue((prevValue) => {
                                return { ...prevValue, chequeOCR: 'Please upload cheque ' };
                            })
                        } else accountValidation();
                    }
                    if (is_upi_payment_enabled_verified != 1) { formIsValid = false; setVerifyDisable(true); upiAccountValidation() }
                }
            } else if (str2bool(formValueForBank.is_bank_payment_enabled) == true && str2bool(formValueForUpi.is_upi_payment_enabled) == true && (str2bool(!formValueForCash.is_cash_payment_enabled) || str2bool(formValueForCash.is_cash_payment_enabled) == '')) {
                formIsValid = false;
                if (isFormValid(formValueForBank) && isFormValid(formValueForUpi) && is_bank_account_number_verified == 1 && is_upi_payment_enabled_verified == 1) {
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 1,
                        account_holder_name: formValueForBank.account_holder_name,
                        account_number: formValueForBank.account_number,
                        ifsc_code: formValueForBank.ifsc_code,
                        bank_name: formValueForBank.bank_name,
                        branch_name: formValueForBank.branch_name,
                        is_upi_payment_enabled: 1,
                        upi_id: formValueForUpi.upi_id,
                        is_cash_payment_enabled: 0
                    });
                    _submitPayment(params)
                } else {
                    if (is_bank_account_number_verified != 1) {
                        formIsValid = false;
                        // 
                        if (!checkOcr) {
                            setErrorValue((prevValue) => {
                                return { ...prevValue, chequeOCR: 'Please upload cheque ' };
                            })
                        } else accountValidation();
                    }
                    if (is_upi_payment_enabled_verified != 1) { formIsValid = false; upiAccountValidation() }
                }

            } else if (str2bool(formValueForBank.is_bank_payment_enabled) == true && (str2bool(!formValueForUpi.is_upi_payment_enabled) || str2bool(formValueForUpi.is_upi_payment_enabled) == '') && str2bool(formValueForCash.is_cash_payment_enabled) == true) {
                formIsValid = false;
                if (isFormValid(formValueForBank) && is_bank_account_number_verified == 1) {
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 1,
                        account_holder_name: formValueForBank.account_holder_name,
                        account_number: formValueForBank.account_number,
                        ifsc_code: formValueForBank.ifsc_code,
                        bank_name: formValueForBank.bank_name,
                        branch_name: formValueForBank.branch_name,
                        is_upi_payment_enabled: 0,
                        upi_id: "",
                        is_cash_payment_enabled: 1
                    });
                    _submitPayment(params)
                } else accountValidation()
            } else if ((str2bool(!formValueForBank.is_bank_payment_enabled) || str2bool(formValueForBank.is_bank_payment_enabled) == '') && str2bool(formValueForUpi.is_upi_payment_enabled) == true && str2bool(formValueForCash.is_cash_payment_enabled) == true) {
                formIsValid = false;
                if (isFormValid(formValueForUpi) && is_upi_payment_enabled_verified == 1) {
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 0,
                        account_holder_name: "",
                        account_number: "",
                        ifsc_code: "",
                        bank_name: "",
                        branch_name: "",
                        is_upi_payment_enabled: 1,
                        upi_id: formValueForUpi.upi_id,
                        is_cash_payment_enabled: 1
                    });
                    _submitPayment(params)
                } else { setVerifyDisable(true); upiAccountValidation() }

            }
            return formIsValid;
        }

    }

    /**
       * @author Sarmistha Mondal
       * @Date_Created 27.11.2023
       * @Date_Modified 
       * @function async
       * @functionName _submitPayment
       * @functionPurpose this function for
       * submit payment
       *
       *  @functionParam {payload object:is_bank_payment_enabled,account_holder_name,account_number,ifsc_code,bank_name,branch_name,
       *  is_upi_payment_enabled,upi_id,is_cash_payment_enabled}
       * 
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _submitPayment = (params) => {
        setVerifyDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.paymentMethodAdd, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 26.02.2024
      * @Date_Modified 
      * @function async
      * @functionName _submitOcrCheque
      * @functionPurpose this function for
      * shipper ocr upload
      *
      *  @functionParam {payload object: ocr_files}
      * 
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */

    const _submitOcrCheque = (file) => {
        setLoader(1)
        formData.set(`ocr_files`, file);

        setCheckOcr(file)
        Service.apiPostCallFormDataRequest(RouteURL.ocrPaymentChequeForShipper, formData).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let res = response.data.ocrDetails;
                    localStorage.setItem("account_number", res.account_number)
                    setFormValueForBank((prevFormValue) => {
                        return {
                            ...prevFormValue,
                            account_holder_name: res.account_holder_name,
                            account_number: res.account_number,
                            ifsc_code: res.ifsc_code,
                            bank_name: res.bank_name,
                            branch_name: res.branch_name
                        };

                    });
                    setIsBankAccountNumberVerified(1)
                    setErrorValue((prevErrorValue) => {
                        return { ...prevErrorValue, chequeOCR: '' }
                    })

                    setLoader(0)


                } else {
                    setLoader(0);
                    setIsBankAccountNumberVerified(0)
                    setErrorValue((prevErrorValue) => {
                        return { ...prevErrorValue, chequeOCR: '' }
                    })
                    setInputDisable((prevFormValue) => {
                        return {
                            ...prevFormValue,
                            account_number: true,
                            ifsc_code: true,
                        };

                    })
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });

    }

    return (
        <>
            <div className="sideright_menu">
                <div className="shipment_menu_rt">
                    <div className="godown_upper_hed">
                        <section className="bg_color_sec">
                            <div className="office_address_sec">

                                <form ref={modalRef}>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="office_address_lt ">
                                                <div className="bank_pay">
                                                    <div><h6>Bank Payments</h6></div>
                                                    <div><input id="wp-comment-cookies-consent" type="checkbox" name="is_bank_payment_enabled"
                                                        checked={formValueForBank.is_bank_payment_enabled == 1 ? true : false}
                                                        onChange={(e) => { formChange(e); setUpdate(!upadate) }}></input></div>
                                                </div>
                                                <div className="uploadbtn">
                                                    <div className="choose_file d-flex align-items-center justify-content-center">
                                                        <div className="upload-btn-wrapper">
                                                            <label for="banner_image">
                                                                <button className="btn-custom">
                                                                    <input type="file" accept=".jpg,.jpeg,.png" onChange={(e) => { _submitOcrCheque(e.target.files[0]); e.target.value = '' }} />
                                                                    <i className="fa-solid fa-upload"></i> Upload Cheque
                                                                </button>
                                                            </label>
                                                            {loader == 1 ? <Loader type="spinner-default" bgColor={'white'} color={'white'} size={30} /> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="error" style={{ alignContent: 'center' }}>
                                                    {errorValue.chequeOCR}
                                                </span>

                                                <form>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="office_field">
                                                                <label>Account Holder Name</label>
                                                                <input type="text" placeholder="A/C Holder Name"
                                                                    name="account_holder_name"
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    value={formValueForBank.account_holder_name}
                                                                    error={errorValue.account_holder_name ? "true" : "false"}
                                                                    disabled={inputDisable?.account_holder_name ? false : true} />

                                                            </div>
                                                            {/* {errorValue.account_holder_name && (
                                                    <span className="error">
                                                        {errorValue.account_holder_name}
                                                    </span>
                                                )} */}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="office_field">
                                                                <label>IFSC Code</label>
                                                                <input type="text"
                                                                    name="ifsc_code"
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    value={formValueForBank.ifsc_code ? formValueForBank.ifsc_code.toUpperCase() : ""}
                                                                    maxLength={11}
                                                                    error={errorValue.ifsc_code ? "true" : "false"}
                                                                    disabled={inputDisable?.ifsc_code ? false : true}
                                                                    placeholder={`${inputDisable.ifsc_code == true ? 'Enter ' : ''}` + `IFSC Code`} />
                                                            </div>
                                                            {errorValue.ifsc_code && (
                                                                <span className="error">
                                                                    {errorValue.ifsc_code}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="office_field">
                                                                <label>Account Number</label>
                                                                <input type="number"
                                                                    placeholder={`${inputDisable.account_number == true ? 'Enter ' : ''}` + `A/C Number`}
                                                                    name="account_number" onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    maxLength={18}
                                                                    minLength={9}
                                                                    pattern="[0-9]*"
                                                                    onKeyDown={e => checkLength(e)}
                                                                    value={formValueForBank.account_number}
                                                                    error={errorValue.account_number ? "true" : "false"}
                                                                    disabled={inputDisable?.account_number ? false : true} />
                                                                <span className="verify_sec "

                                                                    style={{ color: formValueForBank.account_number ? ((localStorage.getItem("account_number") != formValueForBank.account_number) || is_bank_account_number_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        if (formValueForBank['account_number'].match(REGEX['account_number'])) {
                                                                            if (verifyDisable == false) _verifyBankAccountNumber(formValueForBank.account_number)
                                                                        }
                                                                    }}
                                                                >
                                                                    {(localStorage.getItem("account_number") == formValueForBank.account_number) && is_bank_account_number_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("account_number") == formValueForBank.account_number) && is_bank_account_number_verified == 1 ? 'verified' : 'verify'}


                                                                </span>
                                                            </div>
                                                            {errorValue.account_number && (
                                                                <span className="error">
                                                                    {errorValue.account_number}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="office_field">
                                                                <label>Bank Name</label>
                                                                <input type="text"
                                                                    name="bank_name"
                                                                    placeholder="Bank Name"
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    value={formValueForBank.bank_name}
                                                                    error={errorValue.bank_name ? "true" : "false"}
                                                                    disabled={inputDisable?.bank_name ? false : true}
                                                                />

                                                            </div>
                                                            {/* {errorValue.bank_name && (
                                                    <span className="error">
                                                        {errorValue.bank_name}
                                                    </span>
                                                )} */}
                                                        </div>



                                                        <div className="col-sm-6">
                                                            <div className="office_field">
                                                                <label>Branch Name</label>
                                                                <input type="text" placeholder="Enter Branch Name" onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    value={formValueForBank.branch_name}
                                                                    error={errorValue.branch_name ? "true" : "false"}
                                                                    disabled={inputDisable?.branch_name ? false : true} />
                                                            </div>
                                                            {/* {errorValue.branch_name && (
                                                    <span className="error">
                                                        {errorValue.branch_name}
                                                    </span>
                                                )} */}
                                                        </div>

                                                    </div>
                                                </form>

                                            </div>

                                        </div>
                                        <div className="col-sm-4">
                                            <div className="office_address_rt">
                                                <div className="vehicle_rt_box">
                                                    <div className="vehicle_txt">
                                                        <h6>UPI Payments
                                                            <input id="cb1" type="checkbox" name="is_upi_payment_enabled"
                                                                checked={formValueForUpi.is_upi_payment_enabled == 1 ? true : false}
                                                                onChange={(e) => { formChangeForOthers(e); setUpdate(!upadate) }}
                                                            />
                                                            
                                                        </h6>
                                                    </div>
                                                    <div className="office_field">
                                                        <label>UPI ID</label>
                                                        <input type="text" placeholder="Enter UPI ID" name="upi_id"
                                                            onChange={formChangeForOthers}
                                                            onBlur={updateFormTouchValue}
                                                            value={formValueForUpi.upi_id}
                                                            error={upiErrorValue.upi_id ? "true" : "false"} 
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}/>
                                                        <span className="verify_sec "
                                                            style={{ color: formValueForUpi.upi_id ? ((localStorage.getItem("upiaccount_number") != formValueForUpi.upi_id) || is_upi_payment_enabled_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                if (formValueForUpi['upi_id'].match(REGEX['upi_id'])) { if (verifyDisable == false) _verifyverifyUPIId() }
                                                            }}>
                                                            {(localStorage.getItem("upiaccount_number") == formValueForUpi.upi_id) && is_upi_payment_enabled_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("upiaccount_number") == formValueForUpi.upi_id) && is_upi_payment_enabled_verified == 1 ? 'verified' : 'verify'}

                                                        </span>
                                                    </div>
                                                    {upiErrorValue.upi_id && (
                                                        <span className="error">
                                                            {upiErrorValue.upi_id}
                                                        </span>
                                                    )}

                                                </div>

                                                <div className="vehicle_rt_box">
                                                    <div className="vehicle_txt">
                                                        <h6>Cash Payments <input id="wp-comment-cookies-consent" type="checkbox" name="is_cash_payment_enabled"
                                                            checked={formValueForCash.is_cash_payment_enabled == 1 ? true : false}
                                                            onChange={formChangeForOthers}></input></h6>
                                                    </div>
                                                </div>
                                                <div className="save_btn">
                                                    <button type="submit" value="Verify & Save" onClick={_onboardingPaymentSubmit} disabled={verifyDisable ? true : false}>Update</button>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )

}