/**
 * @file_purpose  page for renew subscription
 * @author Sarmistha Mondal
 * @Date_Created 27/06/2024
 * @Date_Modified 
 */
import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from "react-router-dom";
import { ERROR_MESSAGE, REGEX, Constants } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Modal from "react-bootstrap/Modal";

export default function ReNewSubscription(props) {
    const navigate = useNavigate();
    const [subscriptionList, setsubscriptionList] = useState([]);
    useEffect(() => {
        loadGetsubscriptionListList()
    }, []);

    /**
  * @author Sarmistha Mondal
  * @Date_Created 14/02/2024
  * @Date_Modified 
  * @function async
  * @functionName loadGetsubscriptionListList
  * @functionPurpose this function gets
  * Lists of subscription
  *
  *  @functionParam {payload object: user_type}
  * 
  * @functionSuccess Success status and message.
  *
  * @functionError {Boolean} error is there.
  * @functionError {String} message  Description message.
  */
    const loadGetsubscriptionListList = () => {
        let params = JSON.stringify({
            user_type: localStorage.getItem('user_type'),
        })
        Service.apiPostCallRequest(RouteURL.subscriptionList, params)
            .then((res) => {
                if (res.err === Constants.API_RESPONSE_STATUS_SUCCESS) {                   
                    setsubscriptionList(res.data.subscriptionList);
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
     * @author Sarmistha Mondal
     * @Date_Created 14/02/2024
     * @Date_Modified 
     * @function async
     * @functionName _submitUserSubscription
     * @functionPurpose for user subscription
     *
     *  @functionParam {payload object: subscription_id}
     * 
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _submitUserSubscription = (subscription_id) => {
        let params = JSON.stringify({
            subscription_id: subscription_id,
        })
        Service.apiPostTokenCallRequest(RouteURL.userSubscription, params)
            .then((response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    props.onHide('close')
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error) => {
              
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    return (
        <Modal
            show={(subscriptionList.length > 0) ? props.show : ''}
            onHide={props.onHide}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xl"
        >
            <div className="forgot_sec">
                <Modal.Header closeButton closeVariant={'white'}>
                    <h6 className="hed_txt">Your Opinion Matter to us !</h6>
                </Modal.Header>
                <section className="opinion_matter_sec">
                    <section className='bg_color_sec'>
                        <div className='subscription_sec'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='subscrip_top'>
                                            <h4>ReNewSubscription</h4>
                                            <h6>Choose Your Plan</h6>
                                        </div>
                                        <div className='subscrip_dwn'>
                                            <div className='row'>
                                                {subscriptionList.length > 0 && subscriptionList.map(list => (
                                                    <> <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                                        <div className="subscription_box">
                                                            <h6>Rs. {list?.price}/{list?.no_of_year} Year </h6>
                                                            <div className='sub_detail_txt'>
                                                                <p><div
                                                                    className="sample_note"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: list?.description,
                                                                    }}
                                                                /></p>
                                                               
                                                            </div>
                                                            <Link to="#" onClick={(e) => { e.preventDefault(); _submitUserSubscription(list.id) }}>Choose Your Plan <i className="fa-solid fa-chevron-right"></i></Link>
                                                        </div>
                                                    </div></>
                                                ))}


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </section>
            </div >
        </Modal >
    )
}
