
/**
 * @file_purpose  page for godown address
 * @author Sarmistha Mondal
 * @Date_Created 13/03/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim, removeSpecialCharacters,
    checkLength
} from "../../../Utility/Helper";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData"
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OnboardingLocationModal from "../../onboarding/onboardingLocation";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
const mapStyle = {
    height: '300px',
    width: '100%'
}

export default function AddGodownAddressList(props) {
    const mapRef = useRef();
    const modalRef = useRef(null);
    const [openModal, setopenModal] = useState(false)
    const [submitDisable, setSubmitDisable] = useState(false);
    const [formValue, setFormValue] = useState({
        address_name: '',
        address_location: '',
        contact_person_name: '',
        contact_number: '',

    });
    const [formValue1, setFormValue1] = useState({
        alternate_contact_number1: '',
        alternate_contact_number2: '',
    });
    const [errorValue, setErrorValue] = useState({
        address_name: '',
        address_location: '',
        contact_person_name: '',
        contact_number: '',
        alternate_contact_number1: '',
        alternate_contact_number2: '',
    });
    const [formTouchValue, setFormTouchValue] = useState({
        address_name: false,
        address_location: false,
        contact_person_name: false,
        contact_number: false,
        alternate_contact_number1: false,
        alternate_contact_number2: false,
    });
    const [map, setMap] = React.useState(null)
    const [defaultLocation, setDefaultLocation] = useState({
        lat: '',
        lng: ''
    })
    const [markerPosition, setMarkerPosition] = useState({
        lat: '',
        lng: ''
    })

   
    // focus within a specific area
    useEffect(() => {
       
            const modalElement = modalRef.current;
            //add any focusable HTML element you want to include to this string
            const focusableElements = modalElement.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event) => {
                if (event.key === "Tab") {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event) => {
                if (event.key === "Escape") {
                    // props.onHide();
                }
            };

            modalElement.addEventListener("keydown", handleTabKeyPress);
            modalElement.addEventListener("keydown", handleEscapeKeyPress);

            return () => {
                modalElement.removeEventListener("keydown", handleTabKeyPress);
                modalElement.removeEventListener("keydown", handleEscapeKeyPress);
            };
        
    }, []);


    useEffect(() => {
        if (formValue) {
            checkError("address_name");
            checkError("address_location");
            checkError("contact_person_name");
            checkError("contact_number");
        }
        if (!!formValue1.alternate_contact_number1 && !!formTouchValue.alternate_contact_number1) {
            checkError("alternate_contact_number1");
        } else if (!!formValue1.alternate_contact_number2 && !!formTouchValue.alternate_contact_number2) checkError("alternate_contact_number2");
    }, [formValue, formValue1,]);

    /* on change input function */
    const formChange = (event) => {
        const { name, value } = event.target;
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });

        setFormValue1((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        })
    };

    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                if (formValue1.alternate_contact_number1) {
                    if (!!formValue1.alternate_contact_number2) {                  
                        return { ...prevErrorValue, alternate_contact_number2: (formValue1.alternate_contact_number2.match(REGEX.alternate_contact_number2) ? '' : `${ERROR_MESSAGE['alternate_contact_number2Invalid']}`) }
                    } else prevErrorValue.alternate_contact_number2 = "";

                    if (!!formValue1.alternate_contact_number1) {                        
                        return { ...prevErrorValue, alternate_contact_number1: (formValue1.alternate_contact_number1.match(REGEX.alternate_contact_number1) ? '' : `${ERROR_MESSAGE['alternate_contact_number1Invalid']}`) }
                    } else prevErrorValue.alternate_contact_number1 = "";

                } else if (formValue1.alternate_contact_number2) {
                    
                    if (!!formValue1.alternate_contact_number1) {                      
                        return { ...prevErrorValue, alternate_contact_number1: (formValue1.alternate_contact_number1.match(REGEX.alternate_contact_number1) ? '' : `${ERROR_MESSAGE['alternate_contact_number1Invalid']}`) }
                    } else prevErrorValue.alternate_contact_number1 = "";

                    if (!!formValue1.alternate_contact_number2) {                       
                        return { ...prevErrorValue, alternate_contact_number2: (formValue1.alternate_contact_number2.match(REGEX.alternate_contact_number2) ? '' : `${ERROR_MESSAGE['alternate_contact_number2Invalid']}`) }
                    } else prevErrorValue.alternate_contact_number2 = "";

                }
                return {
                    ...prevErrorValue, [targetName]: !formValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (formValue[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)

                };
            });
        }

    };

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };

    /* Validation Checking */
    const validation = (e) => {
        e.preventDefault();
        setFormTouchValue(markAllAsTouched(formTouchValue));
        setFormValue(leftTrimAllValue(formValue));
        checkError("address_name");
        checkError("address_location");
        checkError("contact_person_name");
        checkError("contact_number");
        if (!!formValue1.alternate_contact_number1) {
            checkError("alternate_contact_number1");
        } else if (!!formValue1.alternate_contact_number2) checkError("alternate_contact_number2");
        else _officeAddressSubmit()
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 23/11/2023
    * @Date_Modified 
    * @function async
    * @functionName _officeAddressSubmit
    * @functionPurpose this function for godown office address submit.
    *
    * @functionParam {address_name,address_location,address_lat,address_long,contact_person_name
   * contact_number,alternate_contact_number1,alternate_contact_number2}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _officeAddressSubmit = () => {
        if (isFormValid(formValue)) {
            setSubmitDisable(true)
            let params = JSON.stringify({
                address_name: formValue.address_name,
                address_location: formValue.address_location,
                address_lat: String(markerPosition.lat),
                address_long: String(markerPosition.lng),
                contact_person_name: formValue.contact_person_name,
                contact_number: formValue.contact_number,
                alternate_contact_number1: formValue1.alternate_contact_number1 == undefined ? '' : formValue1.alternate_contact_number1,
                alternate_contact_number2: formValue1.alternate_contact_number2 == undefined ? '' : formValue1.alternate_contact_number2,
            });
            Service.apiPostTokenCallRequest(RouteURL.godownAddressAdd, params).then((response) => {
              
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                                   
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                    props.CloseAddEditModal();
                    props.onChangeSomeState("add");

                } else {                   
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                }
            }).catch((error) => {
                console.log(error);
                setSubmitDisable(false);
            });
        }
    };

    /* map load*/ 
    const onLoad = useCallback((map) => {
        // console.log(map.getPlaceDetails());
        // let bounds;
        mapRef.current = map;
        // const { geometry } = mapRef?.current?.getPlace();
        // console.log(geometry);
        if (navigator.geolocation) {
            // get the current users location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // console.log('1');
                    // save the geolocation coordinates in two variables
                    const { latitude, longitude } = position.coords;
                    // setGetPlaceDetails(position)

                    setDefaultLocation((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(latitude),
                            lng: Number(longitude),
                        };
                    });
                    setMarkerPosition((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(latitude),
                            lng: Number(longitude),
                        };
                    });
                },
                // if there was an error getting the users location
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );

        }

        if (defaultLocation.lat) {
            const bounds = new window.google.maps.LatLngBounds({
                lat: defaultLocation.lat,
                lng: defaultLocation.lng

            });
            // console.log(bounds);
            map.fitBounds(bounds);
            setMap(map)
        }
    }, [])

    const onUnmount = useCallback(() => {
        setMap(null)
    }, [])


    /*close modal  */ 
    const onCloseModal = (data) => {
        if (data?.coords) {
            console.log(data.coords);
            getAddress(data.coords.latitude, data.coords.longitude)
        } else if (data?.location) {
            getAddress(data.location.lat(), data.location.lng())
        } else if (data?.geometry) {
            getAddress(data.geometry.location.lat(), data.geometry.location.lng())
 
        }
    }

    const getAddress = (lat, lng) => {
        let geocoder = new window.google.maps.Geocoder();
        let latlng = new window.google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': latlng }, function (results, status) {         
            //  && results[0].geometry.location_type == ("ROOFTOP" || "APPROXIMATE")
            if (status == 'OK') {
                console.log(results);
                const address = results[0].formatted_address;
                for (var i = 0; i < results[0].address_components.length; i++) {
                    for (var j = 0; j < results[0].address_components[i].types.length; j++) {
                        if (results[0].address_components[i].types[j] == "locality") {
                            setFormValue((prevFormValue) => {
                                return {
                                    ...prevFormValue, address_name: results[0].address_components[i].short_name,
                                    address_location: results[0].formatted_address,
                                };
                            });
                           
                            setMarkerPosition((prevFormValue) => {
                                return {
                                    ...prevFormValue, lat: lat,
                                    lng: lng,
                                };
                            });
                            setDefaultLocation((prevFormValue) => {
                                return {
                                    ...prevFormValue, lat: lat,
                                    lng: lng,
                                };
                            });

                        }
                    }
                }

            }

        });
    }

 
    return (
        <>    <div className="task_modal_sec" >
            <div className="row">
                <div className="col-sm-8" ref={modalRef}>
                    <div className="office_address_lt">
                        <h6>Address Details</h6>

                        <form >
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="office_field">
                                        <label>Address Name</label>
                                        <textarea placeholder="Enter Address Name"
                                            name="address_name"
                                            onClick={() => { setopenModal(true); }}
                                            onBlur={updateFormTouchValue}
                                            value={formValue.address_name}
                                            error={errorValue.address_name ? "true" : "false"}
                                            onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}></textarea>
                                    </div>
                                    {errorValue.address_name && (
                                        <span className="error">
                                            {errorValue.address_name}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-12">
                                    <div className="office_field">
                                        <label>Address Line 1</label>
                                        <textarea placeholder="Enter Detail Address"
                                            name="address_location"
                                            disabled
                                            onBlur={updateFormTouchValue}
                                            value={formValue.address_location}
                                            error={errorValue.address_location ? "true" : "false"}></textarea>
                                    </div>
                                    {errorValue.address_location && (
                                        <span className="error">
                                            {errorValue.address_location}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-6">
                                    <div className="office_field">
                                        <label>Contact Person Name</label>
                                        <input type="text" placeholder="Enter Contact Person Name"
                                            name="contact_person_name"
                                            onChange={formChange}
                                            onBlur={updateFormTouchValue}
                                            value={formValue.contact_person_name}
                                            error={errorValue.contact_person_name ? "true" : "false"} 
                                            onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}/>
                                    </div>
                                    {errorValue.contact_person_name && (
                                        <span className="error">
                                            {errorValue.contact_person_name}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-6">
                                    <div className="office_field">
                                        <label>Contact Number</label>
                                        <input type="number" placeholder="Enter Contact Number"
                                            name="contact_number"
                                            maxLength={10}
                                            pattern="[0-9]*"
                                            onKeyDown={e => checkLength(e)}
                                            onKeyUp={removeSpecialCharacters}
                                            onChange={formChange}
                                            onBlur={updateFormTouchValue}
                                            value={formValue.contact_number}
                                            error={errorValue.contact_number ? "true" : "false"} />
                                    </div>
                                    {errorValue.contact_number && (
                                        <span className="error">
                                            {errorValue.contact_number}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-6">
                                    <div className="office_field">
                                        <label>Alternate Contact Number 1 (Optional)</label>
                                        <input type="number" placeholder="Enter Alternate Contact Number"
                                            name="alternate_contact_number1"
                                            maxLength={10}
                                            pattern="[0-9]*"
                                            onKeyDown={e => checkLength(e)}
                                            onKeyUp={removeSpecialCharacters}
                                            onChange={formChange}
                                            value={formValue1.alternate_contact_number1}
                                            error={errorValue.alternate_contact_number1 ? "true" : "false"} />
                                    </div>
                                    {errorValue.alternate_contact_number1 && (
                                        <span className="error">
                                            {errorValue.alternate_contact_number1}
                                        </span>
                                    )}
                                </div>
                                <div className="col-sm-6">
                                    <div className="office_field">
                                        <label>Alternate Contact Number 2 (Optional)</label>
                                        <input type="number" placeholder="Another Alternate Contact Number"
                                            name="alternate_contact_number2"
                                            maxLength={10}
                                            pattern="[0-9]*"
                                            onKeyDown={e => checkLength(e)}
                                            onKeyUp={removeSpecialCharacters}
                                            onChange={formChange}
                                            value={formValue1.alternate_contact_number2}
                                            error={errorValue.alternate_contact_number2 ? "true" : "false"} />
                                    </div>
                                    {errorValue.alternate_contact_number2 && (
                                        <span className="error">
                                            {errorValue.alternate_contact_number2}
                                        </span>
                                    )}
                                </div>


                            </div>
                        </form>
                        <div className="save_btn">

                            <button type="submit" value="Verify & Save"
                                style={{ background: '#000' }}
                                onClick={validation}> Save</button>
                        </div>
                    </div>
                   


                </div>
                <div className="col-sm-4">
                    <div className="office_address_rt">
                        <div className="address_map">
                            <h6>Select Address Location</h6>
                            <span>Select Address in Map</span>
                            <GoogleMap
                                onLoad={onLoad}
                                center={defaultLocation}
                                mapContainerStyle={mapStyle}
                                onUnmount={onUnmount}
                                options={{
                                    zoom: 15,
                                    disableDefaultUI: true,
                                    mapTypeControl: false,
                                    draggable: false,
                                    scaleControl: false,
                                    scrollwheel: false,
                                    navigationControl: false,
                                    streetViewControl: false,
                                }}
                            >
                                <Marker position={markerPosition} />
                            </GoogleMap>
                     
                        </div>
                    </div>
                </div>
            </div>
            {openModal && <OnboardingLocationModal onHide={() => { setopenModal(false) }}
                show={openModal} onCloseModal={onCloseModal} />}
        </div>
        </>
    )
}