import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TypeOfRegistration from "./views/auth/typeOfRegistration";
import Login from "./views/auth/login";
import Register from "./views/auth/register";
import PageRoute from "./containers/pageRoutes";
import VerifyOTP from "./views/auth/veryfyOTP";
import ForgotPassword from "./views/auth/forgot-password";
import ResetPassword from "./views/auth/reset-password";
import OnboardingList from "./views/onboarding/onboardingList";
import SubscriptionForShipper from "./views/pages/shipper/Subscription";
import PaymentDetails from "./views/pages/shipper/shipperPaymentDetails";
import LandingPage from "./views/pages/landingPage";
import TermAndCondition from "./views/pages/termAndCondition";
import PrivacyPolicy from "./views/pages/privacyPolicy";
import RefundPolicy from "./views/pages/cancellation_refund_policy";
import { Offline, Online } from "react-detect-offline";
import ConnectionError from "./containers/connectionError";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from './Firebase';
import NotiIcon from './assets/images/noti.png'

function App() {
  const [error, setError] = useState(true);
  const [dataPayload, setDataPayload] = useState();
  useEffect(() => {

    try {
      generateToken();
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        toast(Msg(payload), {
          icon: () => <img src={NotiIcon} height={20} width={20} />,
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          onClose: () => {
            setDataPayload(payload?.data)
          },
        })
      });

    }
    catch (err) {
      console.log(err);
    }


  }, [])



  const Msg = (payload) => {
    return (
      <div className="row">
        <div className="col">
          <b>{payload?.data?.title}</b><br />
          {payload?.data?.body}
        </div>
        {/* {payload?.notification?.image &&
          <div className="col-4">
            <img src={payload?.notification?.image} height={40} width={40} style={{ borderRadius: 20 }} />
          </div>} */}
      </div>
    );
  };

  const generateToken = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // console.log('Notification permission granted.');
      // Get the FCM token
      const token = await getToken(messaging, { vapidKey: 'BEeU8Pl4Hl55_eqBjfMmvwcnp8Nuv4ZGAi5xaAy0rto1jt6tl8_CZdzM15y1Dg5awQ51F5dVtgi0XI2Nrt1TcWE' });
      // console.log('FCM Token:', token);

      localStorage.setItem('FcmToken', token)
    } else {
      console.log('Notification permission denied.');
    }

  }



  return (
    <React.Suspense fallback={<></>}>
      <BrowserRouter basename={process.env.REACT_APP_WEB_PLATFORM_HOMEPAGE}>
        <Routes>
          <Route path="/*" element={<PageRoute data={dataPayload} />} />
          {/* <Route path="/" element={<TypeOfRegistration />} /> */}
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/type-of-registration"
            element={<TypeOfRegistration />}
          />
          <Route path="/terms-and-conditions" element={<TermAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/cancellation-refund-policy"
            element={<RefundPolicy />}
          />

          <Route path="/login" element={<Login />} />
          <Route
            path="/register"
            element={
              localStorage.getItem("typeOfRegistration") != "" ||
                localStorage.getItem("typeOfRegistration") != undefined ||
                localStorage.getItem("typeOfRegistration") != null ? (
                <Register />
              ) : (
                <Navigate replace to={"/type-of-registration"} />
              )
            }
          />
          <Route path="/otp" element={<VerifyOTP />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/onboarding-list" element={<OnboardingList />} />
          <Route
            path="/shipper-subscription"
            element={<SubscriptionForShipper />}
          />
          <Route
            path="/payment-details/:subscription_id"
            element={<PaymentDetails />}
          />
        </Routes>
      </BrowserRouter>
      <Offline>
        <ConnectionError show={error} onHide={() => setError(false)} />
      </Offline>
    </React.Suspense>

  );
}

export default App;
