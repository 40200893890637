
/**
 * @file_purpose  page for onboarding list
 * @author Sarmistha Mondal
 * @Date_Created 08/11/2023
 * @Date_Modified 
 */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Constants, ERROR_MESSAGE, REGEX } from "../../Utility/ConstantData";
import Service from "../../apis/Service";
import RouteURL from "../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Godown from "../../../src/assets/images/godwn_ico1.png"
import Vehicle from "../../../src/assets/images/vehicle1.png"
import Payment from "../../../src/assets/images/payment.png"
import Location from "../../../src/assets/images/location.png"
import Calender from "../../../src/assets/images/calendar.png"

export default function OnboardingList(props) {
    const navigate = useNavigate();
    const location = useLocation();
    let found = "";
    const [onboardList, setOnboardList] = useState({
        office_address: false, vehicle: false,
        payment_methods: false, delivery_cities: false,
        availibity_timings: false,
    });

    useEffect(() => {
        _onboardingList()
    }, [])

    /**
    * @author Sarmistha Mondal
    * @Date_Created 23/11/2023
    * @Date_Modified 
    * @function async
    * @functionName _onboardingList
    * @functionPurpose this function for onboarding list.    
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _onboardingList = () => {
        Service.apiPostTokenCallRequest(RouteURL.onboardingList, {}).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let checkTrue = Object.values(response.data);
                found = checkTrue.every((element) => element == true);
                setOnboardList((prevFormValue) => {
                    return {
                        ...prevFormValue, office_address: response.data.office_address,
                        vehicle: response.data.vehicle,
                        payment_methods: response.data.payment_methods, delivery_cities: response.data.delivery_cities,
                        availibity_timings: response.data.availibity_timings,

                    };
                });
                if ((Object.values(response.data).every(x => x == true) == true)) {
                    navigate("/shipment-list")
                    localStorage.setItem('MenuName', 'Shipment')
                }

                localStorage.setItem('onboardStatus', JSON.stringify(response.data))
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }).catch((error) => {
            console.log(error);
            if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            }
        })

    };

    const pageNavigate = (e, page) => {
        e.preventDefault()
        navigate(page, { state: onboardList })
    }

    return (
        <>
            <div className="office_address_top">
                <h4>Onboarding</h4>
                <div className="office_radio">
                    <div className="radio_sec">
                        <input id="office_address" className="radio-custom" name="office_address" type="radio" checked={onboardList?.office_address} disabled />
                        <label for="radio-1" className="radio-custom-label" onClick={(e) => pageNavigate(e, '/onboarding-godown-address')}
                            style={{ fontWeight: location.pathname == `/onboarding-godown-address` ? 800 : '', color: "#000" }}>Godown</label> <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="radio_sec">
                        <input id="vehicle" className="radio-custom" name="vehicle" type="radio" checked={onboardList?.vehicle} disabled />
                        <label for="radio-2" className="radio-custom-label" onClick={(e) => pageNavigate(e, '/onboarding-office-vehicle')}
                            style={{ fontWeight: location.pathname == `/onboarding-office-vehicle` ? 800 : '' }}>Vehicles</label> <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="radio_sec">
                        <input id="payment_methods" className="radio-custom" name="payment_methods" type="radio" checked={onboardList?.payment_methods} disabled />
                        <label for="radio-3" className="radio-custom-label" onClick={(e) => pageNavigate(e, '/onboarding-payment-method')}
                            style={{ fontWeight: location.pathname == `/onboarding-payment-method` ? 800 : '' }}>Payment</label> <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="radio_sec">
                        <input id="delivery_cities" className="radio-custom" name="delivery_cities" type="radio" checked={onboardList?.delivery_cities} disabled />
                        <label for="radio-4" className="radio-custom-label" onClick={(e) => pageNavigate(e, '/onboarding-city')}
                            style={{ fontWeight: location.pathname == `/onboarding-city` ? 800 : '' }}>Delivery</label> <i className="fa-solid fa-angle-right"></i>
                    </div>
                    <div className="radio_sec">
                        <input id="availibity_timings" className="radio-custom" name="availibity_timings" type="radio" checked={onboardList?.availibity_timings} disabled />
                        <label for="radio-5" className="radio-custom-label" onClick={(e) => pageNavigate(e, '/onboarding-timing')}
                            style={{ fontWeight: location.pathname == `/onboarding-timing` ? 800 : '' }}>Timing</label>
                    </div>
                </div>

                <div className="office_mob_top">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={onboardList?.office_address == true ? `nav-link active` : `nav-link`} id="home-tab" data-bs-toggle="tab" data-bs-target="#godown" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={(e) => pageNavigate(e, '/onboarding-godown-address')} ><img src={Godown} alt="" /> <p>Godown</p></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={onboardList?.vehicle == true ? `nav-link active` : `nav-link`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#vehicles" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={(e) => pageNavigate(e, '/onboarding-office-vehicle')}><img src={Vehicle} alt="" /> <p>Vehicles</p></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={onboardList?.payment_methods == true ? `nav-link active` : `nav-link`} id="contact-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={(e) => pageNavigate(e, '/onboarding-payment-method')}><img src={Payment} alt="" /> <p>Payment</p></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={onboardList?.delivery_cities == true ? `nav-link active` : `nav-link`} id="contact-tab" data-bs-toggle="tab" data-bs-target="#delivery" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={(e) => pageNavigate(e, '/onboarding-city')}><img src={Location} alt="" /> <p>Delivery</p></button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={onboardList?.availibity_timings == true ? `nav-link active` : `nav-link`} id="contact-tab" data-bs-toggle="tab" data-bs-target="#timing" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={(e) => pageNavigate(e, '/onboarding-timing')}><img src={Calender} alt="" /> <p>Timing</p></button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}