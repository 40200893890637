/**
 * @file_purpose  page for showing Upload Lorry Receipt
 * @author Sarmistha Mondal
 * @Date_Created 24/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Modal from "react-bootstrap/Modal";
import TataBg from "../../../assets/images/tat_bg.png"

export default function UploadLRModal(props) {
    let formData = new FormData();
    let order_id = props._allData.order_id;
    const [fileData, setFileData] = useState({
        lr_document: [], lr_images: [], invoice_document: [], invoice_images: [],
    });
    const [errorValue, setErrorValue] = useState({ lr_document: "", invoice_document: "" });
    const selectedFIles = [];
    const invoiceFile = [];
    const [submitDisable, setSubmitDisable] = useState(false);

    /* upload lr */
    const handleMultipleLR = (e, i) => {
        let files = e.target.files[0];
        const newArray = [...fileData.lr_document];
        newArray.push(files)
        setFileData((prevFormValue) => {
            return { ...prevFormValue, lr_document: newArray };
        })
        if (fileData?.lr_document?.length < 1) {
            const targetFilesObject = [...newArray]
            targetFilesObject.map((file) => {
                return selectedFIles.push(URL.createObjectURL(file))
            })
        }      
        setFileData((prevFormValue) => {
            return { ...prevFormValue, lr_images: selectedFIles };
        })

    }
    
    /* upload invoice */
    const handleMultipleInvoice = (e, i) => {
        let files = e.target.files[0];
        const newArray = [...fileData.invoice_document];
        newArray.push(files)
        setFileData((prevFormValue) => {
            return { ...prevFormValue, invoice_document: newArray };
        })
        if (fileData?.invoice_document?.length < 1) {
            const targetFilesObject = [...newArray]
            targetFilesObject.map((file) => {
                return invoiceFile.push(URL.createObjectURL(file))
            })
        }
    
        setFileData((prevFormValue) => {
            return { ...prevFormValue, invoice_images: invoiceFile };
        })

    }

    /* Validation Checking */
    const validation = (e) => {
        e.preventDefault();
        if (fileData.lr_document.length == 0) {
            setErrorValue((prevFormValue) => {
                return { ...prevFormValue, lr_document: 'Please upload lorry receipt' };
            });
        } else {
            setErrorValue((prevFormValue) => {
                return { ...prevFormValue, lr_document: '' };
            });
        }

        if (fileData.invoice_document.length == 0) {
            setErrorValue((prevFormValue) => {
                return { ...prevFormValue, invoice_document: 'Please upload invoice receipt' };
            });
        } else {
            setErrorValue((prevFormValue) => {
                return { ...prevFormValue, invoice_document: '' };
            });
        }    

        if (fileData.lr_document[0] && fileData.invoice_document[0]) {           
            _submitDocument()
        }
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 24/04/2024
      * @Date_Modified 
      * @function async
      * @functionName _submitDocument
      * @functionPurpose this function for lr doc and invoice submit.
      *
      * @functionParam {}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _submitDocument = () => {
        formData.append(`order_id`, order_id);
        formData.append(`invoice_document`, fileData.invoice_document[0]);
        formData.append(`lr_document`, fileData.lr_document[0]);
        setSubmitDisable(true)
        Service.apiPostCallFormDataRequest(RouteURL.uploadLRDocument, formData).then((response) => {           
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) { 
                props.CloseAddEditModal();
                props.onChangeSomeState("add");              
                setSubmitDisable(false)
            } else {
               
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        }).catch((error) => {
            setSubmitDisable(false)

        });
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec" >
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Upload Lorry Receipt</h6>
                    </Modal.Header>

                    <section className="reject_inner">
                        <p>Please submit below documents to start deliver</p>
                        <div className="row">
                            <div className="col">
                                <div className="office_address_rt">

                                    <div className="vehicle_rt_box lorry_upload">
                                        <div className="vehicle_txt">
                                            <h6>Lorry Receipt Document <span></span></h6>
                                        </div>
                                        <div className="row">
                                            {fileData?.lr_images != undefined && fileData?.lr_images?.map((url) => {
                                                return (<div className="col-sm-3">
                                                    <div className="tata_moto_sec">
                                                        <img src={url} alt="" />
                                                    </div>
                                                </div>)
                                            })}
                                            
                                            {(fileData?.lr_document != undefined && fileData?.lr_document?.length < 1) &&
                                                <div className="col-sm-2 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                                                    <div className="tata_moto_sec">
                                                        <input type="file" accept=".jpg,.jpeg,.png"
                                                            name="lr_document"
                                                            onChange={(e) => handleMultipleLR(e)}
                                                            error={errorValue.lr_document ? "true" : "false"}
                                                        />
                                                        <img src={TataBg} alt="" />
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                        {fileData?.lr_document.length == 0 && errorValue.lr_document && (
                                            <span className="error">
                                                {errorValue.lr_document}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="office_address_rt">

                                    <div className="vehicle_rt_box lorry_upload">
                                        <div className="vehicle_txt">
                                            <h6>Invoice Document <span></span></h6>
                                        </div>
                                        <div className="row">
                                            {fileData?.invoice_images != undefined && fileData?.invoice_images?.map((url) => {
                                                return (<div className="col-sm-3">
                                                    <div className="tata_moto_sec">
                                                        <img src={url} alt="" />
                                                    </div>
                                                </div>)
                                            })}
                                            {(fileData?.invoice_document != undefined && fileData?.invoice_document?.length < 1) &&
                                                <div className="col-sm-2 col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
                                                    <div className="tata_moto_sec">
                                                        <input type="file" accept=".jpg,.jpeg,.png"
                                                            name="invoice_document"
                                                            onChange={(e) => handleMultipleInvoice(e)}
                                                            error={errorValue.invoice_document ? "true" : "false"}
                                                        />
                                                        <img src={TataBg} alt="" />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {fileData?.invoice_document.length == 0 && errorValue.invoice_document && (
                                            <span className="error">
                                                {errorValue.invoice_document}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 offset-sm-3">
                            <div className="login_btn">
                                <button type="submit" value="get Started"
                                    disabled={submitDisable ? true : false}
                                    style={{ background: '#000' }}
                                    onClick={validation}
                                ><i className="fa fa-upload" aria-hidden="true" /> Submit</button>
                            </div>
                        </div>
                    </section>


                </div>
            </Modal>
        </>
    )
}