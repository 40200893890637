/**
 * @file_purpose  page for payment details
 * @author Sarmistha Mondal
 * @Date_Created 26/02/2024
 * @Date_Modified 
 */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim,
} from "../../../Utility/Helper";
import { Constants } from "../../../Utility/ConstantData";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REGEX, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import HomeImg from "../../../assets/images/car.png";
import Verified from "../../../assets/images/checkmark.png";
import Loader from "react-js-loader";


export default function PaymentDetails(props) {
    let formData = new FormData();
    const navigate = useNavigate();
    const [is_bank_account_number_verified, setIsBankAccountNumberVerified] = useState();
    const [is_upi_payment_enabled_verified, setIsUpiPaymentVerified] = useState();
    const [submitDisable, setSubmitDisable] = useState(false);
    const [upadate, setUpdate] = useState(false);
    const [verifyDisable, setVerifyDisable] = useState(false);
    const [loader, setLoader] = useState(0);
    const [formValueForBank, setFormValueForBank] = useState({
        is_bank_payment_enabled: "",
        account_holder_name: "",
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        branch_name: ""
    });
    const [formValueForUpi, setFormValueForUpi] = useState({
        is_upi_payment_enabled: "",
        upi_id: "",
    });
    const [formValueForCash, setFormValueForCash] = useState({
        is_cash_payment_enabled: ""
    });
    const [errorValue, setErrorValue] = useState({
        account_holder_name: "",
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        branch_name: ""
    });
    const [upiErrorValue, setUpiErrorValue] = useState({ upi_id: "", })
    const [formTouchValueForBank, setFormTouchValueForBank] = useState({
        account_holder_name: false,
        account_number: false,
        ifsc_code: false,
        bank_name: false,
        branch_name: false,
    });
    const [formTouchValueForUpi, setFormTouchValueForUpi] = useState({ upi_id: false })
    const [inputDisable, setInputDisable] = useState({
        account_holder_name: "",
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        branch_name: ""
    })
    const [checkOcr, setCheckOcr] = useState();

    useEffect(() => {

        if (str2bool(formValueForUpi.is_upi_payment_enabled) === true) {
            if (!formValueForUpi.upi_id) {
                return setUpiErrorValue({ upi_id: "UPI id is required" })
            } else {
                if (formValueForUpi.upi_id.match(REGEX['upi_id'])) setUpiErrorValue({ upi_id: "" })
                else return setUpiErrorValue({ upi_id: "Please enter a valid upi id" })
            }
        } else setUpiErrorValue('');

        if (str2bool(formValueForBank.is_bank_payment_enabled) === true) {
            if (!checkOcr && !formValueForBank.account_number) {

                setErrorValue((prevValue) => {
                    return { ...prevValue, chequeOCR: 'Please upload cheque ' };
                })
            } else {
                checkError("account_number")
                checkError("ifsc_code")
            }

            return
        } else setErrorValue('');



    }, [formValueForBank, formValueForUpi]);


    const formChange = (event) => {
        const { name, value, checked } = event.target;
        if (name == 'is_bank_payment_enabled') {
            setFormValueForBank((prevValue) => {
                return { ...prevValue, [name]: checked };
            });
        }
        else {
            setFormValueForBank((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };

    const formChangeForOthers = (event) => {
        const { name, value, checked } = event.target;
        if (name == 'is_upi_payment_enabled') {
         
            setFormValueForUpi((prevValue) => {
                return { ...prevValue, [name]: checked };
            });

        }
        else if (name == 'is_cash_payment_enabled') {           
            setFormValueForCash((prevValue) => {
                return { ...prevValue, [name]: checked };
            });

        }
        else {
            setFormValueForUpi((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };

    /* error message function */
    const checkError = (targetName) => {      
        if (formTouchValueForBank[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: formValueForBank[targetName] == "" ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (formValueForBank[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)
                };

            });

        }
    };


    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValueForBank[name]) {
            setFormTouchValueForBank((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormTouchValueForBank((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
        if (!formTouchValueForUpi[name]) {
            // console.log(formTouchValueForUpi);
            setFormTouchValueForUpi((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormTouchValueForUpi((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };


   
    /**
     * @author Sarmistha Mondal
     * @Date_Created 27.11.2023
     * @Date_Modified 
     * @function async
     * @functionName _verifyBankAccountNumber
     * @functionPurpose this function for
     * verify bank a/c no.
     *
     *  @functionParam {payload object: account_number,ifsc_code}
     * 
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _verifyBankAccountNumber = () => {
        let params = JSON.stringify({
            account_number: formValueForBank.account_number,
            ifsc_code: formValueForBank.ifsc_code
        })
        setVerifyDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.verifyBankAccountnumber, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    localStorage.setItem("account_number", formValueForBank.account_number)
                    setIsBankAccountNumberVerified(1);
                    let res = response.data.ocrDetails;
                    localStorage.setItem("account_number", res.account_number)
                    setFormValueForBank((prevFormValue) => {
                        return {
                            ...prevFormValue,
                            account_holder_name: res.account_holder_name,
                            account_number: res.account_number,
                            ifsc_code: res.ifsc_code,
                            bank_name: res.bank_name,
                            branch_name: res.branch_name
                        };

                    });
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });

                } else {
                    if (response.statusCode == 406) {
                        navigate("/shipper-subscription");
                    }
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });
                }
            }).catch((error) => {
                setVerifyDisable(false)
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }


    /**
    * @author Sarmistha Mondal
    * @Date_Created 27.11.2023
    * @Date_Modified 
    * @function async
    * @functionName _verifyverifyUPIId
    * @functionPurpose this function for
    * verify upi a/c.
    *
    *  @functionParam {payload object: upi_id}
    * 
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _verifyverifyUPIId = () => {
        setVerifyDisable(true)
        let params = JSON.stringify({
            upi_id: formValueForUpi.upi_id,
        })
        Service.apiPostTokenCallRequest(RouteURL.verifyUpid, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    localStorage.setItem("upiaccount_number", formValueForUpi.upi_id)
                    setIsUpiPaymentVerified(1)
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => setVerifyDisable(false)
                    });

                }
            }).catch((error) => {
                // console.log(error);
                setVerifyDisable(false)
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    const str2bool = (value) => {
        // console.log(value, );
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        } else {
            if (value === "true") return true;
            if (value === "false") return false;
        }
        return value;
    }

    /* account no validation*/
    const accountValidation = () => {
     
        setVerifyDisable(true)
        toast.error('Please verfy your a/c no. ', {
            position: toast.POSITION.BOTTOM_RIGHT,
            onClose: () => setVerifyDisable(false)
        });
    }

    /* upi validation*/
    const upiAccountValidation = () => {
        toast.error('Please verfy your upi id. ', {
            position: toast.POSITION.BOTTOM_RIGHT,
            onClose: () => setVerifyDisable(false)
        });
    }

    /* Validation Checking */

    const _PaymentFormSubmit = (e) => {
        e.preventDefault();
        setFormTouchValueForBank(markAllAsTouched(formTouchValueForBank));
        setFormValueForBank(leftTrimAllValue(formValueForBank));
        console.log(formValueForBank, is_bank_account_number_verified);

        let formIsValid = true;
        if ((str2bool(!formValueForBank.is_bank_payment_enabled) || str2bool(formValueForBank.is_bank_payment_enabled) == '') && (str2bool(!formValueForUpi.is_upi_payment_enabled) || str2bool(formValueForUpi.is_upi_payment_enabled) == "") && (str2bool(!formValueForCash.is_cash_payment_enabled) || str2bool(formValueForCash.is_cash_payment_enabled) == '')) {
            setSubmitDisable(true)
            console.log('ok')
            toast.error('Please select payment method! ', {
                position: toast.POSITION.BOTTOM_RIGHT,
                onClose: () => setSubmitDisable(false)
            });

        } else {

            if (str2bool(formValueForBank.is_bank_payment_enabled) == true && isFormValid(formValueForBank) && (str2bool(!formValueForUpi.is_upi_payment_enabled) || str2bool(formValueForUpi.is_upi_payment_enabled) == '') && (str2bool(!formValueForCash.is_cash_payment_enabled) || str2bool(formValueForCash.is_cash_payment_enabled) == '')) {
                formIsValid = false;
                checkError("account_holder_name")
                checkError("account_number")
                checkError("ifsc_code")
                checkError("bank_name")
                if (isFormValid(formValueForBank) && is_bank_account_number_verified == 1) {

                    formIsValid = false;
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 1,
                        account_holder_name: formValueForBank.account_holder_name,
                        account_number: formValueForBank.account_number,
                        ifsc_code: formValueForBank.ifsc_code,
                        bank_name: formValueForBank.bank_name,
                        branch_name: formValueForBank.branch_name,
                        is_upi_payment_enabled: 0,
                        upi_id: "",
                        is_cash_payment_enabled: 0
                    });
                    _submitPayment(params);
                } else {
                   
                    if (!checkOcr) {
                        setErrorValue((prevValue) => {
                            return { ...prevValue, chequeOCR: 'Please upload cheque ' };
                        })
                    } else accountValidation();

                }

            } else if (str2bool(formValueForUpi.is_upi_payment_enabled) == true && isFormValid(formValueForUpi) && (str2bool(!formValueForBank.is_bank_payment_enabled) || str2bool(formValueForBank.is_bank_payment_enabled) == "") && (str2bool(!formValueForCash.is_cash_payment_enabled) || str2bool(formValueForCash.is_cash_payment_enabled) == '')) {

                if (!formValueForUpi.upi_id) {
                    return setUpiErrorValue({ upi_id: "UPI id is required" })
                } else if (!formValueForUpi.upi_id.match(REGEX['upi_id'])) {
                    setUpiErrorValue({ upi_id: "Please enter a valid upi id" })
                } else {

                    if (isFormValid(formValueForUpi) && is_upi_payment_enabled_verified == 1) {
                        formIsValid = false;
                        let params = JSON.stringify({
                            is_bank_payment_enabled: 0,
                            account_holder_name: "",
                            account_number: "",
                            ifsc_code: "",
                            bank_name: "",
                            branch_name: "",
                            is_upi_payment_enabled: 1,
                            upi_id: formValueForUpi.upi_id,
                            is_cash_payment_enabled: 0
                        });
                        _submitPayment(params)
                    } else {
                        setVerifyDisable(true)
                        upiAccountValidation()
                    }
                }

            }
            else if (str2bool(formValueForCash.is_cash_payment_enabled) == true && (str2bool(!formValueForBank.is_bank_payment_enabled) || str2bool(formValueForBank.is_bank_payment_enabled) == '') && (str2bool(!formValueForUpi.is_upi_payment_enabled) || str2bool(formValueForUpi.is_upi_payment_enabled) == '')) {

                formIsValid = false;
                let params = JSON.stringify({
                    is_bank_payment_enabled: 0,
                    account_holder_name: "",
                    account_number: "",
                    ifsc_code: "",
                    bank_name: "",
                    branch_name: "",
                    is_upi_payment_enabled: 0,
                    upi_id: "",
                    is_cash_payment_enabled: 1
                });
                _submitPayment(params)
            } else if (str2bool(formValueForCash.is_cash_payment_enabled) == true && str2bool(formValueForBank.is_bank_payment_enabled) == true && str2bool(formValueForUpi.is_upi_payment_enabled) == true) {
                if (isFormValid(formValueForBank) && isFormValid(formValueForUpi) && is_bank_account_number_verified == 1 && is_upi_payment_enabled_verified == 1) {

                    formIsValid = false;
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 1,
                        account_holder_name: formValueForBank.account_holder_name,
                        account_number: formValueForBank.account_number,
                        ifsc_code: formValueForBank.ifsc_code,
                        bank_name: formValueForBank.bank_name,
                        branch_name: formValueForBank.branch_name,
                        is_upi_payment_enabled: 1,
                        upi_id: formValueForUpi.upi_id,
                        is_cash_payment_enabled: 1
                    });
                    _submitPayment(params)
                } else {
                    if (is_bank_account_number_verified != 1) {
                        formIsValid = false;
                        
                        if (!checkOcr) {
                            setErrorValue((prevValue) => {
                                return { ...prevValue, chequeOCR: 'Please upload cheque ' };
                            })
                        } else accountValidation();

                    }
                    if (is_upi_payment_enabled_verified != 1) { formIsValid = false; setVerifyDisable(true); upiAccountValidation() }
                }
            } else if (str2bool(formValueForBank.is_bank_payment_enabled) == true && str2bool(formValueForUpi.is_upi_payment_enabled) == true && (str2bool(!formValueForCash.is_cash_payment_enabled) || str2bool(formValueForCash.is_cash_payment_enabled) == '')) {
                formIsValid = false;
                if (isFormValid(formValueForBank) && isFormValid(formValueForUpi) && is_bank_account_number_verified == 1 && is_upi_payment_enabled_verified == 1) {
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 1,
                        account_holder_name: formValueForBank.account_holder_name,
                        account_number: formValueForBank.account_number,
                        ifsc_code: formValueForBank.ifsc_code,
                        bank_name: formValueForBank.bank_name,
                        branch_name: formValueForBank.branch_name,
                        is_upi_payment_enabled: 1,
                        upi_id: formValueForUpi.upi_id,
                        is_cash_payment_enabled: 0
                    });
                    _submitPayment(params)
                } else {
                    if (is_bank_account_number_verified != 1) {
                        formIsValid = false;
                      
                        if (!checkOcr) {
                            setErrorValue((prevValue) => {
                                return { ...prevValue, chequeOCR: 'Please upload cheque ' };
                            })
                        } else accountValidation();

                    }
                    if (is_upi_payment_enabled_verified != 1) { formIsValid = false; upiAccountValidation() }
                }

            } else if (str2bool(formValueForBank.is_bank_payment_enabled) == true && (str2bool(!formValueForUpi.is_upi_payment_enabled) || str2bool(formValueForUpi.is_upi_payment_enabled) == '') && str2bool(formValueForCash.is_cash_payment_enabled) == true) {
                formIsValid = false;
             
                if (isFormValid(formValueForBank) && is_bank_account_number_verified == 1) {
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 1,
                        account_holder_name: formValueForBank.account_holder_name,
                        account_number: formValueForBank.account_number,
                        ifsc_code: formValueForBank.ifsc_code,
                        bank_name: formValueForBank.bank_name,
                        branch_name: formValueForBank.branch_name,
                        is_upi_payment_enabled: 0,
                        upi_id: "",
                        is_cash_payment_enabled: 1
                    });
                    _submitPayment(params)
                } else accountValidation()
            } else if ((str2bool(!formValueForBank.is_bank_payment_enabled) || str2bool(formValueForBank.is_bank_payment_enabled) == '') && str2bool(formValueForUpi.is_upi_payment_enabled) == true && str2bool(formValueForCash.is_cash_payment_enabled) == true) {
                formIsValid = false;
                if (isFormValid(formValueForUpi) && is_upi_payment_enabled_verified == 1) {
                    let params = JSON.stringify({
                        is_bank_payment_enabled: 0,
                        account_holder_name: "",
                        account_number: "",
                        ifsc_code: "",
                        bank_name: "",
                        branch_name: "",
                        is_upi_payment_enabled: 1,
                        upi_id: formValueForUpi.upi_id,
                        is_cash_payment_enabled: 1
                    });
                    _submitPayment(params)
                } else { setVerifyDisable(true); upiAccountValidation() }

            }


            return formIsValid;
        }

    }
    

    /**
       * @author Sarmistha Mondal
       * @Date_Created 27.11.2023
       * @Date_Modified 
       * @function async
       * @functionName _submitPayment
       * @functionPurpose this function for
       * submit payment
       *
       *  @functionParam {payload object: upi_id}
       * 
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _submitPayment = (params) => {
        Service.apiPostTokenCallRequest(RouteURL.paymentMethodAdd, params).then(
            (response) => {
               
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    setSubmitDisable(false);
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    if (localStorage.getItem('user_type') == 'Shipper') {
                        // navigate("/shipment-list")
                        navigate("/shipment-booking")                    
                        localStorage.setItem('MenuName', 'Shipment')
                    } else if (localStorage.getItem('user_type') == 'Transporter') {
                        navigate("/onboarding-list")
                    }

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }


    /**
      * @author Sarmistha Mondal
      * @Date_Created 26.02.2024
      * @Date_Modified 
      * @function async
      * @functionName _submitOcrCheque
      * @functionPurpose this function for
      * shipper ocr upload
      *
      *  @functionParam {payload object: ocr_files}
      * 
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */

    const _submitOcrCheque = (file) => {
        setLoader(1)
        formData.set(`ocr_files`, file);
        setCheckOcr(file)
        Service.apiPostCallFormDataRequest(RouteURL.ocrPaymentChequeForShipper, formData).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let res = response.data.ocrDetails;
                    localStorage.setItem("account_number", res.account_number)
                    setFormValueForBank((prevFormValue) => {
                        return {
                            ...prevFormValue,
                            account_holder_name: res.account_holder_name,
                            account_number: res.account_number,
                            ifsc_code: res.ifsc_code,
                            bank_name: res.bank_name,
                            branch_name: res.branch_name
                        };

                    });
                    setIsBankAccountNumberVerified(1)
                    setErrorValue((prevErrorValue) => {
                        return { ...prevErrorValue, shhipergstOCR: '' }
                    })
                    setLoader(0)

                } else {
                    setLoader(0)
                    setIsBankAccountNumberVerified(0)
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setInputDisable((prevFormValue) => {
                        return {
                            ...prevFormValue,
                            account_number: true,
                            ifsc_code: true,
                        };

                    })
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });

    }






    return (
        <>
            <ToastContainer />
            <section className="signup_sec">
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-5 col-sm-12 p-0">
                            <div className="select_page_lt">
                                <h2>Lorem ipsum is <br /><span>a dummy text</span></h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <img src={HomeImg} alt="" />
                            </div>
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-7 col-sm-12">
                            <div className="create_acct_sec">
                                <h4>Add Payment Details</h4>



                                <div className="shipper_create">
                                    <div className="row">
                                        <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-5 col-md-12">
                                            <div className="shipper_business_type">
                                                <p></p>
                                                <div className="create_sub">

                                                    <label className="check_term" htmlFor="text-input">
                                                        <input id="cb1" type="checkbox" name="is_bank_payment_enabled"
                                                            defaultChecked={formValueForBank.is_bank_payment_enabled == 1 ? true : false}
                                                            onChange={(e) => { formChange(e); setUpdate(!upadate) }}
                                                        /> Bank Payment </label>

                                                </div>


                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xxl-7 col-xl-7 col-lg-7 col-md-12">
                                            <div className="shipper_create_rt">
                                                <p>Upload to Autofill The Details Below </p>
                                                <div className="uploadbtn">
                                                    <div className="choose_file d-flex align-items-center justify-content-center">
                                                        <div className="upload-btn-wrapper">
                                                            <label for="banner_image">
                                                                <button className="btn-custom">
                                                                    <input type="file" accept=".jpg,.jpeg,.png" onChange={(e) => _submitOcrCheque(e.target.files[0])} />
                                                                    <i className="fa-solid fa-upload"></i> Upload Cheque
                                                                </button>
                                                            </label>
                                                            {loader == 1 ? <Loader type="spinner-default" bgColor={'white'} color={'white'} size={30} /> : ""}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <span className="error" style={{ alignContent: 'center' }}>
                                                {errorValue.shhiperaadharOCR}
                                            </span>


                                        </div>
                                    </div>

                                </div>
                                <form
                                   
                                    name="signin_form"
                                >

                                    <div className="row">
                                        <>
                                            <div className="col-md-6"><div className="create_sub">
                                                <label style={{ color: '#000' }} >Account Holder Name
                                                </label>
                                                <input type="text"
                                                    name="account_holder_name"
                                                    placeholder="Enter A/C Holder Name"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    value={formValueForBank.account_holder_name}
                                                    error={errorValue.account_holder_name ? "true" : "false"}
                                                    disabled={inputDisable?.account_holder_name ? false : true}
                                                />
                                                {errorValue.account_holder_name && (
                                                    <span className="error">
                                                        {errorValue.account_holder_name}
                                                    </span>
                                                )}
                                            </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="create_sub">
                                                    <label style={{ color: '#000' }}>IFSC Code</label>
                                                    <input type="text" placeholder="Enter IFSC Code" name="ifsc_code"
                                                        onChange={formChange}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValueForBank.ifsc_code ? formValueForBank.ifsc_code.toUpperCase() : ""}
                                                        error={errorValue.ifsc_code ? "true" : "false"}
                                                        disabled={inputDisable?.ifsc_code ? false : true}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                    />
                                                </div>
                                                {errorValue.ifsc_code && (
                                                    <span className="error">
                                                        {errorValue.ifsc_code}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="create_sub">
                                                    <label style={{ color: '#000' }}>Account Number</label>
                                                    <input type="number" placeholder="Enter A/C Number" name="account_number" onChange={formChange}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValueForBank.account_number}
                                                        error={errorValue.account_number ? "true" : "false"}
                                                        disabled={inputDisable?.account_number ? false : true}
                                                    />

                                                    <span className="verify_sec "
                                                        style={{ color: formValueForBank.account_number ? ((localStorage.getItem("account_number") != formValueForBank.account_number) || is_bank_account_number_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            if (formValueForBank['account_number'].match(REGEX['account_number'])) _verifyBankAccountNumber()
                                                        }}>
                                                        {(localStorage.getItem("account_number") == formValueForBank.account_number) && is_bank_account_number_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("account_number") == formValueForBank.account_number) && is_bank_account_number_verified == 1 ? 'verified' : 'verify'}

                                                    </span>



                                                </div>
                                                {errorValue.account_number && (
                                                    <span className="error">
                                                        {errorValue.account_number}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="create_sub">
                                                    <label style={{ color: '#000' }} >Bank Name
                                                    </label>
                                                    <input type="text"
                                                        name="bank_name"
                                                        placeholder="Bank Name"
                                                        onChange={formChange}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValueForBank.bank_name}
                                                        error={errorValue.bank_name ? "true" : "false"}
                                                        disabled={inputDisable?.bank_name ? false : true}
                                                    />
                                                    {errorValue.bank_name && (
                                                        <span className="error">
                                                            {errorValue.bank_name}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="create_sub">
                                                    <label style={{ color: '#000' }}>Branch Name</label>
                                                    <input type="text" name="branch_name" placeholder="Enter Branch Name"
                                                        onChange={formChange}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValueForBank.branch_name}
                                                        error={errorValue.branch_name ? "true" : "false"}
                                                        disabled={inputDisable?.branch_name ? false : true}
                                                    />
                                                </div>
                                                {errorValue.branch_name && (
                                                    <span className="error">
                                                        {errorValue.branch_name}
                                                    </span>
                                                )}
                                            </div>


                                        </>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="create_sub">

                                                    <label className="check_term" htmlFor="text-input">
                                                        <input id="cb1" type="checkbox" name="is_upi_payment_enabled"
                                                            defaultChecked={formValueForUpi.is_upi_payment_enabled == 1 ? true : false}
                                                            onChange={(e) => { formChangeForOthers(e); setUpdate(!upadate) }}
                                                        /> UPI Payment </label>

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="create_sub">
                                                    <label style={{ color: '#000' }}>UPI ID</label>
                                                    <input type="text" placeholder="Enter UPI ID"
                                                        name="upi_id"
                                                        onChange={formChangeForOthers}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValueForUpi.upi_id}
                                                        error={upiErrorValue.upi_id ? "true" : "false"}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                    />
                                                    <span className="verify_sec "
                                                        style={{ color: formValueForUpi.upi_id ? ((localStorage.getItem("upiaccount_number") != formValueForUpi.upi_id) || is_upi_payment_enabled_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            if (formValueForUpi['upi_id'].match(REGEX['upi_id'])) _verifyverifyUPIId()
                                                        }}>
                                                        {(localStorage.getItem("upiaccount_number") == formValueForUpi.upi_id) && is_upi_payment_enabled_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("upiaccount_number") == formValueForUpi.upi_id) && is_upi_payment_enabled_verified == 1 ? 'verified' : 'verify'}

                                                    </span>
                                                    {/* <button type="button" className="btn btn-link verify" style={{
                                                        textDecoration: 'none',
                                                        color: upiErrorValue.upi_id ? '#f00' : ((formValueForUpi.upi_id == localStorage.getItem("upiaccount_number")) && is_upi_payment_enabled_verified == 1 ? '#3c9b0b' : '#f00'),
                                                    }} onClick={() => {
                                                        if (formValueForUpi['upi_id'].match(REGEX['upi_id'])) _verifyverifyUPIId();
                                                    }}
                                                        disabled={verifyDisable ? true : false}
                                                    >     {(localStorage.getItem("upiaccount_number") == formValueForUpi.upi_id) && is_upi_payment_enabled_verified == 1 ? 'verifyed' : 'verify'}</button> */}
                                                </div>
                                                {upiErrorValue.upi_id && (
                                                    <span className="error">
                                                        {upiErrorValue.upi_id}
                                                    </span>
                                                )}
                                            </div>
                                        </div>



                                        <div className="col-sm-6">
                                            <div className="create_sub">

                                                <label className="check_term" htmlFor="text-input">
                                                    <input id="cb1" type="checkbox" name="is_cash_payment_enabled"
                                                        defaultChecked={formValueForCash.is_cash_payment_enabled == 1 ? true : false}
                                                        onChange={formChangeForOthers}
                                                    />Cash Payment</label>

                                            </div>
                                        </div>
                                        {submitDisable +' e'}
                                        <div className="col-sm-12">
                                            <div className="create_sub text-center">
                                                <div className="login_btn">

                                                    <button type="submit" value="get Started"
                                                        style={{ background: '#000' }} onClick={_PaymentFormSubmit}
                                                        disabled={submitDisable ? true : false}
                                                    >Save</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





        </>
    )
}