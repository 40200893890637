/**
 * @file_purpose  page for Congratulation
 * @author Sarmistha Mondal
 * @Date_Created 14/02/2024
 * @Date_Modified 
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Thumb from "../../assets/images/thumsup.png";

export default function Congratulation(props) {
    const navigate = useNavigate();
    const _pageNavigation = () => {
        // navigate("/onboarding-list")
        if (localStorage.getItem('user_type') =='Transporter')navigate("/subscription")
        else navigate("/shipper-subscription") 
        
    }
    return (
        <>
            < Modal
                show={props.show}
                onHide={() => props.onHide()}
                backdrop="static"
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec">

                    <div className="task_modal_sec">
                        <section className="forgot_inn">
                            <h4>Congratulation!</h4>
                            <p style={{ paddingTop: 15 }}>You have Successfully Logged In</p>
                            <img style={{ paddingTop: 30 }} src={Thumb} alt="" />
                            <h6>Lets Onboard to ShipGudds</h6>
                            <p>You will be Start Receiving the Shipment Once Onboarded Successfully.</p>
                            <div className="create_sub text-center">
                                <div className="login_btn">
                                    <button type="submit" value="get Started" onClick={_pageNavigation}
                                        style={{ background: '#000' }}>Take a Subsscription</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Modal >
        </>
    )
}

