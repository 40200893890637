/**
 * @file_purpose  page for showing office material list
 * @author Sarmistha Mondal
 * @Date_Created 05/12/2023
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {checkNull} from "../../../Utility/Helper";
import { Constants } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Accordion from 'react-bootstrap/Accordion';

export default function OfficeMaterialList() {
    const navigate = useNavigate();
    let array = [];
    const [isActive, setIsActive] = useState(false);
    const [openIndex, setOpenIndex] = useState("");
    const [formValue, setFormValue] = useState([]);
    const [_filteredData, _setFilteredData] = useState([]);

    useEffect(() => {
        _onboardingCityList();

    }, []);
    /**
       * @author Sarmistha Mondal
       * @Date_Created 27/11/2023
       * @Date_Modified 
       * @function async
       * @functionName _onboardingCityList
       * @functionPurpose this function for onboarding city list.
       *
       * @functionParam {}
       *
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _onboardingCityList = () => {

        Service.apiPostTokenCallRequest(RouteURL.transporterDeliveryCitiesList, {}).then((response) => {
 
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

                let res = response.data.stateCityList;
                res.forEach((object) => {
                    object.isActive = false;
                    let _checkValue = object.cities.filter(obj => {
                        return obj.checked == true;
                    })
                    object.count = _checkValue.length;
                });
                setFormValue(res)
                _setFilteredData(res);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };
    const prevCountRef = useRef(isActive);
    useEffect(() => {
        prevCountRef.current = isActive;
    }, [isActive]);

    const ToggleButton = (openIndex, i, isActive) => {

        if (openIndex) {
            setIsActive(!isActive);
            setOpenIndex(openIndex);
            formValue[i].isActive = !isActive;
            setFormValue(formValue);
        }
    };

    
    const handleChange = (e, option, index) => {
        const { checked } = e.target;
        const alldata = [...formValue];
        let cityArray = [...alldata[index].cities];
        formValue[index]["checked"] = true;
        if (checked == true) formValue[index]["checked"] = true;
        else formValue[index]["checked"] = false;


        Object.keys(cityArray).forEach((checkbox) => {
            if (checked == true) {
                cityArray[checkbox].checked = true;
            } else {
                cityArray[checkbox].checked = false;
            }
        });

        setFormValue(alldata);
    };


    const handleCheckboxChange = (
        e, data,
        submaindata, key,
        index

    ) => {
        const { checked, name } = e.target;
        const alldata = [...formValue];
        let cityArray = [...alldata[key].cities];
        alldata[key].cities[index].checked = checked;
        if (checked == true) {
            formValue[key]["checked"] = true;
            let citylist = cityArray.filter((option, index) => {
               
                return option.checked == true
            })
            alldata[key].count = citylist.length;
        }
        else {
            let citylist = cityArray.filter((option, index) => {
                return option.checked == true
            })
            if (citylist.length == 0) {
                formValue[key]["checked"] = false;
            } else formValue[key]["checked"] = true;

            alldata[key].count = citylist.length;
        }

        setFormValue(alldata);

    };

    const _selectedCityList = (e) => {
        e.preventDefault();
        let selectCity = formValue.filter((option, index) => {
            return option.checked == true
        })
        selectCity.map((data) => {
            let cityId = data.cities.filter(obj => (obj.checked == true))
            let object = {
                "state": data.id.toString(),
                "cities": cityId.map(obj => { return obj.id }).toString()
            }
            array.push(object)
        })
        if (array.length > 0) {
            _submitCity(array)
        } else {
            toast.error('Please select delivery citie ', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }

        // console.log(array);
    }

    /**
       * @author Sarmistha Mondal
       * @Date_Created 28.11.2023
       * @Date_Modified 
       * @function async
       * @functionName _submitCity
       * @functionPurpose this function for
       * submit city
       *
       *  @functionParam {payload object: state_cities}
       * 
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _submitCity = (array) => {

        let params = JSON.stringify({
            state_cities: JSON.stringify(array)
        });
        Service.apiPostTokenCallRequest(RouteURL.deliveryCitiesAdd, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    const handleSearch = (event) => {
        let value = event.target.value.toLowerCase();
        let result = [];
        if (value != "") {
            result = formValue.filter((data) => {
                if (
                    checkNull(data.name).toLowerCase().search(value) != -1
                ) {
                    return true;
                } else console.log(value);
                return false;
            });
            setFormValue(result);
        } else setFormValue(_filteredData);
    };
    return (
        <>
            <div className="sideright_menu">
                <div className="shipment_menu_rt">
                    <div className="godown_upper_hed">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="godown_upper_hed_lt">
                                    <h4>Delivery Location</h4>
                                </div>
                            </div>
                            <div className="godwn_dwn">
                                <div className="row">
                                    <div className="office_address_lt ">
                                        <div className="delivery_search">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                            <input type="search" placeholder="Search for Cities..." onChange={(event) => handleSearch(event)} />
                                        </div>
                                        <div className="delivery_accordian_sec">
                                            {formValue != undefined && formValue.length > 0 && formValue.map((data, key) => {
                                                // console.log(data);
                                                return (
                                                    <>
                                                        <div className="delivery_sub">
                                                            <div className="delivery_sub_upper">
                                                                <div className="delivery_sub_lft">
                                                                    <input type="checkbox" label={data.name}
                                                                        key={data.id}
                                                                        checked={data.checked}
                                                                        onChange={(value) =>
                                                                            handleChange(value, data, key)
                                                                        } /> <label>{data.name}</label>
                                                                </div>
                                                                <div className="delivery_sub_rt">
                                                                    <span className="count_num">{data.count == "" ? 0 : data.count}/{data.cities.length}</span>
                                                                    <span
                                                                        onClick={() => {
                                                                            ToggleButton(
                                                                                data.id,
                                                                                key,
                                                                                data.isActive
                                                                            );
                                                                        }}
                                                                        style={{ paddingRight: 5 }}
                                                                    >
                                                                        {data.cities.length > 0 && (
                                                                            <>
                                                                                {data.isActive == true &&
                                                                                    openIndex == data.id ? (
                                                                                    <i
                                                                                        className="fa fa-chevron-up"
                                                                                        aria-hidden="true"
                                                                                        style={{
                                                                                            fontSize: 16,

                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <i
                                                                                        className="fa fa-chevron-down"
                                                                                        aria-hidden="true"
                                                                                        style={{
                                                                                            fontSize: 16,

                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </div>

                                                            </div>

                                                            {data.isActive == true &&
                                                                openIndex == data.id ? (
                                                                <Accordion defaultActiveKey="0">
                                                                    <Accordion.Item eventKey="0">

                                                                        <Accordion.Body><div className="city_box">
                                                                            {(data.cities != undefined && data.cities.length > 0 && data.cities.map((item, index) => {
                                                                                return <>

                                                                                    <div className="cat action" style={{ border: item.checked == true ? '1px solid #fcab35' : '1px solid #928FA9' }}>
                                                                                        <label>
                                                                                            <input type="checkbox" checked={item.checked}
                                                                                                onChange={(value) =>
                                                                                                    handleCheckboxChange(value, data, item, key, index)
                                                                                                } /><span>{item.name}</span>
                                                                                        </label>
                                                                                    </div>


                                                                                </>



                                                                            }))} </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

                                                                </Accordion>
                                                            ) : ""}


                                                        </div>


                                                    </>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="save_btn"><button type="submit" value="Verify &amp; Save" onClick={_selectedCityList}>Save</button></div> */}
                    </div>
                </div>

            </div>



        </>
    );
}
