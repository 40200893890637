
/**
 * @file_purpose  page for login
 * @author Sarmistha Mondal
 * @Date_Created 06/11/2023
 * @Date_Modified 
 */
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  getErrorMessage,
  markAllAsTouched,
  leftTrimAllValue,
  isFormValid,
  leftTrim,
} from "../../Utility/Helper";
import { Constants, ERROR_MESSAGE, REGEX } from "../../Utility/ConstantData";
import Service from "../../apis/Service";
import RouteURL from "../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Eye from "../../assets/images/eye.png";
import HiddenEye from "../../assets/images/hidden_eye.png";
import HomeImg from "../../assets/images/car.png";
import Modal from "react-bootstrap/Modal";
import ForgotPassword from "./forgot-password";
import VerifyOTP from "./veryfyOTP";
import ResetPassword from "./reset-password";
import Truck from "../../assets/images/truck.png"
import Congratulation from "./congratulation";

const Login = () => {
  let found = "";
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({ emailOrphone: "", password: "" });
  const [errorValue, setErrorValue] = useState({ emailOrphone: "", password: "" });
  const [forgotPassModal, setforgotPassModalModal] = useState(false);
  const [congo, setCongo] = useState(false);
  const [formTouchValue, setFormTouchValue] = useState({
    emailOrphone: false,
    password: false,
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [openModalForResetPass, setopenModalForResetPass] = useState({
    visible: false,
  });
  const [visible, setVisible] = useState(false);
  const [openModalForOTP, setopenModalForOTP] = useState({
    visible: false,
    type: '',
    emailOrPhone: '',
    pageFor: 'forgotpass'
  });

  useEffect(() => {
    if (formValue) {
      checkError("emailOrphone");
      checkError("password");
    }

  }, [formValue]);


  useEffect(() => {
    localStorage.clear();
  }, []);

  /* on change input function */
  const formChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    setFormValue((prevFormValue) => {
      return { ...prevFormValue, [name]: leftTrim(value) };
    });

  };

  /* error message function */
  const checkError = (targetName) => {
    if (formTouchValue[targetName]) {
      setErrorValue((prevErrorValue) => {
        return {
          ...prevErrorValue, [targetName]: !formValue[targetName] ?
            `${ERROR_MESSAGE[targetName + 'Required']}` :
            (formValue[targetName].match(REGEX[targetName]) ? '' : '')

        };
      });
    }
  };

  const updateFormTouchValue = (event) => {
    const { name, value } = event.target;
    if (!formTouchValue[name]) {
      setFormTouchValue((prevFormTouchValue) => {
        return { ...prevFormTouchValue, [name]: true };
      });
      setFormValue((prevFormValue) => {
        return { ...prevFormValue, [name]: leftTrim(value) };
      });


    }

  };
  /* validate email*/
  const validateEmail = (email) => {
    const re = REGEX["email"]
    return re.test(String(email));
  }
 
  /* checking email OR ph*/
  const validationCheck = (event) => {
    const { name, value } = event.target;
    if (validateEmail(value)) setErrorValue({ emailOrphone: "" })
    else setErrorValue({ emailOrphone: "Please enter a valid email address." })
  
  }


  /**
   * @author Sarmistha Mondal
   * @Date_Created 06/11/2023
   * @Date_Modified 
   * @function async
   * @functionName signin
   * @functionPurpose this function for login.
   *
   * @functionParam {username,password}
   *
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
  const signin = (e) => {
    e.preventDefault();
    setFormTouchValue(markAllAsTouched(formTouchValue));
    setFormValue(leftTrimAllValue(formValue));
    checkError("emailOrphone");
    checkError("password");
    // console.log(formValue);
    if (!!formValue.emailOrphone && !!formValue.password) {
      let params = JSON.stringify({
        username: formValue.emailOrphone,
        password: formValue.password,
      });
      // console.log(params);
      setSubmitDisable(true)
      Service.apiPostCallRequest(RouteURL.login, params).then((response) => {
        // console.log(response);
        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          localStorage.setItem('token', response.data.userDetails.token);
          localStorage.setItem('user_type', response.data.userDetails.user_type);
          localStorage.setItem('firm_name', response.data.profileDetails.firm_name);
          localStorage.setItem('name', response.data.profileDetails.name);
          localStorage.setItem('first_name', (response.data.profileDetails.first_name != null ? response.data.profileDetails.first_name : '') + ' ' + (response.data.profileDetails.last_name != null ? response.data.profileDetails.last_name : ''));
          localStorage.setItem('is_receiver', response.data.userDetails.is_receiver)
          localStorage.setItem('Profile_img', response.data.userDetails.profile_image)
          localStorage.setItem('profile_details', JSON.stringify(response.data.profileDetails))
          localStorage.setItem('email', response.data.userDetails.email)
          setSubmitDisable(false)
          /* for conditionally page navigation*/
          if (response.data.userDetails.user_type == 'Transporter') {
            _onboardingList(response.data.subscriptionDetails)
          } else if (response.data.userDetails.user_type == 'Shipper') {
            if (response.data.is_valid_subscription == false) setCongo(true)
            else {
              navigate("/shipment-list")
              localStorage.setItem('MenuName', 'Shipment')
            }

          } else if (response.data.userDetails.user_type == 'Receiver') {
            navigate("/receiver")
            localStorage.setItem('MenuName', 'receiver')
          } else if (response.data.userDetails.user_type == 'Driver') {
            toast.error('Please install Ship gudds app!! ', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (response.data.userDetails.user_type == 'Manager') {
            navigate("/shipment-list")
            localStorage.setItem('MenuName', 'Shipment')
          }

           _updateFcmToken();


        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            onClose: () => {
              setSubmitDisable(false);
            },
          });
        }
      }).catch((error) => {
        setSubmitDisable(false)
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          window.location.reload();
        }
      });;
    }
  };


  /**
      * @author Sarmistha Mondal
      * @Date_Created 23/11/2023
      * @Date_Modified 
      * @function async
      * @functionName _onboardingList
      * @functionPurpose this function for onboarding list.
      *
      * @functionParam {}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
  const _onboardingList = (subscriptionDetails) => {

    Service.apiPostTokenCallRequest(RouteURL.onboardingList, {}).then((response) => {
      
      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        let checkTrue = Object.values(response.data);
        found = checkTrue.every((element) => element == true);
        if (found != true) {
          /* for conditionally page navigation*/
          if (subscriptionDetails == null) setCongo(true)
          if (response.data.office_address == false) { navigate("/onboarding-godown-address"); localStorage.setItem('onBoard', '/onboarding-godown-address') }
          else if (response.data.vehicle == false) { navigate("/onboarding-office-vehicle"); localStorage.setItem('onBoard', '/onboarding-office-vehicle') }
          else if (response.data.payment_methods == false) { navigate("/onboarding-payment-method"); localStorage.setItem('onBoard', '/onboarding-payment-method') }
          else if (response.data.delivery_cities == false) { navigate("/onboarding-city"); localStorage.setItem('onBoard', '/onboarding-city') }
          else if (response.data.availibity_timings == false) { navigate("/onboarding-timing"); localStorage.setItem('onBoard', '/onboarding-timing') }
          else {
            navigate("/shipment-list")
            localStorage.setItem('MenuName', 'Shipment')
          }
        } else {
          navigate("/shipment-list")
          localStorage.setItem('MenuName', 'Shipment')
        }

      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

  };

/* for open forgotpassword modal*/ 
  const gotoForgotPassword = (event) => {
    event.preventDefault();
    setforgotPassModalModal(!forgotPassModal)
    setopenModalForOTP({ visible: false })
    setopenModalForResetPass({ visible: false })
  };
 
  /* for close forgotpassword modal and otp modal open*/ 
  const onCloseForgotPassModal = (type, emailOrphone) => {
    setforgotPassModalModal(!forgotPassModal)
    setopenModalForOTP({
      visible: true, type: type,
      emailOrPhone: emailOrphone,
      pageFor: 'forgotpass'
    })
    setopenModalForResetPass({ visible: false })
  }

  /* for close forgotpassword modal ,close otp modal and resetpasswod modal open*/
  const _otpModalCloseForOTP = (state) => {
    setforgotPassModalModal(false)
    setopenModalForOTP({ visible: false })
    setopenModalForResetPass({ visible: true })
   
  }

  /* for close forgotpassword modal ,close otp modal and close resetpasswod modal */
  const onChanonCloseResetModal = () => {
    setforgotPassModalModal(false)
    setopenModalForOTP({ visible: false })
    setopenModalForResetPass({ visible: false })
  };

  /**
   * @author Sarmistha Mondal
   * @Date_Created 29/05/2024
   * @Date_Modified 
   * @function async
   * @functionName _updateFcmToken
   * @functionPurpose this function for login time fcm token update. 
   * @functionParam {fcm_token, platform}  
   * @functionSuccess Success status and message.
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
  const _updateFcmToken = () => {
    let params = JSON.stringify({
      fcm_token: localStorage.getItem('FcmToken'),
      platform: 'web',
    });
    Service.apiPostTokenCallRequest(RouteURL.updateFcmToken, params).then((response) => {
      // console.log(response);
      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {


      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }).catch((error) => {
      console.log(error);

    });
  }
  return (
    <>
      <ToastContainer />
      <section className="login_sec">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-5 col-sm-12 p-0">
              <div className="select_page_lt login_ht">
                <h2>Lorem ipsum is <br /><span>a dummy text</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <img src={HomeImg} alt="" />
              </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-7 col-sm-12">
              <div className="login_mob">
                <img src={Truck} alt="" />
                <h6>Welcome</h6>
              </div>

              <form onSubmit={signin} name="signin_form">
                <div className="login_inn">
                  <div className="login_rt">
                    <h4>Log in</h4>
                    <div className="login_mid">
                      <div className="login_mid_inn">
                        <label>Email Id </label>
                        <input
                          type="text"
                          className="form-control"
                          name="emailOrphone"
                          placeholder="Enter Your Registered Email "
                          onChange={formChange}
                          onBlur={updateFormTouchValue}
                          onKeyDown={validationCheck}
                          // onPaste={(e) => {
                          //   e.preventDefault()
                          //   return false;
                          // }}
                          value={formValue.emailOrphone}
                          error={errorValue.emailOrphone ? "true" : "false"}
                        />
                        {errorValue.emailOrphone && (
                          <span className="error">
                            {errorValue.emailOrphone}
                          </span>
                        )}
                      </div>
                      <div className="login_mid_inn eyesec">
                        <label>Password</label>
                        <input
                          type={visible ? "text" : "password"}
                          className="form-control"
                          name="password"
                          placeholder="Enter your Password"
                          onChange={formChange}
                          onBlur={updateFormTouchValue}
                          value={formValue.password}
                          error={errorValue.password ? "true" : "false"}
                        />
                        <span className="verify_sec">
                          <a href="javascript:void(0)"><i onClick={(e) => { e.preventDefault(); setVisible(!visible) }} className={visible ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'}></i></a></span>
                        {errorValue.password && (
                          <span className="error">
                            {errorValue.password}
                          </span>
                        )}
                      </div>
                      <p className="forgot_txt"><span onClick={gotoForgotPassword}>Forgot Password?</span></p>
                      <div className="login_btn">
                        <button type="submit" value="get Started" disabled={submitDisable ? true : false}
                          style={{ background: (Object.values(formValue).every(x => x != '') == false ? '#C5C4D2' : '#000') }}>Sign in</button>
                      </div>
                      <div className="regis_dwn_txt sign_dwntxt">
                        <p>New Here? <Link to="/type-of-registration">Sign Up Now</Link></p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section >


      {/* for forgotpassword modal */}
      {forgotPassModal && <ForgotPassword onCloseForgotPassModal={onCloseForgotPassModal} show={forgotPassModal}
        onHide={() => setforgotPassModalModal(!forgotPassModal)}
      />}

      {/* for otp modal */}
      {openModalForOTP.visible && <VerifyOTP otpFor={openModalForOTP} show={openModalForOTP.visible} onHide={() => setopenModalForOTP({ visible: !openModalForOTP.visible })} otpModalCloseForOTP={_otpModalCloseForOTP} />}

      {/* for reset Password modal */}
      <ResetPassword
        onChanonCloseResetModal={onChanonCloseResetModal}
        onHide={() => { setopenModalForResetPass(false) }}
        show={openModalForResetPass.visible}
      />

      {/* for congratulation modal */}
      {congo && <Congratulation show={congo} onHide={() => { setCongo(false) }} />}
     
     
    </>
  );
};

export default Login;
