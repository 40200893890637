/**
 * @file_purpose  page for showing receiver list
 * @author Sarmistha Mondal
 * @Date_Created 03/04/2024
 * @Date_Modified
 */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import HistoryReceiverPage from "./receiverTabPage/historyReceiver";
import UpComingReceiverPage from "./receiverTabPage/upComingReceiver";


export default function Receiver(props) {
    const navigate = useNavigate();
    const [menuName, setMenuName] = useState('upcoming');
    const is_receiver = localStorage.getItem('is_receiver')
    const [bookingRequestId, setBookingRequestId] = useState({ bookingid: '' });


    /* this function for onclick sidemene page navigation .  */
    const handleMenuSet = (menuName, booking_request_id) => {
        setMenuName(menuName);
        setBookingRequestId((prevErrorValue) => {
            return {
                ...prevErrorValue,
                bookingid: booking_request_id,
            };
        });
    };

    useEffect(() => {
        {
            (() => {
                switch (props?.deepLinking?.event) {
                    case "shipment_completed":
                        return handleMenuSet("history", props?.deepLinking?.booking_request_id);
                    case "shipment_started":
                        return handleMenuSet("upcoming", props?.deepLinking?.booking_request_id)
                    default:
                        return null;
                }
            })()
        }

    }, [props?.deepLinking]);

    return (
        <>
            <div className="sideright_menu">
                <div className="shipment_menu_rt receive_top">
                    <h4>Receiver Details</h4>
                    {is_receiver == 0 &&

                        <div className="login_btn">
                            <button type="" value="get Started"
                                onClick={() => navigate("/register", {
                                    state: { name: 'Shipper', email: localStorage.getItem('email'), user_type: 'Receiver' },
                                })}
                                style={{ background: '#FBAA2A' }}
                            > Become a Shipper</button>
                        </div>
                    }
                    <div className="shipment_dwn_menu">
                        <ul>


                            <li>
                                <Link className={menuName === "upcoming" ? " active" : ""}
                                    style={{
                                        color: menuName === "upcoming" ? "#000" : "",
                                    }}
                                    to="javascript:void(0)"
                                    onClick={(e) => handleMenuSet("upcoming")}
                                >
                                    Up Coming
                                </Link>
                            </li>
                            <li>
                                <a href="javascript:void(0)"
                                    style={{
                                        color: menuName === "history" ? "#000" : "",
                                    }}
                                    onClick={(e) => handleMenuSet("history")}
                                >
                                    History
                                </a>
                            </li>

                        </ul>
                        {(() => {
                            switch (menuName) {
                                case "history":
                                    return <HistoryReceiverPage bookingRequestId={bookingRequestId?.bookingid} />;
                                case "upcoming":
                                    return <UpComingReceiverPage bookingRequestId={bookingRequestId?.bookingid} />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>

                </div>
            </div>
        </>
    );
}
