/**
 * @file_purpose  page for showing shipment all request
 * @author Sarmistha Mondal
 * @Date_Created 15/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import {
    CDataTable, CButton, CCollapse
} from "@coreui/react";
import { Constants } from "../../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../../apis/Service";
import RouteURL from "../../../../apis/RouteURL";
import NewRequestAcceptsRejectModal from "../../commonModal/newRequestAcceptReject";
import SmallTruck from "../../../../assets/images/sm_truck.png"
import Carousel from 'react-bootstrap/Carousel';
import Slider from "react-slick";


export default function AllRequestPage() {
    const [filteredData, setFilteredData] = useState([]);
    const [visible, setVisible] = useState()
    const [modalValue, setModalValue] = useState({
        type: "",
        shipmentBookingId: "",
        shipmentRequestId: "",
        payment_mode: ""
    });
    const [accordion, setAccordion] = useState(0)
    const fields = [
        { key: 'request_id', _classes: "", }, { key: 'shipper_code', _classes: "", }, { key: 'shipper_name', _classes: "", },
        { key: 'from_city', _classes: "", }, { key: 'to_city', _classes: "", },
        { key: 'request_date', label: "Booking Date", _style: { width: "20%" }, _classes: "", },
        { key: 'estimated_delivery_date', label: "Estd Delivery Date", _style: { width: "20%" }, _classes: "", },
        {
            key: "action",
            label: "Action",
            _style: { width: "10%" },
            sorter: false,
            filter: false,
            _classes: "",
        },
        {
            key: "droparrow",
            label: "",
            sorter: false,
            filter: false,
            _classes: "",
        },
    ];


    useEffect(() => {
        _transporterAllRequestList();
    }, [])

    /**
    * @author Sarmistha Mondal
    * @Date_Created 15/04/2024
    * @Date_Modified 
    * @function async
    * @functionName _transporterAllRequestList
    * @functionPurpose this function for transporter new request List.
    *
    * @functionParam {}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _transporterAllRequestList = () => {

        Service.apiPostTokenCallRequest(RouteURL.transporterAllRequest, {}).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.allShipmentRequest.length > 0) {
                    setFilteredData(response?.data?.allShipmentRequest)
                } else setFilteredData([])

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    const _acceptRejectModal = (e, booking_id, id, type, payment_mode) => {
        e.preventDefault();
        setVisible(!visible)
        // console.log(booking_id, id);
        setModalValue({
            type: type,
            shipmentBookingId: booking_id,
            shipmentRequestId: id,
            payment_mode: payment_mode
        })

    }
    const closeAddEditModal = () => {
        setVisible(false)
    };


    const onChangeSomeState = (State) => {
        if (!!State) {
            _transporterAllRequestList();
        }
    };
    const toggleDetails = (index, id,) => {

        setAccordion(id)

    }
    return (
        <>
            <ToastContainer />
            <form>
                <div className="shipments_sec">
                    <div className="">
                        <div className="row">
                            <div className="col-sm-12">

                                <div className="shipment_bottom_panel">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="shipment_search">
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                                <input type="search" placeholder="Search for Req Id, shipper Name" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">

                                        </div>
                                    </div>
                                    <div className="godwn_dwn">
                                        <div className="row">
                                            <div className="col">
                                                <div className="office_address_lt shipping_dwn_pad">
                                                    <div className="shipper_table shipping_pagination">

                                                        <CDataTable
                                                            items={filteredData}
                                                            fields={fields}
                                                            itemsPerPage={10}
                                                            itemsPerPageSelect
                                                            pagination={{
                                                                className: "next_arrow",
                                                                firstButton: <i class="fa-solid fa-angle-left"></i>,
                                                                previousButton: <i class="fa-solid fa-angles-left"></i>,
                                                                nextButton: <i class="fa-solid fa-angles-right"></i>,
                                                                lastButton: <i class="fa-solid fa-angle-right"></i>,
                                                                size: "",
                                                                dots: false
                                                            }}
                                                            scopedSlots={{
                                                                request_id: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: '#000' }}>
                                                                            {item?.shipper_transporter_details?.request_id != null ? item?.shipper_transporter_details?.request_id : '--'}
                                                                        </td>)
                                                                },
                                                                shipper_code: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.shipper_transporter_details.shipper_code != null ? item.shipper_transporter_details.shipper_code : '--'}
                                                                        </td>)
                                                                },
                                                                shipper_name: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item?.shipper_transporter_details?.shipper_name != null ? item?.shipper_transporter_details?.shipper_name : '--'}
                                                                        </td>)
                                                                },
                                                                estimated_delivery_date: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.estimated_delivery_date != null ? item.location_details.estimated_delivery_date : '--'}
                                                                        </td>)
                                                                },
                                                                'from_city': (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.from_city != null ? item.location_details.from_city : '--'}
                                                                        </td>)
                                                                }, 'to_city': (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.to_city != null ? item.location_details.to_city : '--'}
                                                                        </td>)
                                                                },
                                                                request_date: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.booking_date != null ? item.location_details.booking_date : '--'}
                                                                        </td>)
                                                                },
                                                                remaining_hour: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            <CountdownTimer initialMinutes={Number(item.remaining_hour) * 60 + Number(item.remaining_minutes)} />
                                                                        </td>)
                                                                },
                                                                shipper_expected_delivery_date: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            --
                                                                        </td>)
                                                                },
                                                                action: (item, index) => {
                                                                    return (

                                                                        <td className="action_chk">
                                                                            <i className="fa-regular fa-circle-check" onClick={(e) => _acceptRejectModal(e, item.booking_id, item.id, 'accept', item.payment_mode)} />
                                                                            <i className="fa-regular fa-circle-xmark" onClick={(e) => _acceptRejectModal(e, item.booking_id, item.id, 'reject', item.payment_mode)} />
                                                                        </td>


                                                                    );
                                                                },
                                                                droparrow: (item, index) => {
                                                                    return (
                                                                        <td className="py-2 history_sec">

                                                                            {accordion !== item.id ?
                                                                                <CButton
                                                                                    onClick={() => { toggleDetails(index, item.id,) }}
                                                                                >
                                                                                    <i className="fa fa-angle-down" aria-hidden="true"></i>

                                                                                </CButton>
                                                                                :
                                                                                <CButton
                                                                                    onClick={() => { toggleDetails(index,) }}
                                                                                >
                                                                                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                                                                                </CButton>
                                                                            }

                                                                        </td>

                                                                    );
                                                                },
                                                                'details':
                                                                    (item, index) => {
                                                                        return (
                                                                            <CCollapse show={item.id === accordion}>
                                                                                <div className="order_tracking_sec">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-5">
                                                                                            <div className="order_step_inn">
                                                                                                <div className="order-track">
                                                                                                    <div className="order-track-step">
                                                                                                        <div className="order-track-status">
                                                                                                            <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                                                                                            <span className="order-track-status-line"></span>
                                                                                                        </div>
                                                                                                        <div className="order-track-text">
                                                                                                            <span className="order-track-text-sub">Booking Date - {item?.location_details.booking_date}</span>
                                                                                                            <p className="order-track-text-stat">{item?.location_details.from_location}</p>
                                                                                                            <span className="kilometer">{item?.location_details?.distance}</span>
                                                                                                            <span className="kilometer">{item?.location_details?.time_duration}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="order-track-step">
                                                                                                        <div className="order-track-status">
                                                                                                            <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                                                                                            <span className="order-track-status-line"></span>
                                                                                                        </div>
                                                                                                        <div className="order-track-text">
                                                                                                            <span className="order-track-text-sub">Est. Delivery date - _____</span>
                                                                                                            <p className="order-track-text-stat">{item?.location_details.to_location}</p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-2">
                                                                                            {/* <div className="material_sec"> */}

                                                                                            <div className="col-sm-6">
                                                                                                <div className="productmaterial_inn">
                                                                                                    <h6> Payment Mode
                                                                                                    </h6>
                                                                                                    <p> {item.payment_mode}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-sm-6">
                                                                                                {/* <div className="productmaterial_inn">
                                                                                                    <h6>No of boxes
                                                                                                    </h6>
                                                                                                    <p>{item.material_type[0].no_of_units}</p>
                                                                                                </div> */}
                                                                                            </div>

                                                                                            {/* </div> */}
                                                                                        </div>
                                                                                        <div className="col-sm-5">

                                                                                            <div className="dimen_slide" >
                                                                                                <Carousel controls>
                                                                                                    {item?.material_type.length > 0 && item?.material_type.map((data, key) => {
                                                                                                        return (<Carousel.Item key={key}>
                                                                                                            <div >
                                                                                                                <div className="row">
                                                                                                                    <div className="col-sm-6">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-sm-6">
                                                                                                                                <div className="productmaterial_inn">
                                                                                                                                    <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                                                                        <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                                                    </svg> Dimensions (L x W x H)
                                                                                                                                    </h6>
                                                                                                                                    <p>{data.product_dimension}</p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-sm-6">
                                                                                                                                <div className="productmaterial_inn">
                                                                                                                                    <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                                                                        <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                                                    </svg> Material
                                                                                                                                    </h6>
                                                                                                                                    <p>{data.material_type
                                                                                                                                    }</p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-sm-6">
                                                                                                                                <div className="productmaterial_inn">
                                                                                                                                    <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                                                                        <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                                                    </svg> No of boxes
                                                                                                                                    </h6>
                                                                                                                                    <p className="productmaterial_inn_color">{data.no_of_units}</p>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="col-sm-6">
                                                                                                                                <div className="productmaterial_inn">
                                                                                                                                    <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                                                                        <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                                                    </svg> Weight
                                                                                                                                    </h6>
                                                                                                                                    <p>{data.package_weight}</p>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-sm-6">
                                                                                                                        <div className="productmaterial_image">
                                                                                                                            <h6>Product Image</h6>
                                                                                                                            <div className="productmaterial_image_box">

                                                                                                                                <div className="p_box">
                                                                                                                                    {data?.images.length > 0 && data?.images.map((img) => {
                                                                                                                                        return (
                                                                                                                                            <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                                                                                        )
                                                                                                                                    })}

                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Carousel.Item>

                                                                                                        )
                                                                                                    })}

                                                                                                </Carousel>

                                                                                            </div>



                                                                                            <div className="material_sec">
                                                                                                <div className="row">

                                                                                                    {/* <div className="col-sm-6">
                                                                                                        <div className="productmaterial_inn">
                                                                                                            <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                                                <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                            </svg> Last Quote Date & Time
                                                                                                            </h6>
                                                                                                            <p>{item.last_accepted_date_time}</p>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="material_sec">
                                                                                                <div className="row">
                                                                                                    {/* <div className="col-sm-3">
                                                                                                        <div className="productmaterial_inn">
                                                                                                            <h6>Loading Type</h6>
                                                                                                            <p>Full Load</p>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                    <div className="col-sm-6">

                                                                                                    </div>
                                                                                                    {/* <div className="col-sm-3">
                                                                                                        <div className="material_details">
                                                                                                            <a href="">View details</a>
                                                                                                        </div>
                                                                                                    </div> */}

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </CCollapse>
                                                                        )
                                                                    }
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </form>

            {visible && <NewRequestAcceptsRejectModal _allData={modalValue}
                CloseAddEditModal={closeAddEditModal} show={visible} onHide={() => setVisible(false)}
                onChangeSomeState={onChangeSomeState} />}

        </>
    );
}

export const CountdownTimer = ({ initialMinutes }) => {
    const [minutes, setMinutes] = useState(initialMinutes);

    useEffect(() => {
        if (minutes <= 0) {
            return;
        }
        const timer = setInterval(() => {
            setMinutes((prevMin) => prevMin - 1);
        }, 60 * 1000);
        // Clean up the timer
        return () => clearInterval(timer);
    }, [minutes]);
    // Format the remaining time (e.g., “00:05:10” for 5 minutes and 10 seconds)
    const formatTime = (totalMinutes) => {
        const minutes = (totalMinutes % 60).toString()
            .padStart(2, `0`);
        const hours = Math.floor(totalMinutes / 60).toString()
            .padStart(2, `0`);

        return `${hours} h:${minutes} m`;


        // const hour = Math.floor(timeInSeconds / 60)
        //     .toString()
        //     .padStart(2, `0`);
        // const minutes = Math.floor(timeInSeconds)
        //     .toString()
        //     .padStart(2, `0`);
        // //const seconds = (timeInSeconds % 60).toString().padStart(2,`0`);
        // return `${hour} h:${minutes} m`;
        // //:${seconds}
    };

    return (
        <div>
            <p style={{ color: '#1cc11c' }}>{formatTime(minutes)}</p>
        </div>
    );
};


