/**
 * @file_purpose  page for showing view checklist 
 * @author Sarmistha Mondal
 * @Date_Created 09/05/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Constants, ERROR_MESSAGE } from "../../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../../apis/Service";
import RouteURL from "../../../../apis/RouteURL";
import { checkNull } from "../../../../Utility/Helper";
import Dimension from "../../../../assets/images/square-measument.png";
import Slider from "react-slick";
import TataBg from "../../../../assets/images/tat_bg.png"
import SmallTruck from "../../../../assets/images/sm_truck.png";
import { CTooltip } from "@coreui/react";

export default function ViewCheckList() {
    const navigate = useNavigate();
    let { order_id, booking_id } = useParams();
    let formData = new FormData();
    const [submitDisable, setSubmitDisable] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [rejectionFormValue, setRejectionFormValue] = useState({ additional_notes: "" });
    const [errorValue, setErrorValue] = useState({
        additional_notes: "", img: ''
    })
    const [images, setImages] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const selectedFIles = [];
    const [quoteRequests, setQuoteRequests] = useState({
        shipmentQuoteRequests: {},
        location_details: {},
        quoteList: [],
        shipment_details: {}
    });
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        touchMove: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };



    useEffect(() => {
        _receiverShipmentDetails();
    }, [])



    /**
    * @author Sarmistha Mondal
    * @Date_Created 9/05/2024
    * @Date_Modified 
    * @function async
    * @functionName _receiverShipmentDetails
    * @functionPurpose this function for receiver details.
    *
    * @functionParam {order_id}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _receiverShipmentDetails = () => {
        Service.apiPostTokenCallRequest(RouteURL.receiverShipmentDetails, { order_id: order_id }).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setFilteredData(response.data.shipmentOrderDetails)
                let quoteReq = response.data.shipmentOrderDetails;
                let quoteList = response.data.shipmentOrderDetails.quoteList;
                let shipment_details = response.data.shipmentOrderDetails.shipper_transporter_details;
                let location_details = response.data.shipmentOrderDetails.location_details;
                setQuoteRequests((prevState) => ({
                    ...prevState,
                    shipmentQuoteRequests: quoteReq,
                    quoteList: quoteList,
                    shipment_details: shipment_details,
                    location_details: location_details
                }))
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /* file upload*/
    const handleMultipleImages = (e) => {
        let files = e.target.files[0];
        fileNames.push(files)
        setFileNames(fileNames)
        if (fileNames.length <= 4) {
            const targetFilesObject = [...fileNames]
            targetFilesObject.map((file) => {
                return selectedFIles.push(URL.createObjectURL(file))
            })

        }
        setImages(selectedFIles);

    }

    /* Validation Checking */
    const validation = (e, type) => {
        e.preventDefault();
        if (type == 'reject') {
            if (images.length == 0) {
                setErrorValue((prevValue) => {
                    return { ...prevValue, img: 'Please upload images' };
                });
            } else setErrorValue((prevValue) => {
                return { ...prevValue, img: '' };
            });

            if (rejectionFormValue.additional_notes == '') {
                setErrorValue((prevValue) => {
                    return { ...prevValue, additional_notes: 'Please enter message' };
                });
            } else setErrorValue((prevValue) => {
                return { ...prevValue, additional_notes: '' };
            });


            if (rejectionFormValue.additional_notes && images.length > 0) {
                acceptRejectReceiver(type)
            }
        } else {
            acceptRejectReceiver(type)
        }

    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 09/05/2024
    * @Date_Modified 
    * @function async
    * @functionName acceptRejectReceiver
    * @functionPurpose this function for accept or reject request for receiver end.
    *
    * @functionParam {order_id,additional_notes,goods_image}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const acceptRejectReceiver = (type) => {
        let url = ''
        if (type == 'accept') url = RouteURL.receiverChecklistAccept
        else url = RouteURL.receiverChecklistReject
        formData.set(`order_id`, order_id);
        formData.set(`additional_notes`, rejectionFormValue.additional_notes);
        for (let i = 0; i < fileNames.length; i++) {
            formData.append(`goods_image`, fileNames[i]);
        }
        setSubmitDisable(true)
        Service.apiPostCallFormDataRequest(url, formData).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setSubmitDisable(false);
                navigate("/receiver")
            } else {

                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });

    }

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }

    return (
        <>
            <div className="sideright_menu">
                <div className="new_shipment_rt">
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className='booking_hed_txt mb-3'>
                                <div className='row'>
                                    <h4><i class="fa fa-arrow-left" aria-hidden="true" onClick={() => navigate(`/receiver`)} /> Checklist Form</h4>
                                </div>
                            </div>
                            <div className='vehicle_track_inn checklist_frm_sec'>
                                <div className='row'>

                                    <div className='col-sm-7'>
                                        <div className='vehicle_track_sec'>

                                            <div className='reco_trans_lt'>
                                                <div className='reco_trans_lt_top'>
                                                    <h4>{quoteRequests?.shipment_details?.request_id}<br /><span style={{ color: "#FBAA2A", fontSize: 'medium' }}>{quoteRequests?.shipment_details?.shipper_code}</span></h4>
                                                    <h6>{quoteRequests?.shipment_details?.shipper_name}<br />
                                                        <div className="material1_sec">
                                                            <p style={{ color: '#161615' }}> Quote Price: {filteredData.quote_price != null ? ('₹ ' + filteredData.quote_price) : '--'}</p>
                                                            <a href={filteredData?.checklist_details} target="_blank" style={{ color: '#2ED573', textDecoration: 'none' }}>Download LR</a>
                                                        </div>
                                                    </h6>
                                                </div>
                                                <div className='reco_trans_lt_dwn'>
                                                    <div className='row'>
                                                        <div className='col-sm-5'>
                                                            <Slider {...settings}>
                                                                {filteredData?.material_type?.length > 0 && filteredData?.material_type.map((item) => {
                                                                    return (
                                                                        <div className='row'>
                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <p><img src={Dimension} alt="" /> Delivery Date</p>
                                                                                    <h6>{quoteRequests?.location_details?.estimated_delivery_date}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <p><img src={Dimension} alt="" /> Material</p>
                                                                                    <h6>{item?.material_type}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <p><img src={Dimension} alt="" /> Weight</p>
                                                                                    <h6>{item?.package_weight}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <p><img src={Dimension} alt="" /> Dimensions</p>
                                                                                    <h6>{item?.product_dimension}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <p> Number of Boxes</p>
                                                                                    <h6>{item?.no_of_units}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <p>Payment status</p>
                                                                                    <h6>{quoteRequests?.shipment_details?.payment_status == "Paid" ? <>Paid <p style={{ color: '#1E90FF' }}
                                                                                    // onClick={(e) => {
                                                                                    //     paymentDetails(e, 'shipper', item.shipper_transporter_details
                                                                                    //         .request_id, item.quote_price, item.shipper_transporter_details.payment_method, item.document_details
                                                                                    //         .invoice_document
                                                                                    //     )
                                                                                    // }}
                                                                                    >View Details</p></> : quoteRequests?.shipment_details?.payment_status}

                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <p>Delivery Type</p>
                                                                                    <h6>{checkNull(quoteRequests?.shipment_details?.delivery_type)}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-sm-12'>
                                                                                <div className="material1_sec">
                                                                                    <p> Payment Mode</p>
                                                                                    <h6>{quoteRequests?.shipment_details?.payment_mode?.charAt(0).toUpperCase() + quoteRequests?.shipment_details?.payment_mode?.slice(1)}</h6>
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <p>Pickup Type</p>
                                                                                    <h6>{quoteRequests?.shipment_details?.pickup_type == 'Send to us' ? <CTooltip content={item?.shipment_details?.quote_received_details?.address_of_send_to_us == null ? 'Godown Address Not Available' : item?.shipment_details?.quote_received_details?.address_of_send_to_us
                                                                                    }><i class="fa-solid fa-location-dot" style={{ color: "#7068e2" }} /></CTooltip> : ''}{checkNull(quoteRequests?.shipment_details?.pickup_type)}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="material1_sec">
                                                                                    <h6>Product Image</h6>
                                                                                    <div className="productmaterial_image_box">
                                                                                        {item?.images.length > 0 && item?.images.map((img) => {
                                                                                            return (<div className="p_box">
                                                                                                <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                                            </div>
                                                                                            )
                                                                                        })}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)
                                                                })}
                                                            </Slider>
                                                        </div>
                                                        <div className='col-sm-7'>
                                                            <div className="reject_track trans_book">
                                                                <div className="order-track-step">
                                                                    <div className="order-track-status">
                                                                        <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                                                        <span className="order-track-status-line"></span>
                                                                    </div>
                                                                    <div className="order-track-text">
                                                                        <span className="order-track-text-sub">Booking Date - {quoteRequests?.location_details?.booking_date}</span>
                                                                        <p className="order-track-text-stat">{quoteRequests?.location_details?.from_location}</p>
                                                                        <p><span className="kilometer">{quoteRequests?.location_details?.distance}</span></p>
                                                                        <p><span className="kilometer">{quoteRequests?.location_details?.time_duration}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className="order-track-step">
                                                                    <div className="order-track-status">
                                                                        <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                                                        <span className="order-track-status-line"></span>
                                                                    </div>
                                                                    <div className="order-track-text">
                                                                        <span className="order-track-text-sub">Est. Delivery date -{checkNull(quoteRequests?.location_details?.estimated_delivery_date)}</span>
                                                                        <p className="order-track-text-stat">{quoteRequests?.location_details?.to_location}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="checklist_notes">
                                                <textarea placeholder="Add Note" name="additional_notes" value={rejectionFormValue.additional_notes} onChange={(e) => { e.preventDefault(); setRejectionFormValue({ additional_notes: e.target.value }) }}></textarea>
                                                {errorValue.additional_notes && (
                                                    <span className="error">
                                                        {errorValue.additional_notes}
                                                    </span>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="office_address_rt">
                                            <div className="vehicle_rt_box lorry_upload">
                                                <div className="vehicle_txt">
                                                    <h6>Goods Image <span><a href=""> Total image: {images?.length}</a></span></h6>
                                                </div>
                                                <div className="row">

                                                    {images.map((url) => {
                                                        return (<div className="col-sm-6">
                                                            <div className="tata_moto_sec">
                                                                <img src={url} alt="" />
                                                            </div>
                                                        </div>)
                                                    })}

                                                    {images?.length < 4 &&
                                                        <div className="col-sm-4">
                                                            <div className="tata_moto_sec" >
                                                                <input type="file" accept=".jpg,.jpeg,.png"
                                                                    onChange={handleMultipleImages}
                                                                />
                                                                <img src={TataBg} alt="" />
                                                            </div>
                                                        </div>}
                                                </div>
                                                {(images?.length == 0 && errorValue.img) && (
                                                    <span className="error">
                                                        {errorValue.img}
                                                    </span>
                                                )}
                                            </div>

                                        </div>
                                        <div className='reco_trans_rt_inner_dwn'>
                                            <div><button type='reject' style={{ background: '#fff', color: '#000' }} onClick={(e) => validation(e, 'reject')} disabled={submitDisable ? true : false}>Reject</button></div>
                                            <div><button type='reject' style={{ background: '#000', color: '#fff' }}
                                                onClick={(e) => validation(e, 'accept')} disabled={submitDisable ? true : false}>Accept</button></div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}