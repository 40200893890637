/**
 * @file_purpose  page for showing landing page link
 * @author Sarmistha Mondal
 * @Date_Created 15/04/2024
 * @Date_Modified 
 */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

export default function LandingPageFooter() {
    return (<>
        <footer className="footer_sec">
            <div className="footer_sec_up">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1">
                            <div className="footer_inn">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="footer_lt">
                                            <img src={logo} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="footer_lt">
                                            <h6>Quick Links</h6>
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><a href="#about">About us </a></li>
                                                <li><Link to="/terms-and-conditions">Terms & condition </Link></li>
                                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                                <li><Link to="/cancellation-refund-policy">Cancellation & Refund Policy</Link></li>
                                                <li><Link to="/">How it works </Link></li>
                                                <li><Link to="/">Contact us</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="footer_lt">
                                            <div className="footer_lt_dwn">
                                                <h6>Address</h6>
                                                <p style={{ color: '#fff' }}><i class="fa fa-location-arrow" aria-hidden="true" style={{
                                                    color: '#FBAA2A'}}></i> Plot No 69, Sy No.33,34/p, Ravindra Co-op Hs, B Pet, Madhapur, Hyderabad, Shaikpet, Telangana, India, 500081.</p>
                                            </div>
                                            <div className="footer_lt_up">
                                                <h6>Contact</h6>
                                                <p><i className="fa-solid fa-envelope"></i><a href="mailto:support@shipgudds.com">support@shipgudds.com</a></p>
                                            </div>
                                            {/* <div className="footer_lt_dwn">
                                                <h6>Get Social</h6>
                                                <ul>
                                                    <li><a href=""><i className="fa-brands fa-facebook-f"></i></a></li>
                                                    <li><a href=""><i className="fa-brands fa-instagram"></i></a></li>
                                                    <li><a href=""><i className="fa-brands fa-twitter"></i></a></li>
                                                    <li><a href=""><i className="fa-brands fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_sec_dwn">
                <p>Copyright © 2024 Ship Gudds Pvt. Ltd.. All rights reserved.</p>
            </div>
        </footer>
    </>)
}