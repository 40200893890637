/**
 * @file_purpose  page for otp
 * @author Sarmistha Mondal
 * @Date_Created 07/11/2023
 * @Date_Modified 
 */

import React, { useContext, useEffect, useState, useRef } from "react";
import {
    CFormLabel,
    CFormTextarea,
    CButton, CRow, CInputGroup
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE, REGEX, Constants } from "../../Utility/ConstantData";
import { otpInputMaxLength } from "../../Utility/Helper"
import { ToastContainer, toast } from "react-toastify";
import Service from "../../apis/Service";
import RouteURL from "../../apis/RouteURL";
import OtpInput from "react-otp-input";
import Modal from "react-bootstrap/Modal";

const VerifyOTP = (props) => {
    let errorMessage;
    let _token = "";
    const navigate = useNavigate();
    let otpType = props.otpFor.type;
    let emailOrPhone = props.otpFor.emailOrPhone;
    let client_id = props.otpFor.client_id;
    let pageFor = props.otpFor.pageFor;
    let _viewOtp = props.otpFor.viewOtp;
    const [isApiCallInprogress, setIsApiCallInprogress] = useState(false);
    const [newClient_id, setNewClient_id] = useState(client_id);
    const [viewOtp, setViewOtp] = useState(_viewOtp);
    const [formValue, setFormValue] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
    });
    const [state, setState] = useState({
        otp: "",
        otpAadhar: "",
        numInputs: 4,
        separator: "",
        isDisabled: false,
        hasErrored: false,
        isInputNum: true,
        isInputSecure: false,
        minLength: 0,
        placeholder: "",
    });
    const [formValueForAddhar, setFormValueForAddhar] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: ""
    });
    const [otp, setOtp] = useState('');
    const [isDisabled, setIsDisabled] = useState(false)

    const [errorValue, setErrorValue] = useState("");
    const [totpTouchValue, setTotpTouchValue] = useState(false);
    const [countDown, setCountDown] = React.useState(0);
    const [runTimer, setRunTimer] = React.useState(false);
    const [colorStyle, setColorStyle] = useState({
        border1: "1px solid rgb(22 22 21)",
        background1: "",
        border2: "1px solid rgb(22 22 21)",
        background2: "",
        border3: "1px solid rgb(22 22 21)",
        background3: "",
        border4: "1px solid rgb(22 22 21)",
        background4: "",
        border5: "1px solid rgb(22 22 21)",
        background5: "",
        border6: "1px solid rgb(22 22 21)",
        background6: "",
    });

    useEffect(() => {
        localStorage.setItem("pageFor", pageFor)
        // console.log('otp page', props);
        // console.log(runTimer, countDown, runTimer);
        togglerTimer('');
    }, []);
    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

    useEffect(() => {
        let timerId;
        // console.log(runTimer);
        if (runTimer) {
            setCountDown(60 * 2);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer]);

    useEffect(() => {
        // console.log(countDown, runTimer);
        if (countDown < 0 && runTimer) {
            // console.log("expired");
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);


    useEffect(() => {
        return () => {
            setCountDown(0)
            setRunTimer(false)
            console.log("cleaned up");
        };
    }, []);

    /* otp timmer */ 
    const togglerTimer = (data) => {
        if (data != '' && otpType == 'email' && pageFor != 'forgotpass') {
            _generateEmailForOTP()
            setRunTimer((t) => !t);
        } else if (data != '' && otpType == 'phone' && pageFor != 'forgotpass') {
            _generateWhatsappOTP()
            setRunTimer((t) => !t);
        } else if (data != '' && otpType == 'gst' && pageFor != 'forgotpass') {
            _generateGstNumber(); setRunTimer((t) => !t);
        } else if (data != '' && otpType == 'aadhar' && pageFor != 'forgotpass') {
            _generateAadharNumber(); setRunTimer((t) => !t);
        } else if (data != '' && (otpType == 'email' || otpType == 'phone' || props.otpFor.emailOrPhone) && props.otpFor.pageFor == 'forgotpass') {
            _generateForgotPass(); setRunTimer((t) => !t);
        }
        else setRunTimer((t) => !t);

    }

    /* on change input function */
    const otpChange = (event) => {
        const { name, value } = event.target;
        const { data } = event.nativeEvent;
        // console.log(name,'data', data, 'value', value);
        if (otpType != 'aadhar') {
            setFormValue((prevValue) => {
                // return { ...prevValue, [name]: value.length === 1 && value };
                return { ...prevValue, [name]: data == null ? '' : data.length === 1 && data };
            });
            gotoDesiredInput(event);
            // changeOnKeyDown(event)
        } else {
            setFormValueForAddhar((prevValue) => {
                // return { ...prevValue, [name]: data.length === 1 && data };
                return { ...prevValue, [name]: value.length === 1 && value };
            });
            gotoDesiredInputForAadhar(event);
        }

    };

    const changeOnKeyDown = (event) => {
        const name = event.target.name;
        const totpNumber = name[name.length - 1];
        const isBackSpace = event.target.value === "";

    }

    /* for input field delete otp*/ 
    const gotoDesiredInput = (event) => {
        const name = event.target.name;
        const totpNumber = name[name.length - 1];
        const isBackSpace = event.target.value === "";
        const inputs = document.getElementById("inputs");
        inputs.addEventListener("keyup", function (e) {
            const target = e.target;
            const key = e.key.toLowerCase();
            // console.log(key);
            if (key == "backspace" || key == "delete") {
                const prev = target.previousElementSibling;
                if (prev) {
                    prev.focus();
                }
                return;
            }
        });
        if ((!isBackSpace && totpNumber < 4)) {
            const el = document.getElementsByName(
                `otp${isBackSpace ? totpNumber - 1 : +totpNumber + 1}`
            );
            el[0].focus();
        }
      
    };

    /* for aadhar input field delete otp*/ 
    const gotoDesiredInputForAadhar = (event) => {
        const name = event.target.name;
        const totpNumber = name[name.length - 1];
        const isBackSpace = event.target.value === "";
        const inputs = document.getElementById("inputs");
        inputs.addEventListener("keyup", function (e) {
            const target = e.target;
            const key = e.key.toLowerCase();
            if (key == "backspace" || key == "delete") {
                const prev = target.previousElementSibling;
                if (prev) {
                    prev.focus();
                }
                return;
            }
        });

        if (!isBackSpace && totpNumber < 6) {
            const el = document.getElementsByName(
                `otp${isBackSpace ? totpNumber - 1 : +totpNumber + 1}`
            );
            el[0].focus();
        }
      
    };

    /**
      * @author Sarmistha Mondal
      * @Date_Created 16.11.2023
      * @Date_Modified 
      * @function async
      * @functionName _generateEmailForOTP
      * @functionPurpose this function for
      * otp send in email 
      *
      *  @functionParam {payload object: email}
      * 
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _generateEmailForOTP = () => {
        let params = JSON.stringify({
            email: emailOrPhone,
        })
        setIsDisabled(true);
        Service.apiPostCallRequest(RouteURL.generateEmailOtp, params).then(
            (response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    // setIs_email_verified(1)

                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });

                } else {
                    // setIs_email_verified(0)
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                setIsDisabled(false);
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
       * @author Sarmistha Mondal
       * @Date_Created 16.11.2023
       * @Date_Modified 
       * @function async
       * @functionName _generateWhatsappOTP
       * @functionPurpose this function for
       * otp send in whatsapp 
       *
       *  @functionParam {payload object: phone_number}
       * 
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _generateWhatsappOTP = () => {
        let params = JSON.stringify({
            phone_number: emailOrPhone,
        })
        setIsDisabled(true);
        Service.apiPostCallRequest(RouteURL.generateWhatsappOtp, params).then(
            (response) => {
                console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    setViewOtp(response.data.otp_for_whatsapp_verification)
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 16.11.2023
      * @Date_Modified 
      * @function async
      * @functionName _generateGstNumber
      * @functionPurpose this function for
      * verify gst
      *
      *  @functionParam {payload object: gst_number}
      * 
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _generateGstNumber = () => {
        let params = JSON.stringify({
            gst_number: emailOrPhone,
        })
        setIsDisabled(true);
        Service.apiPostCallRequest(RouteURL.verifyGstnumber, params).then(
            (response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    console.log(response);
                    let resOcrDetails = response.data.ocrDetails;
                    setNewClient_id(resOcrDetails.client_id)
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });

                } else {

                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 13.02.2024
      * @Date_Modified 
      * @function async
      * @functionName _generateAadharNumber
      * @functionPurpose this function for
      * verify aadhar
      *
      *  @functionParam {payload object: aadhar_number}
      * 
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _generateAadharNumber = () => {
        setIsDisabled(true);
        let params = JSON.stringify({
            aadhar_number: emailOrPhone,
        })
        Service.apiPostCallRequest(RouteURL.verifyAadharnumber, params).then(
            (response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let resOcrDetails = response.data.ocrDetails;
                    setNewClient_id(resOcrDetails.client_id)
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });

                } else {

                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                setIsDisabled(false);
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }
      
    /* concate all otp field result*/ 
    const getFinalOTP = () => {

        for (var key in formValue) {
            _token += formValue[key].toString();
        }
        return _token;
    };

    /* concate all otp field result*/
    const getFinalOTPForAadhar = () => {

        for (var key in formValueForAddhar) {
            _token += formValueForAddhar[key].toString();
        }
        return _token;
    };

    /* verify otp */ 
    const _verifyOTP = (event) => {
        event.preventDefault();
        setTotpTouchValue(true);
        let _otp = getFinalOTP();
        let _otpForAadhar = getFinalOTPForAadhar();
        // console.log(_otp, otpType);
        if (_otp.match(REGEX["otp"])) {
            console.log(_otp, _otpForAadhar);
            if (localStorage.getItem("pageFor") == 'signup') {
                console.log(_otp, otpType);
                if (otpType == 'email') _EmailOTP(_otp)
                if (otpType == 'phone') _PhoneOTP(_otp)
                if (otpType == 'gst') _GstOTP(_otp)
                // if (otpType == 'aadhar') _AadharOTP(_otpForAadhar)
            } else _ValidateForgotPasswordOTP(_otp)
        } else if (_otpForAadhar.match(REGEX["otpAdhar"])) {
            if (otpType == 'aadhar') _AadharOTP(_otpForAadhar)
        }
      
    };

    /**
      * @author Sarmistha Mondal
      * @Date_Created 16.11.2023
      * @Date_Modified 
      * @function async
      * @functionName _EmailOTP
      * @functionPurpose this function for
      * otp send in email 
      *
      *  @functionParam {payload object: email,otp}
      * 
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _EmailOTP = (otp) => {
        setIsApiCallInprogress(true);
        let params = JSON.stringify({
            email: emailOrPhone,
            otp: otp
        })
        Service.apiPostCallRequest(RouteURL.verifyEmailOtp, params).then(
            (response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

                    // setIsApiCallInprogress(false);
                    setFormValue({})
                    setCountDown(0)
                    localStorage.setItem("email", emailOrPhone)
                    localStorage.setItem("is_email_verified", 1)
                    // props.otpModalClose();
                    props.onChangeSomeState(1)
                    props.otpModalCloseForOTP('ok')
                    setState({ otp: "" })
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });

                } else {
                    console.log(response);
                    props.onChangeSomeState(0)
                    // setIsApiCallInprogress(false);
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    setIsApiCallInprogress(false);
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 21.11.2023
      * @Date_Modified 
      * @function async
      * @functionName _PhoneOTP
      * @functionPurpose this function for
      * verify phone no otp
      *
      *  @functionParam {payload object: whatsapp_number,otp}
      * 
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _PhoneOTP = (otp) => {
        setIsApiCallInprogress(true);
        let params = JSON.stringify({
            whatsapp_number: emailOrPhone,
            otp: otp
        })
        Service.apiPostCallRequest(RouteURL.verifyWhatsappOtp, params).then(
            (response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    // setIsApiCallInprogress(false);
                    setFormValue({})
                    setCountDown(0)
                    localStorage.setItem("phone", emailOrPhone)
                    localStorage.setItem("is_whatsapp_number", 1)
                    // props.otpModalClose();
                    props.onChangeSomeState(1)
                    props.otpModalCloseForOTP('ok')
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });

                } else {
                    props.onChangeSomeState(0)
                    // setIsApiCallInprogress(false);
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    setIsApiCallInprogress(false);
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }


    /**
     * @author Sarmistha Mondal
     * @Date_Created 22.11.2023
     * @Date_Modified 
     * @function async
     * @functionName _ValidateForgotPasswordOTP
     * @functionPurpose this function for
     * otp send in email
     *
     *  @functionParam {payload object: username,otp}
     * 
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _ValidateForgotPasswordOTP = (otp) => {
        setIsApiCallInprogress(true);
        let params = JSON.stringify({
            username: emailOrPhone,
            otp: otp
        })
        Service.apiPostCallRequest(RouteURL.validateForgotpasswordOTP, params).then(
            (response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    // setIsApiCallInprogress(false);
                    // props.otpModalClose();
                    // props.onChangeSomeState(1)
                    props.otpModalCloseForOTP('ok')
                    setState({ otp: "" })
                    setFormValue({})
                    setCountDown(0)
                    localStorage.setItem("resetPasswordUserName", emailOrPhone)
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });

                } else {
                    // setIsApiCallInprogress(false);
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    setIsApiCallInprogress(false);
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
     * @author Sarmistha Mondal
     * @Date_Created 09.02.2024
     * @Date_Modified 
     * @function async
     * @functionName _GstOTP
     * @functionPurpose this function for
     * verify gst otp
     *
     *  @functionParam {payload object: client_id,otp}
     * 
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _GstOTP = (otp) => {
        setIsApiCallInprogress(true);
        let params = JSON.stringify({
            // gst_number: emailOrPhone,
            client_id: newClient_id,
            otp: otp
        })
        Service.apiPostCallRequest(RouteURL.verifyGstOtp, params).then(
            (response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let resOcrDetails = response.data.responseData;
                    // setIsApiCallInprogress(false);
                    setState({ otp: "" })
                    setFormValue({})
                    setCountDown(0)
                    // localStorage.setItem("gstno", emailOrPhone)
                    localStorage.setItem("is_gst_number", 1)
                    // props.otpModalClose();
                    props.onChangeSomeState(1)
                    props.otpModalCloseForOTP('forGst', resOcrDetails)
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });

                } else {
                    props.onChangeSomeState(0)

                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    setIsApiCallInprogress(false);
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
     * @author Sarmistha Mondal
     * @Date_Created 13.02.2024
     * @Date_Modified 
     * @function async
     * @functionName _AadharOTP
     * @functionPurpose this function for
     * verify aadhar otp 
     *
     *  @functionParam {payload object: aadhar_number,otp,client_id}
     * 
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _AadharOTP = (otp) => {
        setIsApiCallInprogress(true);
        let params = JSON.stringify({
            aadhar_number: emailOrPhone,
            client_id: newClient_id,
            otp: otp
        })
        Service.apiPostCallRequest(RouteURL.verifyAadharOtp, params).then(
            (response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let resOcrDetails = response.data.responseData;
                    setState({ otpAadhar: "" })
                    setFormValueForAddhar({})
                    setCountDown(0)
                    // localStorage.setItem("aadharNo", emailOrPhone)
                    localStorage.setItem("is_aadhar_number", 1)
                    // props.otpModalClose();
                    props.onChangeSomeState(1)
                    // props.otpModalCloseForOTP('ok')
                    props.otpModalCloseForOTP('forAadhar', resOcrDetails)
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });

                } else {
                    props.onChangeSomeState(0)
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsApiCallInprogress(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    setIsApiCallInprogress(false);
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
     * @author Sarmistha Mondal
     * @Date_Created 06/11/2023
     * @Date_Modified 
     * @function async
     * @functionName _generateForgotPass
     * @functionPurpose for forgot password otp generate.
     *
     * @functionParam {username}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _generateForgotPass = () => {
        let params = JSON.stringify({
            username: emailOrPhone,
        });
        Service.apiPostCallRequest(RouteURL.generateForgotpasswordOTP, params).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

                toast.success(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                },);

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }).catch((error) => {
            // console.log(error);
            if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            }
        });
    };

    return (
        <>
            < Modal
                // show={openModalForOTP.visible}
                // onHide={() => setopenModalForOTP({ visible: !openModalForOTP.visible })}
                show={props.show}
                onHide={() => { props.onHide(); setFormValue({}); setCountDown(0) }}
                backdrop="static"
                size={otpType == 'aadhar' ? "lg" : 'md'}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Verify </h6>
                    </Modal.Header>
                    <div className="task_modal_sec">
                        <form onSubmit={_verifyOTP}>
                            <section className="forgot_inn fotgot_pad">

                                <p>Please enter your verification code sent to <span>
                                    {/* {otpType != "phone" ? emailOrPhone?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2") : `+91-` + emailOrPhone?.slice(0, 2) + emailOrPhone?.slice(2).replace(/.(?=...)/g, '*')} */}
                                    {(() => {
                                        switch (otpType) {
                                            case "phone":
                                                return `+91-` + emailOrPhone?.slice(0, 2) + emailOrPhone?.slice(2).replace(/.(?=...)/g, '*');
                                            case "email":
                                                return emailOrPhone?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2");
                                            case "gst":
                                                return emailOrPhone?.slice(0, 2) + emailOrPhone?.slice(2).replace(/.(?=...)/g, '*');
                                            case "aadhar":
                                                return emailOrPhone?.slice(0, 2) + emailOrPhone?.slice(2).replace(/.(?=...)/g, '*');
                                            default:
                                                return null;
                                        }
                                    })()}</span></p>
                                {otpType != 'aadhar' &&
                                    <div className="otp_box" id="inputs">

                                        <input
                                            type="number"
                                            name="otp1"
                                            value={formValue.otp1}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            // onKeyPress={() => { if (formValue.otp1.length==1) return false}}
                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border1: "none",
                                                        background1: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border1: "1px solid rgb(22 22 21)",
                                                    background1: ""
                                                };
                                            })}
                                            error={errorValue ? 'true' : 'false'}
                                            style={{ border: colorStyle.border1, background: colorStyle.background1 }}

                                        />
                                        <input
                                            type="number"
                                            name="otp2"
                                            value={formValue.otp2}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            // onKeyPress={() => { if (formValue.otp2.length == 1) return false }}
                                            error={errorValue ? 'true' : 'false'}
                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border2: "none",
                                                        background2: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border2: "1px solid rgb(22 22 21)",
                                                    background2: ""
                                                };
                                            })}
                                            style={{ border: colorStyle.border2, background: colorStyle.background2 }}

                                        />
                                        <input
                                            type="number"
                                            name="otp3"
                                            value={formValue.otp3}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            // onKeyPress={() => { if (formValue.otp3.length == 1) return false }}
                                            error={errorValue ? 'true' : 'false'}

                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border3: "none",
                                                        background3: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border3: "1px solid rgb(22 22 21)",
                                                    background3: ""
                                                };
                                            })}
                                            style={{ border: colorStyle.border3, background: colorStyle.background3 }}
                                        />
                                        <input
                                            type="number"
                                            name="otp4"
                                            value={formValue.otp4}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            // onKeyPress={() => { if (formValue.otp4.length == 1) return false }}
                                            error={errorValue ? 'true' : 'false'}
                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border4: "none",
                                                        background4: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border4: "1px solid rgb(22 22 21)",
                                                    background4: ""
                                                };
                                            })}
                                            style={{ border: colorStyle.border4, background: colorStyle.background4 }}
                                        />
                                        {/* <input type="number" placeholder="" style={{ border: "1px solid rgb(22 22 21)" }} />
                                    <input type="number" placeholder="" style={{background:"#F1F1F7", border:"none"}}/>
                                    <input type="number" placeholder="" />
                                    <input type="number" placeholder="" /> */}
                                    </div>}
                                {/* addhar no */}
                                {otpType == 'aadhar' &&
                                    <div className="otp_box" id="inputs">
                                        <input
                                            type="number"
                                            name="otp1"
                                            value={formValueForAddhar.otp1}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            // onKeyPress={() => { if (formValue.otp1.length==1) return false}}
                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border1: "none",
                                                        background1: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border1: "1px solid rgb(22 22 21)",
                                                    background1: ""
                                                };
                                            })}
                                            error={errorValue ? 'true' : 'false'}
                                            style={{ border: colorStyle.border1, background: colorStyle.background1 }}

                                        />
                                        <input
                                            type="number"
                                            name="otp2"
                                            value={formValueForAddhar.otp2}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            error={errorValue ? 'true' : 'false'}
                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border2: "none",
                                                        background2: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border2: "1px solid rgb(22 22 21)",
                                                    background2: ""
                                                };
                                            })}
                                            style={{ border: colorStyle.border2, background: colorStyle.background2 }}

                                        />
                                        <input
                                            type="number"
                                            name="otp3"
                                            value={formValueForAddhar.otp3}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            error={errorValue ? 'true' : 'false'}

                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border3: "none",
                                                        background3: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border3: "1px solid rgb(22 22 21)",
                                                    background3: ""
                                                };
                                            })}
                                            style={{ border: colorStyle.border3, background: colorStyle.background3 }}
                                        />
                                        <input
                                            type="number"
                                            name="otp4"
                                            value={formValueForAddhar.otp4}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            error={errorValue ? 'true' : 'false'}
                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border4: "none",
                                                        background4: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border4: "1px solid rgb(22 22 21)",
                                                    background4: ""
                                                };
                                            })}
                                            style={{ border: colorStyle.border4, background: colorStyle.background4 }}
                                        />
                                        <input
                                            type="number"
                                            name="otp5"
                                            value={formValueForAddhar.otp5}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            error={errorValue ? 'true' : 'false'}
                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border5: "none",
                                                        background5: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border5: "1px solid rgb(22 22 21)",
                                                    background5: ""
                                                };
                                            })}
                                            style={{ border: colorStyle.border5, background: colorStyle.background5 }}
                                        />
                                        <input
                                            type="number"
                                            name="otp6"
                                            value={formValueForAddhar.otp6}
                                            onChange={otpChange}
                                            onKeyDown={changeOnKeyDown}
                                            inputMode="numeric"
                                            pattern="\d{1}"
                                            maxLength={1}
                                            error={errorValue ? 'true' : 'false'}
                                            onFocus={() => {
                                                setColorStyle((prevValue) => {
                                                    return {
                                                        ...prevValue, border6: "none",
                                                        background6: "#F1F1F7"
                                                    };
                                                })
                                            }}
                                            onBlur={() => setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border6: "1px solid rgb(22 22 21)",
                                                    background6: ""
                                                };
                                            })}
                                            style={{ border: colorStyle.border6, background: colorStyle.background6 }}
                                        />

                                    </div>}
                                <p style={{ paddingTop: 15, color: "#161615", textAlign: "center", margin: 0 }}>{minutes}:{seconds}</p>
                                <p style={{ paddingTop: 15, color: "#C5C4D2", textAlign: "center", margin: 0 }} >Not Received the Code? &nbsp;
                                    <span style={{ color: seconds == '00' ? "#FBAA2A" : '#C5C4D2', cursor: 'pointer' }} onClick={() => { if (seconds == '00') togglerTimer('trigger') }} disabled={isDisabled ? true : false}>
                                        Resend</span></p>

                                {otpType == "phone" && "otp " + viewOtp}
                                <div className="create_sub text-center">
                                    <div className="login_btn">


                                        <button type="submit" value="get Started" style={{ background: (otpType != 'aadhar' ? (Object.values(formValue).every(x => x != '') == false) : (Object.values(formValueForAddhar).every(x => x != '') == false)) ? '#C5C4D2' : '#000' }}
                                            disabled={isApiCallInprogress ? true : false}>Verify</button>
                                    </div>
                                </div>
                            </section>
                        </form>

                    </div>

                </div>
            </Modal >


        </>
    );
};

export default VerifyOTP;
