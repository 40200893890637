
/**
 * @file_purpose  page for showing quotetion list
 * @author Sarmistha Mondal
 * @Date_Created 09/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Dimension from "../../../assets/images/square-measument.png"
import TallTruck from "../../../assets/images/tall_truck.png"
import Call from "../../../assets/images/phone-call.png"
import Whtsp from "../../../assets/images/whtsp.png"
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Slider from "react-slick";
import { Rating } from 'react-simple-star-rating'
import ReactWhatsapp from 'react-whatsapp';
import { CTooltip } from "@coreui/react";
import QuotesRequestAcceptsRejectModal from "./quoteRequestAcceptReject";



function QuoteTransporterList() {
    const navigate = useNavigate();
    const { bookingid, quoteid } = useParams();
    const [submitDisable, setSubmitDisable] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(0);
    const [quoteRequests, setQuoteRequests] = useState({
        shipmentQuoteRequests: {},
        location_details: {},
        material_typeDetails: [],
        quoteList: [],
        shipment_details: {}
    });
    const [visible, setVisible] = useState()
    const [modalValue, setModalValue] = useState({
        type: "",
        shipmentBookingId: "",
        shipmentRequestId: "",
        payload: {}
    });

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        touchMove: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    useEffect(() => {
        _ShipmentQuoteRequestTransporter()
    }, []);

    /**
      * @author Sarmistha Mondal
      * @Date_Created 09/04/2024
      * @Date_Modified 
      * @function async
      * @functionName _ShipmentQuoteRequestTransporter
      * @functionPurpose this function for shipment quote details.
      *
      * @functionParam {shipment_booking_id}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _ShipmentQuoteRequestTransporter = () => {
        let params = JSON.stringify({
            shipment_booking_id: bookingid,
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.shippmentQuoteRequests, params).then((response) => {
           
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let quoteReq = response.data.shipmentQuoteRequests;
                let material_typeDetails = response.data.shipmentQuoteRequests.material_type;
                let quoteList = response.data.shipmentQuoteRequests.quoteList;
                let shipment_details = response.data.shipmentQuoteRequests.shipment_details;
                let location_details = response.data.shipmentQuoteRequests.location_details;
                setQuoteRequests((prevState) => ({
                    ...prevState,
                    shipmentQuoteRequests: quoteReq,
                    material_typeDetails: material_typeDetails,
                    quoteList: quoteList,
                    shipment_details: shipment_details,
                    location_details: location_details
                }))
                setSubmitDisable(false)


            } else {
               
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });

    };

    /* modal open*/ 
    const _acceptRejectModal = (e, booking_id, id, type, data) => {
        e.preventDefault();
        setVisible(!visible)
        setModalValue({
            type: type,
            shipmentBookingId: booking_id,
            shipmentQuoteId: id,
            payload: data
        })

    }

    /* modal close*/ 
    const closeAddEditModal = () => {
        setVisible(false)
    };

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }

    return (
        <div>

            <div className="sideright_menu">
                <div className="new_shipment_rt">
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className='booking_hed_txt mb-3'>
                                <h4><i class="fa fa-arrow-left" aria-hidden="true" onClick={() => navigate(`/shipment-list`)} style={{ cursor: 'pointer' }} /> Quote Transporter Lists</h4>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 col-xxl-4 col-xl-4 col-lg-12 col-md-12 '>
                                    <div className='reco_trans_lt'>
                                        <div className='reco_trans_lt_top'>
                                            <h4>{quoteRequests?.shipment_details?.request_id}</h4>
                                            <p>{quoteRequests?.shipment_details?.shipper_code}</p>
                                            <h6>{quoteRequests?.shipment_details?.name}</h6>
                                        </div>
                                        <div className='reco_trans_lt_dwn'>
                                            <div className='row'>
                                                <div className='col-sm-12 col-xxl-5 col-xl-12'>
                                                    <div className='row'>
                                                        <Slider {...settings}>
                                                            {quoteRequests?.material_typeDetails.length > 0 && quoteRequests?.material_typeDetails.map((item, key) => {
                                                                return (<div>

                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p><img src={Dimension} alt="" /> Material</p>
                                                                            <h6>{item?.material_type}</h6>
                                                                        </div>
                                                                    </div> 
                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="10.141" height="10" viewBox="0 0 5.141 6">
                                                                                <g id="scale" transform="translate(-36.645)">
                                                                                    <g id="Group_8799" data-name="Group 8799" transform="translate(36.645)">
                                                                                        <g id="Group_8798" data-name="Group 8798">
                                                                                            <path id="Path_9414" data-name="Path 9414" d="M40.915,1.059A2.128,2.128,0,0,0,41.761.27.179.179,0,0,0,41.608,0H36.824a.179.179,0,0,0-.154.27,2.127,2.127,0,0,0,.847.789,2.531,2.531,0,0,0,1.018.282V1.8H37.69a.627.627,0,0,0-.621.55L36.708,5.3a.626.626,0,0,0,.621.7H41.1a.626.626,0,0,0,.621-.7L41.363,2.35a.627.627,0,0,0-.621-.55H39.9V1.341A2.532,2.532,0,0,0,40.915,1.059ZM40.681,3.9a1.465,1.465,0,1,1-1.465-1.465A1.466,1.466,0,0,1,40.681,3.9Z" transform="translate(-36.645)" fill="#928fa9" />
                                                                                        </g>
                                                                                    </g>
                                                                                    <g id="Group_8801" data-name="Group 8801" transform="translate(38.1 2.781)">
                                                                                        <g id="Group_8800" data-name="Group 8800">
                                                                                            <path id="Path_9415" data-name="Path 9415" d="M162.628,238.308a1.115,1.115,0,1,0,1.115,1.115A1.117,1.117,0,0,0,162.628,238.308Zm.127,1.243-.433.433a.18.18,0,1,1-.255-.255l.433-.433a.18.18,0,0,1,.255.255Z" transform="translate(-161.513 -238.308)" fill="#928fa9" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </svg> Weight</p>
                                                                            <h6>{item?.package_weight}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.017 6">
                                                                                <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                            </svg> Dimensions</p>
                                                                            <h6>{item?.product_dimension}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.017 6">
                                                                                <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                            </svg> Number of Boxes</p>
                                                                            <h6>{item?.no_of_units}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p> Loading Type</p>
                                                                            <h6>{quoteRequests?.shipment_details?.load_type}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p> Payment Mode</p>
                                                                            <h6>{quoteRequests?.shipment_details?.payment_mode?.charAt(0).toUpperCase() + quoteRequests?.shipment_details?.payment_mode?.slice(1)}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            })}

                                                        </Slider>

                                                        <span className="slider_number_count">{buttonVisible}/<span style={{ color: "#fbaa2a" }}>{quoteRequests?.material_typeDetails.length}</span></span>

                                                    </div>
                                                </div>
                                                <div className='col-sm-12 col-xxl-7 col-xl-12'>
                                                    <div className="reject_track trans_book">
                                                        <div className="order-track-step">
                                                            <div className="order-track-status">
                                                                <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                                                <span className="order-track-status-line"></span>
                                                            </div>
                                                            <div className="order-track-text">
                                                                <span className="order-track-text-sub">Booking Date - {quoteRequests?.location_details?.booking_date}</span>
                                                                <p className="order-track-text-stat">{quoteRequests?.location_details?.from_location}</p>
                                                                <span className="kilometer">{quoteRequests?.location_details?.distance}</span>
                                                                <span className="kilometer">{quoteRequests?.location_details?.time_duration}</span>
                                                            </div>
                                                        </div>
                                                        <div className="order-track-step">
                                                            <div className="order-track-status">
                                                                <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                                                <span className="order-track-status-line"></span>
                                                            </div>
                                                            <div className="order-track-text">
                                                                <span className="order-track-text-sub">Est. Delivery date - {quoteRequests?.quoteList[0]?.quoteDetails
                                                                    .estimated_delivery_date != null ? quoteRequests?.quoteList[0]?.quoteDetails
                                                                    .estimated_delivery_date : ' _____'}</span>
                                                                <p className="order-track-text-stat">{quoteRequests?.location_details?.to_location}</p>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-xxl-8 col-xl-8 col-lg-12 col-md-12'>
                                    {quoteRequests.quoteList.length > 0 ? quoteRequests.quoteList.map((item, key) => {

                                        return <div className='reco_trans_rt' key={key}>
                                            <div className='reco_trans_rt_inner'>
                                                <div className='reco_trans_rt_inner_top'>
                                                    <div className='row'>
                                                        <div className='col-sm-12 col-xxl-6 col-xl-12 col-lg-12 col-md-12'>
                                                            <div className='reco_trans_rt_box qt_brdr '>
                                                                <div className='reco_trans_rt_img'>
                                                                    <img src={item?.profile_image ? item.profile_image : TallTruck} alt='' />
                                                                </div>
                                                                <div className='reco_trans_rt_details'>
                                                                    <h4>{item.firm_name}</h4>
                                                                    <div className='rrv_sec'>
                                                                        <div className='rating'>
                                                                            <Rating
                                                                                initialValue={item.no_of_reviews}
                                                                                allowFraction={true}
                                                                                readonly
                                                                                size={18}
                                                                            />
                                                                        </div>
                                                                        <div className='review'>
                                                                            <span><strong>{item.no_of_reviews}</strong> Reviews</span>
                                                                        </div>
                                                                        <div className='verified'>
                                                                            <span>Verified</span>
                                                                        </div>
                                                                    </div>
                                                                    <p>Avg Response Time <span>{item.average_response_time_in_hour} hour</span></p>
                                                                    <p><span>{item.year_of_experience} Yrs</span> Experiences</p>
                                                                    <p><span>{item.request_served}</span> Request Served</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-5 col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12'>
                                                            <div className='reco_trans_rt_box ps-3 quote_recomended'>
                                                                <div className="material1_sec">
                                                                    <p> Estimated Delivery Date</p>
                                                                    <h6>{item.quoteDetails.estimated_delivery_date}</h6>
                                                                </div>
                                                                <div className="material1_sec">
                                                                    <p> Pickup Type</p>
                                                                    <h6>{item?.quoteDetails?.pickup_type == 'Send to us' ? <CTooltip content={item?.quote_received_details?.address_of_send_to_us == null ? 'Godown Address Not Available' : item?.quote_received_details?.address_of_send_to_us
                                                                    }><i class="fa-solid fa-location-dot" style={{ color: "#7068e2" }} /></CTooltip> : ''} {item.quoteDetails.pickup_type}</h6>
                                                                </div>
                                                                <div className="material1_sec">
                                                                    <p>Quoted Price</p>
                                                                    <h6>₹ {item.quoteDetails.quote_price}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-7 col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-12'>
                                                            <div className='reco_trans_rt_box quote_recomended'>
                                                                <div className='reco_trans_rt_box1 ps-3'>
                                                                
                                                                    <div className="material1_sec">
                                                                        <p> Delivery Type</p>
                                                                        <h6>{item.quoteDetails.delivery_type}</h6>
                                                                    </div>
                                                                    <div className="material1_sec">
                                                                        <p>Additional Notes</p>
                                                                        <h6>{item.additional_notes == null ? 'NA' : item?.additional_notes}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className='reco_trans_rt_box1 wt-40 me-0'>
                                                                    <div className='number_box'>
                                                                        <h4>{item.transporter_code}</h4>
                                                                        <span ></span>
                                                                        <div className='call'>
                                                                            <span> <CTooltip content={item.contact_number
                                                                            }><img src={Call} alt='' /></CTooltip></span>
                                                                            <span><ReactWhatsapp number={item.contact_number
                                                                            } ><img src={Whtsp} alt='' /></ReactWhatsapp></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='reco_trans_rt_inner_dwn'>
                                                    <div><button type='reject' value="reject" disabled={submitDisable ? true : false} onClick={(e) => _acceptRejectModal(e, item.quoteDetails.booking_id, item.quoteDetails.id, 'reject', quoteRequests)} style={{ background: '#fff', color: '#000' }} >Reject</button></div>
                                                    <div><button type='reject' value="reject" disabled={submitDisable ? true : false} onClick={(e) => _acceptRejectModal(e, item.quoteDetails.booking_id, item.quoteDetails.id, 'accept', quoteRequests)} style={{ background: '#000', color: '#fff' }} >Accept</button></div>
                                                </div>
                                            </div>

                                        </div>

                                    }) : <div className="blank_quote">No quatation received yet ...</div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {visible && <QuotesRequestAcceptsRejectModal _allData={modalValue}
                CloseAddEditModal={closeAddEditModal} show={visible} onHide={() => setVisible(false)}            
            />}
        </div>
    )
}

export default QuoteTransporterList