/**
 * @file_purpose  page for Congratulation
 * @author Satyajeet
 * @Date_Created 14/02/2024
 * @Date_Modified
 */

import React from "react";
import Modal from "react-bootstrap/Modal";
import noconnection from "../assets/images/noconnection.png";

export default function ConnectionError(props) {
  const _pageNavigation = () => {
    window.location.reload();
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.onHide()}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="forgot_sec">
          <div className="task_modal_sec">
            <section className="forgot_inn">
              <img style={{ paddingTop: 30 }} src={noconnection} alt="" />
              <h6>Oops! No Internet Connection</h6>
              <p>
                Make sure Wi-Fi or cellular data is turned on and then try
                again.
              </p>
              <div className="create_sub text-center">
                <div className="login_btn">
                  <button
                    type="submit"
                    value="get Started"
                    onClick={_pageNavigation}
                    style={{ background: "#000" }}
                  >
                    TRY AGAIN
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </>
  );
}
