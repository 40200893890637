/**
 * @file_purpose  page for showing shipment update quotes
 * @author Sarmistha Mondal
 * @Date_Created 15/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim,
} from "../../../Utility/Helper";
import Modal from "react-bootstrap/Modal";
import Dimension from "../../../assets/images/square-measument.png";
import Slider from "react-slick";
import SmallTruck from "../../../assets/images/sm_truck.png"


export default function UpdateQuotesModal(props) {
    const modalRef = useRef(null);
    let Type = props._allData.type;
    let shipment_booking_id = props._allData.shipmentBookingId;
    let Quote_price = props._allData.quote_price;
    let Order_id = props._allData.order_id
    const [filteredData, setFilteredData] = useState({});
    const [buttonVisible, setButtonVisible] = useState(0);
    const [productDetailsShow, setProductDetailsShow] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [formValue, setFormValue] = useState({
        updated_quote_price: '',
    });

    const [formTouchValue, setFormTouchValue] = useState({
        updated_quote_price: false,
    });
    const [errorValue, setErrorValue] = useState({
        updated_quote_price: '',
    });

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        touchMove: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };



    // focus within a specific area
    useEffect(() => {
        if (props.show) {
            const modalElement = modalRef.current;
            //add any focusable HTML element you want to include to this string
            const focusableElements = modalElement.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event) => {
                if (event.key === "Tab") {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event) => {
                if (event.key === "Escape") {
                    props.onHide();
                }
            };

            modalElement.addEventListener("keydown", handleTabKeyPress);
            modalElement.addEventListener("keydown", handleEscapeKeyPress);

            return () => {
                modalElement.removeEventListener("keydown", handleTabKeyPress);
                modalElement.removeEventListener("keydown", handleEscapeKeyPress);
            };
        }
    }, [props.show]);


    useEffect(() => {
        if (shipment_booking_id) _shipmentRequestDetails();
    }, [shipment_booking_id])


    // useEffect(() => {
    //     if (formValue) {
    //         console.log(1);
    //         checkError("updated_quote_price");
    //     }
    // }, [formValue])




    /**
    * @author Sarmistha Mondal
    * @Date_Created 15/04/2024
    * @Date_Modified 
    * @function async
    * @functionName _shipmentRequestDetails
    * @functionPurpose this function for shipment request details.
    *
    * @functionParam {shipment_booking_id}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _shipmentRequestDetails = () => {

        Service.apiPostTokenCallRequest(RouteURL.shipmentRequestDetails, { shipment_booking_id: shipment_booking_id }).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setFilteredData(response.data.shipmentRequestDetails)

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /* on change input function */
    const formChange = (event) => {
        const { name, value } = event.target;
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });

    };

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }

    };

    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !formValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` : ""
                };
            });
        }
    };


    /* Validation Checking */
    const validation = (e) => {
        e.preventDefault();
        setFormTouchValue(markAllAsTouched(formTouchValue));
        setFormValue(leftTrimAllValue(formValue));
        checkError("updated_quote_price");

        if (isFormValid(formValue)) {
            if (Type == 'confirm' || Type == 'readyForpickup') _transporterUpdateQuoteSubmit()
            else if (Type == 'accept') _transporterConfirmUpdateQuoteSubmit()
        }
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 18/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _transporterUpdateQuoteSubmit
    * @functionPurpose this function for transport accept quote.
    *
    * @functionParam {order_id, updated_quote_price}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _transporterUpdateQuoteSubmit = () => {
        let params = JSON.stringify({
            order_id: Order_id,
            updated_quote_price: Number(formValue.updated_quote_price),
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterUpdateQuote, params).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setFormValue({ updated_quote_price: "" })
                setErrorValue({ updated_quote_price: "" })
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });

            }
        });
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 18/12/2023
      * @Date_Modified 
      * @function async
      * @functionName _transporterConfirmUpdateQuoteSubmit
      * @functionPurpose this function for transporter update accept quote request.
      *
      * @functionParam {shipment_booking_id, updated_quote_price}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _transporterConfirmUpdateQuoteSubmit = () => {
        let params = JSON.stringify({
            shipment_booking_id: shipment_booking_id,
            updated_quote_price: Number(formValue.updated_quote_price),

        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterUpdateAcceptedQuote, params).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });

            }
        });
    }

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }

    return (
        <>
            < Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec" ref={modalRef}>
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Update Quote</h6>
                    </Modal.Header>

                    <section className="reject_inner">
                        <div className="reject_req_sec">
                            <div className="reject_hed"><h4>{filteredData?.shipment_details?.request_id} <br /><span style={{ color: "#FBAA2A" }}>{filteredData?.shipment_details?.shipper_code}</span></h4></div>
                            <div className="reject_person"><p>{filteredData?.shipment_details?.shipper_name}</p></div>
                        </div>
                        <div className="reject_track">
                            <div className="order-track-step">
                                <div className="order-track-status">
                                    <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                    <span className="order-track-status-line"></span>
                                </div>
                                <div className="order-track-text">
                                    <span className="order-track-text-sub">Booking Date - {filteredData?.location_details?.booking_date}</span>
                                    <p className="order-track-text-stat">{filteredData?.location_details?.from_location}</p>
                                    <span className="kilometer">{filteredData?.location_details?.distance}</span>
                                    <span className="kilometer">{filteredData?.location_details?.time_duration}</span>
                                </div>
                            </div>
                            <div className="order-track-step">
                                <div className="order-track-status">
                                    <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                    <span className="order-track-status-line"></span>
                                </div>
                                <div className="order-track-text">
                                    <span className="order-track-text-sub">Est. Delivery date - {filteredData?.location_details?.estimated_delivery_date != null ? filteredData?.location_details?.estimated_delivery_date : ' _____'}</span>
                                    <p className="order-track-text-stat">{filteredData?.location_details?.to_location}</p>
                                </div>
                            </div>

                        </div>
                        <div className="material_show_dot">
                            <span className="dot_bluclick">
                                <i className="fa-solid fa-ellipsis" onClick={() => setProductDetailsShow(!productDetailsShow)} />
                                {productDetailsShow == true &&
                                    <>
                                        <div className="dot_bluclick_inner shipaccept">
                                            <span><i className="fa-solid fa-xmark" onClick={() => setProductDetailsShow(false)}></i></span>
                                            <Slider {...settings} >
                                                {filteredData?.material_type.length > 0 && filteredData?.material_type.map((item) => {
                                                    return (
                                                        <div className="row d-flex">

                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><img src={Dimension} alt="" /> Material</p>
                                                                    <h6>{item.material_type}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><img src={Dimension} alt="" /> Weight</p>
                                                                    <h6>{item.package_weight}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><img src={Dimension} alt="" /> Dimensions</p>
                                                                    <h6>{item.product_dimension}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><img src={Dimension} alt="" /> Number of Boxes</p>
                                                                    <h6>{item.no_of_units}</h6>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p>Payment Mode</p>
                                                                    <h6> {filteredData?.payment_mode}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p>Loading Type</p>
                                                                    <h6>{filteredData?.load_type} </h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div className="material1_sec">
                                                                    <h6>Product Image</h6>
                                                                    <div className="productmaterial_image_box">
                                                                        {item?.images.length > 0 && item?.images.map((img) => {
                                                                            return (<div className="p_box">
                                                                                <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                            </div>
                                                                            )
                                                                        })}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Slider>
                                            <span className="slider_number_count">{buttonVisible}/<span style={{ color: "#fbaa2a" }}>{filteredData?.material_type.length}</span></span>

                                        </div>
                                    </>


                                }



                            </span>
                        </div>
                        <div className="Reject_field">
                            <div className="row">

                                <div className="col-sm-6">
                                    <div className="office_field">
                                        <label>Existing Quotation</label><br /><br />
                                        {'₹ ' + Quote_price}

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="office_field">
                                        <label>Updated Quotation</label>
                                        <input type="number" placeholder="Enter Quotation" name="updated_quote_price"
                                            onChange={formChange}
                                            onBlur={updateFormTouchValue}
                                            value={formValue.updated_quote_price}
                                            error={errorValue.updated_quote_price ? "true" : "false"} />
                                        {errorValue.updated_quote_price && (
                                            <span className="error">
                                                {errorValue.updated_quote_price}
                                            </span>
                                        )}

                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-4 offset-sm-4">
                                <div className="login_btn">
                                    <button type="submit" value="get Started" onClick={validation} style={{ background: '#000' }}
                                        disabled={submitDisable ? true : false}>Update </button></div>
                            </div>
                        </div>

                    </section>

                </div>
            </Modal>
        </>
    )
}