/**
 * @file_purpose  page for delevery timing
 * @author Sarmistha Mondal
 * @Date_Created 27/11/2023
 * @Date_Modified 15/12/2023
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from 'react-bootstrap/Table';
import { convertTime12to24 } from "../../../Utility/Helper";

export default function TimingsList(props) {
    const navigate = useNavigate();
    const [formValue, setFormValue] = useState([{ "day_name": "", "is_closed": false, "available_from": "", "available_to": "" },]);
    const [availableFromUpdate, setAvailableFromUpdate] = useState(false);
    const [availableToUpdate, setAvailableToUpdate] = useState(false);
    const [isClosedUpdate, setIsClosedUpdate] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    useEffect(() => {
        _onboardingTimingist();

    }, []);

    /**
       * @author Sarmistha Mondal
       * @Date_Created 28/11/2023
       * @Date_Modified 
       * @function async
       * @functionName _onboardingTimingist
       * @functionPurpose this function for onboarding city list.
       *
       * @functionParam {}
       *
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _onboardingTimingist = () => {

        Service.apiPostTokenCallRequest(RouteURL.transporterAvailableTimingInfo, {}).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let res = response.data.availibilityTimings;
                setFormValue(res)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

   


    useEffect(() => {

    }, [isClosedUpdate, availableFromUpdate, availableToUpdate]);


    const formChange = (event, data, key) => {
        const { name, value, checked } = event.target;
        if (name == 'is_closed') {
            formValue[key][name] = checked;
        } else formValue[key][name] = value;
        setFormValue(formValue)
    };


    const ValidateForm = (e) => {
        e.preventDefault();
        let isCheckTrue = formValue.filter((obj) => obj.is_closed == true)
        let isCheckFalse = formValue.filter((obj) => obj.is_closed == false && (obj.available_from != '' || obj.available_to != ''))

        let formIsValid = true;
        if (isCheckTrue) {
            let availableFrom = isCheckTrue.filter((obj) => obj.available_from == '')
            let availableTo = isCheckTrue.filter((obj) => obj.available_to == '')
            setSubmitDisable(true)
            if (isCheckTrue.length > 0 || isCheckFalse.length > 0) {
                if (formValue.filter((obj) => obj.is_closed == true && obj.available_from == '').length > 0) {
                    formIsValid = false;

                    toast.error('Please enter available from time', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                }
                else if (formValue.filter((obj) => obj.is_closed == true && obj.available_to == '').length > 0) {
                    formIsValid = false;
                    toast.error('Please enter available to time', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                } else if (isCheckFalse.length > 0) {
                    if (formValue.filter((obj) => obj.is_closed != true && (obj.available_from != '' || obj.available_to != ''))) {
                        formIsValid = false;
                        toast.error('Please check availability date', {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            onClose: () => {
                                setSubmitDisable(false);
                            },
                        });
                    }
                }
                else _submitTiming(formValue)

            }
            else {
                formIsValid = false;
                toast.error('Please check availability date', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }

            return formIsValid;
        }


    }

    /**
       * @author Sarmistha Mondal
       * @Date_Created 28.11.2023
       * @Date_Modified 
       * @function async
       * @functionName _submitTiming
       * @functionPurpose this function for
       * submit timing
       *
       *  @functionParam {payload object: available_timings}
       * 
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _submitTiming = (formValue) => {

        let params = JSON.stringify({
            available_timings: JSON.stringify(formValue)
        });
        Service.apiPostTokenCallRequest(RouteURL.availableTimingsAdd, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }


    return (
        <>
            <div className="sideright_menu">
                <div className="shipment_menu_rt">
                    <div className="godown_upper_hed">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="godown_upper_hed_lt">
                                    <h4>Timing</h4>
                                </div>
                            </div>
                            <div className="godwn_dwn">
                                <div className="row">
                                    <div className="col">
                                        <div className="office_address_lt ">

                                            <div className="timing_table">


                                                <div className="row">
                                                    <div className=""></div>
                                                </div>


                                                <Table responsive>

                                                    <tbody>
                                                        {formValue != undefined && formValue.length > 0 ? formValue.map((data, key) => {
                                                            return <tr>
                                                                <td style={{ width: "50%" }}><div className="time_date pt-3">
                                                                    <input type="checkbox"
                                                                        checked={data.is_closed}
                                                                        style={{ border: data.is_closed == true ? '' : '2px solid #C5C4D2' }}
                                                                        name="is_closed"
                                                                        onChange={(value) => { setIsClosedUpdate(!isClosedUpdate); formChange(value, data, key) }
                                                                        } />{data.day_name}</div></td>
                                                                <td style={{ width: "50%" }}><div className="time_table"><label>From</label><input type="time" name="available_from"
                                                                    id="available_from"
                                                                    placeholder=""
                                                                    value={convertTime12to24(data.available_from)}
                                                                    onChange={(e) => {
                                                                        setAvailableFromUpdate(!availableFromUpdate)
                                                                        formChange(e, data, key)
                                                                    }} />
                                                                    <label>To</label><input type="time" name="available_to"
                                                                        placeholder=""
                                                                        value={convertTime12to24(data.available_to)}
                                                                        onChange={(e) => {
                                                                            setAvailableToUpdate(!availableToUpdate)
                                                                            formChange(e, data, key)
                                                                        }} /></div></td>
                                                            </tr>
                                                        }) : ""}


                                                    </tbody>
                                                </Table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="save_btn"><button type="submit" value="Verify &amp; Save" onClick={ValidateForm}
                        disabled={submitDisable ? true : false}>Save</button></div>
                </div>
            </div>

        </>
    )
}