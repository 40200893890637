/**
 * @file_purpose  page for showing shipment update quotation request accept
 * @author Sarmistha Mondal
 * @Date_Created 18/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim,
} from "../../../Utility/Helper";
import Modal from "react-bootstrap/Modal";
import Dimension from "../../../assets/images/square-measument.png";
import Select from 'react-select';
import SmallTruck from "../../../assets/images/sm_truck.png"
import Slider from "react-slick";


export default function UpdateAcceptsRejectQuotationModal(props) {
    const modalRef = useRef(null);
    let Type = props._allData.type;
    let order_id = props._allData.order_id;
    let shipment_request_id = props._allData.shipment_request_id
    let booking_id = props._allData.booking_id
    const [filteredData, setFilteredData] = useState({});
    const [reasonList, setReasonList] = useState([]);
    const [reasonlabel, setReasonlabel] = useState();
    const [submitDisable, setSubmitDisable] = useState(false);
    const [rejectionFormValue, setRejectionFormValue] = useState({ rejection_reason: "", additional_notes: "", other_rejection_reason: "" });
    const [rejectionFormTouchValue, setRejectionFormTouchValue] = useState({ rejection_reason: false, additional_notes: false, other_rejection_reason: false });
    const [rejectionErrorValue, setRejectionErrorValue] = useState({
        rejection_reason: "", other_rejection_reason: ""
    });
    const [buttonVisible, setButtonVisible] = useState(0);
    const [productDetailsShow, setProductDetailsShow] = useState(false);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        touchMove: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    // focus within a specific area
    useEffect(() => {
        if (props.show) {
            const modalElement = modalRef.current;
            //add any focusable HTML element you want to include to this string
            const focusableElements = modalElement.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event) => {
                if (event.key === "Tab") {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event) => {
                if (event.key === "Escape") {
                    props.onHide();
                }
            };

            modalElement.addEventListener("keydown", handleTabKeyPress);
            modalElement.addEventListener("keydown", handleEscapeKeyPress);

            return () => {
                modalElement.removeEventListener("keydown", handleTabKeyPress);
                modalElement.removeEventListener("keydown", handleEscapeKeyPress);
            };
        }
    }, [props.show]);

    const _submit = (e, type) => {
        e.preventDefault()
        if (type == 'accept') _updateRejectRequestQuotationSubmit()
        if (type == 'cancel') _updateCancelRequestQuotationSubmit()
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 19/04/2024
    * @Date_Modified 
    * @function async
    * @functionName _updateRejectRequestQuotationSubmit
    * @functionPurpose this function for quotation accept request.
    *
    * @functionParam {}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */

    const _updateRejectRequestQuotationSubmit = () => {

        let params = JSON.stringify({
            order_id: order_id,
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.shippmentUpdatedQuoteAccept, params).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {

                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }


    /**
     * @author Sarmistha Mondal
     * @Date_Created 18/04/2024
     * @Date_Modified 
     * @function async
     * @functionName _updateCancelRequestQuotationSubmit
     * @functionPurpose this function for shipment cancel request.
     *
     * @functionParam {shipment_request_id}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _updateCancelRequestQuotationSubmit = () => {
        let params = JSON.stringify({
            shipment_request_id: shipment_request_id,
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.shippmentCancelRequest, params).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {

                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }


    useEffect(() => {
        if (Type == 'reject') { _shipmentRejectionReasonsList(); _shipmentRequestDetails(); }
    }, [Type])

    /**
        * @author Sarmistha Mondal
        * @Date_Created 19/12/2023
        * @Date_Modified 
        * @function async
        * @functionName _shipmentRejectionReasonsList
        * @functionPurpose this function for shipment rejection reasons list.
        *
        * @functionParam {}
        *
        * @functionSuccess Success status and message.
        *
        * @functionError {Boolean} error is there.
        * @functionError {String} message  Description message.
        */
    const _shipmentRejectionReasonsList = () => {

        Service.apiPostTokenCallRequest(RouteURL.rejectionReasonsList, {}).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let res = response.data.reasonList;
                var newArray = []
                res.map((item, index) => (
                    newArray.push({
                        value: item.id,
                        label: item.reason
                    })
                ))
                setReasonList(newArray)

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /**
     * @author Sarmistha Mondal
     * @Date_Created 18/12/2023
     * @Date_Modified 
     * @function async
     * @functionName _shipmentRequestDetails
     * @functionPurpose this function for shipment request details.
     *
     * @functionParam {shipment_booking_id}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _shipmentRequestDetails = () => {
        Service.apiPostTokenCallRequest(RouteURL.shipmentRequestDetails, { shipment_booking_id: booking_id }).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setFilteredData(response.data.shipmentRequestDetails)

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    useEffect(() => {
        if (rejectionFormValue) {
            checkError("rejection_reason"); checkError("additional_notes");
            if (reasonlabel == "Others") checkError("other_rejection_reason")
        }
    }, [rejectionFormValue])


    /* error message function */
    const checkError = (targetName) => {
        if (rejectionFormTouchValue[targetName]) {
            setRejectionErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !rejectionFormValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` : ""

                };
            });
        }
    };


    /* Validation Checking */
    const rejectValidation = (e) => {
        e.preventDefault();
        setRejectionFormTouchValue(markAllAsTouched(rejectionFormTouchValue));
        setRejectionFormValue(leftTrimAllValue(rejectionFormValue));

        checkError("rejection_reason"); checkError("additional_notes");
        if (reasonlabel == "Others") { checkError("other_rejection_reason") }

        if (rejectionFormValue.rejection_reason && reasonlabel != "Others") {
            setRejectionFormValue((prevFormValue) => {
                return { ...prevFormValue, 'other_rejection_reason': '' };
            });
            _shipperRejectRequestSubmit()
        } else if (reasonlabel == "Others" && rejectionFormValue.rejection_reason && rejectionFormValue.other_rejection_reason) {
            _shipperRejectRequestSubmit()
        }
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 18/04/2024
    * @Date_Modified 
    * @function async
    * @functionName _shipperRejectRequestSubmit
    * @functionPurpose this function for shipper quotation reject request.
    *
    * @functionParam {order_id,rejection_reason,additional_notes,other_rejection_reason}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _shipperRejectRequestSubmit = () => {
        let params = JSON.stringify({
            order_id: order_id,
            rejection_reason: rejectionFormValue.rejection_reason,
            additional_notes: rejectionFormValue.additional_notes,
            other_rejection_reason: rejectionFormValue.other_rejection_reason,
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.shippmentUpdatedQuoteReject, params).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {

                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }

    const rejectionFormChange = (event) => {
        const { name, value } = event.target;
        setRejectionFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
    }

    /* select reason*/
    const reasonSelect = (e) => {
        setReasonlabel(e.label)
        setRejectionFormValue((prevFormValue) => {
            return { ...prevFormValue, rejection_reason: String(e.value) };
        });
        if (e.label != 'Others') {
            setRejectionFormValue((prevFormValue) => {
                return { ...prevFormValue, 'other_rejection_reason': '' };
            });
        }
    }

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }

    return (
        <>
            < Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size={Type == 'reject' ? "lg" : ""}
            >
                <div className="forgot_sec" ref={modalRef}>
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">{Type == 'accept' ? 'Accept' : (Type == 'cancel' ? 'Cancel' : 'Reject')}
                            {/* updated quotation */} Shipment
                        </h6>
                    </Modal.Header>

                    <section className="reject_inner">
                        {(Type == 'accept' || Type == 'cancel') &&
                            <>
                                <p>Do you really want to {Type} this {Type == 'cancel' ? '' : 'accept'}
                                    {/* this quotation? */} shipment ?
                                </p>
                                <div className='reco_trans_rt_inner_dwn' style={{ justifyContent: 'center' }}>
                                    <div>
                                        <button type='' value="reject"
                                            onClick={props.onHide}
                                            style={{ background: '#fff', color: '#000' }}
                                        >Cancel</button></div>
                                    <div><button type='reject' value="reject"
                                        onClick={(e) => _submit(e, Type)}
                                        disabled={submitDisable ? true : false}
                                        style={{ background: '#000', color: '#fff' }}
                                    >Done</button></div>
                                </div>
                            </>
                        }
                        {Type == 'reject' &&

                            <>

                                <div className="reject_req_sec">
                                    <div className="reject_hed"><h4>{filteredData?.shipment_details?.request_id} <br /><span style={{ color: "#FBAA2A" }}>{filteredData?.shipment_details?.shipper_code}</span></h4></div>
                                    <div className="reject_person"><p>{filteredData?.shipment_details?.shipper_name}</p></div>
                                </div>
                                <div className="reject_track">
                                    <div className="order-track-step">
                                        <div className="order-track-status">
                                            <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                            <span className="order-track-status-line"></span>
                                        </div>
                                        <div className="order-track-text">
                                            <span className="order-track-text-sub">Booking Date - {filteredData?.location_details?.booking_date}</span>
                                            <p className="order-track-text-stat">{filteredData?.location_details?.from_location}</p>
                                            <span className="kilometer">{filteredData?.location_details?.distance}</span>
                                            <span className="kilometer">{filteredData?.location_details?.time_duration}</span>
                                        </div>
                                    </div>
                                    <div className="order-track-step">
                                        <div className="order-track-status">
                                            <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                            <span className="order-track-status-line"></span>
                                        </div>
                                        <div className="order-track-text">
                                            <span className="order-track-text-sub">Est. Delivery date -{filteredData.location_details?.estimated_delivery_date != null ? filteredData.location_details?.estimated_delivery_date : ' _____'}</span>
                                            <p className="order-track-text-stat">{filteredData?.location_details?.to_location}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="material_show_dot">
                                    <span className="dot_bluclick">
                                        <i className="fa-solid fa-ellipsis" onClick={() => setProductDetailsShow(!productDetailsShow)} />
                                        {productDetailsShow == true &&
                                            <div className="dot_bluclick_inner shipaccept">
                                                <span><i className="fa-solid fa-xmark" onClick={() => setProductDetailsShow(false)}></i></span>
                                                <Slider {...settings}>
                                                    {filteredData?.material_type.length > 0 && filteredData?.material_type.map((item) => {
                                                        return (
                                                            <div className="row">

                                                                <div className="col-sm-4">
                                                                    <div className="material1_sec">
                                                                        <p><img src={Dimension} alt="" /> Material</p>
                                                                        <h6>{item.material_type}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="material1_sec">
                                                                        <p><img src={Dimension} alt="" /> Weight</p>
                                                                        <h6>{item.package_weight}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="material1_sec">
                                                                        <p><img src={Dimension} alt="" /> Dimensions</p>
                                                                        <h6>{item.product_dimension}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="material1_sec">
                                                                        <p><img src={Dimension} alt="" /> Number of Boxes</p>
                                                                        <h6>{item.no_of_units}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="material1_sec">
                                                                        <h6>Product Image</h6>
                                                                        <div className="productmaterial_image_box">
                                                                            {item?.images.length > 0 && item?.images.map((img) => {
                                                                                return (<div className="p_box">
                                                                                    <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                                </div>
                                                                                )
                                                                            })}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </Slider>
                                                <span className="slider_number_count">{buttonVisible}/<span style={{ color: "#fbaa2a" }}>{filteredData?.material_type.length}</span></span>

                                            </div>
                                        }
                                    </span>
                                </div>
                                <div className="Reject_field">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="office_field">
                                                <label>Rejection Reason</label>
                                                <Select
                                                    placeholder="Select Option"
                                                    onChange={reasonSelect}
                                                    options={reasonList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />

                                                {rejectionErrorValue.rejection_reason && (
                                                    <span className="error">
                                                        {rejectionErrorValue.rejection_reason}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        {reasonlabel == "Others" &&
                                            <div className="col-sm-12">
                                                <div className="office_field">
                                                    <label>Others Reason</label>
                                                    <input type="text" placeholder="Enter Others Reason"
                                                        name="other_rejection_reason"
                                                        onChange={rejectionFormChange}
                                                        value={rejectionFormValue.other_rejection_reason}
                                                        error={rejectionFormValue.other_rejection_reason ? "true" : "false"}
                                                    />

                                                    {rejectionErrorValue.other_rejection_reason && (
                                                        <span className="error">
                                                            {rejectionErrorValue.other_rejection_reason}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        <div className="col-sm-12">
                                            <div className="office_field">
                                                <label>Additional Notes</label>
                                                <input type="text" placeholder="Enter Note"
                                                    name="additional_notes"
                                                    onChange={rejectionFormChange}
                                                    value={rejectionFormValue.additional_notes}
                                                    error={rejectionFormValue.additional_notes ? "true" : "false"} />
                                                
                                            </div>
                                        </div>

                                        <div className="col-sm-8 offset-sm-2">
                                            <div className="login_btn"><button type="submit" value="get Started"
                                                onClick={rejectValidation} disabled={submitDisable ? true : false}
                                                style={{ background: '#000' }}>Confirm & Reject</button></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </section>

                </div>
            </Modal>
        </>
    )

}