/**
 * @file_purpose  page for side menu 
 * @author Sarmistha Mondal
 * @Date_Created 06/11/2023
 * @Date_Modified
 */
import React, { useEffect, useState } from "react";
import { useNavigate, Link, Outlet, NavLink, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShipmentList from "../views/pages/shipment/shipmentList"
import VehicleTracking from "../views/pages/tracking/VehicleTracking";
import NewShipmentBooking from "../views/pages/shipper/NewShipmentBooking";
import MyOfficeAddressList from "../views/pages/transporter/myOfficeAddtessList"
import OfficeVehicleList from "../views/pages/transporter/vehicleList";
import UsersList from "../views/pages/transporter/users";
import PaymentMethods from "../views/pages/transporter/paymentMethod";
import DeliveryLocation from "../views/pages/transporter/deliveryLocation";
import TimingsList from "../views/pages/transporter/availabilityAndTimingsList";
import RecommendedTransport from "../views/pages/shipper/recommendedTransport";
import QuoteTransporterList from "../views/pages/shipper/QuoteTransporterList";
import Receiver from "../views/pages/receiver/receiverList";
import ViewCheckList from "../views/pages/receiver/receiverTabPage/viewChecklist";
import Payment_Details from "../views/pages/shipper_setting/payment_Details";
import ReceiverDetails from "../views/pages/shipper_setting/receiverDetails";
import receiver_img from "../assets/images/receiver.png"

const SideMenu = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    let { order_id, bookingid, quoteid } = useParams();
    let getMenuName = localStorage?.getItem('MenuName')
    let getSubMenu = localStorage?.getItem('subMenuName');
    const user_type = localStorage.getItem('user_type')
    const is_receiver = localStorage.getItem('is_receiver')
    const [subMenuName, setSubMenuName] = useState();
    const [updateMenu, setUpdateMenu] = useState()
    const [menuName, setMenuName] = useState(localStorage?.getItem('MenuName'));
    const [shipperMenuName, setShipperMenuName] = useState(localStorage?.getItem('MenuName'));
    const [width, setWidth] = useState(window.innerWidth)
    const [bookingRequestId, setBookingRequestId] = useState({ bookingid: '' });
    const onboardingStatus = localStorage.getItem('onboardStatus');


    useEffect(() => {

        setSubMenuName()
        if (subMenuName) setSubMenuName(subMenuName)
    }, [subMenuName]);

    /*this function for onclick sidemene page navigation */
    const handleMenuSet = (_menuName, subMenuName, booking_request_id) => {
        const newId = _menuName;
        // setShipperMenuName(_menuName)
        setMenuName(newId);
        setBookingRequestId((prevErrorValue) => {
            return {
                ...prevErrorValue,
                bookingid: booking_request_id,
            };
        });
        if (_menuName) {
            getMenuName = localStorage?.setItem('MenuName', _menuName)
            if (localStorage?.getItem('MenuName') != 'Settings') {
                localStorage.removeItem('subMenuName')
            }

        }

        if (subMenuName) {
            setSubMenuName(subMenuName);
            getSubMenu = localStorage.setItem('subMenuName', subMenuName)

        }
        else if (_menuName != 'Settings') setSubMenuName()
    };

    useEffect(() => {

    }, [updateMenu]);

    useEffect(() => {
       
        if (user_type == 'Transporter' && onboardingStatus != null) {
            if ((Object.values(JSON.parse(onboardingStatus)).every(x => x == true) != true)) {
                document.getElementById("disabled_UL").classList.add("disabled");
            }
        }



        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        if (location.pathname == "/account") {
            getMenuName = localStorage?.setItem('MenuName', '')
            getSubMenu = localStorage.setItem('subMenuName', '')
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, []);

    useEffect(() => {
        setUpdateMenu(menuName)
    }, [menuName, shipperMenuName]);


    return (
        <>
            <ToastContainer />
            {/* {width >= 992 ? */}

            <div className="shipments_sec">
                <div className="container-fluid">
                    <div className="row">

                        <div className="sidemenu_main">
                            <div className="shipment_menu_lt" id="disabled_UL">
                                {(user_type == 'Transporter' || user_type == 'Manager') &&
                                    <ul >
                                        <li onClick={(e) => { e.preventDefault(); handleMenuSet("Shipment", ''); navigate('/shipment-list') }} style={{ backgroundColor: (getMenuName === "Shipment" ? '#FBAA2A' : ''), color: (getMenuName === "Shipment" ? '#fff' : '') }}>
                                            <Link href="javascript:void(0)" style={{ color: (getMenuName === "Shipment" ? '#fff' : '') }}>
                                                <i className="fa-solid fa-truck-fast" style={{ marginRight: 7 }}></i>
                                                Shipment</Link>

                                        </li>
                                        <li onClick={(e) => { e.preventDefault(); handleMenuSet("VehicleTracking", ''); }}
                                            style={{ backgroundColor: (getMenuName == "VehicleTracking" ? '#FBAA2A' : ''), color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}>
                                            <Link to="/vehicle-tracking" style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}>
                                                <g id="order-tracking_1_" data-name="order-tracking (1)" transform="translate(-10 -10)">
                                                    <path id="Path_9402" data-name="Path 9402" d="M25.229,70.368a1.77,1.77,0,0,0-1.622,1.1H20.495a2.087,2.087,0,0,0-3.99,0H13.392a1.77,1.77,0,0,0-1.622-1.1,1.843,1.843,0,0,0,0,3.683,1.769,1.769,0,0,0,1.622-1.1h3.113a2.087,2.087,0,0,0,3.99,0h3.113a1.769,1.769,0,0,0,1.622,1.1,1.843,1.843,0,0,0,0-3.683ZM18.5,72.946a.737.737,0,1,1,.708-.736A.722.722,0,0,1,18.5,72.946Z" transform="translate(0 -47.419)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                    <path id="Path_9403" data-name="Path 9403" d="M31.484,10a5,5,0,0,0-4.817,5.156A5.326,5.326,0,0,0,28.078,18.8l3.406,3.352L34.891,18.8A5.318,5.318,0,0,0,36.3,15.155,5,5,0,0,0,31.484,10Zm2.434,7.757-2.434,2.394L29.01,17.717a3.769,3.769,0,0,1-.967-2.561,3.449,3.449,0,1,1,6.882,0A3.784,3.784,0,0,1,33.918,17.757Z" transform="translate(-12.984)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                    <path id="Path_9404" data-name="Path 9404" d="M42.21,23.333a2.21,2.21,0,1,0,2.21,2.21A2.21,2.21,0,0,0,42.21,23.333Zm0,2.946a.737.737,0,1,1,.736-.736A.736.736,0,0,1,42.21,26.279Z" transform="translate(-23.71 -10.537)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                </g>
                                            </svg>
                                                Tracking
                                            </Link>
                                        </li>

                                        <li onClick={(e) => { e.preventDefault(); handleMenuSet("Settings", '') }} className="settings-hover">

                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingOne">
                                                        <button className={`${getMenuName === "Settings" ? 'accordion-button collapsed setting_yellow' : 'accordion-button collapsed'} `} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={`${(getMenuName === "Settings" && getSubMenu ? "true" : "false")}`} aria-controls="flush-collapseOne" style={{ backgroundColor: (getMenuName === "Settings" ? '#FBAA2A' : ''), color: (getMenuName === "Settings" ? '#fff' : '') }}>
                                                            <i className="fa-solid fa-gear" style={{ marginRight: 8 }}></i>
                                                            Settings
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseOne" className={`${(getMenuName === "Settings" && getSubMenu ? "accordion-collapse collapse show" : "accordion-collapse collapse")}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">
                                                            <ul className="submenu">
                                                                <li onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleMenuSet("Settings", "Godown");
                                                                }}>
                                                                    <Link to="/office-godown-list" style={{ color: (getSubMenu === "Godown" ? '#FBAA2A' : '') }}><i className="fa-solid fa-warehouse"></i> My Godown</Link>
                                                                </li>

                                                                <li onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleMenuSet("Settings", "Vehicles");
                                                                }} ><Link to="/office-vehicle-list" style={{ color: (getSubMenu === "Vehicles" ? '#FBAA2A' : '') }} ><i className="fa-solid fa-truck"></i> My Vehicles</Link>
                                                                </li>

                                                                <li onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleMenuSet("Settings", "User");
                                                                }} ><Link to="/user-list" style={{ color: (getSubMenu === "User" ? '#FBAA2A' : '') }}><i className="fa-solid fa-users"></i> Users</Link>
                                                                </li>

                                                                <li onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleMenuSet("Settings", "Payment");
                                                                }}><Link to="/office-payment-list" style={{ color: (getSubMenu === "Payment" ? '#FBAA2A' : '') }}><i className="fa-regular fa-credit-card"></i> Payment Method</Link>
                                                                </li>

                                                                <li onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleMenuSet("Settings", "Locations");
                                                                }}><Link to="/office-city-list" style={{ color: (getSubMenu === "Locations" ? '#FBAA2A' : '') }}><i className="fa-solid fa-location-dot"></i> Delivery Locations</Link>
                                                                </li>

                                                                <li onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleMenuSet("Settings", "Timings");
                                                                }}><Link to="/office-time-list" style={{ color: (getSubMenu === "Timings" ? '#FBAA2A' : '') }}><i className="fa-regular fa-calendar-days"></i> Timings</Link>
                                                                </li>

                                                            </ul></div>
                                                    </div>
                                                </div>


                                            </div>
                                        </li>
                                    </ul>

                                }
                                {user_type == 'Shipper' &&
                                    <>

                                        <ul>

                                            <li onClick={(e) => { e.preventDefault(); handleMenuSet("Shipment", ''); }} style={{ backgroundColor: (getMenuName === "Shipment" ? '#FBAA2A' : ''), color: (getMenuName === "Shipment" ? '#fff' : '') }} >
                                                <Link to="/shipment-list" style={{ color: (getMenuName === "Shipment" ? '#fff' : '') }}>
                                                    <i className="fa-solid fa-truck-fast" style={{ marginRight: 8 }}></i>
                                                    Shipment</Link>

                                            </li>

                                            <li onClick={(e) => { e.preventDefault(); handleMenuSet("VehicleTracking", ''); }}
                                                style={{ backgroundColor: (getMenuName == "VehicleTracking" ? '#FBAA2A' : ''), color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}>
                                                <Link to="/vehicle-tracking" style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}>
                                                    <g id="order-tracking_1_" data-name="order-tracking (1)" transform="translate(-10 -10)">
                                                        <path id="Path_9402" data-name="Path 9402" d="M25.229,70.368a1.77,1.77,0,0,0-1.622,1.1H20.495a2.087,2.087,0,0,0-3.99,0H13.392a1.77,1.77,0,0,0-1.622-1.1,1.843,1.843,0,0,0,0,3.683,1.769,1.769,0,0,0,1.622-1.1h3.113a2.087,2.087,0,0,0,3.99,0h3.113a1.769,1.769,0,0,0,1.622,1.1,1.843,1.843,0,0,0,0-3.683ZM18.5,72.946a.737.737,0,1,1,.708-.736A.722.722,0,0,1,18.5,72.946Z" transform="translate(0 -47.419)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                        <path id="Path_9403" data-name="Path 9403" d="M31.484,10a5,5,0,0,0-4.817,5.156A5.326,5.326,0,0,0,28.078,18.8l3.406,3.352L34.891,18.8A5.318,5.318,0,0,0,36.3,15.155,5,5,0,0,0,31.484,10Zm2.434,7.757-2.434,2.394L29.01,17.717a3.769,3.769,0,0,1-.967-2.561,3.449,3.449,0,1,1,6.882,0A3.784,3.784,0,0,1,33.918,17.757Z" transform="translate(-12.984)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                        <path id="Path_9404" data-name="Path 9404" d="M42.21,23.333a2.21,2.21,0,1,0,2.21,2.21A2.21,2.21,0,0,0,42.21,23.333Zm0,2.946a.737.737,0,1,1,.736-.736A.736.736,0,0,1,42.21,26.279Z" transform="translate(-23.71 -10.537)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                    </g>
                                                </svg>
                                                    Tracking
                                                </Link>
                                            </li>

                                            {is_receiver == 1 &&
                                                <li onClick={(e) => { e.preventDefault(); handleMenuSet("receiver", ''); }}
                                                    style={{ backgroundColor: (localStorage?.getItem('MenuName') == "receiver" ? '#FBAA2A' : ''), color: (getMenuName === "receiver" ? '#fff' : '') }}>
                                                    <Link to="/receiver" style={{ color: (getMenuName === "receiver" ? '#fff' : '') }}>
                                                        <img src={receiver_img} width="15" height="15" style={{ color: (getMenuName === "receiver" ? '#fff' : '') }} /> &nbsp;
                                                        Receiver</Link>
                                                </li>}

                                            <li onClick={(e) => { e.preventDefault(); handleMenuSet("Settings", '') }} className="settings-hover">

                                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="flush-headingOne">
                                                            <button className={`${getMenuName === "Settings" ? 'accordion-button collapsed setting_yellow' : 'accordion-button collapsed'} `} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded={`${(getMenuName === "Settings" && getSubMenu ? "true" : "false")}`} aria-controls="flush-collapseOne" style={{ backgroundColor: (getMenuName === "Settings" ? '#FBAA2A' : ''), color: (getMenuName === "Settings" ? '#fff' : '') }}>
                                                                <i className="fa-solid fa-gear" style={{ marginRight: 8 }}></i>
                                                                Settings
                                                            </button>
                                                        </h2>
                                                        <div id="flush-collapseOne" className={`${(getMenuName === "Settings" && getSubMenu ? "accordion-collapse collapse show" : "accordion-collapse collapse")}`} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                <ul className="submenu">
                                                                    <li onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleMenuSet("Settings", "payment");
                                                                    }}>
                                                                        <Link to="/payment-details" style={{ color: (getSubMenu === "payment" ? '#FBAA2A' : '') }}><i className="fa-solid fa-warehouse"></i> Payment Details</Link>
                                                                    </li>

                                                                    <li onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleMenuSet("Settings", "receiverdetails");
                                                                    }} ><Link to="/receiver-details" style={{ color: (getSubMenu === "receiverdetails" ? '#FBAA2A' : '') }} ><i className="fa-solid fa-truck"></i> Receiver Details</Link>
                                                                    </li>




                                                                </ul></div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </li>



                                        </ul>
                                    </>
                                }
                                {user_type == 'Receiver' &&
                                    <>
                                        <ul>
                                            <li onClick={(e) => { e.preventDefault(); handleMenuSet("receiver", ''); }}
                                                style={{ backgroundColor: (localStorage?.getItem('MenuName') == "receiver" ? '#FBAA2A' : ''), color: (getMenuName === "receiver" ? '#fff' : '') }}>
                                                <Link to="/receiver" style={{ color: (getMenuName === "receiver" ? '#fff' : '') }}>
                                                    <i className="fa-solid fa-truck-fast" style={{ marginRight: 8 }}></i>
                                                    Receiver</Link>
                                            </li>
                                            <li onClick={(e) => { e.preventDefault(); handleMenuSet("VehicleTracking", ''); }}
                                                style={{ backgroundColor: (getMenuName == "VehicleTracking" ? '#FBAA2A' : ''), color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}>
                                                <Link to="/vehicle-tracking" style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }}>
                                                        <g id="order-tracking_1_" data-name="order-tracking (1)" transform="translate(-10 -10)">
                                                            <path id="Path_9402" data-name="Path 9402" d="M25.229,70.368a1.77,1.77,0,0,0-1.622,1.1H20.495a2.087,2.087,0,0,0-3.99,0H13.392a1.77,1.77,0,0,0-1.622-1.1,1.843,1.843,0,0,0,0,3.683,1.769,1.769,0,0,0,1.622-1.1h3.113a2.087,2.087,0,0,0,3.99,0h3.113a1.769,1.769,0,0,0,1.622,1.1,1.843,1.843,0,0,0,0-3.683ZM18.5,72.946a.737.737,0,1,1,.708-.736A.722.722,0,0,1,18.5,72.946Z" transform="translate(0 -47.419)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                            <path id="Path_9403" data-name="Path 9403" d="M31.484,10a5,5,0,0,0-4.817,5.156A5.326,5.326,0,0,0,28.078,18.8l3.406,3.352L34.891,18.8A5.318,5.318,0,0,0,36.3,15.155,5,5,0,0,0,31.484,10Zm2.434,7.757-2.434,2.394L29.01,17.717a3.769,3.769,0,0,1-.967-2.561,3.449,3.449,0,1,1,6.882,0A3.784,3.784,0,0,1,33.918,17.757Z" transform="translate(-12.984)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                            <path id="Path_9404" data-name="Path 9404" d="M42.21,23.333a2.21,2.21,0,1,0,2.21,2.21A2.21,2.21,0,0,0,42.21,23.333Zm0,2.946a.737.737,0,1,1,.736-.736A.736.736,0,0,1,42.21,26.279Z" transform="translate(-23.71 -10.537)" fill={`${(getMenuName === "VehicleTracking" ? '#fff' : '#928FA9')}`} style={{ color: (getMenuName === "VehicleTracking" ? '#fff' : '') }} />
                                                        </g>
                                                    </svg>
                                                    Tracking
                                                </Link>
                                            </li>
                                        </ul>
                                    </>

                                }
                            </div>
                        </div >
                        {/* shipper page routing */}
                        {(user_type == 'Shipper') &&
                            (() => {
                                switch (location.pathname) {
                                    case "/shipment-list":
                                        return <ShipmentList deepLinking={props?.dataUpdate?._data} />
                                    case '/shipment-booking':
                                        return <NewShipmentBooking />;
                                    case `/shipment-booking/${bookingid}/recommend`:
                                        return <RecommendedTransport />;
                                    case `/shipment-booking/${bookingid}`:
                                        return <QuoteTransporterList />;
                                    case "/vehicle-tracking":
                                        return (location.pathname != '/account' && <VehicleTracking bookingRequestId={bookingRequestId.bookingid} />)
                                    case "/payment-details":
                                        return <Payment_Details />
                                    case "/receiver":
                                        return <Receiver deepLinking={props?.dataUpdate?._data} />
                                    case "/receiver-details":
                                        return <ReceiverDetails />
                                    default:
                                        return null;
                                }

                            })()
                        }

                        {/* transporter or manager page routing */}
                        {(user_type == 'Transporter' || user_type == 'Manager') && (() => {
                            switch (location.pathname) {
                                case "/shipment-list":
                                    return <ShipmentList deepLinking={props?.dataUpdate?._data} />
                                case "/vehicle-tracking":
                                    return <VehicleTracking bookingRequestId={bookingRequestId.bookingid} />
                                case "/office-godown-list":
                                    return <MyOfficeAddressList />
                                case "/user-list":
                                    return <UsersList />
                                case "/office-vehicle-list":
                                    return <OfficeVehicleList />
                                case "/office-payment-list":
                                    return <PaymentMethods />
                                case "/office-city-list":
                                    return <DeliveryLocation />
                                case "/office-time-list":
                                    return <TimingsList />
                                default:
                                    return null;
                            }
                        })()
                        }

                        {/* receiver page routing */}
                        {(user_type == 'Receiver') &&
                            (() => {
                                switch (menuName) {
                                    case "receiver": return (() => {
                                        switch (location.pathname) {
                                            case `/receiver`:
                                                return <Receiver deepLinking={props?.dataUpdate?._data} />;
                                            case `/receiver/${order_id}`:
                                                return <ViewCheckList />;
                                            default:
                                                return null;
                                        }
                                    })()
                                    case "VehicleTracking":
                                        return (<VehicleTracking bookingRequestId={bookingRequestId.bookingid} />)
                                    default:
                                        return null;
                                }
                            })()
                        }
                    </div>
                </div>
            </div>
            {/* : <i class="fa-solid fa-bars" style={{ color: '#000' }}></i>} */}
        </>
    );
};

export default SideMenu;




