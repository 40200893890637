
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyA7zn-jujkZcxFgRCqQVwjN6iSPvjE3Rk0",
    authDomain: "ship-gudds-2024.firebaseapp.com",
    projectId: "ship-gudds-2024",
    storageBucket: "ship-gudds-2024.appspot.com",
    messagingSenderId: "679898905708",
    appId: "1:679898905708:web:9d5964e4116241c3b36c49",
    measurementId: "G-2Q3VQGK9Y7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app)
