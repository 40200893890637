/**
 * @file_purpose  page for select location
 * @author Sarmistha Mondal
 * @Date_Created 02/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef, useCallback } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Modal from "react-bootstrap/Modal";
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const mapStyle = {
    height: '300px',
    width: '100%'
}

const inputStyle = {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    width: '340px',
    height: '38px',
    padding: '0 12px',
    borderRadius: '3px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    outline: 'none',
    textOverflow: 'ellipses',
    position: 'absolute',
    right: '8%',
    top: '11px',
    marginLeft: '-120px'
}
const placesLibrary = ["places"];
export default function OnboardingLocationModal(props) {
    const mapRef = useRef();
    const autocompleteRef = useRef();
    const [selectedAddress, setSelectedAddress] = useState('')
    const [country, setCountry] = useState("in");
    const [getPlaceDetails, setGetPlaceDetails] = useState();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
        // libraries: placesLibrary
    })

    const [map, setMap] = React.useState(null)
    const [markerPosition, setMarkerPosition] = useState({
        lat: '',
        lng: ''
    })

    const [defaultLocation, setDefaultLocation] = useState({
        lat: '',
        lng: ''
    })

    const [center, setCenter] = useState({
        lat: '', // default latitude
        lng: '', // default longitude
    });
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.GOOGLE_MAPS_API_KEY,
        inputAutocompleteValue: "IN",
        options: {
            componentRestrictions: { country },
        },
    });

    /* map load */
    const onLoad = useCallback((map) => {
        // let bounds;
        mapRef.current = map;
        // const { geometry } = mapRef?.current?.getPlace();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setGetPlaceDetails(position)
                    setCenter((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(latitude),
                            lng: Number(longitude),
                        };
                    });
                    setDefaultLocation((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(latitude),
                            lng: Number(longitude),
                        };
                    });
                    setMarkerPosition((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(latitude),
                            lng: Number(longitude),
                        };
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );

        }

        if (center.lat) {
            const bounds = new window.google.maps.LatLngBounds({
                lat: center.lat,
                lng: center.lng

            });
            // console.log(bounds);
            map.fitBounds(bounds);
            setMap(map)
        }
    }, [])

    const onUnmount = useCallback(() => {
        setMap(null)
    }, [])


   
    const handleChange = (e) => {
        const { id, value } = e.target
        setSelectedAddress(value)
    }

    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    }

    /* auto complete input field in map*/
    const handlePlaceChanged = () => {
        const { geometry } = autocompleteRef?.current?.getPlace();

        const bounds = new window.google.maps.LatLngBounds();
        console.log(geometry);
        let Place = new window.google.maps.LatLng(geometry.location?.lat(), geometry.location?.lng());
        let marker = new window.google.maps.Marker({
            position: Place,
        });
        setGetPlaceDetails(geometry)
        if (geometry.viewport) {
            // console.log(1);
            bounds.union(geometry.viewport);
            bounds.extend(Place);
            bounds.getCenter(Place)
            new window.google.maps.Marker({
                position: Place,
            });

        } else {
            bounds.extend(geometry.location);
        }
        mapRef.current?.fitBounds(bounds);

    }

    /* detect address from lat lng */
    const getAddress = (lat, lng) => {
        let geocoder = new window.google.maps.Geocoder();
        let latlng = new window.google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': latlng }, function (results, status) {

            if (status == 'OK' && results[0].geometry.location_type == "GEOMETRIC_CENTER") {
                console.log(results);
                const address = results[0].formatted_address;
                setGetPlaceDetails(results[0])
                setSelectedAddress(address);
            }

        });
    }

    return (<>
        < Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="forgot_sec">
                <Modal.Header closeButton closeVariant={'white'}>
                    <h6 className="hed_txt">Location</h6>
                </Modal.Header>
                <div className="task_modal_sec">
                    <form
                        name="signin_form"
                    >
                        <section className="forgot_inn">

                            <div
                                style={{
                                    marginTop: "20px",
                                    width: '100%',
                                    height: "200px",
                                    display: "flex",
                                    flex: "1",
                                    flexDirection: "column",
                                    marginBottom: "100px",
                                }}
                            >

                                <div className='map_sec'>
                                    {
                                        (isLoaded) ? (
                                            <GoogleMap
                                                onLoad={onLoad}
                                                center={defaultLocation}
                                                mapContainerStyle={mapStyle}
                                                onClick={ev => {
                                                    setMarkerPosition({ lat: ev.latLng.lat(), lng: ev.latLng.lng() })
                                                    getAddress(ev.latLng.lat(), ev.latLng.lng())

                                                }}
                                                options={{
                                                    zoom: 15,
                                                    mapTypeControl: false,
                                                    draggable: true,
                                                    scaleControl: false,
                                                    scrollwheel: false,
                                                    navigationControl: true,
                                                    fullscreenControl: false,
                                                    rotateControl: true,
                                                    streetViewControl: false
                                                }}
                                                onUnmount={onUnmount}
                                            >

                                                <Autocomplete
                                                    onLoad={onLoadAutocomplete}
                                                    onPlaceChanged={handlePlaceChanged}
                                                >
                                                    <>
                                                        <input name="address" value={selectedAddress} onChange={handleChange}
                                                            type='text'
                                                            placeholder='Search Location'
                                                            style={inputStyle}
                                                        />
                                                        <Marker position={markerPosition} />
                                                    </>
                                                </Autocomplete>
                                            </GoogleMap>
                                        ) : <></>
                                    }

                                </div>

                            </div>
                            <div className="create_sub text-center">
                                <div className="login_btn">
                                    <button type="submit" value="get Started"
                                        onClick={(e) => { e.preventDefault(); props.onCloseModal(getPlaceDetails); props.onHide(); }}
                                        style={{ background: '#000' }}
                                    >Add Location</button>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>

            </div>
        </Modal >

    </>)
}