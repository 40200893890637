/**
 * @file_purpose  all url
 * @author Sarmistha Mondal 
 */

const RouteURL = {
    login: "login",
    businessTypeList: "business/type/list",
    shipperRegister: "shipper/register",
    transporterRegister: "transporter/register",
    generateEmailOtp: "generate/email/otp",
    verifyEmailOtp: "verify/email/otp",
    verifyGstnumber: "verify/gstnumber",
    generateWhatsappOtp: "generate/whatsapp/otp",
    verifyWhatsappOtp: "verify/whatsapp/otp",
    verifyPanNumber: "verify/pannumber",
    generateForgotpasswordOTP: "generate/forgotpassword/otp",
    validateForgotpasswordOTP: "validate/forgotpassword/otp",
    resetPassword: "reset/password",
    onboardingList: "onboarding/list",
    officeAddressAdd: "office/address/add",
    godownAddressAdd: "godown/address/add",
    officeAddressList: "office/address/list",
    godownAddressList: "godown/address/list",
    verifyBankAccountnumber: "verify/bank/accountnumber",
    verifyUpid: "verify/upiid",
    paymentMethodAdd: "payment/method/add",
    transporterPaymentInfo: "transporter/payment/info",
    transporterDeliveryCitiesList: "transporter/delivery/cities/list",
    deliveryCitiesAdd: "delivery/cities/add",
    materialTypeList: "transporter/material/types/list",
    materialTypeAdd: "material/type/add",
    transporterAvailableTimingInfo: "transporter/available/timing/info",
    availableTimingsAdd: "available/timings/add",
    transporterDeleteUser: "transporter/delete/user",
    cityList: "city/list",
    materialList: "material/list",
    shipmentBookings: "shipment/bookings",
    shipmentRecommendedTransporter: "shipment/recommended/transporter",
    officeAddressUpdate: "office/address/update",
    transporterUserList: "transporter/user/list",
    transporterRoleList: "transporter/role/list",
    transporterAddUser: "transporter/add/user",
    transporterNewRequest: "transporter/new/request",
    shipmentRequestDetails: "shipment/request/details",
    transporterAcceptRequest: "transporter/accept/request",
    transporterRejectRequest: "transporter/reject/request",
    rejectionReasonsList: "rejection/reasons/list",
    transporterAcceptedRequest: "transporter/accepted/request",
    transporterRejectedRequest: "transporter/rejected/request",

    shippmentQuoteRequests: "shippment/quote/requests",
    shipmentQuoteDetails: "shipment/quote/details",

    transporterReadytopickupRequest: "transporter/readytopickup/request",
    transporterConfirmedRequest: "transporter/confirmed/request",
    transporterOutfordeliveryRequest: "transporter/outfordelivery/request",
    transporterDeliveryRequest: "transporter/delivery/request",
    transporterRadyForloadingRequest: "transporter/readyforloading/request",
    transporterAllRequest: "transporter/all/request",
    shipperPendingRequests: "shipper/pending/requests",
    shipperReadytopickupRequest: "shipper/readytopickup/request",
    shipperHistoryRequests: "shipper/history/requests",
    shipperReadyforloadingRequest: "shipper/readyforloading/request",
    shipperDeliveredRequest: "shipper/delivered/request",
    shipperOutfordeliveryRequest: "shipper/outfordelivery/request",
    shipperAllReceivers :"shipper/all/receivers",
    receiverAllOrder :"receiver-all-order",

    ocrTransporterGst: "ocr/transporter/gst",
    verifyGstOtp: "verify/gst/otp",
    ocrShipperGst: "ocr/shipper/gst",
    ocrShipperAadhar: "ocr/shipper/aadhar",
    verifyAadharnumber: "verify/aadharnumber",
    verifyAadharOtp: "verify/aadhar/otp",
    subscriptionList: "subscription/list",
    userSubscription: "user/subscription",
    userSbscriptionDetails :"user/subscription-details",

    ocrPaymentChequeForShipper: "ocr/payment/cheque",

    // onboard vehical
    vehicleAdd: "vehicle/add",
    verifyVehiclePlatenumber: "verify/vehicle/platenumber",
    vehicleList: "vehicle/list",
    userPaymentInfo: "user/payment/info",
    godownAddressDelete: "godown/address/delete",
    godownAddressUpdate: "godown/address/update",
    vehicleUpdate: "vehicle/update",
    vehicleDelete:"vehicle/delete",

    contactUs: "contact/us",

    shippmentQuoteRequests: "shippment/quote/requests",
    shippmentQuoteAccept: "shippment/quote/accept",
    shippmentQuoteReject: "shippment/quote/reject",
    shippmentWaitingVehicleAssignment: "shippment/waiting/vehicle/assignment",
    shippmentUpdatedQuoteAccept: "shippment/updated/quote/accept",
    shippmentUpdatedQuoteReject: "shippment/updated/quote/reject",
    shippmentCancelRequest: "shippment/cancel/request",
    shipperCancelledRequest :"shipper/cancelled/request",

    transporterUpdateUser: "transporter/update/user",
    transporterUpdateQuote: "transporter/update/quote",
    transporterUpdateAcceptedQuote: "transporter/update/accepted-quote",
    transporterAvailableVehicleList: "transporter/available/vehicle/list",
    transporterAvailableDriverList: "transporter/available/driver/list",
    transporterAssignVehicle: "transporter/assign/vehicle",
    transporterAssignVehicleSaveGst: "transporter/assign/vehicle/save/gst",
    transporterAssignVehicleUniqueid: "transporter/assign/vehicle/save/uniqueid",
    uploadLRDocument: "upload/lr/document",
    uploadDeliveryDocument: "upload/delivery/document",
    uploadDeliveryPaymentDetails: "upload/delivery/payment-details",
    shipperPayment: "shipper/payment",
    transporterStartDelivery: "transporter/start/delivery",
    transporterEndDelivery: "transporter/end/delivery",
    transporterCancelledRequest :"transporter/cancelled/request",

    /* receiver*/
    receiverChecklistHistoryList: "receiver/checklist/history/list",
    receiverChecklistUpcomingList: "receiver/checklist/upcoming/list",
    receiverShipmentDetails: "receiver/shipment/details",
    receiverChecklistAccept: "receiver/checklist/accept",
    receiverChecklistReject: "receiver/checklist/reject",

    /* rating*/
    postRatingReview: "post/rating/review",
    ratingReviewList :"rating/review/list",

    /* tracking */
    vehicleTrackingList :"vehicle/tracking/list",

    /* notification*/ 
    updateFcmToken :"update-fcm-token",
    updateProfileImage :"update-profile-image",
    getNotifications :"get-notifications",
    updateNotificationReadStatus :"update-notification-read-status",
    getUnreadNotificationCount :"get-unread-notification-count",
    generateInvoice :"generate-invoice",

    getVehicleCategory: "/get-vehicle-category",
    updateWhatsappNumber :"update-whatsapp-number",
    deleteAccount:"delete-account",
    shipguddsContact :"shipgudds-contact",


};
export default RouteURL;
