/**
 * @file_purpose  page for showing privacy policy page
 * @author Sarmistha Mondal
 * @Date_Created 18/03/2024
 * @Date_Modified 
 */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import how_bg from "../../assets/images/how_bg.png";
import abt_car from "../../assets/images/abt_car.png";
import Group_29511 from "../../assets/images/Group 29511.png";
import appImg from "../../assets/images/app.png";
import img1 from "../../assets/images/1.png";
import img2 from "../../assets/images/2.png";
import img3 from "../../assets/images/3.png";
import quoteImg from "../../assets/images/quote.png";
import logo from "../../assets/images/logo.png";
import display from "../../assets/images/display.png";
import bigImg from "../../assets/images/b1.png";
import bigImg1 from "../../assets/images/b2.png";
import LandingPageFooter from "./landingPageFooter";

export default function PrivacyPolicy() {
    const navigate = useNavigate();

    return (
        <>
            <header className="header_sec1">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="header_inn1">
                                <div className="row">
                                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-9">

                                        <div className="header_inn_lt">

                                            <div className="menu_sec"  >
                                                <nav className="navbar navbar-expand-lg navbar-light">
                                                    <div className="container-fluid">
                                                        <Link className="navbar-brand" to="/"><img src={logo} alt="" /></Link>
                                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#navbarSupportedContent"
                                                            aria-controls="navbarSupportedContent" aria-expanded="false"
                                                            aria-label="Toggle navigation">
                                                            <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
                                                        </button>


                                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
                                                                <li className="nav-item">
                                                                    <Link className="nav-link active" aria-current="page"
                                                                        to="/">Home</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="nav-link" to="/">About us</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="nav-link" to="/terms-and-conditions">Terms & Condition</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="nav-link" to="/">How it works</Link>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Link className="nav-link" to="/">Contact us</Link>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </nav>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 d-none d-md-block">
                                        <div className="header_inn_rt">
                                            <ul>
                                                <li><a href=""><i className="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-instagram"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-twitter"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </header >

            <section className="terms_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="terms_inn">
                                <h6>Privacy Policy</h6>

                                This Privacy Policy describes how PM LOGISTIC SOLUTIONS PVT LTD.  Incorporated on 09 June 2023, CIN NO. U52243TS2023PTC173936, PAN NO: AANCP8379B ("LOGISTIC SERVICES") collects, uses, and protects the personal information of users ("Users") of the logistics platform "SHIPGUDDS" provided by the Company. By accessing or using the Platform, Users consent to the collection, use, and disclosure of their personal information in accordance with this Privacy Policy.
                               
                                <h6 style={{ textAlign: 'left' }}>1. Information We Collect</h6>

                                Personal Information: We may collect personal information such as name, contact details, and address when Users register on the Platform or submit booking requests.<br />
                                Transactional Information: We may collect information related to transactions conducted through the Platform, including booking details and payment information.<br />
                                Device Information: We may collect information about the device and browser used to access the Platform, such as IP address, browser type, and operating system.<br />
                                Location Information: With User consent, we may collect location information to provide location-based services, such as determining pickup and delivery locations.<br />
                                Other Information: We may collect other information provided by Users or automatically collected during their use of the Platform.

                                <h6 style={{ textAlign: 'left' }}>2. Use of Information</h6>
                                We may use the collected information for the following purposes:<br /><br />

                                To provide and improve the Platform and its services.<br />
                                To process and fulfil booking requests and transactions.<br />
                                To communicate with Users regarding their bookings, account, and Platform updates.<br />
                                To personalize User experience and provide targeted content and advertisements.<br />
                                To detect, prevent, and address technical issues, fraud, or security breaches.<br />
                                To comply with legal and regulatory requirements.


                                <h6 style={{ textAlign: 'left' }}>3. Disclosure of Information</h6>

                                We may disclose Users' personal information to third parties in the following circumstances:<br /><br />

                                To Transporters to facilitate the provision of transportation services.<br />
                                To third-party service providers who assist us in operating the Platform and providing services.<br />
                                To comply with legal obligations, enforce our policies, or protect the rights, property, or safety of Users or others.

                                <h6 style={{ textAlign: 'left' }}>4. Data Security</h6>

                                We take reasonable measures to protect Users' personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.


                                <h6 style={{ textAlign: 'left' }}>5. Data Retention</h6>
                                We retain Users' personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.


                                <h6 style={{ textAlign: 'left' }}>6. User Rights</h6>
                                Users may have certain rights regarding their personal information, including the right to access, correct, or delete their information. Users may also have the right to object to or restrict certain processing activities. To exercise these rights, Users may contact us using the contact information provided below.


                                <h6 style={{ textAlign: 'left' }}>7. Third-Party Links and Services</h6>

                                The Platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such third parties. Users should review the privacy policies of these third parties before providing any personal information.
                                <h6 style={{ textAlign: 'left' }}>8. Children's Privacy</h6>
                                The Platform is not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided personal information to us, please contact us using the contact information provided below.


                                <h6 style={{ textAlign: 'left' }}>9. Changes to this Privacy Policy</h6>
                                We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on the Platform. Users are encouraged to review this Privacy Policy periodically for any changes.

                                <h6 style={{ textAlign: 'left' }}>10. Contact Us</h6>
                                If you have any questions or concerns about this Privacy Policy, please contact us at shipguddsindia@gmail.com.<br /><br />
                                <br />


                                By using the Platform, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.
                           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LandingPageFooter/>
          
        </>
    )
}