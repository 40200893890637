/**
 * @file_purpose  page for showing shipment new request accept
 * @author Sarmistha Mondal
 * @Date_Created 18/12/2023
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim,
} from "../../../Utility/Helper";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Select from 'react-select';
import SmallTruck from "../../../assets/images/sm_truck.png"
import Slider from "react-slick";

export default function NewRequestAcceptsRejectModal(props) {
    const modalRef = useRef(null);
    let shipment_booking_id = props._allData.shipmentBookingId;
    let request_id = props._allData.shipmentRequestId;
    let Type = props._allData.type;
    const [submitDisable, setSubmitDisable] = useState(false);
    const [filteredData, setFilteredData] = useState({});
    const [reasonList, setReasonList] = useState([]);
    const [addressShow, setAddressShow] = useState(false);
    const [addressforpickup_goods, setaddressforpickup_goods] = useState(false);
    const [productDetailsShow, setProductDetailsShow] = useState(false);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        touchMove: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const [formValue, setFormValue] = useState({
        quote_price: '',
        estimated_delivery_date: '',
        pickup_type: 'sendtous',
        delivery_type: "We will drop",
    });

    const [optionalFields, setoptionalFields] = useState({
        address_of_send_to_us: '',
        address_for_pickup_goods: ''
    });
    
    const [formTouchValue, setFormTouchValue] = useState({
        quote_price: false,
        estimated_delivery_date: false,
        pickup_type: false,
        address_of_send_to_us: false,
        address_for_pickup_goods: false
    });
    const [errorValue, setErrorValue] = useState({
        quote_price: '',
        estimated_delivery_date: '',
        pickup_type: '',
        address_of_send_to_us: '',
        address_for_pickup_goods: ''
    });
    const [rejectionFormValue, setRejectionFormValue] = useState({ rejection_reason: "", additional_notes: "" });
    const [rejectionFormTouchValue, setRejectionFormTouchValue] = useState({ rejection_reason: false, });
    const [rejectionErrorValue, setRejectionErrorValue] = useState({
        rejection_reason: "",
    });
    const [addressList, setAddressList] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(0);

    // focus within a specific area
    useEffect(() => {
        if (props.show) {
            const modalElement = modalRef.current;
            //add any focusable HTML element you want to include to this string
            const focusableElements = modalElement.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event) => {
                if (event.key === "Tab") {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event) => {
                if (event.key === "Escape") {
                    props.onHide();
                }
            };

            modalElement.addEventListener("keydown", handleTabKeyPress);
            modalElement.addEventListener("keydown", handleEscapeKeyPress);

            return () => {
                modalElement.removeEventListener("keydown", handleTabKeyPress);
                modalElement.removeEventListener("keydown", handleEscapeKeyPress);
            };
        }
    }, [props.show]);


    useEffect(() => {
        if (Type) _shipmentRequestDetails();
        if (Type == 'accept') _AddressList()
        if (Type == 'reject') _shipmentRejectionReasonsList()
    }, [Type])

    useEffect(() => {
        if (Type == 'reject') _shipmentRejectionReasonsList()
    }, [])


    useEffect(() => {
        if (formValue) {
            checkError("quote_price");
            checkError("estimated_delivery_date");
            checkError("pickup_type");
            if (formValue.pickup_type == 'sendtous') {
                setAddressShow(true)
                checkError("address_of_send_to_us");
            } else {
                setErrorValue((prevFormValue) => {
                    return { ...prevFormValue, 'address_of_send_to_us': '' };
                });
                setAddressShow(false);
            }
            if (formValue.delivery_type == 'Pick Up') {
                setaddressforpickup_goods(true)
                checkError("address_for_pickup_goods");
            } else {
                setaddressforpickup_goods(false);
                setErrorValue((prevFormValue) => {
                    return { ...prevFormValue, 'address_for_pickup_goods': '' };
                });
            }
        }
    }, [formValue])

    useEffect(() => {
        if (formValue.pickup_type == 'sendtous') {
            checkError("address_of_send_to_us");
        } else if (optionalFields.address_of_send_to_us) {
            setErrorValue((prevFormValue) => {
                return { ...prevFormValue, 'address_of_send_to_us': '' };
            });
        }

    }, [formValue.pickup_type, optionalFields.address_of_send_to_us])


    useEffect(() => {
        if (formValue.delivery_type == 'Pick Up') {
            checkError("address_for_pickup_goods");
        } else {
            setErrorValue((prevFormValue) => {
                return { ...prevFormValue, 'address_for_pickup_goods': '' };
            });
        }
    }, [formValue.delivery_type, optionalFields.address_for_pickup_goods])

    useEffect(() => {
        if (rejectionFormValue) { checkError("rejection_reason"); }
    }, [rejectionFormValue])


    /**
    * @author Sarmistha Mondal
    * @Date_Created 18/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _shipmentRequestDetails
    * @functionPurpose this function for shipment request details.
    *
    * @functionParam {shipment_booking_id}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _shipmentRequestDetails = () => {

        Service.apiPostTokenCallRequest(RouteURL.shipmentRequestDetails, { shipment_booking_id: shipment_booking_id }).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setFilteredData(response.data.shipmentRequestDetails)

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /**
     * @author Sarmistha Mondal
     * @Date_Created 19/12/2023
     * @Date_Modified 
     * @function async
     * @functionName _shipmentRejectionReasonsList
     * @functionPurpose this function for shipment rejection reasons list.
     *
     * @functionParam {}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _shipmentRejectionReasonsList = () => {

        Service.apiPostTokenCallRequest(RouteURL.rejectionReasonsList, {}).then((response) => {
           
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let res = response.data.reasonList;
                var newArray = []
                res.map((item, index) => (
                    newArray.push({
                        value: item.id,
                        label: item.reason
                    })
                ))
                setReasonList(newArray)
                

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /* on change input function */
    const formChange = (event) => {
        const { name, value } = event.target;       
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
    };


    /* on change input function */
    const rejectionFormChange = (event) => {
        const { name, value } = event.target;       
        setRejectionFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
    }

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }

    };

    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {

                    ...prevErrorValue, [targetName]: !formValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` : ""
                    // (formValue[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)

                };


            });
        }
        if (rejectionFormTouchValue[targetName]) {
            setRejectionErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !rejectionFormValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` : ""

                };
            });
        }
    };


    /* Validation Checking */
    const validation = (e) => {
        e.preventDefault();
        setFormTouchValue(markAllAsTouched(formTouchValue));
        setFormValue(leftTrimAllValue(formValue));
        checkError("quote_price");
        checkError("estimated_delivery_date");
        checkError("pickup_type");
        if (formValue.pickup_type == 'sendtous') {
            checkError("address_of_send_to_us");
        }
        if (formValue.delivery_type == 'Pick Up') {
            checkError("address_for_pickup_goods");
        }
        delete formValue.address_of_send_to_us;
        delete formValue.address_for_pickup_goods
        if (formValue.estimated_delivery_date && formValue.quote_price && formValue.pickup_type == 'wewill' && formValue.delivery_type == 'We will drop') {
            _transporterAcceptRequestSubmit()
        } else {
           
            if (isFormValid(formValue) && ((formValue.pickup_type == 'sendtous' && !!optionalFields.address_of_send_to_us) || (formValue.delivery_type == 'Pick Up' && !!optionalFields.address_for_pickup_goods))) {
                _transporterAcceptRequestSubmit()
            }
        }

    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 18/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _transporterAcceptRequestSubmit
    * @functionPurpose this function for transport accept request.
    *
    * @functionParam {shipment_booking_id,shipment_request_id,additional_notes,quote_price
    * estimated_delivery_date,pickup_type,address_of_send_to_us}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _transporterAcceptRequestSubmit = () => {
        let params = JSON.stringify({
            shipment_booking_id: shipment_booking_id,
            shipment_request_id: request_id,
            quote_price: formValue.quote_price,
            estimated_delivery_date: formValue.estimated_delivery_date,
            pickup_type: formValue.pickup_type == 'sendtous' ? 'Send to Us' : 'We will Pick Up',
            address_of_send_to_us: formValue.pickup_type == 'sendtous' ? optionalFields.address_of_send_to_us : "",
            delivery_type: formValue.delivery_type,
            address_for_pickup_goods: formValue.delivery_type == 'Pick Up' ? optionalFields.address_for_pickup_goods : ""
        });
       
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterAcceptRequest, params).then((response) => {
           
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {                
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }

    /* Validation Checking and request call */
    const rejectValidation = (e) => {
        e.preventDefault();
        setRejectionFormTouchValue(markAllAsTouched(rejectionFormTouchValue));
        setRejectionFormValue(leftTrimAllValue(rejectionFormValue));
        checkError("rejection_reason");
        if (rejectionFormValue.rejection_reason) {
            _transporterRejectRequestSubmit()
        }
    }


    /**
    * @author Sarmistha Mondal
    * @Date_Created 18/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _transporterRejectRequestSubmit
    * @functionPurpose this function for transport reject request.
    *
    * @functionParam {shipment_booking_id,shipment_request_id,rejection_reason,additional_notes}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _transporterRejectRequestSubmit = () => {
        let params = JSON.stringify({
            shipment_booking_id: shipment_booking_id,
            shipment_request_id: request_id,
            rejection_reason: rejectionFormValue.rejection_reason,
            additional_notes: rejectionFormValue.additional_notes,
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterRejectRequest, params).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }

    /* select reason*/ 
    const reasonSelect = (e) => {
        setRejectionFormValue((prevFormValue) => {
            return { ...prevFormValue, rejection_reason: String(e.value) };
        });

    }


    /**
     * @author Sarmistha Mondal
     * @Date_Created 24/11/2023
     * @Date_Modified 
     * @function async
     * @functionName _AddressList
     * @functionPurpose this function for godown address list.
     *
     * @functionParam {}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _AddressList = () => {

        Service.apiPostTokenCallRequest(RouteURL.godownAddressList, {}).then((response) => {
          
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.addressList.length > 0) {
                    let result = response.data.addressList.map(({ is_deleted, is_verified, updated, ...rest }) => ({
                        ...rest,
                    })); // remove array element
                   
                    let res = response.data.addressList;

                    var newArray = []
                    res.map((item, index) => (
                        newArray.push({
                            value: item.id,
                            label: item.address_name
                        })
                    ))
                    setAddressList(newArray)
                }
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /* slider navigation*/ 
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;

        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/ 
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }

    return (
        <>
            < Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec" ref={modalRef}>
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">{Type == 'accept' ? 'Accept' : 'Reject'} Request</h6>
                    </Modal.Header>

                    <section className="reject_inner">
                        <div className="reject_req_sec">
                            <div className="reject_hed"><h4>{filteredData?.shipment_details?.request_id} <br /><span style={{ color: "#FBAA2A" }}>{filteredData?.shipment_details?.shipper_code}</span></h4></div>
                            <div className="reject_person"><p>{filteredData?.shipment_details?.shipper_name}</p></div>
                        </div>
                        <div className="reject_track">
                            <div className="order-track-step">
                                <div className="order-track-status">
                                    <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                    <span className="order-track-status-line"></span>
                                </div>
                                <div className="order-track-text">
                                    <span className="order-track-text-sub">Booking Date - {filteredData?.location_details?.booking_date}</span>
                                    <p className="order-track-text-stat">{filteredData?.location_details?.from_location}</p>
                                    <span className="kilometer">{filteredData?.location_details?.distance}</span>
                                    <span className="kilometer">{filteredData?.location_details?.time_duration}</span>
                                </div>
                            </div>
                            <div className="order-track-step">
                                <div className="order-track-status">
                                    <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                    <span className="order-track-status-line"></span>
                                </div>
                                <div className="order-track-text">
                                    <span className="order-track-text-sub">Est. Delivery date -{filteredData.location_details?.estimated_delivery_date != null ? filteredData.location_details?.estimated_delivery_date : ' _____'}</span>
                                    <p className="order-track-text-stat">{filteredData?.location_details?.to_location}</p>
                                </div>
                            </div>

                        </div>

                        <div className="material_show_dot">
                            <span className="dot_bluclick">
                                <i className="fa-solid fa-ellipsis" onClick={() => setProductDetailsShow(!productDetailsShow)} />
                                {productDetailsShow == true &&
                                    <>
                                        <div className="dot_bluclick_inner shipaccept">
                                            <span><i className="fa-solid fa-xmark" onClick={() => setProductDetailsShow(false)}></i></span>
                                            <Slider {...settings}>
                                                {filteredData?.material_type.length > 0 && filteredData?.material_type.map((item) => {
                                                    return (
                                                        <div className="row d-flex">

                                                            <div className="col-sm-12">
                                                                <div className="material1_sec">
                                                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 5.737 6">
                                                                        <g id="Group_29516" data-name="Group 29516" transform="translate(-803 -366)">
                                                                            <path id="Path_9494" data-name="Path 9494" d="M26.667,1.6,29.308.463,28.214.014a.19.19,0,0,0-.144,0L25.444,1.093Z" transform="translate(777.726 366)" fill="#928fa9" />
                                                                            <path id="Path_9495" data-name="Path 9495" d="M172.654,56.125l-2.641,1.132.984.4,2.7-1.108Z" transform="translate(634.871 310.54)" fill="#928fa9" />
                                                                            <path id="Path_9496" data-name="Path 9496" d="M13.76,122.008l-1.069-.439v.831a.19.19,0,0,1-.379,0v-.987l-1.231-.506v3.339a.19.19,0,0,0,.118.175l2.561,1.052Z" transform="translate(791.919 246.525)" fill="#928fa9" />
                                                                            <path id="Path_9497" data-name="Path 9497" d="M269.069,122.008v3.466l2.561-1.052a.19.19,0,0,0,.118-.175v-3.339Z" transform="translate(536.989 246.525)" fill="#928fa9" />
                                                                        </g>
                                                                    </svg>Material</p>
                                                                    <h6>{item.material_type}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="10.141" height="10" viewBox="0 0 5.141 6">
                                                                        <g id="scale" transform="translate(-36.645)">
                                                                            <g id="Group_8799" data-name="Group 8799" transform="translate(36.645)">
                                                                                <g id="Group_8798" data-name="Group 8798">
                                                                                    <path id="Path_9414" data-name="Path 9414" d="M40.915,1.059A2.128,2.128,0,0,0,41.761.27.179.179,0,0,0,41.608,0H36.824a.179.179,0,0,0-.154.27,2.127,2.127,0,0,0,.847.789,2.531,2.531,0,0,0,1.018.282V1.8H37.69a.627.627,0,0,0-.621.55L36.708,5.3a.626.626,0,0,0,.621.7H41.1a.626.626,0,0,0,.621-.7L41.363,2.35a.627.627,0,0,0-.621-.55H39.9V1.341A2.532,2.532,0,0,0,40.915,1.059ZM40.681,3.9a1.465,1.465,0,1,1-1.465-1.465A1.466,1.466,0,0,1,40.681,3.9Z" transform="translate(-36.645)" fill="#928fa9" />
                                                                                </g>
                                                                            </g>
                                                                            <g id="Group_8801" data-name="Group 8801" transform="translate(38.1 2.781)">
                                                                                <g id="Group_8800" data-name="Group 8800">
                                                                                    <path id="Path_9415" data-name="Path 9415" d="M162.628,238.308a1.115,1.115,0,1,0,1.115,1.115A1.117,1.117,0,0,0,162.628,238.308Zm.127,1.243-.433.433a.18.18,0,1,1-.255-.255l.433-.433a.18.18,0,0,1,.255.255Z" transform="translate(-161.513 -238.308)" fill="#928fa9" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> Weight</p>
                                                                    <h6>{item.package_weight}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.017 6">
                                                                        <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                        <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                        <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                    </svg> Dimensions</p>
                                                                    <h6>{item.product_dimension}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p> Number of Boxes</p>
                                                                    <h6>{item.no_of_units}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p>Payment Mode</p>
                                                                    <h6> {filteredData?.payment_mode}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p>Loading Type</p>
                                                                    <h6>{filteredData?.load_type} </h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <h6>Product Image</h6>
                                                                    <div className="productmaterial_image_box">
                                                                        {item?.images.length > 0 && item?.images.map((img) => {
                                                                            return (<div className="p_box">
                                                                                <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                            </div>
                                                                            )
                                                                        })}

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })}
                                            </Slider>
                                            <span className="slider_number_count">{buttonVisible}/<span style={{ color: "#fbaa2a" }}>{filteredData?.material_type.length}</span></span>
                                        </div>

                                    </>

                                }



                            </span>
                        </div>
                        <div className="Reject_field">
                            <div className="row">
                                {Type == 'accept' &&
                                    <>
                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Quoted Price</label>
                                                <input type="number" placeholder="Enter Quote Price" name="quote_price"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    value={formValue.quote_price}
                                                    error={errorValue.quote_price ? "true" : "false"} />
                                                {errorValue.quote_price && (
                                                    <span className="error">
                                                        {errorValue.quote_price}
                                                    </span>
                                                )}

                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Estimated Delivery Date</label>
                                                <input type="date" name="estimated_delivery_date"
                                                    placeholder="Enter Estimated Delivery Date"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    min={moment(new Date()).format("YYYY-MM-DD")}
                                                    value={formValue.estimated_delivery_date}
                                                    error={errorValue.estimated_delivery_date ? "true" : "false"} />
                                                {errorValue.estimated_delivery_date && (
                                                    <span className="error">
                                                        {errorValue.estimated_delivery_date}
                                                    </span>
                                                )}

                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="office_field">
                                                <label>Pickup Type</label>
                                                <div className="office_radio">
                                                    <div className="radio_sec">
                                                        <input id="radio-1" className="radio-custom" type="radio" value="sendtous" name="pickup_type"
                                                            checked={formValue.pickup_type == 'sendtous' ? true : false}
                                                            onBlur={updateFormTouchValue}
                                                            onChange={formChange}
                                                        />
                                                        <label for="radio-1" className="radio-custom-label" style={{ color: "#000" }}>Send to Us</label>
                                                    </div>
                                                    <div className="radio_sec">
                                                        <input id="radio-2" className="radio-custom" name="pickup_type" type="radio" value="wewill"
                                                            checked={formValue.pickup_type == 'wewill' ? true : false}
                                                            onChange={formChange}
                                                            onBlur={updateFormTouchValue}
                                                        />
                                                        <label for="radio-2" className="radio-custom-label">We will Pick Up</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {errorValue.pickup_type && (
                                                <span className="error">
                                                    {errorValue.pickup_type}
                                                </span>
                                            )}
                                        </div>

                                        {addressShow == true &&
                                            <div className="col-sm-12">
                                                <div className="office_field">
                                                    <label> Select address to send goods</label>
                                                    <Select
                                                        placeholder="Select Option"
                                                        onChange={(e) => {
                                                            setoptionalFields((prevFormValue) => {
                                                                return { ...prevFormValue, 'address_of_send_to_us': e.value };
                                                            });

                                                        }}
                                                        options={addressList}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />
                                                    {!optionalFields.address_of_send_to_us && errorValue.address_of_send_to_us && (
                                                        <span className="error">
                                                            {errorValue.address_of_send_to_us}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        }

                                        <div className="col-sm-12">
                                            <div className="office_field">
                                                <label>Delivery Type</label>
                                                <div className="office_radio">

                                                    <div className="radio_sec">
                                                        <input id="radio-4" className="radio-custom" name="delivery_type" type="radio" value="We will drop"
                                                            onChange={formChange}
                                                            checked={formValue.delivery_type == 'We will drop' ? true : false}
                                                        />
                                                        <label for="radio-4" className="radio-custom-label">We will drop</label>
                                                    </div>
                                                    <div className="radio_sec">
                                                        <input id="radio-3" className="radio-custom" type="radio" value="Pick Up" name="delivery_type"
                                                            checked={formValue.delivery_type == 'Pick Up' ? true : false}
                                                            onChange={formChange}
                                                        />
                                                        <label for="radio-3" className="radio-custom-label" style={{ color: "#000" }}>Pick Up</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {addressforpickup_goods == true &&
                                            <div className="col-sm-12">
                                                <div className="office_field">
                                                    <label>Select address for pickup goods</label>
                                                    <Select
                                                        placeholder="Select Option"
                                                        onChange={(e) => {
                                                            setoptionalFields((prevFormValue) => {
                                                                return { ...prevFormValue, 'address_for_pickup_goods': e.value };
                                                            });
                                                        }}
                                                        options={addressList}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                    />
                                                </div>
                                                {!optionalFields.address_for_pickup_goods && errorValue.address_for_pickup_goods && (
                                                    <span className="error">
                                                        {errorValue.address_for_pickup_goods}
                                                    </span>
                                                )}
                                            </div>}
                                        <div className="col-sm-4 offset-sm-4">
                                            <div className="login_btn"><button type="submit" value="get Started" disabled={submitDisable ? true : false} onClick={validation} style={{ background: '#000' }}>Confirm </button></div>
                                        </div>
                                    </>

                                }
                                {Type == 'reject' &&
                                    <>

                                        <div className="col-sm-12">
                                            <div className="office_field">
                                                <label>Rejection Reason</label>
                                                <Select
                                                    placeholder="Select Option"
                                                    onChange={reasonSelect}
                                                    options={reasonList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />

                                                {rejectionErrorValue.rejection_reason && (
                                                    <span className="error">
                                                        {rejectionErrorValue.rejection_reason}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="office_field">
                                                <label>Additional Notes</label>
                                                <input type="text" placeholder="Enter Note"
                                                    name="additional_notes"
                                                    onChange={rejectionFormChange}
                                                    value={rejectionFormValue.additional_notes}
                                                    error={rejectionFormValue.additional_notes ? "true" : "false"} />
                                              
                                            </div>
                                        </div>



                                        <div className="col-sm-4 offset-sm-4">
                                            <div className="login_btn"><button type="submit" value="get Started"
                                                onClick={rejectValidation} disabled={submitDisable ? true : false}
                                                style={{ background: '#000' }}>Confirm & Reject</button></div>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>

                    </section>

                </div>
            </Modal>
        </>
    )
}