/**
 * @file_purpose  page for payment details
 * @author Sarmistha Mondal
 * @Date_Created 06/05/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Modal from "react-bootstrap/Modal";
import { useNavigate, Link } from "react-router-dom";


export default function PaymentDetailsModal(props) {
    let quote_price = props?._allData?.quote_price;
    let payment_method = props?._allData?.payment_method;
    let invoice_document = props?._allData?.invoice_document;
    const [rating, setRating] = useState(0)
    const [submitDisable, setSubmitDisable] = useState(false);
    const [errorRating, setErrorRating] = useState(false);
    const [review, setreview] = useState()

    /* Validation Checking */
    const validation = (e) => {
        e.preventDefault();
        if (rating) {
            // _submitRating()
        } else {
            if (rating == 0) {
                setErrorRating('Please Rating Us');
            } else setErrorRating();
        }
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 06/05/2024
      * @Date_Modified 
      * @function async
      * @functionName _submitRating
      * @functionPurpose this function for submition rating.
      *
      * @functionParam {}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _submitRating = () => {

        setSubmitDisable(true)
        let params = JSON.stringify({
            // user_id: (localStorage.getItem('user_type') == 'Transporter' ? shipper_id : transporter_id),
            rating: rating,
            review: review
        });
        // console.log(params);
        // Service.apiPostTokenCallRequest(RouteURL.postRatingReview, params).then((response) => {
        //     // console.log(response);
        //     if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        //         props.CloseAddEditModal();
        //         props.onChangeSomeState("add");
        //         setSubmitDisable(false)
        //     } else {
        //         setSubmitDisable(false)
        //         toast.error(response.message, {
        //             position: toast.POSITION.BOTTOM_RIGHT,
        //         });
        //     }
        // }).catch((error) => {
        //     setSubmitDisable(false)

        // });
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Payment Details</h6>
                    </Modal.Header>

                    <section className="reject_inner refund_sec">

                        <div className="row">
                            <div className="col-6">
                                <div className="office_field">
                                    <label><span>Paid :</span> ₹ {quote_price}</label>

                                </div>
                            </div>
                            <div className="col-sm-6 col-6">
                                <div className="office_field">
                                    <label><span>Date :</span> 30 July, 2023</label>

                                </div>
                            </div>
                            <div className="col-sm-6 col-6">
                                <div className="office_field">
                                    <label><span>Payment Proof</span></label>
                                    <p><Link to={`${invoice_document}`} target="_blank" ><img src={invoice_document} alt="" height={40} width={40} /> </Link></p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-6">
                                <div className="office_field">
                                    <label> <span>Payment Method</span></label>
                                    <p>{payment_method}</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 offset-sm-3">
                            <div className="login_btn">
                                <button type="submit" value="get Started"
                                    // disabled={submitDisable ? true : false}
                                    style={{ background: '#000', textTransform: "capitalize" }}
                                    onClick={validation}
                                > Refund</button>
                            </div>
                        </div>
                    </section>


                </div>
            </Modal>
        </>
    )
}