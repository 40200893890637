/**
 * @file_purpose  page for showing user list
 * @author Sarmistha Mondal
 * @Date_Created 13/12/2023
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { CDataTable, } from "@coreui/react";
import { Constants } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import AddUserForm from "./addUser";
import Modal from "react-bootstrap/Modal";

export default function UsersList() {
    const [filteredData, setFilteredData] = useState([]);
    const [_filteredData, _setFilteredData] = useState([]);
    const [visible, setVisible] = useState()
    const [deleteModal, setDeleteModal] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    const [upadate, setUpdate] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [user_id, setUserId] = useState("");
    const [modalValue, setModalValue] = useState({
        type: "",
        data: {},
        user_id: "",
        name: ""
    });
    const fields = [
        { key: 'first_name', label: "User Name", },
        { key: 'email', label: "Email Address", },
        { key: 'phone_number', label: "WhatsApp Number" },
        { key: 'role', label: "Role" },
        { key: 'created', label: "Last Update" },
        { key: 'status', },
        {
            key: "action",
            label: "Action",
            _style: { width: "20%" },
            sorter: false,
            filter: false,
        },
    ];

    useEffect(() => {
        _getTransporterUserList();
    }, [upadate])




    /**
    * @author Sarmistha Mondal
    * @Date_Created 13/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _getTransporterUserList
    * @functionPurpose this function for transporter user list.
    *
    * @functionParam {}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _getTransporterUserList = () => {
        Service.apiPostTokenCallRequest(RouteURL.transporterUserList, {}).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.userList.length > 0) {
                    setFilteredData(response.data.userList)
                    _setFilteredData(response.data.userList)
                }

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /* open modal*/
    const _userModal = (e, type, item) => {
        e.preventDefault();
        setVisible(!visible)
        setModalValue({
            type: type,
            data: item
        })

    }

    /* close modal*/
    const closeAddEditModal = () => {
        setVisible(false);
        setDeleteModal(false)
    };

    /*after modal close get api call*/
    const onChangeSomeState = (State) => {
        if (!!State) {
            _getTransporterUserList();
        }
    };



    /**
     * @author Sarmistha Mondal
     * @Date_Created 15/03/2024
     * @Date_Modified
     * @function async
     * @functionName _updateUser
     * @functionPurpose this function for user update.
     *
     * @functionParam {first_name, last_name, user_id, contact_number,role_id,status }
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */

    const _updateUser = (e, item, type) => {

        setSubmitDisable(false)
        let params = JSON.stringify({
            first_name: item.first_name,
            last_name: item.last_name,
            user_id: item.user_id,
            contact_number: item.phone_number,
            role_id: item.role_id,
            status: type,
        });
        Service.apiPostTokenCallRequest(RouteURL.transporterUpdateUser, params).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

                toast.success(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(true);
                    },
                });



            } else {

                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(true);
                    },
                });
            }
        }).catch((error) => {
            setSubmitDisable(true);

        });

    };

    /* search user*/
    const handleSearch = (event) => {
        let value = event.target.value;
        let result = [];
        setSearchQuery(value);
        if (value != "") {

            const filtered = filteredData.filter((item) =>
                item.first_name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredData(filtered);
        } else setFilteredData(_filteredData);
    };

    /* delete user*/
    const deleteModalOpen = (e, id, name) => {
        e.preventDefault();
        setDeleteModal(true)
        setModalValue({
            user_id: id,
            name: name
        })
    }


    /**
       * @author Sarmistha Mondal
       * @Date_Created 14/05/2024
       * @Date_Modified 
       * @function async
       * @functionName _deleteUsersubmit
       * @functionPurpose this function for delete user from list.
       *
       * @functionParam {user_id}
       *
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _deleteUsersubmit = (e) => {
        e.preventDefault();
        setSubmitDisable(false)
        let params = JSON.stringify({
            user_id: modalValue.user_id
        });
        Service.apiPostTokenCallRequest(RouteURL.transporterDeleteUser, params).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setDeleteModal(false);
                onChangeSomeState('add')
                toast.success(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(true);
                    },
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(true);
                    },
                });
            }
        }).catch((error) => {
            setSubmitDisable(true);

        });
    }
    return (
        <>
            <div className="sideright_menu">
                <div className="shipment_menu_rt">

                    <div className="godown_upper_hed">
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="godown_upper_hed_lt">
                                    <h4>Users</h4>
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <div className="godown_upper_hed_rt">
                                    <div className="office_field"><button type="button"
                                        onClick={(e) => { _userModal(e, 0,) }}
                                    ><i className="fa-solid fa-plus"></i> Add Users </button></div>

                                    <div className="delivery_search"><i className="fa-solid fa-magnifying-glass"></i>

                                        <input type="search" value={searchQuery} placeholder="Search For User Name..." onChange={(event) => handleSearch(event)} />

                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="godwn_dwn">
                        <div className="row">
                            <div className="col">
                                <div className="office_address_lt shipping_dwn_pad user_pad_sec">
                                    <div className="shipper_table shipping_pagination">
                                        <>
                                            <CDataTable
                                                items={filteredData}
                                                fields={fields}
                                                itemsPerPage={10}
                                                itemsPerPageSelect
                                                pagination={{
                                                    className: "next_arrow",
                                                    firstButton: <i class="fa-solid fa-angle-left"></i>,
                                                    previousButton: <i class="fa-solid fa-angles-left"></i>,
                                                    nextButton: <i class="fa-solid fa-angles-right"></i>,
                                                    lastButton: <i class="fa-solid fa-angle-right"></i>,
                                                    size: "",
                                                    dots: false
                                                }}
                                                scopedSlots={{

                                                    first_name: (item, index) => {
                                                        return (
                                                            <td style={{ color: '#FBAA2A' }}>
                                                                {item.first_name + " " + item.last_name}
                                                            </td>
                                                        )
                                                    },
                                                    created: (item) => (
                                                        <td style={{ color: "#161615" }}>
                                                            {item.created}
                                                            {/* {moment(item.created).format("YYYY/MM/DD")} */}
                                                        </td>
                                                    ),
                                                    role: (item, index) => {
                                                        return (
                                                            <td style={{ color: '#1E90FF' }}>
                                                                {item.role}
                                                            </td>
                                                        )
                                                    },
                                                    status: (item, i) => (
                                                        <td style={{ display: "flex", justifyContent: "center" }}>
                                                            <div className="form-check form-switch switch_sec">
                                                                <input className="form-check-input" type="checkbox"
                                                                    checked={item.status == "Inactive" ? false : true}
                                                                    disabled={submitDisable ? false : true}
                                                                    onChange={(e) => {
                                                                        setUpdate(!upadate)
                                                                        if (e.target.checked != true) {
                                                                            filteredData[i].status = 'Inactive';
                                                                            _updateUser(e, item, 'Inactive')
                                                                        } else {
                                                                            filteredData[i].status = 'Active';
                                                                            _updateUser(e, item, 'Active')
                                                                        }

                                                                    }} />
                                                            </div>

                                                        </td>
                                                    ),
                                                    phone_number: (item, index) => {
                                                        return (
                                                            <td style={{ color: "#161615" }}>
                                                                {item.phone_number}
                                                            </td>
                                                        )
                                                    },

                                                    action: (item, index) => {
                                                        return (

                                                            <td className="action_chk">
                                                                <i className="fa-solid fa-pencil" style={{ color: '#000' }} title="Edit"
                                                                    onClick={(e) => { _userModal(e, 1, item) }}
                                                                />
                                                                <i className="fa-solid fa-trash-can" title="Delete"
                                                                    onClick={(e) => deleteModalOpen(e, item.user_id, (item.first_name + " " + item.last_name))}
                                                                />
                                                            </td>


                                                        );
                                                    },
                                                }}
                                            />
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {deleteModal && <Modal
                show={deleteModal}
                onHide={() => setDeleteModal(false)}
                backdrop="static"
            > <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}> <h6 className="hed_txt">Delete User
                    </h6></Modal.Header>
                </div>
                <div className="task_modal_sec">
                    <div className="row">
                        <div className="col">
                            <div className="office_address_lt" >
                                <p>Do you really want to delete "{modalValue.name}" from your users list?
                                </p>
                                <div className='reco_trans_rt_inner_dwn' style={{ justifyContent: 'center' }}>
                                    <div>
                                        <button type='' value="reject"
                                            onClick={() => setDeleteModal(false)}
                                            style={{ background: '#fff', color: '#000' }}
                                        >Cancel</button></div>
                                    <div><button type='reject' value="reject"
                                        onClick={(e) => _deleteUsersubmit(e)}
                                        disabled={submitDisable ? false : true}
                                        style={{ background: '#000', color: '#fff' }}
                                    >Delete</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>}

            {visible && <AddUserForm Type={1} CloseAddEditModal={closeAddEditModal} _allData={modalValue}
                onChangeSomeState={onChangeSomeState} show={visible} onHide={() => setVisible(false)} />}

        </>
    );
}
