/**
 * @file_purpose  page for showing landing page
 * @author Sarmistha Mondal
 * @Date_Created 18/03/2024
 * @Date_Modified 
 */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import LandingPageFooter from "./landingPageFooter";

export default function TermAndCondition() {
    const navigate = useNavigate();

    return (
        <>
            <header className="header_sec1">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="header_inn1">
                                <div className="row">
                                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-9">

                                        <div className="header_inn_lt">

                                            <div className="menu_sec"  >
                                                <nav className="navbar navbar-expand-lg navbar-light">
                                                    <div className="container-fluid">
                                                        <a className="navbar-brand" href="/"><img src={logo} alt="" /></a>
                                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#navbarSupportedContent"
                                                            aria-controls="navbarSupportedContent" aria-expanded="false"
                                                            aria-label="Toggle navigation">
                                                            <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
                                                        </button>


                                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
                                                                <li className="nav-item">
                                                                    <a className="nav-link active" aria-current="page"
                                                                        href="/">Home</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/">About us</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/terms-and-conditions">Terms & Condition</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/">How it works</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/">Contact us</a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </nav>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 d-none d-md-block">
                                        <div className="header_inn_rt">
                                            <ul>
                                                <li><a href=""><i className="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-instagram"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-twitter"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </header >

            <section className="terms_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="terms_inn">
                                <h6>SG TERMS & CONDITIONS</h6>


                                

                                Terms and Conditions of Service

                                These terms and conditions ("Terms") govern your use of the logistics platform "SHIPGUDDS" provided by PM LOGISTIC SOLUTIONS PVT LTD.  Incorporated on 09 June 2023, CIN NO. U52243TS2023PTC173936, PAN NO: AANCP8379B, by accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, do not access or use the Platform.

                                <h6 style={{textAlign:'left'}}>1. Definitions</h6>

                                "Shipper" refers to any person or entity using the Platform to book goods for transportation.<br />
                                "Transporter" refers to any person or entity using the Platform to accept bookings for transportation services.<br />
                                "User" refers to any person or entity accessing or using the Platform.<br />

                                <h6 style={{ textAlign: 'left' }}>2. Services Provided</h6>

                                The Company operates an online platform that facilitates the booking of goods transportation services by connecting Shippers with Transporters. The Platform allows Shippers to submit booking requests, and Transporters to accept such requests based on availability and other factors.

                                <h6 style={{ textAlign: 'left' }}>3. Booking Process</h6>
                                Shippers may submit booking requests through the Platform, providing details such as the nature of goods, pickup and delivery locations, and desired transportation dates.<br />
                                Transporters may accept or decline booking requests based on their availability and capacity.<br />
                                Once a booking request is accepted by a Transporter, a binding contract for transportation services is formed between the Shipper and the Transporter.
                                <h6 style={{ textAlign: 'left' }}>4. Fees and Payments</h6>
                                The Company may charge fees for the use of its Platform, which will be communicated to Users.<br />
                                Payment terms and methods will be specified by the Company and agreed upon by Users prior to booking confirmation.<br />
                                Users are responsible for any taxes or duties applicable to the transportation services provided.<br />
                                <h6 style={{ textAlign: 'left' }}>5. Responsibilities of Users</h6>
                                Shippers are responsible for accurately describing the goods to be transported and ensuring compliance with all applicable laws and regulations.<br />
                                Transporters are responsible for safely transporting goods and complying with all applicable transportation laws and regulations.<br />
                                Users must not engage in any fraudulent, illegal, or harmful activities on the Platform.
                                <h6 style={{ textAlign: 'left' }}>6. Limitation of Liability</h6>
                                The Company is not liable for any damages, losses, or liabilities arising from the transportation services provided by Transporters.<br />
                                The Company is not liable for any damages, losses, or liabilities arising from the actions or omissions of Users.<br />
                                <h6 style={{ textAlign: 'left' }}>7. Termination</h6>
                                The Company reserves the right to suspend or terminate a User's access to the Platform at any time for any reason, without prior notice.<br />
                                Upon termination, Users must cease all use of the Platform and any associated services.<br />
                                Users agree to indemnify and hold harmless the Company from any claims, damages, or liabilities arising from their use of the Platform or breach of these Terms.
                                <h6 style={{ textAlign: 'left' }}>8. Governing Law and Dispute Resolution</h6>
                                These Terms shall be governed by and construed in accordance with the laws of India.<br />
                                Any dispute arising out of or relating to these Terms shall be resolved through arbitration in accordance with the Indian Arbitration and Conciliation Act, [year].
                                <h6 style={{ textAlign: 'left' }}>9. Amendments</h6>
                                The Company reserves the right to modify or update these Terms at any time without prior notice.<br />
                                Users are responsible for regularly reviewing these Terms to stay informed of any changes.<br />
                                <h6 style={{ textAlign: 'left' }}>10. Contact Information</h6>
                                If you have any questions or concerns about these Terms, please contact us at shipguddsindia@gmail.com.<br /><br /><br />

                                By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms.
                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <LandingPageFooter/>
        </>
    )
}