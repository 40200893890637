/**
 * @file_purpose  page for showing recommended transport
 * @author Sarmistha Mondal
 * @Date_Created 08/12/2023
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { CTooltip } from "@coreui/react";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Dimension from "../../../assets/images/square-measument.png"
import TallTruck from "../../../assets/images/tall_truck.png"
import Call from "../../../assets/images/phone-call.png"
import Whtsp from "../../../assets/images/whtsp.png";
import { Rating } from 'react-simple-star-rating'
import ReactWhatsapp from 'react-whatsapp';
import Slider from "react-slick";


export default function RecommendedTransport(props) {
    const navigate = useNavigate();
    const { bookingid } = useParams();
    const [buttonVisible, setButtonVisible] = useState(0);
    const [recommendedTransport, setRecommendedTransport] = useState({
        bookingDetails: {},
        location_details: {},
        material_typeDetails: [],
        shipment_details: {},
        recomemded_transporter: []
    });

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        touchMove: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    useEffect(() => {
       
        _ShipmentRecommendedTransporter()
    }, []);

    /**
      * @author Sarmistha Mondal
      * @Date_Created 08/12/2023
      * @Date_Modified 
      * @function async
      * @functionName _ShipmentRecommendedTransporter
      * @functionPurpose this function for shipment recommended details.
      *
      * @functionParam {shipment_booking_id}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _ShipmentRecommendedTransporter = () => {
        let params = JSON.stringify({
            shipment_booking_id: bookingid,
        });
        Service.apiPostTokenCallRequest(RouteURL.shipmentRecommendedTransporter, params).then((response) => {
        
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let book_res = response.data.bookingDetails;
                let location_details = response.data.bookingDetails.location_details;
                let material_typeDetails = response.data.bookingDetails.material_type;
                let shipment_details = response.data.bookingDetails.shipment_details;
                let recomemded_transporter = response.data.bookingDetails.recomemded_transporter;
            
                setRecommendedTransport((prevState) => ({
                    ...prevState,
                    bookingDetails: book_res,
                    location_details: location_details,
                    material_typeDetails: material_typeDetails,
                    shipment_details: shipment_details,
                    recomemded_transporter: recomemded_transporter
                }));

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };
  

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;       
        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }

    return (
        <>
            <div className="sideright_menu">
                <div className="new_shipment_rt">
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className='booking_hed_txt mb-3'>
                                <h4>Recommended Transporter</h4>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 col-xxl-4 col-xl-4 col-lg-12 col-md-12 '>
                                    <div className='reco_trans_lt'>
                                        <div className='reco_trans_lt_top'>
                                            <h4>{recommendedTransport?.shipment_details?.request_id}</h4>
                                            <p>{recommendedTransport?.shipment_details?.shipper_code}</p>
                                            <h6>{recommendedTransport?.shipment_details?.shipper_name}</h6>
                                        </div>
                                        <div className='reco_trans_lt_dwn'>
                                            <div className='row'>
                                                <div className='col-sm-12 col-xxl-5 col-xl-12'>
                                                    <div className='row'>

                                                        <Slider {...settings} >
                                                            {recommendedTransport?.material_typeDetails.length > 0 && recommendedTransport?.material_typeDetails.map((item, key) => {
                                                                return (<div>

                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p><img src={Dimension} alt="" /> Material</p>
                                                                            <h6>{item?.material_type}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p><img src={Dimension} alt="" /> Weight</p>
                                                                            <h6>{item?.package_weight}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p><img src={Dimension} alt="" /> Dimensions</p>
                                                                            <h6>{item?.product_dimension}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="material1_sec">
                                                                            <p> Number of Boxes</p>
                                                                            <h6>{item?.no_of_units}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-sm-12'>
                                                                        <div className="material1_sec">
                                                                            <p> Loading Type</p>
                                                                            <h6>{recommendedTransport?.bookingDetails?.load_type}</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-sm-12'>
                                                                        <div className="material1_sec">
                                                                            <p> Payment Mode</p>
                                                                            <h6>{recommendedTransport?.bookingDetails?.payment_mode}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            })}

                                                        </Slider>


                                                    </div>
                                                    <span className="slider_number_count">{buttonVisible}/<span style={{ color: "#fbaa2a" }}>{recommendedTransport?.material_typeDetails.length}</span></span>
                                                </div>
                                                <div className='col-sm-12 col-xxl-7 col-xl-12'>
                                                    <div className="reject_track trans_book">
                                                        <div className="order-track-step">
                                                            <div className="order-track-status">
                                                                <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                                                <span className="order-track-status-line"></span>
                                                            </div>
                                                            <div className="order-track-text">
                                                                <span className="order-track-text-sub">Booking Date - {recommendedTransport?.location_details?.booking_date}</span>
                                                                <p className="order-track-text-stat">{recommendedTransport?.location_details?.from_location}</p>
                                                                <span className="kilometer">{recommendedTransport?.location_details?.distance}</span>
                                                                <span className="kilometer">{recommendedTransport?.location_details?.time_duration}</span>
                                                            </div>
                                                        </div>
                                                        <div className="order-track-step">
                                                            <div className="order-track-status">
                                                                <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                                                <span className="order-track-status-line"></span>
                                                            </div>
                                                            <div className="order-track-text">
                                                                <span className="order-track-text-sub">Est. Delivery date - _____</span>
                                                                <p className="order-track-text-stat">{recommendedTransport?.location_details?.to_location}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <div className="login_btn">
                                        <button type="submit" value="get Started"
                                            onClick={() => navigate(`/shipment-list`, {
                                                state: {
                                                    bookingid: bookingid
                                                }
                                            })}
                                            style={{ background: '#000' }}
                                        >Pending Request</button>
                                    </div>
                                </div>

                                <div className='col-sm-12 col-xxl-8 col-xl-8 col-lg-12 col-md-12'>
                                    {recommendedTransport?.recomemded_transporter?.length > 0 ? recommendedTransport?.recomemded_transporter.map((item, key) => {
                                        return <div className='reco_trans_rt' key={key}>
                                            <div className='reco_trans_rt_inner'>
                                                <div className='reco_trans_rt_inner_top recommend_main border-0'>
                                                    <div className='row'>
                                                        <div className='col-sm-12 col-xxl-7 col-xl-7 col-lg-7 col-md-12'>
                                                            <div className='reco_trans_rt_box border-0'>
                                                                <div className='reco_trans_rt_img'>
                                                                    <img src={item?.profile_image ? item.profile_image : TallTruck} alt='' />

                                                                </div>
                                                                <div className='reco_trans_rt_details'>
                                                                    <h4>{item.firm_name}</h4>
                                                                    <div className='rrv_sec'>
                                                                        <div className='rating'>
                                                                            <Rating
                                                                                initialValue={item.no_of_reviews}
                                                                                readonly
                                                                                size={18}
                                                                            />
                                                                        </div>
                                                                        <div className='review'>
                                                                            <span><strong>{item.no_of_reviews}</strong> Reviews</span>
                                                                        </div>
                                                                        <div className='verified'>
                                                                            <span>Verified</span>
                                                                        </div>
                                                                    </div>
                                                                    <p>Avg Response Time <span>{item.average_response_time_in_hour} hour</span></p>
                                                                    <p><span>{item.year_of_experience} Yrs</span> Experiences</p>
                                                                    <p><span>{item.request_served}</span> Request Served</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 col-xxl-2 col-xl-2 col-lg-2 col-md-12'>

                                                        </div>
                                                        <div className='col-sm-12 col-xxl-3 col-xl-3 col-lg-3 col-md-12'>
                                                            <div className='reco_trans_rt_box recomended'>

                                                                <div className='reco_trans_rt_box1 wt-40'>
                                                                    <div className='number_box'>
                                                                        <h4>{item.transporter_code}</h4>
                                                                        <div className='call'>
                                                                            <span> <CTooltip content={item.contact_number
                                                                            }><img src={Call} alt='' /></CTooltip></span>
                                                                            <span><ReactWhatsapp number={item.contact_number
                                                                            } ><img src={Whtsp} alt='' /></ReactWhatsapp></span>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    }) : <div style={{ display: 'flex', justifyContent: "center", margin: 10, fontSize: 20 }}>No quotation received yet ...</div>}
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}