
/**
 * @file_purpose  page for forgot password
 * @author Sarmistha Mondal
 * @Date_Created 08/11/2023
 * @Date_Modified 
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim,
} from "../../Utility/Helper";
import { Constants, REGEX } from "../../Utility/ConstantData";
import Service from "../../apis/Service";
import RouteURL from "../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Lock from "../../assets/images/lock.png";

const ForgotPassword = (props) => {
    const navigate = useNavigate();
    const [formValue, setFormValue] = useState({ emailOrphone: "", });
    const [errorValue, setErrorValue] = useState({ emailOrphone: "", });
    const [isDisabled, setIsDisabled] = useState(false)
    const [formTouchValue, setFormTouchValue] = useState({
        emailOrphone: false,
    });
    const [openModal, setopenModal] = useState({
        visible: false,
        type: '',
        emailOrPhone: '',
        pageFor: 'forgotpass'
    });
  
   


    useEffect(() => {
        setFormValue({ emailOrphone: "", })
    }, []);
    useEffect(() => {
        if (formValue.emailOrphone != '') {

            checkError("emailOrphone");
        }
    }, [formValue]);

    const formChange = (event) => {
        const { name, value } = event.target;
        // console.log(name, value);
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
    };
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue,
                    [targetName]: getErrorMessage(targetName, formValue[targetName]),
                };
            });
        }
    };
  
    /* validate email*/
    const validateEmail = (email) => {
        const re = REGEX["email"]
        return re.test(String(email));
    }
    
    /* checking email */
    const validationCheck = (event) => {
        const { name, value } = event.target;
       
        if (validateEmail(value)) {
                console.log('ok');
                setErrorValue({ emailOrphone: "" }); 
                setopenModal((prev) => {
                    return {
                        ...prev,
                        type: 'email'
                    };
                });
            }
            else setErrorValue({ emailOrphone: "Please enter a valid email address." })
        
    }

    /**
       * @author Sarmistha Mondal
       * @Date_Created 06/11/2023
       * @function async
       * @functionName _resetPassword
       * @functionPurpose this function for reset password.
       *
       * @functionParam {username}
       *
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _resetPassword = (e) => {
        e.preventDefault();
        setFormTouchValue(markAllAsTouched(formTouchValue));
        setFormValue(leftTrimAllValue(formValue));
        checkError("emailOrphone");
        if (isFormValid(formValue)) {
            let params = JSON.stringify({
                username: formValue.emailOrphone,
            });
            setIsDisabled(true);
            Service.apiPostCallRequest(RouteURL.generateForgotpasswordOTP, params).then((response) => {
                // console.log(response);
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    props.onCloseForgotPassModal(openModal.type, formValue.emailOrphone)
                    setopenModal((prev) => {
                        return {
                            ...prev, visible: true,
                            type: openModal.type,
                            emailOrPhone: formValue.emailOrphone
                        };
                    });
                    setFormValue({ emailOrphone: '' })
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    },);

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setIsDisabled(false);
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                setIsDisabled(false);
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
        }
    };
  
    



    return (
        <>

            < Modal
                show={props.show}
                onHide={() => { setFormValue({ emailOrphone: '' }); props.onHide(); }}
                backdrop="static"
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Forgot Password</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="forgot_inn">
                            <img src={Lock} alt="" />
                            <form
                                onSubmit={_resetPassword}
                                name="signin_form"
                            >
                                <p>Enter Your Registered Email ID to Reset Your Password</p>
                                <input type="text" placeholder="Enter Your Registered Email" onChange={formChange}
                                    name="emailOrphone"
                                    onKeyUp={validationCheck}
                                    value={formValue.emailOrphone}
                                    error={errorValue.emailOrphone ? "true" : "false"} />
                               
                                    <span className="error" style={{height:15}}>
                                    {errorValue.emailOrphone && 
                                        errorValue.emailOrphone
                                    }
                                    </span>
                              
                                <div className="create_sub text-center">
                                    <div className="login_btn">
                                        <button type="submit" value="get Started" style={{ background: (Object.values(formValue).every(x => x != '') == false ? '#C5C4D2' : '#000') }}
                                            disabled={isDisabled ? true : false}>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </section>   
                    </Modal.Body>
                </div>
            </Modal>
             </>
    );
};

export default ForgotPassword;
