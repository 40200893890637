/**
 * @file_purpose  page for showing vehicle tracking
 * @author Sarmistha Mondal
 * @Date_Created 17/05/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import Accordion from 'react-bootstrap/Accordion'
import Dimension from "../../../assets/images/square-measument.png"
import call from "../../../assets/images/phone-call.png"
import SmallTruck from "../../../assets/images/sm_truck.png"
import { ToastContainer, toast } from "react-toastify";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Slider from "react-slick";
import { CTooltip } from "@coreui/react";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const mapStyle = {
    height: '400px',
    width: '100%'
}
export default function VehicleTracking(props) {
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState(props.bookingRequestId);
    const [buttonVisible, setButtonVisible] = useState(0);
    const [select, setSelect] = useState({ id: '' })
    const [map, setMap] = React.useState(null)
    const [defaultLocation, setDefaultLocation] = useState({
        lat: '',
        lng: ''
    })
    const [markerPosition, setMarkerPosition] = useState({
        lat: '',
        lng: ''
    })

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        touchMove: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.GOOGLE_MAPS_API_KEY,
        inputAutocompleteValue: "IN",
        options: {
            componentRestrictions: 'in',
        },
    });


    useEffect(() => {
    }, [buttonVisible])
    
    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }

    useEffect(() => {        
        _vehicleTrackingList()
    }, [search])

    useEffect(() => {
        setSearch(props.bookingRequestId)
    }, [props?.bookingRequestId])


    /**
     * @author Sarmistha Mondal
     * @Date_Created 17/05/2024
     * @Date_Modified 
     * @function async
    * @functionName _vehicleTrackingList
     * @functionPurpose this function for vehicle list.
     *
     * @functionParam {}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _vehicleTrackingList = () => {
        Service.apiPostTokenCallRequest(RouteURL.vehicleTrackingList, { filter: search, page_no: 999999 }).then((response) => {         
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                setFilteredData(response.data.allTrackingRequest)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

   
    const _selectAccord = (e, id, address_lat, address_long) => {     
        e.preventDefault();
        setSelect((prevFormValue) => {
            if (prevFormValue.id != id || prevFormValue.id == 0) {
                return { ...prevFormValue, id: id };
            }else{
                return { ...prevFormValue, id: '' }; 
            }
        })
        getAddress(Number(address_lat), Number(address_long))
    }

    /* selected vehicle curent location in map*/ 
    const getAddress = (lat, lng) => {
        let geocoder = new window.google.maps.Geocoder();
        let latlng = new window.google.maps.LatLng(lat, lng);
       
        geocoder.geocode({ 'latLng': latlng }, function (results, status) {
            if (status == 'OK') {
                console.log(results);
                const address = results[0].formatted_address;
                for (var i = 0; i < results[0].address_components.length; i++) {
                    for (var j = 0; j < results[0].address_components[i].types.length; j++) {
                        if (results[0].address_components[i].types[j] == "locality") {
                            setMarkerPosition((prevFormValue) => {
                                return {
                                    ...prevFormValue, lat: lat,
                                    lng: lng,
                                };
                            });
                            setDefaultLocation((prevFormValue) => {
                                return {
                                    ...prevFormValue, lat: lat,
                                    lng: lng,
                                };
                            });
                        }
                    }
                }
            }
        });
    }

    return (
        <>

            <div className="sideright_menu">
                <div className="new_shipment_rt">
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className='booking_hed_txt mb-3'>
                                <h4>Vehicle Tracking</h4>
                            </div>
                            {filteredData.length > 0 ?
                                <div className='vehicle_track_inn'>
                                    <div className='row'>

                                        <div className='col-sm-12'>
                                            <div className='vehicle_track_inn_search'>
                                                <div className="delivery_search"><i className="fa-solid fa-magnifying-glass"></i><input type="search" placeholder="Search for Req Id" value={search} onChange={(e) => setSearch(e.target.value)} /></div>
                                            </div>
                                        </div>
                                        <div className='col-sm-5'>
                                            <div className='vehicle_track_sec'>
                                                {filteredData.map((item, key) => {
                                                    return (
                                                        <Accordion  activeKey={key} defaultActiveKey={key} style={{marginBottom:10}}>
                                                            <Accordion.Item eventKey={select.id} >
                                                             
                                                                <Accordion.Header onClick={(e) => {
                                                                    _selectAccord(e, key, item?.location_details?.to_location_lat, item?.location_details?.to_location_long)
                                                                }}>

                                                                    <div className='accordian_head'>
                                                                        <h4>{item?.shipper_transporter_details?.request_id} <span>{item?.location_details?.estimated_delivery_date}</span></h4>
                                                                        <div className="reject_track">
                                                                            <div className="order-track-status">
                                                                                <span className="order-track-status-dot" style={{ background: "#7068E2" }}>
                                                                                    <i className="fa-solid fa-location-dot"></i>
                                                                                </span>
                                                                                <span className="order-track-status-line"></span>
                                                                                <span className="order-track-status-dot" style={{ background: "#2ED573" }}>
                                                                                    <i className="fa-solid fa-arrow-right"></i>
                                                                                </span>
                                                                            </div>
                                                                            <div className='track_del'>
                                                                                <div className='track_del_lt'>
                                                                                    <p>{item?.location_details?.from_location}<br /> </p>
                                                                                </div>
                                                                                <div className='track_del_lt'>
                                                                                    <p>{item?.location_details.to_location},<br /> </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className='reco_trans_lt'>
                                                                        <div className='reco_trans_lt_top'>
                                                                            <h4>{item?.shipper_transporter_details?.request_id}</h4>
                                                                        </div>

                                                                        <div className='reco_trans_lt_dwn'>
                                                                            <div className='row'>
                                                                                <div className='col-sm-12 col-xxl-5 col-xl-12'>
                                                                                    <div className='row'>
                                                                                        <Slider {...settings}>
                                                                                            {item?.material_type.length > 0 && item?.material_type.map((item, key) => {
                                                                                                return (<div>
                                                                                                    <div className="col-sm-12">
                                                                                                        <div className="material1_sec">
                                                                                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 5.737 6">
                                                                                                                <g id="Group_29516" data-name="Group 29516" transform="translate(-803 -366)">
                                                                                                                    <path id="Path_9494" data-name="Path 9494" d="M26.667,1.6,29.308.463,28.214.014a.19.19,0,0,0-.144,0L25.444,1.093Z" transform="translate(777.726 366)" fill="#928fa9" />
                                                                                                                    <path id="Path_9495" data-name="Path 9495" d="M172.654,56.125l-2.641,1.132.984.4,2.7-1.108Z" transform="translate(634.871 310.54)" fill="#928fa9" />
                                                                                                                    <path id="Path_9496" data-name="Path 9496" d="M13.76,122.008l-1.069-.439v.831a.19.19,0,0,1-.379,0v-.987l-1.231-.506v3.339a.19.19,0,0,0,.118.175l2.561,1.052Z" transform="translate(791.919 246.525)" fill="#928fa9" />
                                                                                                                    <path id="Path_9497" data-name="Path 9497" d="M269.069,122.008v3.466l2.561-1.052a.19.19,0,0,0,.118-.175v-3.339Z" transform="translate(536.989 246.525)" fill="#928fa9" />
                                                                                                                </g>
                                                                                                            </svg> Material</p>
                                                                                                            <h6>{item?.material_type}</h6>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-sm-12">
                                                                                                        <div className="material1_sec">
                                                                                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="10.141" height="10" viewBox="0 0 5.141 6">
                                                                                                                <g id="scale" transform="translate(-36.645)">
                                                                                                                    <g id="Group_8799" data-name="Group 8799" transform="translate(36.645)">
                                                                                                                        <g id="Group_8798" data-name="Group 8798">
                                                                                                                            <path id="Path_9414" data-name="Path 9414" d="M40.915,1.059A2.128,2.128,0,0,0,41.761.27.179.179,0,0,0,41.608,0H36.824a.179.179,0,0,0-.154.27,2.127,2.127,0,0,0,.847.789,2.531,2.531,0,0,0,1.018.282V1.8H37.69a.627.627,0,0,0-.621.55L36.708,5.3a.626.626,0,0,0,.621.7H41.1a.626.626,0,0,0,.621-.7L41.363,2.35a.627.627,0,0,0-.621-.55H39.9V1.341A2.532,2.532,0,0,0,40.915,1.059ZM40.681,3.9a1.465,1.465,0,1,1-1.465-1.465A1.466,1.466,0,0,1,40.681,3.9Z" transform="translate(-36.645)" fill="#928fa9" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Group_8801" data-name="Group 8801" transform="translate(38.1 2.781)">
                                                                                                                        <g id="Group_8800" data-name="Group 8800">
                                                                                                                            <path id="Path_9415" data-name="Path 9415" d="M162.628,238.308a1.115,1.115,0,1,0,1.115,1.115A1.117,1.117,0,0,0,162.628,238.308Zm.127,1.243-.433.433a.18.18,0,1,1-.255-.255l.433-.433a.18.18,0,0,1,.255.255Z" transform="translate(-161.513 -238.308)" fill="#928fa9" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg> Weight</p>
                                                                                                            <h6>{item?.package_weight}</h6>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-sm-12">
                                                                                                        <div className="material1_sec">
                                                                                                            <p><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.017 6">
                                                                                                                <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                            </svg> Dimensions</p>
                                                                                                            <h6>{item?.product_dimension}</h6>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-sm-12">
                                                                                                        <div className="material1_sec">
                                                                                                            <p><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                                                <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                            </svg> Number of Boxes</p>
                                                                                                            <h6>{item?.no_of_units}</h6>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>)
                                                                                            })}

                                                                                        </Slider>

                                                                                        <span className="slider_number_count">{buttonVisible}/<span style={{ color: "#fbaa2a" }}>{item?.material_type.length}</span></span>

                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-sm-12 col-xxl-7 col-xl-12'>
                                                                                    <div className="reject_track trans_book">
                                                                                        <div className="order-track-step">
                                                                                            <div className="order-track-status">
                                                                                                <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                                                                                <span className="order-track-status-line"></span>
                                                                                            </div>
                                                                                            <div className="order-track-text">
                                                                                                <span className="order-track-text-sub">Booking Date - {item?.location_details?.booking_date}</span>
                                                                                                <p className="order-track-text-stat">{item?.location_details?.from_location}</p>
                                                                                                <span className="kilometer">{item?.location_details?.distance}</span>
                                                                                                <span className="kilometer">{item?.location_details?.time_duration}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="order-track-step">
                                                                                            <div className="order-track-status">
                                                                                                <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                                                                                <span className="order-track-status-line"></span>
                                                                                            </div>
                                                                                            <div className="order-track-text">
                                                                                                <span className="order-track-text-sub">Est. Delivery date - {item?.location_details?.estimated_delivery_date != null ? item?.location_details?.estimated_delivery_date : ' _____'}</span>
                                                                                                <p className="order-track-text-stat">{item?.location_details?.to_location}</p>

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div className='vehicle_esti_dwn_sec'>
                                                                            <div className='row'>
                                                                                <div className='col-sm-5'>

                                                                                    <div className="material1_sec">
                                                                                        <p>Riceiver</p>
                                                                                        <h6>{item?.receiver_name}<span>
                                                                                            <CTooltip content={item?.receiver_contact_number}><img src={call} alt='' /></CTooltip></span></h6>
                                                                                    </div>
                                                                                    <div className="material1_sec">
                                                                                        <p>Driver</p>
                                                                                        <h6>{item?.vehicle_details?.assigned_driver} <span>
                                                                                            <CTooltip content={item?.shipper_transporter_details?.driver_phone_number}><img src={call} alt='' /></CTooltip></span></h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-sm-7'>
                                                                                    <div className='productmaterial_image'>
                                                                                        <span>Vehicle</span>
                                                                                        <h6>{item?.vehicle_details?.assigned_vehicle}</h6>
                                                                                        <img src={item?.vehicle_details?.vehicle_images[0] == null ? SmallTruck : item?.vehicle_details?.vehicle_images[0]} alt='' height={100} width={100} style={{ borderRadius: 15 }} /></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>

                                                        </Accordion>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                        <div className='col-sm-7'><div className="office_address_rt">
                                            <div className="address_map">
                                                <h6>Vehicle Current Location</h6>
                                                {/* <GoogleMap
                                                    // onLoad={onLoad}
                                                    center={defaultLocation}
                                                    mapContainerStyle={mapStyle}
                                                    // onUnmount={onUnmount}
                                                    options={{
                                                        zoom: 15,
                                                        disableDefaultUI: true,
                                                        mapTypeControl: false,
                                                        draggable: false,
                                                        scaleControl: false,
                                                        scrollwheel: false,
                                                        navigationControl: false,
                                                        streetViewControl: false,
                                                    }}
                                                >
                                                    <Marker position={markerPosition} />
                                                </GoogleMap> */}
                                                <iframe src={`http://maps.google.com/maps?q=${markerPosition.lat, markerPosition.lng}&z=16&output=embed`} height="450" width="600"></iframe>

                                            </div>
                                        </div></div>
                                    </div>
                                </div> : <div className="blank_quote">There is no shipments available...</div>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

