/**
 * @file_purpose  base url
 * @author Sarmistha Mondal 
 */
import axios from "axios";
export default axios.create({
  // baseURL: `http://3.128.13.177:5006/api/v1/`
  // baseURL: `http://192.168.1.25:5005/api/v1` // red apple staging
  // baseURL: `https://api.shipgudds.com/api/v1/` //client server staging
  // baseURL: `https://staginglocal.redappletech.com/shipgudds-api/api/v1/`
  baseURL: process.env.REACT_APP_API_BASE_URL//`https://staginglocal.redappletech.com/shipgudds-stg-api/api/v1/`
});
