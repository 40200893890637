/**
 * @file_purpose  page for page routeing after login 
 * @author Sarmistha Mondal
 * @Date_Created 06/11/2023
 * @Date_Modified
 */
import React, { lazy, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Myaccount from "../views/pages/myAccount";
import TheHeader from "./header";
// const QuoteList = lazy(() => import("../views/pages/shipment/transporterTabPage/quoteList"));
const OnboardingOfficeAddress = lazy(() => import("../views/onboarding/onboardingStep1"));
const OnboardingOfficeVehicle = lazy(() => import("../views/onboarding/onboardingStep2"));
const OnboardingPaymentMethods = lazy(() => import("../views/onboarding/onboardingStep3"));
const OnboardingCitys = lazy(() => import("../views/onboarding/onboardingStep4"));
const OnboardingTimings = lazy(() => import("../views/onboarding/onboardingStep5"));
const Subscription = lazy(() => import("../views/pages/Subscription"));
const SideMenu = lazy(() => import("./sideMenu"));

export default function PageRoute(props) {
  const [pageLoad, setPageLoad] = useState({ _data: {} });
  const [dataFromChild, setDataFromChild] = useState("");
  /**
    * @functionName _dataUpdate
    * @functionPurpose this function header page update .  
    * */
  const _dataUpdate = (data) => {
    if (data) {
      setPageLoad((prev) => {
        return { ...prev, _data: data };
      });

    }
  }

  useEffect(() => {
    _dataUpdate(props?.data)
  }, [props?.data])

  
  useEffect(() => {
    setDataFromChild()
  }, [dataFromChild])


  const handleDataFromChild = (data) => { setDataFromChild(data); }
 
  return (
    <>
      <React.Suspense fallback={<></>}>
        <TheHeader element={_dataUpdate} updateImg={dataFromChild}/>
        <Routes>
          {/* <Route path="/*" element={<PageRoute />} /> */}
          <Route path="/onboarding-godown-address" element={<OnboardingOfficeAddress />} />
          <Route path="/onboarding-office-vehicle" element={<OnboardingOfficeVehicle />} />
          <Route path="/onboarding-payment-method" element={<OnboardingPaymentMethods />} />
          <Route path="/onboarding-city" element={<OnboardingCitys />} />
          <Route path="/onboarding-timing" element={<OnboardingTimings />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/shipment-booking" element={<SideMenu />} />
          <Route path="/shipment-booking/:bookingid" element={<SideMenu />} />
          <Route path="/shipment-booking/:bookingid/:quoteid" element={<SideMenu />} />
          <Route path="/vehicle-tracking" element={<SideMenu dataUpdate={pageLoad} />} />
          <Route path="/receiver" element={<SideMenu dataUpdate={pageLoad} />} />
          <Route path="/receiver/:order_id" element={<SideMenu />} />
          <Route path="/office-godown-list" element={<SideMenu />} />
          <Route path="/user-list" element={<SideMenu />} />
          <Route path="/office-vehicle-list" element={<SideMenu />} />
          <Route path="/office-payment-list" element={<SideMenu />} />
          <Route path="/office-city-list" element={<SideMenu />} />
          <Route path="/office-time-list" element={<SideMenu />} />
          <Route path="/shipment-list" element={<SideMenu dataUpdate={pageLoad} />} />
          {/* <Route path="/quote-list/:requestId" element={<QuoteList />} /> */}
          <Route path="/account" element={<Myaccount sendDataToParent={handleDataFromChild} />} />
          <Route path="/payment-details" element={<SideMenu />} />
          <Route path="/receiver-details" element={<SideMenu />} />

        </Routes>
      </React.Suspense>
    </>
  );
}


