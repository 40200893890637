/**
 * @file_purpose  page for showing cancellation refund policy page
 * @author Sarmistha Mondal
 * @Date_Created 15/04/2024
 * @Date_Modified 
 */

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import LandingPageFooter from "./landingPageFooter";
import logo from "../../assets/images/logo.png";

export default function RefundPolicy() {
    const navigate = useNavigate();

    return (
        <>
            <header className="header_sec1">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="header_inn1">
                                <div className="row">
                                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-9">

                                        <div className="header_inn_lt">

                                            <div className="menu_sec"  >
                                                <nav className="navbar navbar-expand-lg navbar-light">
                                                    <div className="container-fluid">
                                                        <a className="navbar-brand" href="/"><img src={logo} alt="" /></a>
                                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#navbarSupportedContent"
                                                            aria-controls="navbarSupportedContent" aria-expanded="false"
                                                            aria-label="Toggle navigation">
                                                            <span className="navbar-toggler-icon"><i className="fa-solid fa-bars"></i></span>
                                                        </button>


                                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
                                                                <li className="nav-item">
                                                                    <a className="nav-link active" aria-current="page"
                                                                        href="/">Home</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/">About us</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/terms-and-conditions">Terms & Condition</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/">How it works</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" href="/">Contact us</a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </nav>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 d-none d-md-block">
                                        <div className="header_inn_rt">
                                            <ul>
                                                <li><a href=""><i className="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-instagram"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-twitter"></i></a></li>
                                                <li><a href=""><i className="fa-brands fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </header >

            <section className="terms_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="terms_inn">
                                <h6>CANCELLATION & REFUND POLICY</h6>

                                At PM LOGISTIC SOLUTIONS PVT LTD, we strive to provide exceptional service and ensure customer satisfaction. We understand that circumstances may arise where you need to cancel your order or request a refund. To facilitate this process and maintain transparency, we have outlined our cancellation and refund policy below:<br /><br />

                                <h6 style={{ textAlign: 'left' }}>1.**CANCELLATION POLICY:**</h6>

                                - Customers should be able to cancel their bookings/orders at least 2 days in advance before the scheduled pickup or delivery time.<br />
                                - some penalties or fees will be charged associated with cancellations, especially for last-minute cancellations.<br />
                                -For cancellation shipper & transporter should connect directly or through our platform<br /><br />

                                <h6 style={{ textAlign: 'left' }}>2. **REFUND POLICY:**</h6>
                                - Refunds might be issued for cancellations made within 48 HOURS from the time of pickup or if the service fails to meet agreed-upon standards.<br />
                                -Once shippers or transporters cancellation request is approved, they both mutually have to decide for the refund amount.<br />
                                - Subscription fees made to PM LOGISTIC SOLUTIONS PVT LTD by shipper & transporter will not be refunded.<br /><br />

                                <h6 style={{ textAlign: 'left' }}>3. **EXCEPTIONS AND SPECIAL CASES:**</h6>
                                - Cancellation and refund policy may not apply, such as force majeure events or instances of customer misuse.<br /><br />
                                
                                <h6 style={{ textAlign: 'left' }}>4. **COMMUNICATION CHANNELS:**</h6>
                                - Shipper & Transporter can reach out to each other directly or thorough the platform's customer support team for assistance with cancellations, refunds, or any related inquiries.<br /><br />

                                <h6 style={{ textAlign: 'left' }}>5. **UPDATES AND MODIFICATIONS:**</h6>
                                - PM LOGISTIC SERVICES PVT LTD Reserve the right to update or modify the cancellation and refund policies as needed without prior notice. We encourage you to review this policy periodically for any updates or revisions. If you have any questions or require further assistance, please contact our customer service shipguddsindia@gmail.com <br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <LandingPageFooter/>
        </>
    )
}