/**
 * @file_purpose  page for reset password
 * @author Sarmistha Mondal
 * @Date_Created 08/11/2023
 * @Date_Modified 
 */
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim,
} from "../../Utility/Helper";
import { ERROR_MESSAGE, REGEX, Constants } from "../../Utility/ConstantData";
import Service from "../../apis/Service";
import RouteURL from "../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import ResetLock from "../../assets/images/lock1.png";

export default function ResetPassword(props) {
    const navigate = useNavigate();
    const buttonText = window.location.href.slice(window.location.origin.length).startsWith('/auth/reset-password') ? 'Reset Password' : 'Set Password';
    const [formValue, setFormValue] = useState({ password: '', confirmPassword: '' });
    const [errorValue, setErrorValue] = useState({ password: '', confirmPassword: '' });
    const [formTouchValue, setFormTouchValue] = useState({ password: false, confirmPassword: false });
    const [isDisabled, setIsDisabled] = useState(false)
    const [passwordShown, setPasswordShown] = useState({
        newpassword: false,
        confirmPassword: false,
    });

    useEffect(() => {
        setFormValue({})
    }, []);

    useEffect(() => {
        checkError('password');
        checkError('confirmPassword');
    }, [formValue]);

    /* password visible*/
    const toggleNewPasswordVisibility = (e) => {
        setPasswordShown((prev) => {
            return {
                ...prev, newpassword: passwordShown.newpassword ? false : true,

            };
        });
    };

    /* confirm password visible*/
    const toggleConfPasswordVisibility = (e) => {
        setPasswordShown((prev) => {
            return {
                ...prev,
                confirmPassword: passwordShown.confirmPassword ? false : true,
            };
        });
    };

    /* on change input function */
    const formChange = (event) => {
        const { name, value } = event.target;
        setFormValue(prevFormValue => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        })
    }

    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue(prevErrorValue => {
                if (!formValue[targetName]) {
                    return { ...prevErrorValue, [targetName]: ERROR_MESSAGE[targetName + 'Required'] }
                } else if (!formValue[targetName].match(REGEX.number)) {
                    return { ...prevErrorValue, [targetName]: ERROR_MESSAGE['passwordNumber'] }
                } else if (!formValue[targetName].match(REGEX.lowercase)) {
                    return { ...prevErrorValue, [targetName]: ERROR_MESSAGE['passwordLowerCase'] }
                } else if (!formValue[targetName].match(REGEX.uppercase)) {
                    return { ...prevErrorValue, [targetName]: ERROR_MESSAGE['passwordUpperCase'] }
                } else if (!formValue[targetName].match(REGEX.special)) {
                    return { ...prevErrorValue, [targetName]: ERROR_MESSAGE['passwordSpecial'] }
                } else if (formValue[targetName].match(REGEX.space)) {
                    return { ...prevErrorValue, [targetName]: ERROR_MESSAGE['passwordSpace'] }
                } else if (formValue[targetName].length < 8) {
                    return { ...prevErrorValue, [targetName]: ERROR_MESSAGE['passwordLength'] }
                } else if (formValue.password && formValue.confirmPassword && formValue.confirmPassword !== formValue.password) {
                    // console.log(formValue.confirmPassword, formValue.password);
                    return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['confirmPasswordInvalid'] }
                } else prevErrorValue[targetName] = "";

                return {
                    ...prevErrorValue, [targetName]: !formValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (formValue[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)
                };
            })
        }
    }

    /* onKeyDown error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue(prevFormTouchValue => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue(prevFormValue => {
                return { ...prevFormValue, [name]: leftTrim(value) }
            });
        }
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 08/11/2023
      * @function async
      * @functionName _ResetPassword
      * @functionPurpose this function for reset password.
      *
      * @functionParam {username,password}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _ResetPassword = (event) => {
        event.preventDefault();
        // console.log('ok');
        setFormTouchValue(markAllAsTouched(formTouchValue));
        setFormValue(leftTrimAllValue(formValue));
        checkError('password');
        checkError('confirmPassword');
        setIsDisabled(true);
        if (isFormValid(formValue)) {
            if (formValue.password === formValue.confirmPassword) {
                let params = JSON.stringify({
                    username: localStorage.getItem("resetPasswordUserName"),
                    password: formValue.password
                });

                Service.apiPostCallRequest(RouteURL.resetPassword, params).then((response) => {
                    // console.log(response);
                    if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                        // navigate("/login");
                        props.onChanonCloseResetModal()
                        localStorage.clear();
                        setIsDisabled(false);
                        setFormValue({ password: "", confirmPassword: "" })
                        toast.success(response.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,

                        });

                    } else {

                        toast.error(response.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            onClose: () => { setIsDisabled(false) },
                        });
                    }
                }).catch((error) => {
                    // console.log(error);
                    setIsDisabled(false);
                    if (error.response.status === 401) {
                        localStorage.clear();
                        navigate("/login");
                        window.location.reload();
                    }
                });
            }
        }
    }


    return (
        <>
            < Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Reset Password</h6>
                    </Modal.Header>
                    <div className="task_modal_sec">
                        <form
                            onSubmit={_ResetPassword}
                            name="signin_form"
                        >
                            <section className="forgot_inn">
                                <img src={ResetLock} alt="" />
                                <div className="rest_pass_sec">
                                    <div className="login_mid_inn">
                                        <label>New password</label>
                                        <input placeholder="Enter New Password"
                                            type={passwordShown.newpassword ? "text" : "password"}
                                            name="password" error={errorValue.password ? 'true' : 'false'}
                                            value={formValue.password}
                                            onChange={formChange}
                                            onKeyDown={updateFormTouchValue} />

                                        <span className="verify_sec"><a href="javascript:void(0)"><i onClick={toggleNewPasswordVisibility} className={passwordShown.newpassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'}></i></a></span>
                                    </div>
                                    {errorValue.password && <span className="error">{errorValue.password}</span>}
                                    <div className="login_mid_inn">
                                        <label>Confirm password</label>
                                        <input placeholder="Confirm Password"
                                            type={passwordShown.confirmPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            error={errorValue.confirmPassword ? 'true' : 'false'}
                                            value={formValue.confirmPassword}
                                            onChange={formChange}
                                            onKeyDown={updateFormTouchValue} />

                                        <span className="verify_sec"><a href="javascript:void(0)"><i onClick={toggleConfPasswordVisibility} className={passwordShown.confirmPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'}></i></a></span>
                                    </div>
                                    {errorValue.confirmPassword && <span className="error">{errorValue.confirmPassword}</span>}
                                </div>

                                <div className="create_sub text-center">
                                    <div className="login_btn">
                                        <button type="submit" value="get Started"
                                            style={{ background: '#000' }}
                                        >Submit</button>
                                    </div>
                                </div>
                            </section>
                        </form>
                    </div>

                </div>
            </Modal >


        </>
    )
}
