/**
 * @file_purpose  page for rating
 * @author Sarmistha Mondal
 * @Date_Created 06/05/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Modal from "react-bootstrap/Modal";
import { Rating } from 'react-simple-star-rating';
import SmallTruck from "../../../assets/images/sm_truck.png";
const fillColorArray = [
    "#f17a45",
    "#f17a45",
    "#f19745",
    "#f19745",
    "#f1a545",
    "#f1a545",
    "#f1b345",
    "#f1b345",
    "#f1d045",
    "#f1d045"
];

export default function RatingModal(props) {
    const modalRef = useRef(null);
    let shipper_id = props._allData.shipper_id;
    let transporter_id = props._allData.transporter_id;
    let request_id = props._allData.request_id;
    let shipper_code = props._allData.shipper_code
    const [rating, setRating] = useState(0)
    const [submitDisable, setSubmitDisable] = useState(false);
    const [errorRating, setErrorRating] = useState(false);
    const [review, setreview] = useState()

    // focus within a specific area
    useEffect(() => {
        if (props.show) {
            const modalElement = modalRef.current;
            //add any focusable HTML element you want to include to this string
            const focusableElements = modalElement.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event) => {
                if (event.key === "Tab") {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event) => {
                if (event.key === "Escape") {
                    props.onHide();
                }
            };

            modalElement.addEventListener("keydown", handleTabKeyPress);
            modalElement.addEventListener("keydown", handleEscapeKeyPress);

            return () => {
                modalElement.removeEventListener("keydown", handleTabKeyPress);
                modalElement.removeEventListener("keydown", handleEscapeKeyPress);
            };
        }
    }, [props.show]);

    /* Validation Checking */
    const validation = (e) => {
        e.preventDefault();
        if (rating) {
            _submitRating()
        } else {
            if (rating == 0) {
                setErrorRating('Please Rating Us');
            } else {
                setErrorRating();
            }
        }
    }

    /**
      * @author Sarmistha Mondal
      * @Date_Created 06/05/2024
      * @Date_Modified 
      * @function async
      * @functionName _submitRating
      * @functionPurpose this function for submition rating.
      *
      * @functionParam {user_id,rating,review}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _submitRating = () => {
        setSubmitDisable(true)
        let params = JSON.stringify({
            user_id: (localStorage.getItem('user_type') == 'Transporter' ? shipper_id : transporter_id),
            rating: rating,
            review: review
        });
        
        Service.apiPostTokenCallRequest(RouteURL.postRatingReview, params).then((response) => {
            
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
               
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        }).catch((error) => {
            setSubmitDisable(false)

        });
    }

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec" ref={modalRef}>
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Your Opinion Matter to us !</h6>
                    </Modal.Header>
                    <section className="opinion_matter_sec">
                        <span style={{ marginBottom: 15,fontWeight:500 }}>Rate Our Service</span>
                        <div className="row">
                            <div className="col-2">
                                <img src={SmallTruck} alt="" height={100} width={100} />
                            </div>
                            <div className="col-10" style={{ textAlign: 'start', paddingLeft: '10%' }}>
                              <div className="row">
                                    <div className="reject_hed"><h4>{request_id} <br />
                                        <span style={{ color: "#FBAA2A" }}>{shipper_code}</span>
                                    </h4></div>
                              </div>
                                <div className="row">
                                    <div className="opinion_mid">
                                        <Rating
                                            initialValue={rating}
                                            onClick={(rate) => setRating(rate)}
                                            size={40}
                                            allowFraction
                                            transition
                                            fillColorArray={fillColorArray}
                                        />
                                    </div>
                                    {rating == 0 && errorRating && (
                                        <span className="error">
                                            {errorRating}
                                        </span>
                                    )}
                                </div>
                               
                             
                            </div>
                        </div>
                     
                       
                        <div className="option_area">
                            <textarea type="text" placeholder="Leave a comment.." name="review" rows="4" maxLength={60}
                                value={review} onChange={(e) => { e.preventDefault(); setreview(e.target.value) }}
                            />
                            <span>{review?.length ? review?.length : 0}/60 Words</span>
                        </div>
                        <div className="login_btn mt-1">
                            <button type="submit" value="get Started" disabled={submitDisable ? true : false} onClick={validation} style={{ background: '#000' }}>Submit</button>
                        </div>

                    </section>
                </div>
            </Modal>
        </>
    )
}