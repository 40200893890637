/**
 * @file_purpose  page for select location
 * @author Sarmistha Mondal
 * @Date_Created 02/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef, useCallback } from "react";
// import Autocomplete from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Modal from "react-bootstrap/Modal";
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const mapStyle = {
    height: '300px',
    width: '100%'
}



const inputStyle = {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    width: '340px',
    height: '38px',
    padding: '0 12px',
    borderRadius: '3px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    outline: 'none',
    textOverflow: 'ellipses',
    position: 'absolute',
    right: '8%',
    top: '11px',
    marginLeft: '-120px'
}
const placesLibrary = ["places"];
export default function LocationModal(props) {
    const [country, setCountry] = useState("in");
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,//"AIzaSyCaMLHa3JXiZll8QwyUM3b9ycA1xJAF_6I"
        // libraries: placesLibrary
    })

    const [map, setMap] = React.useState(null)
    const [markerPosition, setMarkerPosition] = useState({
        lat: '',
        lng: ''
    })

    const [defaultLocation, setDefaultLocation] = useState({
        lat: '',
        lng: ''
    })

    const [center, setCenter] = useState({
        lat: '', // default latitude
        lng: '', // default longitude
    });
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.GOOGLE_MAPS_API_KEY,//'AIzaSyAhaD_vw_DSvTW1wbebZIznuhEBxlqfu1U',
        inputAutocompleteValue: "IN",
        options: {
            componentRestrictions: { country },
        },
    });
    
    // useEffect(() => {
    //     // fetch place details for the first element in placePredictions array
    //     if (placePredictions.length)

    //         placesService?.getDetails(
    //             {
    //                 placeId: placePredictions[0].place_id,
    //             },
    //             (placeDetails) => { console.log(placeDetails); savePlaceDetailsToState(placeDetails)}
    //         ); console.log(placePredictions);
    // }, [placePredictions]);


    const getLatLang = (id) => {
        // console.log(id);
        placesService?.getDetails(
            {
                placeId: id,
            },
            (placeDetails) => {
                // console.log('getLatLang', placeDetails.geometry.viewport.Jh.lo);
                setCenter((prevFormValue) => {
                    return {
                        ...prevFormValue, lat: Number(placeDetails.geometry.viewport.Zh.lo),
                        lng: Number(placeDetails.geometry.viewport.Jh.lo),
                    };
                });
                setDefaultLocation((prevFormValue) => {
                    return {
                        ...prevFormValue, lat: Number(placeDetails.geometry.viewport.Zh.lo),
                        lng: Number(placeDetails.geometry.viewport.Jh.lo),
                    };
                });
                setMarkerPosition((prevFormValue) => {
                    return {
                        ...prevFormValue, lat: Number(placeDetails.geometry.viewport.Zh.lo),
                        lng: Number(placeDetails.geometry.viewport.Jh.lo),
                    };
                });
            }
        );
    }
    const [value, setValue] = useState("");
    const [getPlaceDetails, setGetPlaceDetails] = useState();
    const onLoad = useCallback((map) => {
        // console.log(map.getPlaceDetails());
        // let bounds;
        mapRef.current = map;
        // const { geometry } = mapRef?.current?.getPlace();
        // console.log(geometry);
        // console.log(mapRef.current);
        // console.log(mapRef.current);
        if (navigator.geolocation) {
            // get the current users location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // console.log('1');
                    // save the geolocation coordinates in two variables
                    const { latitude, longitude } = position.coords;
                    setGetPlaceDetails(position)
                    setCenter((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(latitude),
                            lng: Number(longitude),
                        };
                    });
                    setDefaultLocation((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(latitude),
                            lng: Number(longitude),
                        };
                    });
                    setMarkerPosition((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(latitude),
                            lng: Number(longitude),
                        };
                    });
                },
                // if there was an error getting the users location
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );

        }

        if (center.lat) {
            const bounds = new window.google.maps.LatLngBounds({
                lat: center.lat,
                lng: center.lng

            });
            // console.log(bounds);
            map.fitBounds(bounds);
            setMap(map)
        }
    }, [])

    const onUnmount = useCallback(() => {
        setMap(null)
    }, [])
    // useEffect(() => { console.log(value); }, [value])
    // new//
    const mapRef = useRef();
    const autocompleteRef = useRef();
    const [selectedAddress, setSelectedAddress] = useState('')

    const handleChange = (e) => {

        const { id, value } = e.target
        // console.log(value);
        setSelectedAddress(value)
    }
    const onLoadAutocomplete = (autocomplete) => {
        autocompleteRef.current = autocomplete;


    }
    const handlePlaceChanged = () => {
        // console.log('ok');
        // _setaddress()
        const { geometry } = autocompleteRef?.current?.getPlace();
       
        const bounds = new window.google.maps.LatLngBounds();
        // console.log(new window.google.maps.Marker());
        console.log(geometry);
        let Place = new window.google.maps.LatLng(geometry.location?.lat(), geometry.location?.lng());
        let marker = new window.google.maps.Marker({
            position: Place,
        });
        setGetPlaceDetails(geometry)
        if (geometry.viewport) {
            // console.log(1);
            bounds.union(geometry.viewport);
            bounds.extend(Place);
            bounds.getCenter(Place)
            new window.google.maps.Marker({
                position: Place,
            });
                
        } else {
            bounds.extend(geometry.location);
        }
        getAddress(geometry.location?.lat(), geometry.location?.lng())
        setMarkerPosition((prevFormValue) => {
            return {
                ...prevFormValue, lat: geometry.location?.lat(),
                lng: geometry.location?.lng(),
            };
        });
        setDefaultLocation((prevFormValue) => {
            return {
                ...prevFormValue, lat: geometry.location?.lat(),
                lng: geometry.location?.lng(),
            };
        });
        // console.log(bounds);
        // console.log(mapRef.current.Marker());
        // marker.setMap(map);
        mapRef.current?.fitBounds(bounds);

    }
    const _setaddress = ()=>{
        // const { geometry } = autocompleteRef?.current?.getPlace();

        // if (geometry.location?.lat()) {
        //     setMarkerPosition((prevFormValue) => {
        //         return {
        //             ...prevFormValue, lat: geometry.location?.lat(),//geometry.location?.lat(),
        //                 lng: geometry.location?.lng(),
        //         };
        //     });
        // }
    }
    const getAddress = (lat, lng) => {
        // console.log(lat, lng);
        let geocoder = new window.google.maps.Geocoder();
        let latlng = new window.google.maps.LatLng(lat, lng);
        // let infowindow = new window.google.maps.InfoWindow();
        // console.log(infowindow)
        geocoder.geocode({ 'latLng': latlng }, function (results, status) {

            if (status == 'OK' && results[0].geometry.location_type == "GEOMETRIC_CENTER") {
                console.log(results);
                // console.log(results[0].geometry.location);
                // results.filter(())
                // let address = results.filter(obj => (obj.geometry.location_type == "GEOMETRIC_CENTER"))[0].formatted_address
                //   console.log(a);
                const address = results[0].formatted_address;
                setGetPlaceDetails(results[0])
                // console.log(address)
                setSelectedAddress(address);
                // // console.log(results, status);
                // infowindow.setContent(results[0].formatted_address);
                // infowindow.open(map, marker);
            }

        });
    }
    return (<>
        < Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="forgot_sec">
                <Modal.Header closeButton closeVariant={'white'}>
                    <h6 className="hed_txt">Location</h6>
                </Modal.Header>
                <div className="task_modal_sec">
                    <form
                        name="signin_form"
                    >
                        <section className="forgot_inn">
                            {/*<label >Location</label>
                            <input
                                placeholder='your location'
                                // ref={inputRef}
                                style={inputStyle}
                                // value={value}
                                onChange={(evt) => {
                                    getPlacePredictions({ input: evt.target.value });
                                }}
                                loading={isPlacePredictionsLoading}
                            /> */}
                            {/* <Autocomplete
                                apiKey={process.env.GOOGLE_MAPS_API_KEY}
                                style={{ width: "90%" }}
                                onPlaceSelected={(place) => {
                                    // e.preventDefault();
                                     setGetPlaceDetails(place)
                                    console.log(place); getLatLang(place.place_id)
                                }}
                                options={{
                                    types: ["(regions)"],
                                    componentRestrictions: { country: "IN" },
                                }}
                                // defaultValue="Amsterdam"
                            /> */}
                            <div
                                style={{
                                    marginTop: "20px",
                                    width: '100%',
                                    height: "200px",
                                    display: "flex",
                                    flex: "1",
                                    flexDirection: "column",
                                    marginBottom: "100px",
                                }}
                            >
                                {/* {!value &&
                                <> {placePredictions?.map((item)=>{
                                    return (
                                        <ul style={{listStyle:'none'}}>
                                            <li onClick={() => { setValue(item.description); getLatLang(item.place_id);}}>{item.description}</li>
                                        </ul>
                                   
                                    )
                                })}</>
                               
                                } */}
                                <div className='map_sec'>
                                    {
                                        (isLoaded) ? (
                                            <GoogleMap
                                                onLoad={onLoad}
                                                center={defaultLocation}                                                
                                                mapContainerStyle={mapStyle}
                                                onClick={ev => {
                                                    setMarkerPosition({ lat: ev.latLng.lat(), lng: ev.latLng.lng() })
                                                    getAddress(ev.latLng.lat(), ev.latLng.lng())

                                                }}
                                                options={{
                                                    zoom: 15,
                                                    mapTypeControl: false,
                                                    draggable: true,
                                                    scaleControl: false,
                                                    scrollwheel: false,
                                                    navigationControl: true,
                                                    fullscreenControl:false,
                                                    rotateControl:true,
                                                    streetViewControl:false
                                                }}
                                                onUnmount={onUnmount}
                                            >

                                                <Autocomplete
                                                    onLoad={onLoadAutocomplete}
                                                    onPlaceChanged={handlePlaceChanged}
                                                    // options={}
                                                >
                                                    <>
                                                        <input name="address" value={selectedAddress} onChange={handleChange}
                                                            type='text'
                                                            placeholder='Search Location'
                                                            style={inputStyle}
                                                        />
                                                        <Marker position={markerPosition} />
                                                    </>
                                                </Autocomplete>
                                            </GoogleMap>
                                        ) : <></>
                                    }

                                </div>

                            </div>
                            <div className="create_sub text-center">
                                <div className="login_btn">
                                    <button type="submit" value="get Started"
                                        onClick={(e) => { e.preventDefault(); props.onCloseModal(getPlaceDetails); props.onHide(); }}
                                        style={{ background: '#000' }}
                                    >Add Location</button>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>

            </div>
        </Modal >

    </>)
}