/**
 * @file_purpose  page for showing start stop delivery
 * @author Sarmistha Mondal
 * @Date_Created 02/05/2024
 * @Date_Modified 
 */
import React, { useState,  } from "react";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Modal from "react-bootstrap/Modal";
import VerifyOTPForStopDelivery from "./otpForEndDelivery";

export default function DeliveryStartStopModal(props) {
    
    let Type = props._allData.type;
    let order_id = props._allData.order_id;
    let request_id = props._allData.request_id;
    let shipper_code = props._allData.shipper_code;
    const [submitDisable, setSubmitDisable] = useState(false);
    const [otpModal, setOtpModal] = useState();
    const [modalValue, setModalValue] = useState({
        type: "",
        shipment_request_id: "",
        quote_price: "", payment_method: "", invoice_document: "",
        order_id: "",
        type: "",
        request_id: "",
        shipper_code: ""
    });

    const _submit = (e) => {
        e.preventDefault();
        if (Type == 'start') {
            _startRequest()
        } else {
            _stopRequest()
        }
    }



    /**
     * @author Sarmistha Mondal
     * @Date_Created 02/04/2024
     * @Date_Modified 
     * @function async
     * @functionName _startRequest
     * @functionPurpose this function for start delivery.
     *
     * @functionParam {order_id}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _startRequest = (e) => {

        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterStartDelivery, { order_id: order_id }).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
                
            }
        });

    };


    /**
        * @author Sarmistha Mondal
        * @Date_Created 08/05/2024
        * @Date_Modified 
        * @function async
        * @functionName _stopRequest
        * @functionPurpose this function for stop delivery.
        *
        * @functionParam {order_id}
        *
        * @functionSuccess Success status and message.
        *
        * @functionError {Boolean} error is there.
        * @functionError {String} message  Description message.
        */
    const _stopRequest = ( ) => {
        setOtpModal(true);
        setModalValue({
            order_id: order_id,
            // type: 'stop',
            // request_id: request_id,
            // shipper_code: shipper_code
        })
        // setSubmitDisable(true)
        // Service.apiPostTokenCallRequest(RouteURL.transporterEndDelivery, { order_id: order_id }).then((response) => {
        //     // console.log(response);
        //     if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        //         props.CloseAddEditModal();
        //         props.onChangeSomeState("add");
        //         setSubmitDisable(false)
        //     } else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.BOTTOM_RIGHT,
        //         });
        //         setSubmitDisable(false)
        //     }
        // });

    };







    return (
        <>
            < Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">{Type == 'start' ? 'Start' : 'Stop'} Delivery 

                        </h6>
                    </Modal.Header>

                    <section className="reject_inner">

                        <>

                            <div className="reject_req_sec">
                                <div className="reject_hed" style={{ alignItems: 'center' }}><h4>{request_id} <br /><span style={{ color: "#FBAA2A", alignItems: 'center' }}>{shipper_code}</span></h4></div>

                            </div>
                            <p>Are you sure you want to {Type == 'start' ? 'start' : 'stop'} the delivery of the shipment?
                            </p>


                            <div className="col">
                                <div className="login_btn">
                                    <button type="" value="get Started"
                                        disabled={submitDisable ? true : false}
                                        style={{ background: '#000', color: '#fff' }}
                                        onClick={_submit}
                                    > Confirm & {Type == 'start' ? 'Start' : 'Stop'}</button>
                                </div>
                            </div>
                        </>


                    </section>

                </div>
            </Modal>
            {otpModal && <VerifyOTPForStopDelivery show={otpModal} onHide={() => setOtpModal(false)} />}
        </>
    )

}