/**
 * @file_purpose  page for showing shipment pending request
 * @author Sarmistha Mondal
 * @Date_Created 19/12/2023
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
    CButton,
    CCollapse,
    CRow,
    CDataTable,
} from "@coreui/react";
import { Constants } from "../../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../../apis/Service";
import RouteURL from "../../../../apis/RouteURL";
import SmallTruck from "../../../../assets/images/sm_truck.png"
import Slider from "react-slick";

export default function PendingRequestPage(props) {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState(props.bookingRequestId);
    const [buttonVisible, setButtonVisible] = useState(0);
    const [accordion, setAccordion] = useState(0)
    const fields = [
        { key: 'request_id', _classes: "shipper02", },
        { key: 'shipper_code', _classes: "shipper01", },
        // { key: 'shipper_name', _classes: "shipper01", },
        { key: 'from_city', _classes: "shipper02", },
        { key: 'to_city', _classes: "shipper02", },
        { key: 'quote_received', _classes: "shipper01", },
        { key: 'min_quote_price', _classes: "shipper01", },
        { key: 'status', _classes: "shipper02", },
        {
            key: "action",
            label: "Action",
            sorter: false,
            filter: false,
            _classes: "",
        },
        {
            key: "droparrow",
            label: "",
            sorter: false,
            filter: false,
            _classes: "shipper02",
        },

    ];



    useEffect(() => {
        _shipperPendingRequestsList();
    }, [search])

    useEffect(() => {
        setSearch(props.bookingRequestId)
    }, [props?.bookingRequestId])

    /**
    * @author Sarmistha Mondal
    * @Date_Created 19/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _shipperPendingRequestsList
    * @functionPurpose this function for shipper pending request List.
    *
    * @functionParam {filter, page_no}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _shipperPendingRequestsList = () => {
        Service.apiPostTokenCallRequest(RouteURL.shipperPendingRequests, { filter: search, page_no: 999999 }).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.pendingRequestDetails.length > 0) {
                    setFilteredData(response.data.pendingRequestDetails)
                } else setFilteredData([])

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };
    const toggleDetails = (index, id,) => { setAccordion(id) }

    const settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        // console.log(props.currentSlide + 1);
        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        // console.log(props.currentSlide - 1);
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }
    return (
        <>
            <form>
                <div className="shipments_sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">

                                <div className="shipment_bottom_panel">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="shipment_search">
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                                <input type="search" placeholder="Search for Req Id" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="shipment_track_txt">
                                                <div className='addmore_goods'>
                                                    <button type='button' value="submit" className="btn btn-outline-warning"
                                                        style={{ backgroundColor: '#FBAA2A', color: '#fff' }}
                                                        onClick={(e) => { e.preventDefault(); navigate('/shipment-booking') }}
                                                    ><i className="fa-solid fa-plus"></i> New Shipment</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="godwn_dwn">
                                        <div className="row">
                                            <div className="col">
                                                <div className="office_address_lt shipping_dwn_pad">
                                                    <div className="shipper_table shipping_pagination">
                                                        <CDataTable
                                                            items={filteredData}
                                                            fields={fields}
                                                            itemsPerPage={10}
                                                            itemsPerPageSelect
                                                            pagination={{
                                                                className: "next_arrow",
                                                                firstButton: <i class="fa-solid fa-angle-left"></i>,
                                                                previousButton: <i class="fa-solid fa-angles-left"></i>,
                                                                nextButton: <i class="fa-solid fa-angles-right"></i>,
                                                                lastButton: <i class="fa-solid fa-angle-right"></i>,
                                                                size: "",
                                                                dots: false
                                                            }}

                                                            scopedSlots={{
                                                                request_id: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: "#000", }}>
                                                                            {item.shipment_details.request_id}
                                                                        </td>)
                                                                },
                                                                shipper_code: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.shipment_details.shipper_code != null ? item.shipment_details.shipper_code : '--'}
                                                                        </td>)
                                                                },
                                                                shipper_name: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: "#FBAA2A" }}>
                                                                            {item.shipment_details.shipper_name != null ? item.shipment_details.shipper_name : '--'}
                                                                        </td>)
                                                                },
                                                                quote_received: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.quote_received}


                                                                        </td>)
                                                                },
                                                                min_quote_price: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: '#000' }}>
                                                                            {item.min_quote_price != "-" ? <>
                                                                                {'₹ ' + item.min_quote_price}
                                                                            </> : '-'}
                                                                        </td>)
                                                                },
                                                                from_city: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.from_city}
                                                                        </td>)
                                                                },
                                                                to_city: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.to_city}
                                                                        </td>)
                                                                },

                                                                action: (item, index) => {
                                                                    return (
                                                                        <td className="action_chk">
                                                                            {item.quote_received != "-" ? <b>
                                                                                <Link style={{ color: '#2ED573', marginRight: 10, cursor: 'pointer' }} to={`/shipment-booking/${item.id}`}
                                                                                >View Details</Link>
                                                                            </b> : '-'}
                                                                        </td>

                                                                    );
                                                                },
                                                                last_accepted_date_time: (item, index) => {
                                                                    return (
                                                                        <td >
                                                                            {item.last_accepted_date_time == '-' ? "-" : <span style={{ color: '#1E90FF' }}>{item.last_accepted_date_time}</span>}
                                                                        </td>

                                                                    );
                                                                },
                                                                status: (item, index) => {
                                                                    return (
                                                                        <td >
                                                                            {item.quote_received == "-"?
                                                                                <b style={{ color: '#1E90FF' }}> Waiting for transporter to accept</b> : <b> Waiting for shipper to accept</b>}
                                                                        </td>)
                                                                },
                                                                droparrow: (item, index) => {
                                                                    return (
                                                                        <td className="py-2 history_sec">

                                                                            {accordion !== item.id ?
                                                                                <CButton
                                                                                    onClick={() => { toggleDetails(index, item.id,) }}
                                                                                >
                                                                                    <i className="fa fa-angle-down" aria-hidden="true"></i>

                                                                                </CButton>
                                                                                :
                                                                                <CButton
                                                                                    onClick={() => { toggleDetails(index,) }}
                                                                                >
                                                                                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                                                                                </CButton>
                                                                            }

                                                                        </td>

                                                                    );
                                                                },

                                                                'details':
                                                                    (item, index) => {
                                                                        return (
                                                                            <CCollapse show={item.id === accordion}>
                                                                                <div className="order_tracking_sec">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4">
                                                                                            <div className="order_step_inn">
                                                                                                <div className="order-track">
                                                                                                    <div className="order-track-step">
                                                                                                        <div className="order-track-status">
                                                                                                            <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                                                                                            <span className="order-track-status-line"></span>
                                                                                                        </div>
                                                                                                        <div className="order-track-text">
                                                                                                            <span className="order-track-text-sub">Booking Date - {item?.location_details.booking_date}</span>
                                                                                                            <p className="order-track-text-stat">{item?.location_details.from_location}</p>
                                                                                                            <span className="kilometer">{item?.location_details?.distance}</span>
                                                                                                            <span className="kilometer">{item?.location_details?.time_duration}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="order-track-step">
                                                                                                        <div className="order-track-status">
                                                                                                            <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                                                                                            <span className="order-track-status-line"></span>
                                                                                                        </div>
                                                                                                        <div className="order-track-text">
                                                                                                            <span className="order-track-text-sub">Exp. Delivery date - {item?.location_details?.estimated_delivery_date
                                                                                                                != null ? item?.location_details?.expected_delivery_date
                                                                                                                : '--'}</span>
                                                                                                            <p className="order-track-text-stat">{item?.location_details.to_location}</p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3 pt-4">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6> Payment Mode
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color"> {item.payment_mode}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6>Loading Type</h6>
                                                                                                        <p className="productmaterial_inn_color">{item?.shipment_details?.load_type}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6>Last Quote Date & Time</h6>
                                                                                                        <p className="productmaterial_inn_color">  {item.last_accepted_date_time == '-' ? "-" : <span style={{ color: '#1E90FF' }}>{item.last_accepted_date_time}</span>}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-5">

                                                                                            <div className="dimen_slide" >

                                                                                                <div className="slider-container">
                                                                                                    <Slider {...settings}>
                                                                                                        {item?.material_type.length > 0 && item?.material_type.map((data, key) => {
                                                                                                            return (
                                                                                                                <div key={key}>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-6 pt-4">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.017 6">
                                                                                                                                            <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                                            <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                                            <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                                                        </svg> Dimensions (L x W x H)
                                                                                                                                        </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.product_dimension}</p>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="10.141" height="10" viewBox="0 0 5.141 6">
                                                                                                                                            <g id="scale" transform="translate(-36.645)">
                                                                                                                                                <g id="Group_8799" data-name="Group 8799" transform="translate(36.645)">
                                                                                                                                                    <g id="Group_8798" data-name="Group 8798">
                                                                                                                                                        <path id="Path_9414" data-name="Path 9414" d="M40.915,1.059A2.128,2.128,0,0,0,41.761.27.179.179,0,0,0,41.608,0H36.824a.179.179,0,0,0-.154.27,2.127,2.127,0,0,0,.847.789,2.531,2.531,0,0,0,1.018.282V1.8H37.69a.627.627,0,0,0-.621.55L36.708,5.3a.626.626,0,0,0,.621.7H41.1a.626.626,0,0,0,.621-.7L41.363,2.35a.627.627,0,0,0-.621-.55H39.9V1.341A2.532,2.532,0,0,0,40.915,1.059ZM40.681,3.9a1.465,1.465,0,1,1-1.465-1.465A1.466,1.466,0,0,1,40.681,3.9Z" transform="translate(-36.645)" fill="#928fa9" />
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                                <g id="Group_8801" data-name="Group 8801" transform="translate(38.1 2.781)">
                                                                                                                                                    <g id="Group_8800" data-name="Group 8800">
                                                                                                                                                        <path id="Path_9415" data-name="Path 9415" d="M162.628,238.308a1.115,1.115,0,1,0,1.115,1.115A1.117,1.117,0,0,0,162.628,238.308Zm.127,1.243-.433.433a.18.18,0,1,1-.255-.255l.433-.433a.18.18,0,0,1,.255.255Z" transform="translate(-161.513 -238.308)" fill="#928fa9" />
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                            </g>
                                                                                                                                        </svg> Weight
                                                                                                                                        </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.package_weight}</p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6> No of boxes
                                                                                                                                        </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.no_of_units}</p>

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-6">
                                                                                                                            <div className="productmaterial_image">
                                                                                                                                <h6>Product Image</h6>
                                                                                                                                <div className="productmaterial_image_box">

                                                                                                                                    <div className="p_box">
                                                                                                                                        {data?.images.length > 0 && data?.images.map((img) => {
                                                                                                                                            return (
                                                                                                                                                <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                                                                                            )
                                                                                                                                        })}

                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-12">
                                                                                                                            <div className="productmaterial_inn">
                                                                                                                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 5.737 6">
                                                                                                                                    <g id="Group_29516" data-name="Group 29516" transform="translate(-803 -366)">
                                                                                                                                        <path id="Path_9494" data-name="Path 9494" d="M26.667,1.6,29.308.463,28.214.014a.19.19,0,0,0-.144,0L25.444,1.093Z" transform="translate(777.726 366)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9495" data-name="Path 9495" d="M172.654,56.125l-2.641,1.132.984.4,2.7-1.108Z" transform="translate(634.871 310.54)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9496" data-name="Path 9496" d="M13.76,122.008l-1.069-.439v.831a.19.19,0,0,1-.379,0v-.987l-1.231-.506v3.339a.19.19,0,0,0,.118.175l2.561,1.052Z" transform="translate(791.919 246.525)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9497" data-name="Path 9497" d="M269.069,122.008v3.466l2.561-1.052a.19.19,0,0,0,.118-.175v-3.339Z" transform="translate(536.989 246.525)" fill="#928fa9" />
                                                                                                                                    </g>
                                                                                                                                </svg> Material
                                                                                                                                </h6>
                                                                                                                                <p className="productmaterial_inn_color">{data.material_type
                                                                                                                                }</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}

                                                                                                    </Slider>
                                                                                                    <span className="slider_number_count"><span style={{ color: "#fbaa2a" }}></span></span>

                                                                                                </div>

                                                                                            </div>



                                                                                            <div className="material_sec">
                                                                                                <div className="row">


                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="material_sec">
                                                                                                <div className="row">

                                                                                                    <div className="col-sm-6">

                                                                                                    </div></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </CCollapse>
                                                                        )
                                                                    }
                                                            }}
                                                        />


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </form >


        </>
    );
}




