/**
 * @file_purpose  page for showing transporter confirm list
 * @author Sarmistha Mondal
 * @Date_Created 15/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import {
    CButton,
    CCard,
    CCol,
    CCollapse,
    CRow,
    CDataTable, CCardBody
} from "@coreui/react";
import { Constants } from "../../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../../apis/Service";
import RouteURL from "../../../../apis/RouteURL";
import SmallTruck from "../../../../assets/images/sm_truck.png"
import UpdateQuotesModal from "../../commonModal/updateQuote";
import AssignVehicleModal from "../../commonModal/assignVehicle";
import UpdateAcceptsRejectQuotationModal from "../../commonModal/updateAcceptRejectQuatetion";
import Slider from "react-slick";

export default function ConfirmedRequestPage(props) {
    const [search, setSearch] = useState(props?.bookingRequestId);
    const [filteredData, setFilteredData] = useState([]);
    const [visible, setVisible] = useState();
    const [vehicleVisible, setVehicleVisible] = useState();
    const [accordion, setAccordion] = useState(0)
    const [buttonVisible, setButtonVisible] = useState(0);
    const [modalValue, setModalValue] = useState({
        type: "confirm",
        shipmentBookingId: "",
        shipmentId: "",
        quote_price: "",
        order_id: ""
    });
    const [cancelModal, setcancelModal] = useState();
    const [CancelmodalValue, setCancelModalValue] = useState({
        type: "",
        shipment_request_id: "",
    });
    const [vehicleModalValue, setVehicleModalValue] = useState({
        shipmentBookingId: "",
    });

    const fields = [
        { key: 'request_id', _classes: "", }, { key: 'shipper_code', _classes: "shipper01", }, { key: 'shipper_name', _classes: "shipper01", },
        { key: 'from_city', _classes: "shipper02", }, { key: 'to_city', _classes: "shipper02", },
        { key: 'request_date', label: "Booking Date", _style: { width: "10%" }, _classes: "shipper03", },
        { key: 'estimated_delivery_date', label: "Estd Delivery Date", _classes: "shipper01", },
        { key: 'status', label: "Status", _classes: "shipper02", },
        { key: 'quote_price', _style: { width: "10%" }, _classes: "shipper02", },
        {
            key: "action",
            label: "Action",
            sorter: false,
            filter: false,
            _classes: "shipper02",
        },
        {
            key: "droparrow",
            label: "",
            sorter: false,
            filter: false,
            _classes: "",
        },
    ];




    useEffect(() => {
        _transporterConfirmedRequestList();
    }, [search])

    useEffect(() => {
        setSearch(props.bookingRequestId)
    }, [props?.bookingRequestId])

    /**
    * @author Sarmistha Mondal
    * @Date_Created 15/04/2024
    * @Date_Modified 
    * @function async
    * @functionName _transporterConfirmedRequestList
    * @functionPurpose this function for transporter confirm List.
    *
    * @functionParam {filter, page_no}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _transporterConfirmedRequestList = () => {
        Service.apiPostTokenCallRequest(RouteURL.transporterConfirmedRequest, { filter: search, page_no: 999999 }).then((response) => {
      
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.confirmedShipmentRequest.length > 0) {
                    setFilteredData(response.data.confirmedShipmentRequest)
                } else setFilteredData([])

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    const toggleDetails = (index, id,) => {
        setAccordion(id)
    }

    /*quotaion modal open*/ 
    const _updateQuoteModal = (e, booking_id, id, quote_price, order_id) => {
        e.preventDefault();
        setVisible(!visible)
        setModalValue({
            type: "confirm",
            shipmentBookingId: booking_id,
            shipmentId: id,
            quote_price: quote_price,
            order_id: order_id,
        })

    }
    /*close modal*/
    const closeAddEditModal = () => {
        setVisible(false);
        setVehicleVisible(false)
        setcancelModal(false)
    };

    /*after modal close get api call*/
    const onChangeSomeState = (State) => {
        if (!!State) {
            _transporterConfirmedRequestList();
        }
    };

    /*modal open for vehicle*/
    const _assignVehicleModal = (e, booking_id, order_id) => {
        e.preventDefault();
        setVehicleVisible(!vehicleVisible)
        // console.log(booking_id, id);
        setVehicleModalValue({
            shipmentBookingId: booking_id,
            order_id: order_id
        })
    }

    /*modal open */
    const _acceptRejectModal = (e, type, shipment_request_id) => {
        e.preventDefault();
        setcancelModal(!cancelModal)
        setCancelModalValue({
            type: type,
            shipment_request_id: shipment_request_id,
        })
    }

    const settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }
    return (
        <>
            <ToastContainer />
            <form>
                <div className="shipments_sec">
                    <div className="">
                        <div className="row">
                            <div className="col-sm-12">

                                <div className="shipment_bottom_panel">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="shipment_search">
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                                <input type="search" placeholder="Search for Req Id" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="shipment_track_txt">


                                            </div>
                                        </div>
                                    </div>
                                    <div className="godwn_dwn">
                                        <div className="row">
                                            <div className="col">
                                                <div className="office_address_lt shipping_dwn_pad">
                                                    <div className="shipper_table shipping_pagination">
                                                        <CDataTable
                                                            items={filteredData}
                                                            fields={fields}
                                                            itemsPerPage={10}
                                                            itemsPerPageSelect
                                                            pagination={{
                                                                className: "next_arrow",
                                                                firstButton: <i class="fa-solid fa-angle-left"></i>,
                                                                previousButton: <i class="fa-solid fa-angles-left"></i>,
                                                                nextButton: <i class="fa-solid fa-angles-right"></i>,
                                                                lastButton: <i class="fa-solid fa-angle-right"></i>,
                                                                size: "",
                                                                dots: false
                                                            }}
                                                            scopedSlots={{
                                                                request_id: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: '#000' }}>
                                                                            {item.shipment_details.request_id != null ? item.shipment_details.request_id : '--'}
                                                                        </td>)
                                                                },
                                                                shipper_code: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.shipment_details.shipper_code != null ? item.shipment_details.shipper_code : '--'}
                                                                        </td>)
                                                                },
                                                                shipper_name: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: "#FBAA2A" }}>
                                                                            {item.shipment_details.shipper_name != null ? item.shipment_details.shipper_name : '--'}
                                                                        </td>)
                                                                },
                                                                estimated_delivery_date: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.estimated_delivery_date != null ? item.location_details.estimated_delivery_date : '--'}
                                                                        </td>)
                                                                },
                                                                'from_city': (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.from_city != null ? item.location_details.from_city : '--'}
                                                                        </td>)
                                                                }, 'to_city': (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.to_city != null ? item.location_details.to_city : '--'}
                                                                        </td>)
                                                                },
                                                                request_date: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.booking_date != null ? item.location_details.booking_date : '--'}
                                                                        </td>)
                                                                },
                                                                status: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: '#2ED573', fontSize: 14 }}>
                                                                            <b><i className="fa-regular fa-circle-check"></i> Accepted</b>

                                                                        </td>)
                                                                },
                                                                shipper_expected_delivery_date: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            --
                                                                        </td>)
                                                                },
                                                                quote_price: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: '#000' }}>
                                                                            {item.quote_price != null ? <>
                                                                                {'₹ ' + item.quote_price}
                                                                                <i className="fa-solid fa-pencil" onClick={(e) => _updateQuoteModal(e, item.booking_id, item.id, item.quote_price, item.order_id)} />
                                                                            </> : '--'}
                                                                        </td>)
                                                                },
                                                                droparrow: (item, index) => {
                                                                    return (
                                                                        <td className="py-2 history_sec">

                                                                            {accordion !== item.id ?
                                                                                <CButton
                                                                                    onClick={() => { toggleDetails(index, item.id,) }}
                                                                                >
                                                                                    <i className="fa fa-angle-down" aria-hidden="true"></i>

                                                                                </CButton>
                                                                                :
                                                                                <CButton
                                                                                    onClick={() => { toggleDetails(index,) }}
                                                                                >
                                                                                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                                                                                </CButton>
                                                                            }

                                                                        </td>

                                                                    );
                                                                },
                                                                action: (item, index) => {
                                                                    return (

                                                                        <td className="action_chk">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 22.267 22.106" onClick={(e) => _assignVehicleModal(e, item.booking_id, item.order_id,)} title="Assign Vehicle" style={{ cursor: 'pointer' }}>
                                                                                <g id="delivery-truck-_2_" transform="translate(-0.089 511.681)">
                                                                                    <path id="Path_9540" data-name="Path 9540" d="M5.621-511.574a6.2,6.2,0,0,0-5.375,6.419,6.063,6.063,0,0,0,1.229,3.424,6.174,6.174,0,0,0,7.287,2.015A5.768,5.768,0,0,0,10.8-501.1a6.179,6.179,0,0,0,1.706-3.222,7.7,7.7,0,0,0-.021-2.329,6.306,6.306,0,0,0-1.126-2.492,6.247,6.247,0,0,0-3.789-2.376A8.689,8.689,0,0,0,5.621-511.574Zm1.525,1.392a4.731,4.731,0,0,1,2.664,1.353,4.781,4.781,0,0,1,.967,5.4,4.6,4.6,0,0,1-.967,1.383A4.782,4.782,0,0,1,3-502.062a4.782,4.782,0,0,1-1.332-4.125,4.827,4.827,0,0,1,2.552-3.519A4.693,4.693,0,0,1,7.147-510.182Z" transform="translate(-0.096)" stroke="#161615" stroke-width="0.1" />
                                                                                    <path id="Path_9541" data-name="Path 9541" d="M67.636-447.637a.729.729,0,0,0-.361.382,8,8,0,0,0-.047,1.212v1.091l-1.156.013c-1.091.013-1.164.017-1.276.1a.607.607,0,0,0-.3.576.607.607,0,0,0,.3.576c.112.086.185.09,1.272.1l1.156.013.013,1.156c.013,1.087.017,1.16.1,1.272a.607.607,0,0,0,.576.3.607.607,0,0,0,.576-.3c.086-.112.09-.185.1-1.272l.013-1.156,1.156-.013c1.087-.013,1.16-.017,1.272-.1a.607.607,0,0,0,.3-.576.607.607,0,0,0-.3-.576c-.112-.086-.185-.09-1.272-.1l-1.156-.013-.013-1.156c-.013-1.087-.017-1.16-.1-1.272a1.1,1.1,0,0,0-.2-.2A.8.8,0,0,0,67.636-447.637Z" transform="translate(-61.596 -61.174)" stroke="#161615" stroke-width="0.1" />
                                                                                    <path id="Path_9542" data-name="Path 9542" d="M14.29-319.638a.729.729,0,0,0-.361.382c-.034.082-.047,1.345-.047,3.966v3.841l-5.963-.009-5.968-.013-.155-.12a.969.969,0,0,1-.211-.215,9.763,9.763,0,0,1-.077-1.714c-.021-1.551-.026-1.624-.112-1.736a.607.607,0,0,0-.576-.3.607.607,0,0,0-.576.3c-.086.112-.09.18-.1,1.71a17.308,17.308,0,0,0,.034,1.826,2.2,2.2,0,0,0,.6,1.057,2.125,2.125,0,0,0,.872.5c.052,0,.215.279.571.988.571,1.121.765,1.375,1.272,1.62a2.887,2.887,0,0,0,1.5.228h.769l.12.245a2.363,2.363,0,0,0,.868.889,2.041,2.041,0,0,0,1.951-.026,2.251,2.251,0,0,0,.829-.872l.116-.236h5.74l.12.245a2.363,2.363,0,0,0,.868.889,2.041,2.041,0,0,0,1.951-.026,2.251,2.251,0,0,0,.829-.872l.116-.236h.524a3.665,3.665,0,0,0,.769-.064,2.1,2.1,0,0,0,1.512-1.5,25.053,25.053,0,0,0-.013-3.892c-.133-.5-.275-.675-1.895-2.29-1.989-1.981-1.774-1.873-3.725-1.873H15.261l-.013-1.156c-.013-1.091-.017-1.164-.1-1.276a1.1,1.1,0,0,0-.2-.2A.8.8,0,0,0,14.29-319.638Zm3.428,4.133c.189.095,2.853,2.75,2.956,2.947.077.15.082.262.082,1.731,0,1.749,0,1.77-.284,1.981-.155.12-.185.125-.683.138l-.524.017-.116-.245a2.293,2.293,0,0,0-.833-.872,2.041,2.041,0,0,0-1.951-.026,2.382,2.382,0,0,0-.868.885c-.245.5-.241.576-.241-3.188v-3.437h1.16A4.256,4.256,0,0,1,17.718-315.5Zm-10.526,5.5a2.2,2.2,0,0,0-1.319,1.061l-.12.249H5.027a4.869,4.869,0,0,1-.842-.047c-.211-.077-.314-.215-.6-.782l-.275-.546,2.049,0C7.209-310.07,7.394-310.061,7.192-310.009Zm6.689.623v.687H9.637l-.116-.236a2.118,2.118,0,0,0-1.315-1.07c-.211-.056.06-.064,2.724-.064l2.952,0Zm-5.916.748a.67.67,0,0,1,.2,1.108.635.635,0,0,1-.937,0A.676.676,0,0,1,7.965-308.639Zm9.624,0a.67.67,0,0,1,.2,1.108.635.635,0,0,1-.937,0A.676.676,0,0,1,17.589-308.639Z" transform="translate(0 -183.675)" stroke="#161615" stroke-width="0.1" />
                                                                                    <path id="Path_9543" data-name="Path 9543" d="M384.5-191.638a.743.743,0,0,0-.387.468,9.151,9.151,0,0,0-.013.962c.013.9.021.937.309,1.147.112.086.176.09,1.057.09s.945,0,1.057-.09a.607.607,0,0,0,.3-.576.607.607,0,0,0-.3-.576c-.1-.077-.193-.095-.584-.1l-.464-.017-.017-.464c-.013-.5-.052-.6-.3-.786A.8.8,0,0,0,384.5-191.638Z" transform="translate(-367.465 -306.176)" stroke="#161615" stroke-width="0.1" />
                                                                                </g>
                                                                            </svg>

                                                                            <i className="fa-solid fa-trash-can" title="Cancel" style={{ cursor: 'pointer' }}
                                                                                onClick={(e) => _acceptRejectModal(e, 'cancel', item.shipment_request_id)}
                                                                            />
                                                                        </td>


                                                                    );
                                                                },
                                                                'details':
                                                                    (item, index) => {
                                                                        return (
                                                                            <CCollapse show={item.id === accordion}>
                                                                                <div className="order_tracking_sec">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4">
                                                                                            <div className="order_step_inn">
                                                                                                <div className="order-track">
                                                                                                    <div className="order-track-step">
                                                                                                        <div className="order-track-status">
                                                                                                            <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                                                                                            <span className="order-track-status-line"></span>
                                                                                                        </div>
                                                                                                        <div className="order-track-text">
                                                                                                            <span className="order-track-text-sub">Booking Date - {item?.location_details.booking_date}</span>
                                                                                                            <p className="order-track-text-stat">{item?.location_details.from_location}</p>
                                                                                                            <span className="kilometer">{item?.location_details?.distance}</span>
                                                                                                            <span className="kilometer">{item?.location_details?.time_duration}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="order-track-step">
                                                                                                        <div className="order-track-status">
                                                                                                            <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                                                                                            <span className="order-track-status-line"></span>
                                                                                                        </div>
                                                                                                        <div className="order-track-text">
                                                                                                            <span className="order-track-text-sub">Est. Delivery date -{item.location_details.estimated_delivery_date != null ? item.location_details.estimated_delivery_date : '--'}</span>
                                                                                                            <p className="order-track-text-stat">{item?.location_details.to_location}</p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3 pt-4">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6> Loading Type
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">{item?.load_type}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6> Payment Mode
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color"> {item.payment_mode}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-5">

                                                                                            <div className="dimen_slide" >
                                                                                                <div className="slider-container">
                                                                                                    <Slider {...settings}>
                                                                                                        {item?.material_type.length > 0 && item?.material_type.map((data, key) => {
                                                                                                            return (
                                                                                                                <div key={key}>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-6 pt-4">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.017 6">
                                                                                                                                            <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                                            <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                                            <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                                                        </svg> Dimensions (L x W x H)
                                                                                                                                        </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.product_dimension}</p>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="10.141" height="10" viewBox="0 0 5.141 6">
                                                                                                                                            <g id="scale" transform="translate(-36.645)">
                                                                                                                                                <g id="Group_8799" data-name="Group 8799" transform="translate(36.645)">
                                                                                                                                                    <g id="Group_8798" data-name="Group 8798">
                                                                                                                                                        <path id="Path_9414" data-name="Path 9414" d="M40.915,1.059A2.128,2.128,0,0,0,41.761.27.179.179,0,0,0,41.608,0H36.824a.179.179,0,0,0-.154.27,2.127,2.127,0,0,0,.847.789,2.531,2.531,0,0,0,1.018.282V1.8H37.69a.627.627,0,0,0-.621.55L36.708,5.3a.626.626,0,0,0,.621.7H41.1a.626.626,0,0,0,.621-.7L41.363,2.35a.627.627,0,0,0-.621-.55H39.9V1.341A2.532,2.532,0,0,0,40.915,1.059ZM40.681,3.9a1.465,1.465,0,1,1-1.465-1.465A1.466,1.466,0,0,1,40.681,3.9Z" transform="translate(-36.645)" fill="#928fa9" />
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                                <g id="Group_8801" data-name="Group 8801" transform="translate(38.1 2.781)">
                                                                                                                                                    <g id="Group_8800" data-name="Group 8800">
                                                                                                                                                        <path id="Path_9415" data-name="Path 9415" d="M162.628,238.308a1.115,1.115,0,1,0,1.115,1.115A1.117,1.117,0,0,0,162.628,238.308Zm.127,1.243-.433.433a.18.18,0,1,1-.255-.255l.433-.433a.18.18,0,0,1,.255.255Z" transform="translate(-161.513 -238.308)" fill="#928fa9" />
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                            </g>
                                                                                                                                        </svg> Weight
                                                                                                                                        </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.package_weight}</p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6> No of boxes </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.no_of_units}</p>

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-6">
                                                                                                                            <div className="productmaterial_image">
                                                                                                                                <h6>Product Image</h6>
                                                                                                                                <div className="productmaterial_image_box">

                                                                                                                                    <div className="p_box">
                                                                                                                                        {data?.images.length > 0 && data?.images.map((img) => {
                                                                                                                                            return (
                                                                                                                                                <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                                                                                            )
                                                                                                                                        })}

                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-12">
                                                                                                                            <div className="productmaterial_inn">
                                                                                                                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 5.737 6">
                                                                                                                                    <g id="Group_29516" data-name="Group 29516" transform="translate(-803 -366)">
                                                                                                                                        <path id="Path_9494" data-name="Path 9494" d="M26.667,1.6,29.308.463,28.214.014a.19.19,0,0,0-.144,0L25.444,1.093Z" transform="translate(777.726 366)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9495" data-name="Path 9495" d="M172.654,56.125l-2.641,1.132.984.4,2.7-1.108Z" transform="translate(634.871 310.54)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9496" data-name="Path 9496" d="M13.76,122.008l-1.069-.439v.831a.19.19,0,0,1-.379,0v-.987l-1.231-.506v3.339a.19.19,0,0,0,.118.175l2.561,1.052Z" transform="translate(791.919 246.525)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9497" data-name="Path 9497" d="M269.069,122.008v3.466l2.561-1.052a.19.19,0,0,0,.118-.175v-3.339Z" transform="translate(536.989 246.525)" fill="#928fa9" />
                                                                                                                                    </g>
                                                                                                                                </svg> Material
                                                                                                                                </h6>
                                                                                                                                <p className="productmaterial_inn_color">{data.material_type}</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}

                                                                                                    </Slider>
                                                                                                    <span className="slider_number_count"><span style={{ color: "#fbaa2a" }}></span></span>
                                                                                                    {/* <span className="slider_number_count">{buttonVisible}/<span style={{ color: "#fbaa2a" }}>{item?.material_type?.length}</span></span> */}
                                                                                                </div>
                                                                                            </div>



                                                                                            <div className="material_sec">
                                                                                                <div className="row">


                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="material_sec">
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-6">
                                                                                                    </div></div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </CCollapse>
                                                                        )
                                                                    }
                                                            }}

                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


            </form >


            {/* update quote modal */}
            {modalValue &&
                <UpdateQuotesModal _allData={modalValue}
                    CloseAddEditModal={closeAddEditModal} show={visible} onHide={() => setVisible(false)}
                    onChangeSomeState={onChangeSomeState} />
            }

            {/* assign Vehicle modal*/}
            {vehicleVisible &&
                <AssignVehicleModal show={vehicleVisible} onHide={() => setVehicleVisible(false)} _allData={vehicleModalValue}
                    CloseAddEditModal={closeAddEditModal}
                    onChangeSomeState={onChangeSomeState} />
            }

            {/* calcel modal */}
            {cancelModal == true && <UpdateAcceptsRejectQuotationModal _allData={CancelmodalValue}
                CloseAddEditModal={closeAddEditModal} show={cancelModal} onHide={() => setcancelModal(false)}
                onChangeSomeState={onChangeSomeState} />}
        </>
    );
}




