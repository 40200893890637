/**
 * @file_purpose  page for otp
 * @author Sarmistha Mondal
 * @Date_Created 10/05/2024
 * @Date_Modified 
 */

import React, { useState, useRef, useEffect } from "react";
import { ERROR_MESSAGE, REGEX, Constants } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import Modal from "react-bootstrap/Modal";

const VerifyOTPForStopDelivery = (props) => {
    const modalRef = useRef(null);
    let order_id = props._allData.order_id;
    const [submitDisable, setSubmitDisable] = useState(false);
    const [formValue, setFormValue] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
    });

    const [colorStyle, setColorStyle] = useState({
        border1: "1px solid rgb(22 22 21)",
        background1: "",
        border2: "1px solid rgb(22 22 21)",
        background2: "",
        border3: "1px solid rgb(22 22 21)",
        background3: "",
        border4: "1px solid rgb(22 22 21)",
        background4: "",
    });

    const [errorValue, setErrorValue] = useState("");
    const [totpTouchValue, setTotpTouchValue] = useState(false);


    // focus within a specific area
    useEffect(() => {
        if (props.show) {
            const modalElement = modalRef.current;
            //add any focusable HTML element you want to include to this string
            const focusableElements = modalElement.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            const handleTabKeyPress = (event) => {
                if (event.key === "Tab") {
                    if (event.shiftKey && document.activeElement === firstElement) {
                        event.preventDefault();
                        lastElement.focus();
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault();
                        firstElement.focus();
                    }
                }
            };

            const handleEscapeKeyPress = (event) => {
                if (event.key === "Escape") {
                    props.onHide();
                }
            };

            modalElement.addEventListener("keydown", handleTabKeyPress);
            modalElement.addEventListener("keydown", handleEscapeKeyPress);

            return () => {
                modalElement.removeEventListener("keydown", handleTabKeyPress);
                modalElement.removeEventListener("keydown", handleEscapeKeyPress);
            };
        }
    }, [props.show]);

    const otpChange = (event) => {
        const { name, value } = event.target;
        const { data } = event.nativeEvent;

        setFormValue((prevValue) => {
            return { ...prevValue, [name]: data == null ? '' : data.length === 1 && data };
        });
        gotoDesiredInput(event);


    };

    const changeOnKeyDown = (event) => {
        const name = event.target.name;
        const totpNumber = name[name.length - 1];
        const isBackSpace = event.target.value === "";
      
    }

    
    /* for input field delete otp*/
    const gotoDesiredInput = (event) => {
        const name = event.target.name;
        const totpNumber = name[name.length - 1];
        const isBackSpace = event.target.value === "";

        const inputs = document.getElementById("inputs");
        inputs.addEventListener("keyup", function (e) {
            const target = e.target;
            const key = e.key.toLowerCase();
         
            if (key == "backspace" || key == "delete") {
                const prev = target.previousElementSibling;
                if (prev) {
                    prev.focus();
                }
                return;
            }
        });
        
        if ((!isBackSpace && totpNumber < 4)) {
            const el = document.getElementsByName(
                `otp${isBackSpace ? totpNumber - 1 : +totpNumber + 1}`
            );
            el[0].focus();
        }
        

    };
   

    let _token = "";
    /* concate all otp field result*/
    const getFinalOTP = () => {
        for (var key in formValue) {
            _token += formValue[key].toString();
        }
        return _token;
    };

    /* verify otp */
    const _verifyOTP = (event) => {
        event.preventDefault();
        setTotpTouchValue(true);
        let _otp = getFinalOTP();
       
        if (_otp.match(REGEX["otp"])) {
            _stopRequest(_otp)
        }
    };


    /**
    * @author Sarmistha Mondal
    * @Date_Created 10/05/2024
    * @Date_Modified 
    * @function async
    * @functionName _stopRequest
    * @functionPurpose this function for end delivery.
    *
    * @functionParam {order_id,otp_for_delivered}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _stopRequest = (otp) => {
        let params = JSON.stringify({
            order_id: order_id,
            otp_for_delivered: otp
        })
   
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.transporterEndDelivery, params).then((response) => {           
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });

    };


    return (
        <>
            < Modal
                show={props.show}
                onHide={() => { props.onHide(); setFormValue({}); }}
                backdrop="static"
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec" ref={modalRef}>
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Verify Code</h6>
                    </Modal.Header>
                    <div className="task_modal_sec">
                        <form onSubmit={_verifyOTP}>
                            <section className="forgot_inn fotgot_pad">

                                <div className="otp_box" id="inputs">
                                    <input
                                        type="number"
                                        name="otp1"
                                        value={formValue.otp1}
                                        onChange={otpChange}
                                        onKeyDown={changeOnKeyDown}
                                        inputMode="numeric"
                                        pattern="\d{1}"
                                        maxLength={1}
                                        onFocus={() => {
                                            setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border1: "none",
                                                    background1: "#F1F1F7"
                                                };
                                            })
                                        }}
                                        onBlur={() => setColorStyle((prevValue) => {
                                            return {
                                                ...prevValue, border1: "1px solid rgb(22 22 21)",
                                                background1: ""
                                            };
                                        })}
                                        error={errorValue ? 'true' : 'false'}
                                        style={{ border: colorStyle.border1, background: colorStyle.background1 }}

                                    />
                                    <input
                                        type="number"
                                        name="otp2"
                                        value={formValue.otp2}
                                        onChange={otpChange}
                                        onKeyDown={changeOnKeyDown}
                                        inputMode="numeric"
                                        pattern="\d{1}"
                                        maxLength={1}
                                        error={errorValue ? 'true' : 'false'}
                                        onFocus={() => {
                                            setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border2: "none",
                                                    background2: "#F1F1F7"
                                                };
                                            })
                                        }}
                                        onBlur={() => setColorStyle((prevValue) => {
                                            return {
                                                ...prevValue, border2: "1px solid rgb(22 22 21)",
                                                background2: ""
                                            };
                                        })}
                                        style={{ border: colorStyle.border2, background: colorStyle.background2 }}

                                    />
                                    <input
                                        type="number"
                                        name="otp3"
                                        value={formValue.otp3}
                                        onChange={otpChange}
                                        onKeyDown={changeOnKeyDown}
                                        inputMode="numeric"
                                        pattern="\d{1}"
                                        maxLength={1}
                                        error={errorValue ? 'true' : 'false'}
                                        onFocus={() => {
                                            setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border3: "none",
                                                    background3: "#F1F1F7"
                                                };
                                            })
                                        }}
                                        onBlur={() => setColorStyle((prevValue) => {
                                            return {
                                                ...prevValue, border3: "1px solid rgb(22 22 21)",
                                                background3: ""
                                            };
                                        })}
                                        style={{ border: colorStyle.border3, background: colorStyle.background3 }}
                                    />
                                    <input
                                        type="number"
                                        name="otp4"
                                        value={formValue.otp4}
                                        onChange={otpChange}
                                        onKeyDown={changeOnKeyDown}
                                        inputMode="numeric"
                                        pattern="\d{1}"
                                        maxLength={1}
                                        error={errorValue ? 'true' : 'false'}
                                        onFocus={() => {
                                            setColorStyle((prevValue) => {
                                                return {
                                                    ...prevValue, border4: "none",
                                                    background4: "#F1F1F7"
                                                };
                                            })
                                        }}
                                        onBlur={() => setColorStyle((prevValue) => {
                                            return {
                                                ...prevValue, border4: "1px solid rgb(22 22 21)",
                                                background4: ""
                                            };
                                        })}
                                        style={{ border: colorStyle.border4, background: colorStyle.background4 }}
                                    />

                                </div>


                                <div className="create_sub text-center">
                                    <div className="login_btn">
                                        <button type="submit" value="get Started" style={{ background: '#000' }}
                                            disabled={submitDisable ? true : false}>Verify</button>
                                    </div>
                                </div>
                            </section>
                        </form>

                    </div>

                </div>
            </Modal >


        </>
    );
};

export default VerifyOTPForStopDelivery;
