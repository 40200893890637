/**
 * @file_purpose  page for helper
 * @author Sarmistha Mondal
 * @Purpose reusable function 
 * @Date_Created 03/11/2023
 * @Date_Modified 
 */
import { ERROR_MESSAGE, REGEX } from "./ConstantData";
let resetTimeout;
const leftTrim = (string) => {
  return (string ?? "").toString().trimStart();
};

const markAllAsTouched = (object) => {
  return Object.keys(object).reduce((accumulator, key) => {
    return { ...accumulator, [key]: true };
  }, {});
};

const leftTrimAllValue = (object, exceptLeftTrimValues = []) => {
  if (object) {
    return Object?.keys(object).reduce((accumulator, key) => {
      return {
        ...accumulator,
        [key]: exceptLeftTrimValues.find((element) => element === key)
          ? object[key]
          : leftTrim(object[key]),
      };
    }, {});
  } else return null;
};

const getErrorMessage = (key, value, isOptional) => {
  if (
    (value && value?.toString().match(REGEX[key])) ||
    (isOptional && (!value || (value && value?.toString().match(REGEX[key]))))
  )
    return "";
  else {
    return !value
      ? `${ERROR_MESSAGE[key + "Required"] ?? "This field is required"}`
      : `${ERROR_MESSAGE[key + "Invalid"]}`;
  }
};

const isFormValid = (formValue, optionalFields) => {

  for (var key in formValue) {
    if (formValue[key] === null) {
      formValue[key] = "";
    }
    // console.log(formValue[key] +'--'+ formValue[key].toString().match(REGEX[key]));
    if (formValue[key] && formValue[key].toString().match(REGEX[key])) continue;
    else if (
      optionalFields &&
      optionalFields[key] &&
      formValue[key].toString().match(REGEX[key])
    ) {
      console.log('ok');
      continue;
    } else return false;
  }
  return true;
};

const getYMDDate = (date) => {
  if (!date) return null;
  date = new Date(date);
  const finalDate = date?.getDate().toString().padStart(2, 0);
  const finalMonth = (date?.getMonth() + 1).toString().padStart(2, 0);
  const finalYear = date?.getFullYear();
  return `${finalYear}/${finalMonth}/${finalDate}`;
};

const limitDecimalPlaces = (e, count) => {
  if (e.target.value.indexOf(".") === -1) {
    return;
  }
  if (e.target.value.length - e.target.value.indexOf(".") > count) {
    e.target.value = parseFloat(e.target.value).toFixed(count);
  }
};

const yearsList = () => {
  let currentYear = new Date().getFullYear(), years = [];
  let startYear = 1900;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

const checkNull = (value, defaultValue = "--") => {
  if (value === undefined || value === null) {
    return defaultValue;
  } else {
    return value;
  }
};

const removeSpecialCharacters = e => {
  e.target.value = e.target.value.replace(/[^0-9 ]/g, '');
};

const checkLength = (e) => {
  const KEYS = {
    leftKey: 37,
    rightKey: 39,
    backspace: 8,
    deleteKey: 46,
    digitZero: 48,
    digitNine: 57,
    _digitZero: 96,
    _digitNine: 105,
  };
  if (
    e.keyCode === KEYS.backspace ||
    e.keyCode === KEYS.deleteKey ||
    e.keyCode === KEYS.rightKey ||
    e.keyCode === KEYS.leftKey) {
    return true;
  }

  if ((e.keyCode < KEYS.digitZero || e.keyCode > KEYS._digitNine) ||
    e.target.value.length === e.target.maxLength ||
    e.shiftKey) {
    if (e.keyCode == 9) { e.stopPropagation(); return true; }
    else {
      e.stopPropagation();
      e.preventDefault();
    }

    return false;
  }

  return true;
}

const trim = (x) => {
  let value = String(x)
  return value.replace(/^\s+|\s+$/gm, '')
};

const isEmpty = (value) => {
  if (value === null || value === undefined || trim(value) === '' || value.length === 0) {
    return true
  } else {
    return false
  }
};

// convert time 12 hr to 24 hr 
const convertTime12to24 = (time12h) => {
  const [timeWithoutPeriod, period] = time12h.split(" ");
  let [hours, minutes] = timeWithoutPeriod.split(":");
  if (period === "PM" && hours !== "12") {
    hours = String(Number(hours) + 12);
  }
  if (period === "AM" && hours === "12") {
    hours = "00";
  }
  return `${hours}:${minutes}`;
}

export {
  leftTrim,
  getErrorMessage,
  markAllAsTouched,
  leftTrimAllValue,
  isFormValid,
  getYMDDate,
  limitDecimalPlaces,
  yearsList,
  checkNull,
  removeSpecialCharacters,
  checkLength,
  isEmpty,
  convertTime12to24
};
