/**
 * @file_purpose  page for header 
 * @author Sarmistha Mondal
 * @Date_Created 06/11/2023
 * @Date_Modified
 */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Truck from "../assets/images/truck.png";
import transIcon from "../assets/images/tran.png";
import logoutIcon from "../assets/images/logout_icon.png";
import { Constants } from "../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../apis/Service";
import RouteURL from "../apis/RouteURL";
import SideMenu from "./sideMenu";
import Modal from "react-bootstrap/Modal";


const TheHeader = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    let menuName = localStorage.getItem('MenuName')
    const user_type = localStorage.getItem('user_type');
    const profile_image = localStorage.getItem('Profile_img')
    const profile_details = JSON.parse(localStorage.getItem('profile_details'))
    const [show, setShow] = useState(false);
    const [notificationShow, setNotificationShow] = useState(false);
    const [allNotification, setAllNotification] = useState([]);
    const [update, setUpdate] = useState(false);
    const [unread_notifications, setunread_notifications] = useState();
    const [visible, setVisible] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('token') == null) navigate("/", { replace: true })

    }, []);

    useEffect(() => {
        if (show == true) {
            setTimeout(() => {
                setShow(false)
            }, 9000);

        }
    }, [show])

    useEffect(() => {
        if (localStorage.getItem('token') != null) _getNotificationList()
    }, [update]);

    /**
     * @functionName logout
     * @functionPurpose this function for logout .  
     *  
     * */
    const logout = () => {
        _updateFcmToken();
    };

    /**
    * @author Sarmistha Mondal
    * @Date_Created 29/05/2024
    * @Date_Modified 
    * @function async
    * @functionName _updateFcmToken
    * @functionPurpose this function for logout time fcm token update. 
    * @functionParam {fcm_token, platform}  
    * @functionSuccess Success status and message.
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _updateFcmToken = () => {
        let params = JSON.stringify({
            fcm_token: '',
            platform: 'web',
        });
        Service.apiPostTokenCallRequest(RouteURL.updateFcmToken, params).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                localStorage.clear();
                navigate("/", { replace: true })

            } else {

                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }).catch((error) => {
            console.log(error);

        });
    }

    const yourFunction = (e) => {
        e.preventDefault()
        if (!show) {
            document.addEventListener("click", handleOutsideClick, false);
        } else {
            document.removeEventListener("click", handleOutsideClick, false);
        }

        setShow(!show)
    };

    const handleOutsideClick = e => {
        // setShow(false)
        // console.log(e);
        // if (!node.contains(e.target)) yourFunction();
    };

    const pageNavigation = (e) => {
        e.preventDefault();
        if (menuName == 'Shipment') {
            navigate("/shipment-list")
        } else if (localStorage.getItem('onBoard')) {
            navigate(localStorage.getItem('onBoard'))
        }


    }

    /**
   * @author Sarmistha Mondal
   * @Date_Created 29/05/2024
   * @Date_Modified 
   * @function async
   * @functionName _getNotificationList
   * @functionPurpose this function for notification list.   
   * @functionSuccess Success status and message.
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
    const _getNotificationList = () => {
        Service.apiGetCallRequest(RouteURL.getNotifications, {}).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.notification_list.length > 0) {
                    setAllNotification(response.data.notification_list)
                }
                setunread_notifications(response?.data?.unread_notifications)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /**
  * @author Sarmistha Mondal
  * @Date_Created 29/05/2024
  * @Date_Modified 
  * @function async
  * @functionName _updateNotificationReadStatus
  * @functionPurpose this function for notification list.
  *
  * @functionParam {}
  *
  * @functionSuccess Success status and message.
  *
  * @functionError {Boolean} error is there.
  * @functionError {String} message  Description message.
  */
    const _updateNotificationReadStatus = (e, notification_id) => {
        e.preventDefault();
        Service.apiPostTokenCallRequest(RouteURL.updateNotificationReadStatus, { notification_id: notification_id }).then((response) => {
            // console.log(response);
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    const pageRedirect = (e, event) => {
        // console.log(event);
        e.preventDefault();
        props.element(event)
    }

    const _openModal = (e) => {
        e.preventDefault();
        setVisible(true)
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 27/06/2024
    * @Date_Modified 
    * @function async
    * @functionName _submitDeleteAccount
    * @functionPurpose this function for delete account.
    *
    * @functionParam {}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _submitDeleteAccount = () => {
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.deleteAccount, {}).then((response) => {
            // console.log(response);
            setSubmitDisable(false)
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                navigate('/')
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }).catch((error) => {
            setSubmitDisable(false)

        })
    }

    return (
        <>
            <header className="header_sec">
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="header_inn">
                                <div className="headlogo_sec">
                                    <img src={Truck} alt="" />
                                    {/* onClick={pageNavigation} */}
                                </div>
                                <div className="header_rt">
                                    <span className="notification_sec">
                                        <i className="fa-regular fa-bell" style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); setNotificationShow(!notificationShow) }} />

                                        {unread_notifications != 0 && <span className="noti_num" onClick={(e) => { e.preventDefault(); setNotificationShow(!notificationShow) }}>{unread_notifications}</span>}
                                        {notificationShow == true ?
                                            <div className="notification_main" style={{ height: allNotification.length > 4 ? 400 : 'auto' }}>
                                                <span className="noti_cross" onClick={(e) => { e.preventDefault(); setNotificationShow(!notificationShow) }}><i className="fa-solid fa-xmark"></i></span>
                                                <>
                                                    {allNotification.length > 0 ? allNotification.map((data) => {
                                                        return (
                                                            <>
                                                                <div className="noti_box" style={{ cursor: 'pointer' }} onMouseEnter={(e) => { _updateNotificationReadStatus(e, data.notification_id); setUpdate(!update); }}
                                                                    onClick={(e) => { pageRedirect(e, data?.data) }} >
                                                                    <div className="noti_box_lt">
                                                                        <div className={data.reading_status == false ? `noti_box_lt_icon` : `noti_box_lt_icon open_env`}><i className={data.reading_status == false ? `fa-regular fa-envelope` : `fa-solid fa-envelope-open-text`}></i></div>
                                                                        <div className="noti_box_txt" >
                                                                            <h6>{data?.title}</h6>
                                                                            <span>{data?.body}</span>
                                                                            {/* <p>Ahmedabad, Gujrat, 380015</p> */}
                                                                            <span>{data?.createdAt}
                                                                                {/* 1 August, 2023 */}
                                                                            </span>

                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="noti_box_rt">
                                                                        <i className="fa-solid fa-xmark"></i>
                                                                    </div> */}
                                                                </div>
                                                            </>
                                                        )
                                                    })

                                                        : <span>No new notification !!!</span>}


                                                </>
                                                {/* <div className="notifoot">
                                                    <span className="read"><a href="">Read All</a></span>
                                                    <span className="delete"><a href="">Delete All</a></span>
                                                </div> */}
                                            </div> : ""}
                                    </span>
                                    <div className="trans_drop" onClick={(event) => {
                                        yourFunction(event);
                                    }}>
                                        <div className="trans_drop_pic" >
                                            <img src={profile_image ? profile_image : transIcon} style={{ height: 30, width: 30, borderRadius: 15 }} />
                                        </div>
                                        <div className="trans_drop_txt"><p>
                                            {
                                                (() => {
                                                    switch (user_type) {
                                                        case "Shipper":
                                                            return (profile_details.business_type == 'Organization' ? localStorage.getItem('firm_name') : localStorage.getItem('first_name'))
                                                        // ((localStorage.getItem('firm_name') == (null || 'null')) ? localStorage.getItem('first_name') : localStorage.getItem('firm_name'))
                                                        case "Transporter":
                                                            return (profile_details.register_type == 'Individual' ? localStorage.getItem('first_name') : localStorage.getItem('firm_name'))
                                                        case "Receiver":
                                                            return localStorage.getItem('name')
                                                        case "Manager":
                                                            return localStorage.getItem('first_name')
                                                        default:
                                                            return null;
                                                    }
                                                })()
                                            }</p></div>
                                        <div className="trans_dwn_arw" ><a href="" ><i className="fa-solid fa-angle-down"></i></a>

                                        </div>
                                        {show == true ?
                                            <div className="logout_pop">
                                                <div className="logout_icon">
                                                    <img src={profile_image ? profile_image : logoutIcon} alt="" style={{ height: 40, width: 40, borderRadius: 20, cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); navigate("/account") }} />
                                                    <div className="logout_edit" style={{ cursor: 'pointer' }}>
                                                        <i className="fa-solid fa-pencil" onClick={(e) => { e.preventDefault(); navigate("/account") }} />
                                                    </div>
                                                </div>
                                                <div className="log_btn_out" onClick={logout}>
                                                    <button type="button" ><i className="fa-solid fa-arrow-right-from-bracket"></i> Log Out</button>
                                                </div>
                                                <div className="log_btn_out">
                                                    <button type="button" onClick={_openModal}><i className="fa-solid fa-trash-can"></i> Delete Account</button>
                                                </div>
                                            </div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {location.pathname == '/account' && <SideMenu />}
            {visible && < Modal
                show={visible}
                onHide={() => setVisible(false)}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">Delete Account
                        </h6>
                    </Modal.Header>
                    <section className="reject_inner">

                        <p>Do you really want to delete this account?
                        </p>
                        <div className='reco_trans_rt_inner_dwn' style={{ justifyContent: 'center' }}>
                            <div>
                                <button type='' value="reject"
                                    onClick={() => setVisible(false)}
                                    style={{ background: '#fff', color: '#000' }}
                                >Cancel</button></div>
                            <div><button type='reject' value="reject"
                                onClick={_submitDeleteAccount}
                                // disabled={submitDisable ? true : false}
                                style={{ background: '#000', color: '#fff' }}
                            >Done</button></div>
                        </div>


                    </section>
                </div>
            </Modal>}
        </>
    );
}
export default React.memo(TheHeader);


