/**
 * @file_purpose  page for add edit deal receive payment
 * @author Sarmistha Mondal
 * @Date_Created 04/10/2023
 * @Date_Modified 15/03/2024
 */
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim, removeSpecialCharacters,
    checkLength
} from "../../../Utility/Helper";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";

export default function AddUserForm(props) {
    const modalRef = useRef(null);
    let Type = props._allData.type;
    let data = props._allData.data
    const [submitDisable, setSubmitDisable] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [formValue, setFormValue] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role_id: '',
        status: '',
        user_id: ""
    });
    const [formTouchValue, setFormTouchValue] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        role_id: false,
        status: false,
    });
    const [errorValue, setErrorValue] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        role_id: '',
        status: '',
    });


    // focus within a specific area
    useEffect(() => {

        const modalElement = modalRef.current;
        //add any focusable HTML element you want to include to this string
        const focusableElements = modalElement.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        const handleTabKeyPress = (event) => {
            if (event.key === "Tab") {
                if (event.shiftKey && document.activeElement === firstElement) {
                    event.preventDefault();
                    lastElement.focus();
                } else if (
                    !event.shiftKey &&
                    document.activeElement === lastElement
                ) {
                    event.preventDefault();
                    firstElement.focus();
                }
            }
        };

        const handleEscapeKeyPress = (event) => {
            if (event.key === "Escape") {
                // props.onHide();
            }
        };

        modalElement.addEventListener("keydown", handleTabKeyPress);
        modalElement.addEventListener("keydown", handleEscapeKeyPress);

        return () => {
            modalElement.removeEventListener("keydown", handleTabKeyPress);
            modalElement.removeEventListener("keydown", handleEscapeKeyPress);
        };

    }, []);

    useEffect(() => {
        if (Type == 1) {
            setFormValue({
                firstName: data.first_name,
                lastName: data.last_name,
                email: data.email,
                phone: data.phone_number,
                role_id: data.role_id,
                status: data.status,
                user_id: data.user_id
            })
        }
        _getTransporterRoleList()

    }, []);
    useEffect(() => {

        if (formValue) {
            checkError("firstName");
            checkError("lastName");
            checkError("email");
            checkError("phone");
            checkError("role_id");
            checkError("status");
        }

    }, [formValue]);

    /* on change input function */
    const formChange = (event) => {
        const { name, value, checked } = event.target;
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
    };

    /* select role*/
    const roleSelect = (e) => {
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, role_id: String(e.value) };
        });
    }

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }

    };

    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !formValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (formValue[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)

                };
            });
        }


    };

    /**
       * @author Sarmistha Mondal
       * @Date_Created 12/12/2023
       * @Date_Modified 
       * @function async
       * @functionName __getTransporterRoleList
       * @functionPurpose this function for transporter role list.
       *
       * @functionParam {}
       *
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _getTransporterRoleList = () => {

        Service.apiPostTokenCallRequest(RouteURL.transporterRoleList, {}).then((response) => {
          
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.roleList.length > 0) {
                    const data = response.data.roleList;
                    var newArray = []
                    data.map((item, index) => (
                        newArray.push({
                            value: item.id,
                            label: item.name
                        })
                    ))
                    setFilteredData(newArray)
                }
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    const validation = (e) => {
        e.preventDefault();
        if (Type == 0) {
            _addTransporterUser()
        } else {
            _updateUser()
        }
    }


    /**
     * @author Sarmistha Mondal
     * @Date_Created 15/03/2024
     * @Date_Modified
     * @function async
     * @functionName _updateUser
     * @functionPurpose this function for update user update.
     *
     * @functionParam {first_name, last_name, user_id, contact_number,role_id,status }
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */

    const _updateUser = () => {
        setSubmitDisable(true)
        let params = JSON.stringify({
            first_name: formValue.firstName,
            last_name: formValue.lastName,
            user_id: formValue.user_id,
            contact_number: formValue.phone,
            role_id: formValue.role_id,
            status: formValue.status,
        });
        
        Service.apiPostTokenCallRequest(RouteURL.transporterUpdateUser, params).then((response) => {
          
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                props.onChangeSomeState("add");                
                toast.success(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });

            } else {
              
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        }).catch((error) => {
            setSubmitDisable(false);
            console.log(error);

        });

    };

    /**
      * @author Sarmistha Mondal
      * @Date_Created 12/12/2023
      * @Date_Modified
      * @function async
      * @functionName _addTransporterUser
      * @functionPurpose this function for add user submit.
      *
      * @functionParam {first_name, last_name, user_id, contact_number,role_id,status }
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */

    const _addTransporterUser = () => {
        setFormTouchValue(markAllAsTouched(formTouchValue));
        checkError("firstName");
        checkError("lastName");
        checkError("email");
        checkError("phone");
        checkError("role_id");
        checkError("status");
        delete formValue[""];
        delete formValue.user_id;
        if (isFormValid(formValue)) {
            setSubmitDisable(true)
            let params = JSON.stringify({
                first_name: formValue.firstName,
                last_name: formValue.lastName,
                email: formValue.email,
                contact_number: formValue.phone,
                role_id: formValue.role_id,
                status: formValue.status,
            });
            Service.apiPostTokenCallRequest(RouteURL.transporterAddUser, params).then((response) => {
               
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                                      
                    props.CloseAddEditModal();
                    props.onChangeSomeState("add");
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                } else {
                    
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                }
            }).catch((error) => {
                console.log(error);
                setSubmitDisable(false);
            });
        }
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                size="lg"
            > <div className="forgot_sec" ref={modalRef}>
                    <Modal.Header closeButton closeVariant={'white'}> <h6 className="hed_txt">{Type == 0 ? 'Add' : 'Edit'} User
                    </h6></Modal.Header>
                </div>
                <div className="task_modal_sec">
                    <div className="row">
                        <div className="col">
                            <div className="office_address_lt" >
                                <form >

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label> First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="firstName"
                                                    placeholder="Enter First Name"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    value={formValue.firstName}
                                                    error={errorValue.firstName ? "true" : "false"}
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }} />
                                            </div>
                                            {errorValue.firstName && (
                                                <span className="error">
                                                    {errorValue.firstName}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="lastName"
                                                    placeholder="Enter Last Name"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    value={formValue.lastName}
                                                    error={errorValue.lastName ? "true" : "false"}
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}
                                                />
                                            </div>
                                            {errorValue.lastName && (
                                                <span className="error">
                                                    {errorValue.lastName}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Contact Number</label>
                                                <input
                                                    type="number" placeholder="Enter Contact Number"
                                                    maxLength={10}
                                                    pattern="[0-9]*"
                                                    name="phone"
                                                    className="form-control"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    onKeyDown={e => checkLength(e)}
                                                    onKeyUp={removeSpecialCharacters}
                                                    value={formValue.phone}
                                                    error={errorValue.phone ? "true" : "false"}
                                                />
                                            </div>

                                            {errorValue.phone && (
                                                <span className="error">
                                                    {errorValue.phone}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Email</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="email"
                                                    placeholder="Enter Email"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    disabled={Type == 1 ? true : false}
                                                    value={formValue.email}
                                                    error={errorValue.email ? "true" : "false"}
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}
                                                />
                                            </div>

                                            {errorValue.email && (
                                                <span className="error">
                                                    {errorValue.email}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Role</label>
                                                <Select
                                                    name="role_id"
                                                    value={filteredData.filter(obj => formValue.role_id == obj.value)}
                                                    options={filteredData}
                                                    onChange={roleSelect}
                                                    onBlur={updateFormTouchValue}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    isDisabled={Type == 1 ? true : false}
                                                />
                                               
                                            </div>

                                            {errorValue.role_id && (
                                                <span className="error">
                                                    {errorValue.role_id}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Status</label>
                                                <div className="office_radio">
                                                    <div className="radio_sec">
                                                        <input id="radio-1" className="radio-custom" type="radio" value="Active" name="status" checked={formValue.status == 'Active' ? true : false} onChange={formChange} onBlur={updateFormTouchValue} />
                                                        <label for="radio-1" className="radio-custom-label" style={{ color: "#000" }}>Active</label>
                                                    </div>
                                                    <div className="radio_sec">
                                                        <input id="radio-2" className="radio-custom" name="status" type="radio" value="Inactive" checked={formValue.status == 'Inactive' ? true : false} onChange={formChange} onBlur={updateFormTouchValue} />
                                                        <label for="radio-2" className="radio-custom-label">Inactive</label>
                                                    </div>

                                                </div>
                                                {errorValue.status && (
                                                    <span className="error">
                                                        {errorValue.status}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="save_btn">

                                        <button type="submit" value="Verify & Save"
                                            style={{ background: '#000' }}
                                            onClick={validation}
                                            disabled={submitDisable ? true : false}> Save</button>
                                    </div>

                                </form >
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
