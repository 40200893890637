/**
 * @file_purpose  page for vehical
 * @author Sarmistha Mondal
 * @Date_Created 20/03/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim,
} from "../../../Utility/Helper";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TataBg from "../../../assets/images/tat_bg.png"
import Verified from "../../../assets/images/checkmark.png";

export default function AddVehical(props) {
    const modalRef = useRef(null);
    const navigate = useNavigate();
    let formData = new FormData();
    const [filteredData, setFilteredData] = useState([]);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [plateDisable, setPlateDisable] = useState(false);
    const [vehicleFormData, setVehicleFormData] = useState({
        vin_chassis_number: "",
        vehicle_make: "",
        vehicle_type: "",
        vehicle_model: "",
        purchase_date: "",
        year: "",
        vehicle_plate_number: "",
    });
    const [vehicle_category_id, setvehicle_category_id] = useState({ id: '' })
    const [is_vehicle_plate_number_verified, setIs_Vehicle_plate_number_verified] = useState(0);
    const [errorValue, setErrorValue] = useState({
        vehicle_plate_number: "",
        vehicle_image: "",
        insurance_document: "",
        load_type: "",
        vehicle_category_id: ""
    });
    const [formTouchValue, setFormTouchValue] = useState({
        vehicle_plate_number: false,
        vehicle_image: false,
        insurance_document: false,
        load_type: false,
        vehicle_category_id: false
    })
    const [inputDisable, setInputDisable] = useState({
        vin_chassis_number: "",
        vehicle_make: "",
        vehicle_type: "",
        vehicle_model: "",
        purchase_date: "",
        year: "",
        is_vehicle_plate_number_verified: "",
        vehicle_group: "",
        vehicle_plate_number: "",
        vehicle_image: "",
        insurance_document: "",
        load_type: "",
        vehicle_category_id: ""
    })
    const [trackingDetails, setTrackingDetails] = useState('full')
    const [images, setImages] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [insuranceFiles, setInsuranceFiles] = useState([])
    const [insuranceImg, setInsuranceImg] = useState([])
    const selectedFIles = [];
    const insuranceFile = []; 

    // focus within a specific area
    useEffect(() => {

        const modalElement = modalRef.current;
        //add any focusable HTML element you want to include to this string
        const focusableElements = modalElement.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        const handleTabKeyPress = (event) => {
            if (event.key === "Tab") {
                if (event.shiftKey && document.activeElement === firstElement) {
                    event.preventDefault();
                    lastElement.focus();
                } else if (
                    !event.shiftKey &&
                    document.activeElement === lastElement
                ) {
                    event.preventDefault();
                    firstElement.focus();
                }
            }
        };

        const handleEscapeKeyPress = (event) => {
            if (event.key === "Escape") {
                // props.onHide();
            }
        };

        modalElement.addEventListener("keydown", handleTabKeyPress);
        modalElement.addEventListener("keydown", handleEscapeKeyPress);

        return () => {
            modalElement.removeEventListener("keydown", handleTabKeyPress);
            modalElement.removeEventListener("keydown", handleEscapeKeyPress);
        };

    }, []);

    /* vehicle img upload*/ 
    const handleMultipleImages = (e) => {
        let files = e.target.files[0];
        fileNames.push(files)
        setFileNames(fileNames)
        if (fileNames.length <= 4) {
            const targetFilesObject = [...fileNames]
            targetFilesObject.map((file) => {
                // console.log(file);
                return selectedFIles.push(URL.createObjectURL(file))
            })

        }

        setImages(selectedFIles);

    }

    /* insurance doc upload*/ 
    const handleDocumentImages = (e) => {
        let files = e.target.files[0];
        insuranceFiles.push(files)
        setInsuranceFiles(insuranceFiles)
        const targetFilesObject = [...insuranceFiles]
        targetFilesObject.map((file) => {
            return insuranceFile.push(URL.createObjectURL(file))
        })
        setInsuranceImg(insuranceFile)
    }

    /* on change input function */
    const formChange = (event) => {
        const { name, value, } = event.target;

        setVehicleFormData((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });

    };
    useEffect(() => {
        if (trackingDetails == 'full') {
            if (trackingDetails == 'full' && is_vehicle_plate_number_verified == 1 && vehicle_category_id.id == '') checkError("vehicle_category_id");
        } else {
            setErrorValue((err) => {
                return { ...err, vehicle_category_id: '' }
            })
        }

    }, [trackingDetails]);


    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !vehicleFormData[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (vehicleFormData[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)

                };

            });
        }
    };

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setVehicleFormData((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };

    useEffect(() => {
        loadVehicleCategoryList();      

    }, []);

    /**
     * @author Sarmistha Mondal
     * @Date_Created 13/06/2024
     * @Date_Modified
     * @function async
     * @functionName loadVehicleCategoryList
     * @functionPurpose this function gets the transporter.
     *
     * @functionParam {payload object:}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */

    const loadVehicleCategoryList = () => {
        Service.apiPostTokenCallRequest(RouteURL.getVehicleCategory, {})
            .then((res) => {
                if (res.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let _res = res.data;
                    var arrCategory = [{ value: '', label: 'Select Option', color: '#0052CC', },]
                    _res.map((item, index) =>
                        arrCategory.push({
                            value: item.vehicle_category_id,
                            label: item.vehicle_category,
                        })
                    );
                    setFilteredData(arrCategory);

                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
    }

    const handleChangeCategory = (e) => {
        setvehicle_category_id((prevFormValue) => {
            return { ...prevFormValue, id: e.value };
        });
        setErrorValue((err) => {
            return { ...err, vehicle_category_id: '' }
        })
    };

    /**
  * @author Sarmistha Mondal
  * @Date_Created 04.03.2024
  * @Date_Modified 
  * @function async
  * @functionName _generateVehicalDetails
  * @functionPurpose this function for
  * validate vehical no 
  *
  *  @functionParam {payload object: vehicle_plate_number}
  * 
  * @functionSuccess Success status and message.
  *
  * @functionError {Boolean} error is there.
  * @functionError {String} message  Description message.
  */
    const _generateVehicalDetails = () => {
        let params = JSON.stringify({
            vehicle_plate_number: vehicleFormData.vehicle_plate_number,
        })
        setPlateDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.verifyVehiclePlatenumber, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let res = response.data.rcDetails;
                    setIs_Vehicle_plate_number_verified(1);
                    localStorage.setItem("vehicle_plate_number", vehicleFormData.vehicle_plate_number)
                    setVehicleFormData((prevFormValue) => {
                        return {
                            ...prevFormValue,
                            purchase_date: res.purchase_date,
                            vehicle_make: res.vehicle_make,
                            vehicle_model: res.vehicle_model,
                            vehicle_type: res.vehicle_type,
                            vin_chassis_number: res.vin_chassis_number,
                            year: res.year,
                        };

                    });
                    setInputDisable((prevErrorValue) => {
                        return { ...prevErrorValue, trackingDetails: true, }
                    })
                    setErrorValue((prevErrorValue) => {
                        return { ...prevErrorValue, vehicle_plate_number: '' }
                    })
                    
                    toast.success(response?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setPlateDisable(false);
                        },
                    });
                } else {
                    
                    setIs_Vehicle_plate_number_verified(0)
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setPlateDisable(false);
                        },
                    });
                }
            }).catch((error) => {
                setPlateDisable(false)
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    useEffect(() => {
        checkError("vehicle_plate_number");

    }, [formTouchValue.vehicle_plate_number]);

    /* Validation Checking */
    const ValidateForm = (e) => {
        e.preventDefault();
        setFormTouchValue(markAllAsTouched(formTouchValue));
        if (trackingDetails == 'full' && !vehicle_category_id.id && is_vehicle_plate_number_verified == 1) { checkError("vehicle_category_id"); }

        if (isFormValid(vehicleFormData) && images?.length > 0) {
            if (trackingDetails == 'full' && vehicle_category_id.id != '') _vehicalAddSubmit(e)
            else _vehicalAddSubmit(e)
        } else console.log('no');
    };


    /**
      * @author Sarmistha Mondal
      * @Date_Created 08/03/2024
      * @Date_Modified 
      * @function async
      * @functionName _vehicalAddSubmit
      * @functionPurpose this function for onboarding office vehical submit.
      *
      * @functionParam {vin_chassis_number,vehicle_make,vehicle_type,vehicle_model,purchase_date
      * year,is_vehicle_plate_number_verified,vehicle_group,vehicle_plate_number,load_type,vehicle_category_id,
      * vehicle_image,insurance_document}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */
    const _vehicalAddSubmit = (e) => {
        e.preventDefault();
        formData.set(`vin_chassis_number`, vehicleFormData.vin_chassis_number);
        formData.set(`vehicle_make`, vehicleFormData.vehicle_make);
        formData.set(`vehicle_type`, vehicleFormData.vehicle_type);
        formData.set(`vehicle_model`, vehicleFormData.vehicle_model);
        formData.set(`purchase_date`, vehicleFormData.purchase_date);
        formData.set(`year`, vehicleFormData.year);
        formData.set(`is_vehicle_plate_number_verified`, is_vehicle_plate_number_verified == 1 ? '1' : '0');
        formData.set(`vehicle_group`, vehicleFormData.vehicle_group);
        formData.set(`vehicle_plate_number`, vehicleFormData.vehicle_plate_number);
        formData.set(`load_type`, trackingDetails == 'full' ? 'full' : 'partial');
        formData.set(`vehicle_category_id`, trackingDetails == 'full' ? vehicle_category_id.id : '');
        for (let i = 0; i < fileNames.length; i++) {
            formData.append(`vehicle_image`, fileNames[i]);
        }
        for (let i = 0; i < insuranceFiles.length; i++) {
            formData.set(`insurance_document`, insuranceFiles[i]);
        }
       
        setSubmitDisable(true)

        Service.apiPostCallFormDataRequest(RouteURL.vehicleAdd, formData).then((response) => {
            
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {              
                toast.success(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });

                props.CloseAddEditModal();
                props.onChangeSomeState("add");

            } else {
                
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        }).catch((error) => {
            setSubmitDisable(false);
        });

    }
    const onClickTrackingDetails = (e, type) => {
        e.preventDefault();      
        if (type == 'partial') setvehicle_category_id((prevFormValue) => { return { ...prevFormValue, id: "", } })
        setTrackingDetails(type)
    }
    return (
        <>
            <section className="bg_color_sec" ref={modalRef}>
                <div className="office_address_sec">


                    <div className="row">
                        <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-12 col-md-12">
                            <div className="office_address_lt">
                                <h6>Vehicle Details</h6>

                                <form >
                                    <div className="row">
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field">
                                                <label>Vehicle plate number</label>
                                                <input type="text" placeholder="Enter Plate Number" name="vehicle_plate_number"
                                                    value={vehicleFormData.vehicle_plate_number}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    error={errorValue.vehicle_plate_number ? "true" : "false"}
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}
                                                />
                                                <span className="verify_sec "
                                                    style={{ color: vehicleFormData.vehicle_plate_number ? ((localStorage.getItem("vehicle_plate_number") != vehicleFormData.vehicle_plate_number) || is_vehicle_plate_number_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        if (vehicleFormData['vehicle_plate_number'].match(REGEX['vehicle_plate_number']))
                                                            if (plateDisable == false) _generateVehicalDetails()
                                                    }}
                                                >

                                                    {(localStorage.getItem("vehicle_plate_number") == vehicleFormData.vehicle_plate_number) && is_vehicle_plate_number_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("vehicle_plate_number") == vehicleFormData.vehicle_plate_number) && is_vehicle_plate_number_verified == 1 ? 'verified' : 'verify'}
                                                </span>
                                            </div>
                                            {errorValue.vehicle_plate_number && (
                                                <span className="error">
                                                    {errorValue?.vehicle_plate_number}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field">
                                                <label>Vehicle Type</label>
                                                <input type="text" placeholder="Vehicle Type" name="vehicle_type"
                                                    value={vehicleFormData.vehicle_type}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    disabled={inputDisable.vehicle_type ? false : true} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field">
                                                <label>V/N Chassis Number</label>
                                                <input type="text" placeholder="V/N Chassis Number" name="vin_chassis_number"
                                                    value={vehicleFormData.vin_chassis_number}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    disabled={inputDisable.vin_chassis_number ? false : true} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field">
                                                <label>Vehicle Make</label>
                                                <input type="text" placeholder="Vehicle Make" name="vehicle_make"
                                                    value={vehicleFormData.vehicle_make}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    disabled={inputDisable.vehicle_make ? false : true} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field">
                                                <label>Vehicle Group</label>
                                                <input type="text" name="vehicle_group"
                                                    placeholder="Vehicle Group"
                                                    // placeholder={`${inputDisable.vehicle_group == true ? 'Enter ' : ''}` + `Vehicle Group`}
                                                    value={vehicleFormData.vehicle_group}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    disabled={inputDisable.vehicle_group ? false : true} />

                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field">
                                                <label>Vehicle Model</label>
                                                <input type="text" name="vehicle_model"
                                                    placeholder="Vehicle Model"
                                                    value={vehicleFormData.vehicle_model}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    disabled={inputDisable.vehicle_model ? false : true} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field">
                                                <label>Year</label>
                                                <input type="number" placeholder=" Year" name="year"
                                                    value={vehicleFormData.year}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    disabled={inputDisable.year ? false : true} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field">
                                                <label>Purchase Date</label>
                                                <input type="text" placeholder="Purchase Date" name="purchase_date"
                                                    value={vehicleFormData.purchase_date}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    disabled={inputDisable.purchase_date ? false : true} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field" >
                                                <h6>Consignment</h6>
                                                <div className="tracking_btn">
                                                    <div className="tracksm_btn" >
                                                        <button type="button" value="full" disabled={inputDisable.trackingDetails ? false : true}
                                                            style={{ backgroundColor: (trackingDetails == 'full') ? '#FBAA2A' : '' }} onClick={(e) => onClickTrackingDetails(e, 'full')}>Full Load</button></div>
                                                    <div className="tracksm_btn">
                                                        <button type="button" value="partial" disabled={inputDisable.trackingDetails ? false : true}
                                                            style={{ backgroundColor: trackingDetails == 'partial' ? '#FBAA2A' : '' }}
                                                            onClick={(e) => onClickTrackingDetails(e, 'partial')}>Partial Load</button></div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Consignment</label>
                                                <div className="office_radio">
                                                    <div className="radio_sec">
                                                        <input id="radio-4" className="radio-custom" name="load_type" type="radio" value="full" onChange={formChange} onBlur={updateFormTouchValue}
                                                            error={errorValue.load_type ? "true" : "false"} />
                                                        <label for="radio-4" className="radio-custom-label">Full Load</label>
                                                    </div>
                                                    <div className="radio_sec">
                                                        <input id="radio-3" className="radio-custom" name="load_type" type="radio" value="partial"  onChange={formChange} onBlur={updateFormTouchValue}
                                                            error={errorValue.load_type ? "true" : "false"} />
                                                        <label for="radio-3" className="radio-custom-label" style={{ color: "#000" }}>Partial Load</label>
                                                    </div>                                                   

                                                </div>

                                            </div>
                                        </div> */}
                                        {trackingDetails == 'full' &&
                                            <div className="col-sm-6">
                                                <div className="office_field">
                                                    <label>Vehicle Category</label>
                                                    <Select
                                                        className="dropdown"
                                                        name="vehicle_category_id"
                                                        placeholder="Select Option"
                                                        isSearchable={false}
                                                        options={filteredData}
                                                        onChange={(e) => handleChangeCategory(e)}
                                                    />
                                                </div>
                                                {errorValue.vehicle_category_id && (
                                                    <span className="error">
                                                        {errorValue?.vehicle_category_id}
                                                    </span>
                                                )}
                                            </div>}

                                        {/* <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                            <div className="office_field" >
                                                <h6>Tracking Details</h6>
                                                <div className="tracking_btn"
                                                // aria-disabled={inputDisable.trackingDetails ? false : 'true'}

                                                >
                                                    <div className="tracksm_btn" >
                                                        <button type="button" value="ShipGudds Tracking" disabled={inputDisable.trackingDetails ? false : true}
                                                            style={{ backgroundColor: (is_vehicle_plate_number_verified == 1 && trackingDetails == 'ShipGudds') ? '#FBAA2A' : '' }} onClick={(e) => onClickTrackingDetails(e, 'ShipGudds')}>ShipGudds Tracking</button></div>
                                                    <div className="tracksm_btn">
                                                        <button type="button" value="ShipGudds Tracking" disabled={inputDisable.trackingDetails ? false : true}
                                                            style={{ backgroundColor: trackingDetails == 'Self' ? '#FBAA2A' : '' }}
                                                            onClick={(e) => onClickTrackingDetails(e, 'Self')}>Self Tracking</button></div>
                                                </div>
                                            </div>
                                        </div>
                                        {trackingDetails != 'Self' &&
                                            <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                                                <div className="office_field">
                                                    <label>Tracking Device IEMI</label>
                                                    <input type="number" placeholder="Enter IEMI Number" name="tracking_device_iemi"
                                                        value={vehicleFormData.tracking_device_iemi}
                                                        error={errorValue.tracking_device_iemi ? "true" : "false"}
                                                        onChange={formChange}
                                                        onBlur={updateFormTouchValue} />
                                                </div>
                                                {errorValue.tracking_device_iemi && (
                                                    <span className="error">
                                                        {errorValue?.tracking_device_iemi}
                                                    </span>
                                                )}
                                            </div>
                                        } */}


                                    </div>
                                </form>

                            </div>

                        </div>
                        <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-12 col-md-12">
                            <div className="office_address_rt">


                                <div className="vehicle_rt_box lorry_upload">
                                    <div className="vehicle_txt">
                                        <h6 style={{ display: "inline-block" }}>Add Vehicle Image {is_vehicle_plate_number_verified == 1 && images?.length == 0 ? <span style={{ color: 'red' }}>*</span> : ''}<span style={{ float: "right" }}><a href="javascript:void(0)"> Total image: {images?.length}</a></span></h6>
                                       
                                    </div>
                                    <div className="row">
                                        {
                                            images.map((url) => {
                                                return (<div className="col-sm-6 col-6">
                                                    <div className="tata_moto_sec">
                                                        <img src={url} alt="" />
                                                    </div>
                                                </div>)
                                            })}

                                        {images?.length < 4 &&
                                            <div className="col-sm-6 col-6">
                                                <div className="tata_moto_sec" >
                                                    {(is_vehicle_plate_number_verified == 1 && !!vehicleFormData.id) || (is_vehicle_plate_number_verified == 1 && (vehicleFormData.id == null || vehicleFormData.id == undefined)) &&
                                                        <input type="file" accept=".jpg,.jpeg,.png"
                                                            onChange={handleMultipleImages}
                                                        />}
                                                    <img src={TataBg} alt="" />
                                                </div>
                                            </div>}
                                        {/* {fileNames} */}
                                    </div>
                                </div>

                                <div className="vehicle_rt_box lorry_upload">
                                    <div className="vehicle_txt">
                                        <h6>Insurance Document (Optional)</h6>
                                        <p>Total Docs: {insuranceImg?.length}</p>
                                    </div>
                                    <div className="row">

                                        {insuranceImg.map((file) => {
                                            return (<div className="col-sm-6 col-6">
                                                <div className="vehicle_doc">
                                                    <span><i className="fa-regular fa-trash-can"></i></span>
                                                    <img src={file} alt="" />
                                                    {/* <img src={VehicleDoc} alt="" /> */}
                                                    <p> Licences</p>
                                                </div>
                                            </div>
                                            )
                                        })}

                                        {insuranceImg?.length < 2 &&
                                            <div className="col-sm-6 col-6">
                                                <div className="tata_moto_sec">
                                                    {(is_vehicle_plate_number_verified == 1 && !!vehicleFormData.id) || (is_vehicle_plate_number_verified == 1 && (vehicleFormData.id == null || vehicleFormData.id == undefined)) &&
                                                        <input type="file" accept=".jpg,.jpeg,.png"
                                                            onChange={handleDocumentImages}
                                                        />}
                                                    <img src={TataBg} alt="" />
                                                </div>
                                            </div>}
                                        <div className="col-sm-6"></div>
                                    </div>
                                </div>
                                <div className="save_btn">
                                    <button type="submit" value="Verify & Save" style={{ background: '#000' }}
                                        // style={{ background: ((Object.values(vehicleFormData).every(x => x != '') != false) && fileNames.length > Number(0)) ? '#000' : '#C5C4D2' }}
                                        onClick={ValidateForm}>Verify & Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
}