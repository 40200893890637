/**
 * @file_purpose  page for showing shipment list
 * @author Sarmistha Mondal
 * @Date_Created 15/12/2023
 * @Date_Modified
 */

import React, { useState, useEffect, useRef } from "react";
import NewRequestPage from "./transporterTabPage/newRequest";
import AcceptedRequestPage from "./transporterTabPage/acceptedRequest";
import RejectRequestPage from "./transporterTabPage/rejectRequest";
import PendingRequestPage from "../shipper/shipperTabPage/pendingRequestforShipper";
import ReadyForPickupPage from "./transporterTabPage/readyForPickup";
import ConfirmedRequestPage from "./transporterTabPage/confirmedRequest";
import OutForDeliveryPage from "./transporterTabPage/outForDelivery";
import DeliveredRequestPage from "./transporterTabPage/delivered";
import AllRequestPage from "./transporterTabPage/allRequest";
import HistoryPage from "../shipper/shipperTabPage/history";
import WaitingForVehicleAssignmentPage from "../shipper/shipperTabPage/WaitingForVehicleAssignment";
import ReadyForPickupPageForShipper from "../shipper/shipperTabPage/readyForPickupShipper";
import OutForDeliveryPageShipper from "../shipper/shipperTabPage/outForDeliveryShipper";
import DeliveredRequestPageShipper from "../shipper/shipperTabPage/deliveryShipper";
import CancleRequestPage from "./transporterTabPage/cancelRequestList";
import CancleRequestPageShipper from "../shipper/shipperTabPage/cancelRequestShipper";

export default function ShipmentList(props) {
  const user_type = localStorage.getItem("user_type");
  const [menuName, setMenuName] = useState();
  const [bookingRequestId, setBookingRequestId] = useState({bookingid:''});
  const handleMenuSet = (e, menuName, booking_request_id) => {
    setMenuName(menuName);
    setBookingRequestId((prevErrorValue) => {
      return {
        ...prevErrorValue,
        bookingid: booking_request_id,
      };
    });
  };
 

  useEffect(() => {

    if (user_type != "Shipper") {
      setMenuName("newrequest");
    } else setMenuName("pendingrequest");

  }, []);

  useEffect(() => {
    if (user_type == 'Transporter' || user_type == 'Manager') {
      (() => {
        switch (props?.deepLinking?.event) {
          case "new_request_match":
            return handleMenuSet('', 'newrequest', props?.deepLinking?.booking_request_id);
          case "quotation_accepted":
            return handleMenuSet('', "confirmed", props?.deepLinking?.booking_request_id);
          case "e_way_uploaded":
            return handleMenuSet('', "readyForpickup", props?.deepLinking?.booking_request_id);
          case "payment_received":
            return handleMenuSet('', "readyForpickup", props?.deepLinking?.booking_request_id);         
          case "trip_rejected":
            return handleMenuSet('', "reject", props?.deepLinking?.booking_request_id);
          case "shipment_completed":
            return handleMenuSet('', "delivered", props?.deepLinking?.booking_request_id);
          case "trip_cancelled":
            return handleMenuSet('', "cancel", props?.deepLinking?.booking_request_id)
          default:
            return null;
        }
      })()
    } else if (user_type == "Shipper") {
     
      (() => {
        switch (props?.notificationData?.event) {
          case "quote_received":
            return handleMenuSet('', "pendingrequest", props?.notificationData?.booking_request_id);
          case "vehicle_assigned":
            return handleMenuSet('', "readyForpickup", props?.notificationData?.booking_request_id);
          case "shipment_started":
            return handleMenuSet('', "outfordelivery", props?.notificationData?.booking_request_id);
          case "payment_pending" :
            return handleMenuSet('', "readyForpickup", props?.notificationData?.booking_request_id);
          case "shipment_completed":
            return handleMenuSet('', "delivered", props?.notificationData?.booking_request_id);
          default:
            return null;
        }
      })()
    }

  }, [props?.deepLinking, props?.notificationData]);

  return (
    <>
      <div className="sideright_menu">
        <div className="shipment_menu_rt">
          <h4>Shipments</h4>
          {user_type != "Shipper" ? (
            <div className="shipment_dwn_menu">
              <ul>
               
                <li>
                  <a
                    href="javascript:void(0)"
                    style={{
                      color: menuName === "newrequest" ? "#000" : "",

                    }}
                    onClick={(e) => handleMenuSet(e, "newrequest")}
                  >
                    New Request
                  </a>
                </li>
                <li>
                  <a
                    className={menuName === "accept" ? " active" : ""}
                    style={{
                      color: menuName === "accept" ? "#000" : "",

                    }}
                    href="javascript:void(0)"
                    onClick={(e) => handleMenuSet(e, "accept")}
                  >
                    Accepted
                  </a>
                </li>

                <li>
                  <a
                    href="javascript:void(0)"
                    className={menuName === "confirmed" ? " active" : ""}
                    style={{
                      color: menuName === "confirmed" ? "#000" : "",

                    }}
                    onClick={(e) => handleMenuSet(e, "confirmed")}
                  >
                    Confirmed
                  </a>
                </li>

                <li>
                  <a
                    href="javascript:void(0)"
                    style={{
                      color: menuName === "readyForpickup" ? "#000" : "",

                    }}
                    onClick={(e) => handleMenuSet(e, "readyForpickup")}
                  >
                    Ready for Pickup
                  </a>
                </li>
               
                <li>
                  <a
                    href="javascript:void(0)"
                    style={{
                      color: menuName === "outfordelivery" ? "#000" : "",

                    }}
                    onClick={(e) => handleMenuSet(e, "outfordelivery")}
                  >
                    Out for Delivery
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    style={{
                      color: menuName === "delivered" ? "#000" : "",

                    }}
                    onClick={(e) => handleMenuSet(e, "delivered")}
                  >
                    Delivered
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    style={{
                      color: menuName === "reject" ? "#000" : "",

                    }}
                    onClick={(e) => handleMenuSet(e, "reject")}
                  >
                    Rejected
                  </a>
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    style={{
                      color: menuName === "cancel" ? "#000" : "",

                    }}
                    onClick={(e) => handleMenuSet(e, "cancel")}
                  >
                    Cancelled
                  </a>
                </li>
              </ul>
              {(() => {
                switch (menuName) {
                  case "allrequest":
                    return <AllRequestPage bookingRequestId={bookingRequestId.bookingid} />;
                  case "newrequest":
                    return <NewRequestPage bookingRequestId={bookingRequestId.bookingid} />;
                  case "accept":
                    return <AcceptedRequestPage bookingRequestId={bookingRequestId.bookingid} />;
                  case "confirmed":
                    return <ConfirmedRequestPage bookingRequestId={bookingRequestId.bookingid} />;
                  case "readyForpickup":
                    return <ReadyForPickupPage bookingRequestId={bookingRequestId.bookingid}/>;                 
                  case "outfordelivery":
                    return <OutForDeliveryPage bookingRequestId={bookingRequestId.bookingid} />;
                  case "reject":
                    return <RejectRequestPage bookingRequestId={bookingRequestId.bookingid} />;
                  case "delivered":
                    return <DeliveredRequestPage bookingRequestId={bookingRequestId.bookingid} />;
                  case "cancel":
                    return <CancleRequestPage bookingRequestId={bookingRequestId.bookingid} />
                  default:
                    return null;
                }
              })()}
            </div>
          ) : (
            <>
              <div className="shipment_dwn_menu">
                <ul>
                  <li>
                    <a
                      href="javascript:void(0)"
                      style={{
                        color: menuName === "pendingrequest" ? "#000" : "",

                      }}
                      className={menuName === "pendingrequest" ? " active" : ""}
                      onClick={(e) => handleMenuSet(e, "pendingrequest")}
                    >
                      Pending Requests
                    </a>
                  </li>
                 

                  <li >
                    <a href="javascript:void(0)"
                      style={{ color: (menuName === "waitforvehicle" ? '#000' : ''), }}
                      className={(menuName === "waitforvehicle" ? " active" : "")}
                      onClick={(e) => handleMenuSet(e, "waitforvehicle")}>Waiting for Vehicle Assignment</a>
                  </li>

                  <li>
                    <a href="javascript:void(0)" style={{ color: (menuName === "readyForpickup" ? '#000' : ''), }} onClick={(e) => handleMenuSet(e, "readyForpickup")}>Ready for Pickup</a>
                  </li>
                 

                  <li>
                    <a href="javascript:void(0)" style={{ color: (menuName === "outfordelivery" ? '#000' : ''), }} onClick={(e) => handleMenuSet(e, "outfordelivery")}>Out for Delivery</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" style={{ color: (menuName === "delivered" ? '#000' : ''), }} onClick={(e) => handleMenuSet(e, "delivered")}>Delivered</a>
                  </li>
                
                    <li>
                      <a
                        href="javascript:void(0)"
                        style={{
                          color: menuName === "cancelShipper" ? "#000" : "",

                        }}
                        onClick={(e) => handleMenuSet(e, "cancelShipper")}
                      >
                        Cancelled
                      </a>
                    </li>
                    
                </ul>
                {(() => {
                  switch (menuName) {
                    case "pendingrequest":
                      return <PendingRequestPage bookingRequestId={bookingRequestId.bookingid} />;
                    case "waitforvehicle":
                      return <WaitingForVehicleAssignmentPage bookingRequestId={bookingRequestId.bookingid} />;
                    case "readyForpickup":
                      return <ReadyForPickupPageForShipper bookingRequestId={bookingRequestId.bookingid} />;                  
                    case "outfordelivery":
                      return <OutForDeliveryPageShipper />;
                    case "delivered":
                      return <DeliveredRequestPageShipper bookingRequestId={bookingRequestId.bookingid} />;                   
                    case "history":
                      return <HistoryPage />;
                    case "cancelShipper":
                      return <CancleRequestPageShipper />
                    default:
                      return null;
                  }
                })()}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
