import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  checkLength,
  leftTrim, removeSpecialCharacters
} from "../../Utility/Helper";
import { Constants, REGEX } from "../../Utility/ConstantData";
import Service from "../../apis/Service";
import RouteURL from "../../apis/RouteURL";
import Tpro from "../../assets/images/tpro.png";
import { ToastContainer, toast } from "react-toastify";
import { Rating } from 'react-simple-star-rating'
import VerifyOTP from "../auth/veryfyOTP";
import Verified from "../../assets/images/checkmark.png";
import ReNewSubscription from "./commonModal/reNewSubscription";


const Myaccount = (props) => {
  let formData = new FormData();
  const navigate = useNavigate();
  const user_type = localStorage.getItem('user_type')
  const profile_image = localStorage.getItem('Profile_img')
  const profile_details = JSON.parse(localStorage.getItem('profile_details'))
  const [files, setFile] = useState();
  const [ratingList, setRatingList] = useState([]);
  const [getAddressList, setGetAddressList] = useState([]);
  const [openModal, setopenModal] = useState(false)
  const [is_phone_verified, setIsPhoneVerified] = useState(0);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [data, setData] = useState();
  const [formValue, setFormValue] = useState({
    phone: ''
  });
  const [errorValue, setErrorValue] = useState({ phone: '' });
  const [filteredData, setFilteredData] = useState();
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
  const [contactDetails, setContactDetails] = useState();
  const [openModalForOTP, setopenModalForOTP] = useState({
    visible: false,
    type: '',
    emailOrPhone: '',
    pageFor: 'signup',
    client_id: ''
  });


 
  useEffect(() => {
    _getRatingReviewList()
    _userSbscriptionDetails()
    _ShipguddsContact()
    if (user_type == 'Transporter' || user_type == 'Manager') _OfficeAddressList()

  }, []);

  /* on change input function */
  const formChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevFormValue) => {
      return { ...prevFormValue, [name]: leftTrim(value) };
    });
  };


  /* Validation Checking */
  const validation = (e) => {
    e.preventDefault();

    if (!formValue.phone) {
      setErrorValue((prevFormValue) => {
        return { ...prevFormValue, phone: 'Please enter a valid whatsapp number.' };
      });

    } else {
      setErrorValue((prevFormValue) => {
        return { ...prevFormValue, phone: '' };
      });

    }

    if (formValue.phone && is_phone_verified == 1) {
      _submitPhoneNo()
    }
  }

  /* img upload*/
  const handleChangeFile = (event) => {
    const selectedFIles = [];
    const files = event.target.files[0];
    setFile(URL.createObjectURL(event.target.files[0]));
    selectedFIles.push(URL.createObjectURL(files))

    // console.log(files);
    setFile(selectedFIles)
    _submitProfilePic(files)
  };


  /**
    * @author Sarmistha Mondal
    * @Date_Created 27/05/2024
    * @Date_Modified 
    * @function async
    * @functionName _submitProfilePic
    * @functionPurpose this function for profile image.
    *
    * @functionParam {}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
  const _submitProfilePic = (file) => {
    formData.set(`profile_image`, file);
    Service.apiPostCallFormDataRequest(RouteURL.updateProfileImage, formData).then(
      (response) => {
        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          localStorage.setItem('Profile_img', response.data.profile_image)
          props.sendDataToParent('update');
          toast.success(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          window.location.reload();
        }
      });

  }



  /**
     * @author Sarmistha Mondal
     * @Date_Created 30/05/2024
     * @Date_Modified 
     * @function async
     * @functionName _getRatingReviewList
     * @functionPurpose this function for rating review list.
     *
     * @functionParam {}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
  const _getRatingReviewList = () => {

    Service.apiPostTokenCallRequest(RouteURL.ratingReviewList, {}).then(
      (response) => {
        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          if (response.data.ratingReviewList.length > 0) {
            setRatingList(response.data.ratingReviewList)
          } else setRatingList([])

        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          window.location.reload();
        }
      });

  }

  /**
* @author Sarmistha Mondal
* @Date_Created 24/11/2023
* @Date_Modified 
* @function async
* @functionName _OfficeAddressList
* @functionPurpose this function for Office Address List.
*
* @functionParam {}
*
* @functionSuccess Success status and message.
*
* @functionError {Boolean} error is there.
* @functionError {String} message  Description message.
*/
  const _OfficeAddressList = () => {

    Service.apiPostTokenCallRequest(RouteURL.godownAddressList, {}).then((response) => {

      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        if (response.data.addressList.length > 0) {
          setGetAddressList(response.data.addressList)

        }
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

  };


  /**
* @author Sarmistha Mondal
* @Date_Created 24/11/2023
* @Date_Modified 
* @function async
* @functionName _ShipguddsContact
* @functionPurpose this function for contact details.
*
* @functionParam {}
*
* @functionSuccess Success status and message.
*
* @functionError {Boolean} error is there.
* @functionError {String} message  Description message.
*/
  const _ShipguddsContact = () => {
    Service.apiPostTokenCallRequest(RouteURL.shipguddsContact, {}).then((response) => {

      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        setContactDetails(response.data)
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

  };


  /**
 * @author Sarmistha Mondal
 * @Date_Created 16.11.2023
 * @Date_Modified 
 * @function async
 * @functionName _generateWhatsappOTP
 * @functionPurpose this function for
 * otp send in whatsapp 
 *
 *  @functionParam {payload object: phone_number}
 * 
 * @functionSuccess Success status and message.
 *
 * @functionError {Boolean} error is there.
 * @functionError {String} message  Description message.
 */
  const _generateWhatsappOTP = () => {
    let params = JSON.stringify({
      phone_number: formValue.phone,
    })
    document.getElementById("gen_otp").classList.add("disabled");
    Service.apiPostCallRequest(RouteURL.generateWhatsappOtp, params).then(
      (response) => {
        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          setopenModalForOTP({ visible: true, type: 'phone', emailOrPhone: formValue.phone, pageFor: 'signup', viewOtp: response.data.otp_for_whatsapp_verification })
          document.getElementById("gen_otp").classList.remove("disabled");
          toast.success(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

        } else {

          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          document.getElementById("gen_otp").classList.remove("disabled");
        }
      }).catch((error) => {
        // console.log(error);
        document.getElementById("gen_otp").classList.remove("disabled");

      });
  }

  /* close modal*/
  const _otpModalCloseForOTP = (state, res) => {
    setopenModalForOTP({ visible: false })
  }


  /*after modal close get api call*/
  const onChangeSomeState = (State) => {
    if (!!State) {
      setIsPhoneVerified(State)
    }
  };


  /**
    * @author Sarmistha Mondal
    * @Date_Created 26/06/2024
    * @Date_Modified 
    * @function async
    * @functionName _submitPhoneNo
    * @functionPurpose this function for update ph no.
    *
    * @functionParam {whatsapp_number}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
  const _submitPhoneNo = () => {
    setSubmitDisable(true)
    Service.apiPostTokenCallRequest(RouteURL.updateWhatsappNumber, { whatsapp_number: formValue.phone }).then((response) => {

      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        profile_details["whatsapp_number"] = formValue.phone;
        localStorage.setItem('profile_details', JSON.stringify(profile_details))
        setFormValue((prevFormValue) => {
          return { ...prevFormValue, phone: "" };
        });
        setopenModal(false)
        setSubmitDisable(false)
      } else {

        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          onClose: () => {
            setSubmitDisable(false);
          },
        });
      }
    }).catch((error) => {
      setSubmitDisable(false)

    });
  }

  /**
    * @author Sarmistha Mondal
    * @Date_Created 15/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _userSbscriptionDetails
    * @functionPurpose this function for subscription details
    *
    * @functionParam {}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
  const _userSbscriptionDetails = () => {

    Service.apiPostTokenCallRequest(RouteURL.userSbscriptionDetails, {}).then((response) => {

      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        setFilteredData(response.data)

      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

  };

  /* open subscription modal*/
  const _openSubscriptionModal = (e,) => {
    e.preventDefault();
    setSubscriptionModalOpen(true)

  }

  /* close subscription modal*/
  const _closeSubscriptionModal = (State) => {
    if (State) {
      _userSbscriptionDetails()
    }
    setSubscriptionModalOpen(false)
  }


  return (
    <>


      <div className="sideright_menu">
        <div className="shipment_menu_rt">
          <div className="row">
            <div className="col-sm-4">
              <div className="transport_profile_main">
                <div className="transport_profile_main_upper">


                  <div className="profile_image_ship">
                    <img src={files ? files : (profile_image ? profile_image : Tpro)} />
                    <div className="pencil_sec" style={{ cursor: 'pointer' }}>
                      <i className="fa-solid fa-pencil" style={{ cursor: 'pointer' }} >
                      </i>
                      <input type="file" accept=".jpg,.jpeg,.png" onChange={(e) => { handleChangeFile(e) }} name="cover-photo" id="icon_upload" />
                    </div>

                  </div>

                  <h6>{(() => {
                    switch (user_type) {
                      case "Shipper":
                        return (profile_details.business_type == 'Organization' ? localStorage.getItem('firm_name') : localStorage.getItem('first_name'))
                      case "Transporter":
                        return (profile_details.register_type == 'Individual' ? localStorage.getItem('first_name') : localStorage.getItem('firm_name'))
                      case "Receiver":
                        return localStorage.getItem('name')
                      case "Manager":
                        return localStorage.getItem('first_name')
                      default:
                        return null;
                    }
                  })()}</h6>
                </div>
                {user_type != 'Receiver' &&
                  <div className="transport_profile_main_contact">
                    <div className="trans_con_hed">
                      <div className="trans_con_hed_lt">
                        <h5 style={{ color: '#EB2F06' }}>Subscription expires on : {filteredData?.subscriptionDetails?.subscription_end_date}</h5>
                      </div>
                    </div>

                    <div className="transport_mail">
                      <div className="transport_mail_inn">
                        <p>
                          <div
                            className="sample_note"
                            dangerouslySetInnerHTML={{
                              __html: filteredData?.subscriptionDetails?.description,
                            }}
                          /></p>
                        {filteredData?.renew_flag == true &&
                          <div className="login_btn">
                            <button type="submit" value="get Started" disabled={submitDisable ? true : false}
                              style={{ background: '#000', color: '#fff' }} onClick={(e) => _openSubscriptionModal(e)}>Renew</button>
                          </div>}

                      </div>
                    </div>
                  </div>}
                <div className="transport_profile_main_contact">
                  <div className="trans_con_hed">
                    <div className="trans_con_hed_lt">
                      <h6>Contact Details</h6>
                    </div>

                  </div>
                  <div className="transport_mail">
                    <div className="transport_mail_inn">
                      <span>
                        <i className="fa-solid fa-envelope"></i>
                      </span>
                      <div className="transport_mail_inn_rt_txt">
                        <p style={{ color: "#928FA9", fontSize: 9 }}>
                          Email Address
                        </p>
                        <p>{localStorage.getItem('email')}</p>
                      </div>
                    </div>

                    {user_type != 'Receiver' &&
                      <>
                        <div className="transport_mail_inn">
                          <span>
                            <i class="fa-solid fa-phone" style={{ color: 'white', fontSize: 'small' }}></i>
                          </span>
                          <div className="transport_mail_inn_rt_txt">
                            <p style={{ color: "#928FA9", fontSize: 9 }}>
                              WhatsApp Number
                            </p>
                            <p>{profile_details?.whatsapp_number}    {user_type != 'Manager' && <i className="fa-solid fa-pen" style={{ cursor: 'pointer' }} onClick={(e) => setopenModal(!openModal)} />}</p>
                            {openModal &&
                              <div className="row" style={{ display: 'flex' }}>
                                <div className="col-sm-10">
                                  <div className="create_sub">
                                    <input type="number" placeholder="Enter whatsapp number"
                                      name="phone"
                                      onChange={formChange}
                                      maxLength={10}
                                      pattern="[0-9]*"
                                      onKeyDown={e => checkLength(e)}
                                      onKeyUp={removeSpecialCharacters}
                                      // onBlur={updateFormTouchValue}
                                      value={formValue.phone}
                                      error={formValue.phone ? "true" : "false"}
                                    />
                                    <span className="verify_sec" id="gen_otp"
                                      style={{ top: '25%', background: 'none', color: formValue.phone ? ((localStorage.getItem("phone") != formValue.phone) || is_phone_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                      onClick={() => {
                                        if (formValue['phone'].match(REGEX['phone'])) _generateWhatsappOTP()
                                      }}
                                    >
                                      {(localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1 ? 'verified' : 'verify'}
                                    </span>
                                  </div>
                                  {!formValue?.phone && errorValue.phone && (
                                    <span className="error" style={{ background: 'none', }}>
                                      {errorValue.phone}
                                    </span>
                                  )}
                                </div>
                                <div className="col-sm-2" style={{
                                  alignContent: 'center', paddingTop: 14, paddingLeft: 0
                                }}>
                                  <button type="submit" value="get Started" onClick={validation}
                                    style={{ background: '#000', color: '#fff', height: 44, borderRadius: 4 }}>Save</button>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        {profile_details.business_type != 'Individual' &&
                          <div className="transport_mail_inn">
                            <span>
                              <i className="fa-solid fa-id-card" style={{ color: 'white', fontSize: 'small' }}></i>
                            </span>
                            <div className="transport_mail_inn_rt_txt">
                              <p style={{ color: "#928FA9", fontSize: 9 }}>
                                GST Number
                              </p>
                              <p>{profile_details?.gst_number}</p>
                            </div>
                          </div>}
                        {profile_details.business_type == 'Individual' &&
                          <>
                            <div className="transport_mail_inn">
                              <span>
                                <i className="fa-solid fa-id-card" style={{ color: 'white', fontSize: 'small' }}></i>
                              </span>
                              <div className="transport_mail_inn_rt_txt">
                                <p style={{ color: "#928FA9", fontSize: 9 }}>
                                  Addhar Number
                                </p>
                                <p>{profile_details?.aadhar_number}</p>
                              </div>
                            </div>
                            {user_type == 'Transporter' &&
                              <div className="transport_mail_inn">
                                <span>
                                  <i className="fa-solid fa-id-card" style={{ color: 'white', fontSize: 'small' }}></i>
                                </span>
                                <div className="transport_mail_inn_rt_txt">
                                  <p style={{ color: "#928FA9", fontSize: 9 }}>
                                    Pan Number
                                  </p>
                                  <p>{profile_details?.pan_number}</p>
                                </div>
                              </div>}
                          </>}
                      </>}
                  </div>
                </div>
                <div className="transport_profile_main_contact">
                  <div className="trans_con_hed">
                    <div className="trans_con_hed_lt">
                      <h6>Contact us</h6>
                    </div>

                  </div>
                  <div className="transport_mail">
                    <div className="transport_mail_inn">
                      <p style={{ color: '#928fa9', fontSize: 'small' }}>
                        You can get in touch with us through below platforms. Our team will reach out to you as soon as it would be possible.
                      </p>
                      <div className="transport_mail">
                        <div className="transport_mail_inn">
                          <span>
                            <i class="fa-solid fa-phone" style={{ color: 'white', fontSize: 'small' }}></i>
                          </span>
                          <div className="transport_mail_inn_rt_txt">
                            <p style={{ color: "#928FA9", fontSize: 9 }}>
                              Contact Number
                            </p>
                            <p>{contactDetails?.contact_number}</p>
                          </div>
                        </div>
                        <div className="transport_mail_inn">
                          <span>
                            <i className="fa-solid fa-envelope"></i>
                          </span>
                          <div className="transport_mail_inn_rt_txt">
                            <p style={{ color: "#928FA9", fontSize: 9 }}>
                              Email Address
                            </p>
                            <p>{contactDetails?.email}</p>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="transporter_info_rt">
                <div className="trans_ifo_inn">
                  <div className="trans_con_hed">
                    <div className="trans_con_hed_lt">
                      <h6>{(() => {
                        switch (user_type) {
                          case "Shipper":
                            return 'Shipper'
                          case "Transporter":
                            return 'Transporter'
                          case "Receiver":
                            return 'Receiver'
                          default:
                            return null;
                        }
                      })()} Info</h6>
                    </div>

                  </div>
                  <div className="trans_ifo_inn1">
                    <p>{(() => {
                      switch (user_type) {
                        case "Shipper":
                          return (profile_details.business_type == 'Organization' ? 'Firm Name' : 'Shipper Name')
                        case "Transporter":
                          return 'Firm Name'
                        case "Receiver":
                          return 'Name'
                        default:
                          return null;
                      }
                    })()}</p>
                    <h6>{
                      (() => {
                        switch (user_type) {
                          case "Shipper":
                            return (profile_details.business_type == 'Organization' ? localStorage.getItem('firm_name') : localStorage.getItem('first_name'))
                          case "Transporter":
                            return (profile_details.register_type == 'Individual' ? localStorage.getItem('first_name') : localStorage.getItem('firm_name'))
                          case "Receiver":
                            return localStorage.getItem('name')
                          case "Manager":
                            return localStorage.getItem('first_name')
                          default:
                            return null;
                        }
                      })()

                    }</h6>
                  </div>
                  {user_type == "Shipper" ?
                    <> <div className="trans_ifo_inn1">
                      <p>Address</p>
                      <h6>{profile_details?.office_address}</h6>
                    </div></> : <>
                      {getAddressList.length > 0 && getAddressList.map((item) => {
                        return (
                          <div className="trans_ifo_inn1">
                            <p>{item?.address_name}</p>
                            <h6>{item?.address_location}</h6>
                          </div>
                        )
                      })}

                    </>}

                </div>
              </div>
              {user_type != 'Receiver' &&
                <div className="rating_review">
                  <div className="trans_ifo_inn">
                    <div className="trans_con_hed">
                      <div className="trans_con_hed_lt mb-3">
                        <h6>Rating and Reviews</h6>
                      </div>
                    </div>
                    {ratingList.length > 0 ? ratingList.map((item) => {
                      return (
                        <div className="rating_sec">
                          <div className="rating_star">
                            <Rating
                              initialValue={item?.rating}
                              allowFraction={true}
                              readonly
                              size={18}
                            />
                          </div>
                          <div className="rating_img">
                            <img src={item?.sender_profile_image} alt="" />
                          </div>
                          <div className="rating_txt">
                            <h5>{item?.review_from}</h5>
                            <p>
                              {item?.review}
                            </p>
                            <span>{item?.created}</span>
                          </div>
                        </div>
                      )
                    }) : <span>No data found</span>}



                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div >
      {openModalForOTP.visible == true ? <VerifyOTP otpFor={openModalForOTP} show={openModalForOTP.visible} onHide={() => setopenModalForOTP({ visible: !openModalForOTP.visible })} otpModalCloseForOTP={_otpModalCloseForOTP} onChangeSomeState={onChangeSomeState} /> : ""}


      {subscriptionModalOpen && <ReNewSubscription show={_openSubscriptionModal} onHide={_closeSubscriptionModal} />}




    </>

  );
};
export default Myaccount;
