/**
 * @file_purpose  page for showing vehicle list
 * @author Sarmistha Mondal
 * @Date_Created 05/12/2023
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim, isEmpty, checkNull
} from "../../../Utility/Helper";
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import TataBg from "../../../assets/images/tat_bg.png"
import Vlogo from "../../../assets/images/TataMotors.png";
import VehicleDoc from "../../../assets/images/document.png"
import AddVehical from "./addVehical";
import Modal from "react-bootstrap/Modal";
import Select from 'react-select';


export default function OfficeVehicleList() {
    const navigate = useNavigate();
    let formData = new FormData();
    const inputRef = useRef(null);
    const [_filteredData, _setFilteredData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [Id, setId] = useState();
    const [submitDisable, setSubmitDisable] = useState(false);
    const [visible, setVisible] = useState()
    const [getOfficeVehicleList, setGetOfficeVehicleList] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [vehicleId, setVehicleId] = useState();
    const [categoryData, setcategoryData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [vehicleFormData, setVehicleFormData] = useState({
        vehicle_id: "",
        vin_chassis_number: "",
        vehicle_make: "",
        vehicle_type: "",
        vehicle_model: "",
        purchase_date: "",
        year: "",
        vehicle_plate_number: "",
        status: "",
        load_type: "",
        vehicle_category_id: ""
    });
    const [is_vehicle_plate_number_verified, setIs_Vehicle_plate_number_verified] = useState(0);
    const [errorValue, setErrorValue] = useState({
        vehicle_plate_number: "",
        vehicle_image: "",
        insurance_document: "",
        load_type: "",
        vehicle_category_id: ""
    });
    const [formTouchValue, setFormTouchValue] = useState({
        vehicle_plate_number: false,
        tracking_device_iemi: false,
        vehicle_image: false,
        insurance_document: false,
        load_type: false,
        vehicle_category_id: false
    })
    const [inputDisable, setInputDisable] = useState({
        vin_chassis_number: "",
        vehicle_make: "",
        vehicle_type: "",
        vehicle_model: "",
        purchase_date: "",
        year: "",
        is_vehicle_plate_number_verified: "",
        vehicle_group: "",
        vehicle_plate_number: "",
        vehicle_image: "",
        insurance_document: "",
        trackingDetails: "",
        load_type: "",
        vehicle_category_id: ""
    })
    const [vehicle_category_id, setvehicle_category_id] = useState({ id: '' })
    const [trackingDetails, setTrackingDetails] = useState()
    const [images, setImages] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [insuranceFiles, setInsuranceFiles] = useState([])
    const [insuranceImg, setInsuranceImg] = useState([])
    const [upadate, setUpdate] = useState(false);
    const [newImg, setNewImg] = useState([]);
    const [afterRemoveImg, setAfterRemoveImg] = useState([]);
    const [uploadeNewImg, setUploadeNewImg] = useState([]);
    const selectedFIles = [];
    const insuranceFile = [];

    /* upload vehicle img*/
    const handleMultipleImages = (e) => {
        let margeImg;
        let files = e.target.files[0];
        // console.log(files);
        e.target.value = '';
        fileNames.push(files)
        setFileNames(fileNames)
        if (fileNames.length <= 4) {
            const targetFilesObject = [...fileNames]
            targetFilesObject.map((file) => {
                return selectedFIles.push(URL.createObjectURL(file))
            })

        }
        let temp = localStorage.getItem(`_removeImg`);
        if (isEmpty(temp)) {
            if (newImg != null) {
                margeImg = [...newImg, ...selectedFIles];
                setUploadeNewImg(margeImg)
            } else {
                margeImg = [...selectedFIles];
                setUploadeNewImg(margeImg)
            }
        } else {
            margeImg = [...afterRemoveImg, ...selectedFIles]
            setUploadeNewImg(selectedFIles)
        }

        setImages(margeImg);
        setInputDisable((prev) => {
            return { ...prev, vehicle_image: !inputDisable.vehicle_image };
        })
    }

    const removeImg = (e, index, name) => {
        // console.log(e, index, name);
        e.preventDefault();
        let temp = localStorage.getItem(`_removeImg`);
        // console.log(temp);
        if (isEmpty(temp)) {
            localStorage.setItem(`_removeImg`, JSON.stringify(name))
        } else {
            temp = temp + ',' + JSON.stringify(name);
            localStorage.setItem(`_removeImg`, temp)
        }
        let files = [...images];
        files.splice(index, 1);
        inputRef.current.value = "";
        // console.log('files', files);
        // console.log('images', images);
        // setInsuranceImg(files)
        setAfterRemoveImg(files)
        setImages(files)
        // setNewImg(files)
        setInputDisable((prev) => {
            return { ...prev, vehicle_image: !inputDisable.vehicle_image };
        })
    }

    useEffect(() => {
    }, [newImg])

    useEffect(() => {
        if (trackingDetails == 'full') {
            if (trackingDetails == 'full' && is_vehicle_plate_number_verified == 1 && vehicle_category_id.id == '') checkError("vehicle_category_id");
        } else {
            setErrorValue((err) => {
                return { ...err, vehicle_category_id: '' }
            })
        }

    }, [trackingDetails,]);

    /* upload document*/
    const handleDocumentImages = (e) => {
        let files = e.target.files[0];
        e.target.value = '';
        insuranceFiles.push(files)
        setInsuranceFiles(insuranceFiles)
        const targetFilesObject = [...insuranceFiles]
        targetFilesObject.map((file) => {
            return insuranceFile.push(URL.createObjectURL(file))
        })
        setInsuranceImg(insuranceFile)
        setInputDisable((prev) => {
            return { ...prev, insurance_document: !inputDisable.insurance_document };
        })
    }

    /* remove image*/
    const removeFile = (e, name) => {
        e.preventDefault();
        let temp = localStorage.getItem(`_removeFileDoc${vehicleFormData.id}`);
        if (isEmpty(temp)) {
            localStorage.setItem(`_removeFileDoc${vehicleFormData.id}`, JSON.stringify(name))
        } else {
            temp = temp + ',' + JSON.stringify(name);
            localStorage.setItem(`_removeFileDoc${vehicleFormData.id}`, temp)
        }

        let files = [...insuranceImg];
        files.splice(0, 1);
        setInsuranceImg(files)
        setInputDisable((prev) => {
            return { ...prev, insurance_document: !inputDisable.insurance_document };
        })

    }


    /* on change input function */
    const formChange = (event) => {
        const { name, value, } = event.target;

        setVehicleFormData((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });

    };

    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !vehicleFormData[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (vehicleFormData[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)

                };

            });
        }
    };


    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setVehicleFormData((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };

    /**
        * @author Sarmistha Mondal
        * @Date_Created 13/06/2024
        * @Date_Modified
        * @function async
        * @functionName loadVehicleCategoryList
        * @functionPurpose this function gets the transporter.
        *
        * @functionParam {payload object:}
        *
        * @functionSuccess Success status and message.
        *
        * @functionError {Boolean} error is there.
        * @functionError {String} message  Description message.
        */

    const loadVehicleCategoryList = () => {
        Service.apiPostTokenCallRequest(RouteURL.getVehicleCategory, {})
            .then((res) => {
                if (res.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let _res = res.data;
                    var arrCategory = []
                    _res.map((item, index) =>
                        arrCategory.push({
                            value: item.vehicle_category_id,
                            label: item.vehicle_category,
                        })
                    );
                    setcategoryData(arrCategory);

                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
    }


    /* choose type */
    const onClickTrackingDetails = (e, type) => {
        e.preventDefault();
        if (type == 'partial') setvehicle_category_id((prevFormValue) => { return { ...prevFormValue, id: "", } })
        setTrackingDetails(type)
    }

    /* select vehicle category*/
    const handleChangeCategory = (e) => {
        setvehicle_category_id((prevFormValue) => {
            return { ...prevFormValue, id: e.value };
        });
        setErrorValue((err) => {
            return { ...err, vehicle_category_id: '' }
        })
    };

    useEffect(() => {
        _onboardingOfficeVehicleList()
        loadVehicleCategoryList()
    }, []);

    /**
   * @author Sarmistha Mondal
   * @Date_Created 07/03/2024
   * @Date_Modified 
   * @function async
   * @functionName _onboardingOfficeVehicleList
   * @functionPurpose this function for onboarding Office Vehicle List.
   *
   * @functionParam {}
   *
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
    const _onboardingOfficeVehicleList = () => {
        Service.apiPostTokenCallRequest(RouteURL.vehicleList, {}).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let details = response.data.vehicleList;
                setGetOfficeVehicleList(details.length)
                setFilteredData(details)
                _setFilteredData(details);
                if (details.length > 0) {
                    setId(details[0].id)
                    setVehicleFormData(details[0])
                    setIs_Vehicle_plate_number_verified(details[0].is_vehicle_plate_number_verified)
                    setInsuranceImg(details[0].insurance_document)
                    setImages(details[0].vehicle_image)
                    setNewImg(details[0].vehicle_image)
                    setTrackingDetails(details[0].load_type)
                    setvehicle_category_id({ id: details[0].vehicle_category_id })
                }
                setInputDisable((prevFormValue) => {
                    return {
                        ...prevFormValue, vin_chassis_number: "",
                        vehicle_make: "",
                        vehicle_type: "",
                        vehicle_model: "",
                        purchase_date: "",
                        year: "",
                        is_vehicle_plate_number_verified: "",
                        vehicle_group: "",
                        vehicle_plate_number: "",
                        vehicle_image: "",
                        insurance_document: "",
                        trackingDetails: "",
                        load_type: "",
                        vehicle_category_id: ""
                    };
                });


            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });
    }

    /* Validation Checking */
    const ValidateForm = (e) => {
        e.preventDefault();
        setFormTouchValue(markAllAsTouched(formTouchValue));


        delete vehicleFormData.assign_driver;
        delete vehicleFormData.available_status;
        delete vehicleFormData.is_deleted
        delete vehicleFormData.is_vehicle_plate_number_verified
        delete vehicleFormData.purchase_date
        delete vehicleFormData.transporter_id
        delete vehicleFormData.can_deleted
        delete vehicleFormData.vehicle_group
        delete vehicleFormData.vehicle_make
        delete vehicleFormData.vehicle_model
        delete vehicleFormData.vehicle_plate_number
        delete vehicleFormData.year
        delete vehicleFormData.vin_chassis_number
        delete vehicleFormData.vehicle_type
        delete vehicleFormData.insurance_document
        delete vehicleFormData.vehicle_image
        delete vehicleFormData.updated_at
        delete vehicleFormData.tracking_device_iemi
        delete vehicleFormData.load_type
        delete vehicleFormData.vehicle_category_id
        delete vehicleFormData.vehicle_category

        if (trackingDetails == 'full' && !vehicle_category_id.id && is_vehicle_plate_number_verified == 1) { checkError("vehicle_category_id"); }
        if (isFormValid(vehicleFormData) && images?.length > 0) {
            if (trackingDetails === 'full' && vehicle_category_id.id != null) _UpdateVehicalSubmit(e)
            else if (trackingDetails === 'partial') _UpdateVehicalSubmit(e)
        } else console.log('no');
    };


    /**
     * @author Sarmistha Mondal
     * @Date_Created 08/03/2024
     * @Date_Modified 
     * @function async
     * @functionName _UpdateVehicalSubmit
     * @functionPurpose this function for update vehical .
     *
     * @functionParam {}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _UpdateVehicalSubmit = (e) => {
        e.preventDefault();

        formData.set(`load_type`, trackingDetails == 'full' ? 'full' : 'partial');
        if (trackingDetails == 'full') {
            formData.set(`vehicle_category_id`, vehicle_category_id.id)
        }
        formData.set(`vehicle_id`, String(vehicleFormData.id));
        formData.set(`status`, vehicleFormData.status);

        let tempImg = localStorage.getItem('_removeImg');
        if (isEmpty(tempImg)) {
            formData.set(`deleted_image`, '');
        } else {
            tempImg = localStorage.getItem('_removeImg').split(',').map(e => JSON.parse(e));
            formData.set(`deleted_image`, JSON.stringify(tempImg));
        }

        let temp = localStorage.getItem(`_removeFileDoc${vehicleFormData.id}`);
        if (isEmpty(temp)) {
            formData.set(`deleted_document`, '');
        } else {
            let _temp = localStorage.getItem(`_removeFileDoc${vehicleFormData.id}`);
            formData.set(`deleted_document`, new URL(JSON.parse(_temp)).pathname.split('/')[1]);
        }

        for (let i = 0; i < fileNames.length; i++) {
            formData.append(`vehicle_image`, fileNames[i]);
        }

        for (let i = 0; i < insuranceFiles.length; i++) {
            formData.set(`insurance_document`, insuranceFiles[i]);
        }


        setSubmitDisable(true)

        Service.apiPostCallFormDataRequest(RouteURL.vehicleUpdate, formData).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                localStorage.removeItem(`_removeFileDoc${vehicleFormData.id}`);
                localStorage.removeItem('_removeImg')
                _onboardingOfficeVehicleList()
                toast.success(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });

            } else {

                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        }).catch((error) => {
            console.log(error);
            setSubmitDisable(false);
        });

    }

    /* select vehicle*/
    const ToggleButton = (openIndex, i, item) => {
        if (openIndex) {
            setVehicleFormData(item);
            setId(item.id);
            setInsuranceImg(item.insurance_document)
            setImages(item.vehicle_image)
            setNewImg(item.vehicle_image)
            setTrackingDetails(item.load_type)
            setvehicle_category_id({ id: item.vehicle_category_id })

        }
    };

    /* close modal*/
    const closeAddEditModal = () => {
        setVisible(false)
    };

    /*after modal close get api call*/
    const onChangeSomeState = (State) => {
        if (!!State) {
            _onboardingOfficeVehicleList();
        }
    };

    /* search vehicle*/
    const handleSearch = (event) => {
        let value = event.target.value.toLowerCase();
        let result = [];
        //|| (Helpers.checkNull(data.status_name).toLowerCase().search("^"+value) != -1 )
        if (value != "") {
            result = filteredData.filter((data) => {
                if (
                    checkNull(data.vehicle_plate_number).toLowerCase().search(value) != -1
                ) {
                    return true;
                }// else console.log(value);
                return false;
            });
            // console.log(result);
            setFilteredData(result);
        } else setFilteredData(_filteredData);
    };

    /* open delete modal*/
    const _deleteVehicle = (e, id) => {
        e.preventDefault();
        setDeleteModal(true)
        setVehicleId(id)
    }


    /**
    * @author Sarmistha Mondal
    * @Date_Created 13/03/2024
    * @Date_Modified 
    * @function async
    * @functionName _confirmDeleteVehicle
    * @functionPurpose this function for delete vehicle.
    *
    * @functionParam {vehicle_id}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _confirmDeleteVehicle = () => {
        let params = JSON.stringify({
            vehicle_id: String(vehicleId),
        })
        setDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.vehicleDelete, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    _onboardingOfficeVehicleList();
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setDisable(false);
                            setDeleteModal(false)
                        },
                    });

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setDisable(false);
                            setDeleteModal(false)
                        },
                    });
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    setDisable(false);
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }


    return (
        <>

            <div className="sideright_menu">
                <div className="shipment_menu_rt">

                    <div className="godown_upper_hed">
                        <div className="row">
                            <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-12 col-md-12">
                                <div className="godown_upper_hed_lt">
                                    <h4>My Vehicles</h4>
                                </div>
                            </div>
                            <div className="col-sm-12 col-xxl-7 col-xl-7 col-lg-12 col-md-12">
                                <div className="godown_upper_hed_rt">
                                    <div className="office_field"><button type="button"
                                        onClick={(e) => { e.preventDefault(); setVisible(!visible) }}
                                    ><i className="fa-solid fa-plus"></i> Add Vehicles </button></div>
                                    <div className="delivery_search">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input type="search" placeholder="Search For Vehicle Plate Number..."
                                            onChange={(event) => handleSearch(event)} /></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="godwn_dwn">
                        <div className="row">
                            <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-4 col-md-12 ">
                                <div className='vehicle_track_sec'>
                                    <div className='godown_office_main'>
                                        {filteredData.map((item, key) => {
                                            return (<>
                                                <div className={`${Id == item.id ? `godown_office_box office_box_select` : `godown_office_box `}`} onClick={() => { ToggleButton(item.id, key, item); }}>
                                                    <div className="vehicle_number_lt">
                                                        <span className="godown_office_box_upper_img">
                                                            <img src={item?.vehicle_image != null ? item?.vehicle_image[0] : Vlogo} alt="" />

                                                        </span>
                                                        <div className="godown_office_box_upper">
                                                            <h6 style={{ color: Id == item.id ? ' #FBAA2A' : '#000' }}>{item.vehicle_plate_number}</h6>
                                                            <span style={{ color: item?.status == 'Active' ? ' #2ED573' : '#928FA9' }}>{item?.status}</span>
                                                            <p><span>{item.vehicle_type}</span><br /> {item.vehicle_model}</p>
                                                        </div>
                                                    </div>
                                                    <div className="godown_office_box_dwn">
                                                        <p>Assigned by Main Office</p>
                                                        <p className="mt-3"><br />
                                                            {/* <a href="javascript:void(0)" style={{ textDecoration: 'none' }}> {item.vehicle_plate_number}</a> */}
                                                        </p>
                                                        <div className="godwn_arrow">
                                                            {
                                                                Id == item.id ?
                                                                    <i className="fa-solid fa-angle-left" style={{
                                                                        color:
                                                                            Id == item.id ? ' #FBAA2A' : '#000'
                                                                    }} /> :
                                                                    <i className="fa-solid fa-angle-right" />}

                                                        </div>
                                                    </div>

                                                </div>
                                            </>)
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 cl-xxl-8 col-xl-8 col-lg-8 col-md-12 ">
                                <div className="office_address_lt vehicle_gap">
                                    <div className="row">
                                        <div className="col-6"><h6>Vehicles Details :<span style={{ color: '#FBAA2A' }}> {vehicleFormData.vehicle_plate_number}</span></h6>

                                            <div className="form-check form-switch vehicle_switch" >
                                                <label className="form-check-label" >{vehicleFormData.status}</label>
                                                <input className="form-check-input" type="checkbox"
                                                    checked={vehicleFormData.status == 'Active' ? true : false}
                                                    onChange={(e) => {
                                                        setUpdate(!upadate)
                                                        if (e.target.checked != true) {
                                                            vehicleFormData.status = 'Inactive';
                                                        } else {
                                                            vehicleFormData.status = 'Active';
                                                        }

                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-6 d-flex flex-row-reverse text-secondary">
                                            <i className="fa-solid fa-trash-can" title="Delete"
                                                onClick={(e) => _deleteVehicle(e, vehicleFormData.id)}
                                            /></div>
                                    </div>
                                    {/* <h6 >Vehicles Details :<span style={{ color: '#FBAA2A' }}> {vehicleFormData.vehicle_plate_number}</span>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-check form-switch vehicle_switch" >
                                                    <label className="form-check-label" >{vehicleFormData.status}</label>
                                                    <input className="form-check-input" type="checkbox"
                                                        checked={vehicleFormData.status == 'Active' ? true : false}
                                                        onChange={(e) => {
                                                            setUpdate(!upadate)
                                                            if (e.target.checked != true) {
                                                                vehicleFormData.status = 'Inactive';
                                                            } else {
                                                                vehicleFormData.status = 'Active';
                                                            }

                                                        }}
                                                    />
                                                    
                                                </div>
                                            </div>
                                            <div className="col-6 d-flex flex-row-reverse text-secondary"><i className="fa-solid fa-trash-can" title="Delete"
                                                onClick={(e) => _deleteVehicle(e, vehicleFormData.id)} style={{fontSize:'medium'}}
                                            /></div>
                                        </div>  
                                    </h6> */}
                                    <>  <form >
                                        <div className="row ">
                                            <div className="col">
                                                <form >
                                                    {/* <div className="row">
                                                        
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                            <div className="office_field">
                                                                <label>Vehicle plate number</label>
                                                                <input type="text" placeholder="Enter Plate Number" name="vehicle_plate_number"
                                                                    className="form-control"
                                                                    value={vehicleFormData.vehicle_plate_number}
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    disabled={inputDisable.vehicle_plate_number ? false : true}
                                                                    error={errorValue.vehicle_plate_number ? "true" : "false"}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                />

                                                            </div>
                                                            {errorValue.vehicle_plate_number && (
                                                                <span className="error">
                                                                    {errorValue?.vehicle_plate_number}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                            <div className="office_field">
                                                                <label>Vehicle Type</label>
                                                                <input type="text" placeholder="Vehicle Type" name="vehicle_type"
                                                                    className="form-control"
                                                                    value={vehicleFormData.vehicle_type}
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    disabled={inputDisable.vehicle_type ? false : true} />

                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                            <div className="office_field">
                                                                <label>V/N Chassis Number</label>
                                                                <input type="text" placeholder="V/N Chassis Number" name="vin_chassis_number"
                                                                    className="form-control"
                                                                    value={vehicleFormData.vin_chassis_number}
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    disabled={inputDisable.vin_chassis_number ? false : true} />

                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                            <div className="office_field">
                                                                <label>Vehicle Make</label>
                                                                <input type="text" placeholder="Vehicle Make" name="vehicle_make"
                                                                    className="form-control"
                                                                    value={vehicleFormData.vehicle_make}
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    disabled={inputDisable.vehicle_make ? false : true} />

                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                            <div className="office_field">
                                                                <label>Vehicle Group</label>
                                                                <input type="text" name="vehicle_group"
                                                                    placeholder="Vehicle Group"
                                                                    className="form-control"
                                                                    // placeholder={`${inputDisable.vehicle_group == true ? 'Enter ' : ''}` + `Vehicle Group`}
                                                                    value={checkNull(vehicleFormData.vehicle_group)}
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    disabled={inputDisable.vehicle_group ? false : true} />


                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                            <div className="office_field">
                                                                <label>Vehicle Model</label>
                                                                <input type="text" name="vehicle_model"
                                                                    placeholder="Vehicle Model"
                                                                    className="form-control"
                                                                    value={vehicleFormData.vehicle_model}
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    disabled={inputDisable.vehicle_model ? false : true} />

                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                            <div className="office_field">
                                                                <label>Year</label>
                                                                <input type="number" placeholder=" Year" name="year"
                                                                    className="form-control"
                                                                    value={vehicleFormData.year}
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    disabled={inputDisable.year ? false : true} />

                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                                                            <div className="office_field">
                                                                <label>Purchase Date</label>
                                                                <input type="text" placeholder="Purchase Date" name="purchase_date"
                                                                    className="form-control"
                                                                    value={vehicleFormData.purchase_date}
                                                                    onChange={formChange}
                                                                    onBlur={updateFormTouchValue}
                                                                    disabled={inputDisable.purchase_date ? false : true} />

                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-xxl-6 col-xl-12 col-lg-12 col-md-12">
                                                            <div className="office_field" >
                                                                <h6>Consignment</h6>
                                                                <div className="tracking_btn">
                                                                    <div className="tracksm_btn" >
                                                                        <button type="button" value="full"
                                                                            // disabled={inputDisable.trackingDetails ? false : true}
                                                                            checked={vehicleFormData.load_type}
                                                                            style={{ backgroundColor: (trackingDetails == 'full') ? '#FBAA2A' : '' }} onClick={(e) => onClickTrackingDetails(e, 'full')}>Full Load</button></div>
                                                                    <div className="tracksm_btn">
                                                                        <button type="button" value="partial"
                                                                            // disabled={inputDisable.trackingDetails ? false : true}
                                                                            checked={vehicleFormData.load_type}
                                                                            style={{ backgroundColor: trackingDetails == 'partial' ? '#FBAA2A' : '' }}
                                                                            onClick={(e) => onClickTrackingDetails(e, 'partial')}>Partial Load</button></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {trackingDetails == 'full' &&
                                                            <div className="col-sm-6">
                                                                <div className="office_field">
                                                                    <label>Vehicle Category</label>
                                                                    <Select
                                                                        className="dropdown"
                                                                        name="vehicle_category_id"
                                                                        placeholder="Select Option"
                                                                        defaultValue={categoryData.filter((x) => x.value == vehicleFormData.vehicle_category_id)}
                                                                        isSearchable={false}
                                                                        options={categoryData}
                                                                        onChange={(e) => handleChangeCategory(e)}
                                                                        disabled={inputDisable.vehicle_category_id ? false : true}
                                                                    />
                                                                    {/* <span className="verify_sec"><a href="javascript:void(0)"><i
                                                                        onClick={() => {
                                                                            setInputDisable((prev) => {
                                                                                return { ...prev, vehicle_category_id: !inputDisable.vehicle_category_id };
                                                                            })
                                                                        }}
                                                                        className='fa fa-pencil'></i></a>
                                                                    </span> */}
                                                                </div>
                                                                {errorValue.vehicle_category_id && (
                                                                    <span className="error">
                                                                        {errorValue?.vehicle_category_id}
                                                                    </span>
                                                                )}
                                                            </div>}


                                                    </div>
                                                </form>
                                                <div className="col-sm-12 col-xxl-8 col-xl-12">
                                                    <div className="office_address_rt">

                                                        <div className="vehicle_rt_box lorry_upload">
                                                            <div className="vehicle_txt">
                                                                <h6>Add Vehicle Image <span><a href="javascript:void(0)"> Total image: {images?.length}</a></span></h6>
                                                            </div>
                                                            <div className="row">

                                                                {images?.map((url, index) => {
                                                                    return (<div className="col-sm-6 col-6">
                                                                        <div className="tata_moto_sec">
                                                                            <span onClick={(e) => removeImg(e, index, url)}><i className="fa-regular fa-trash-can"></i></span>
                                                                            <img src={url} alt="" />
                                                                        </div>
                                                                    </div>)
                                                                })}
                                                                {(images == null || images?.length < 4) &&
                                                                    <div className="col-sm-6 col-6">
                                                                        <div className="tata_moto_sec" >
                                                                            <input type="file" accept=".jpg,.jpeg,.png"
                                                                                onChange={handleMultipleImages}
                                                                                ref={inputRef}
                                                                            />

                                                                            <img src={TataBg} alt="" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="vehicle_rt_box lorry_upload">
                                                            <div className="vehicle_txt">
                                                                <h6>Insurance Document (Optional)</h6>
                                                                <p>Total Docs: {insuranceImg?.length}</p>
                                                            </div>
                                                            <div className="row">

                                                                {insuranceImg != null && insuranceImg?.map((file) => {
                                                                    return (<div className="col-sm-6 col-6">
                                                                        <div className="vehicle_doc">
                                                                            <span onClick={(e) => removeFile(e, file)}><i className="fa-regular fa-trash-can"></i></span>


                                                                            <img src={VehicleDoc} alt="" />
                                                                            <p> Licences</p>
                                                                        </div>
                                                                    </div>
                                                                    )
                                                                })}

                                                                {(insuranceImg == null || (insuranceImg?.length < 1)) &&
                                                                    <div className="col-sm-6 col-6">
                                                                        <div className="tata_moto_sec">
                                                                            <input type="file" accept=".jpg,.jpeg,.png"
                                                                                onChange={handleDocumentImages}
                                                                            />

                                                                            <img src={TataBg} alt="" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="col-sm-6"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>

                                    </>

                                </div>
                                <div className="col-sm-12">
                                    <div className="office_field mt-4"><button type="button"
                                        onClick={ValidateForm}
                                        // disabled={inputDisable.vehicle_category_id == true ? (submitDisable ? true : false) : true}
                                        style={{ background: '#000' }}
                                    // disabled={Object.values(inputDisable).some(x => x !== '') == true ? (submitDisable ? true : false) : true}
                                    // style={{ background: (Object.values(inputDisable).some(x => x !== '') == true ? '#000' : '#C5C4D2') }}
                                    >Save</button></div>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {visible && <Modal
                show={visible}
                onHide={() => setVisible(false)}
                backdrop="static"
                // aria-labelledby="ModalHeader"
                size="xl"
            > <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}> <h6 className="hed_txt">Add Vehical
                    </h6></Modal.Header>
                </div>
                <AddVehical Type={1} CloseAddEditModal={closeAddEditModal}
                    onChangeSomeState={onChangeSomeState} />

            </Modal>}

            {/* delete vehicle */}
            {deleteModal && <Modal
                show={deleteModal}
                onHide={() => setDeleteModal(false)}
                backdrop="static"
            > <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}> <h6 className="hed_txt">Delete Vehicles
                    </h6></Modal.Header>
                </div>
                <div className="task_modal_sec">
                    <div className="row">
                        <div className="col">
                            <div className="office_address_lt" >
                                <p>Do you want to delete this vehicles?
                                </p>
                                <div className='reco_trans_rt_inner_dwn' style={{ justifyContent: 'center' }}>
                                    <div>
                                        <button type='' value="reject"
                                            onClick={() => setDeleteModal(false)}
                                            style={{ background: '#fff', color: '#000' }}
                                        >Cancel</button></div>
                                    <div><button type='reject' value="reject"
                                        onClick={(e) => _confirmDeleteVehicle(e)}
                                        disabled={disable ? true : false}
                                        style={{ background: '#000', color: '#fff' }}
                                    >Delete</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>}
        </>
    );
}
