/**
 * @file_purpose  page for showing office address list
 * @author Sarmistha Mondal
 * @Date_Created 05/12/2023
 * @Date_Modified 13/12/2023
 */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Constants, REGEX, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim, removeSpecialCharacters,
    checkLength, checkNull
} from "../../../Utility/Helper";
import Modal from "react-bootstrap/Modal";
import AddGodownAddressList from "./addGodown";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import OnboardingLocationModal from "../../onboarding/onboardingLocation";


const mapStyle = {
    height: '300px',
    width: '100%'
}
export default function OfficeAddressList() {
    const mapRef = useRef();
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [_filteredData, _setFilteredData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [disable, setDisable] = useState(false);
    const [godownId, setGodownId] = useState();
    const [Id, setId] = useState();
    const [openModal, setopenModal] = useState(false)
    const [inputDisable, setInputDisable] = useState({
        address_name: '',
        address_location: '',
        contact_person_name: '',
        contact_number: '',
        alternate_contact_number1: '',
        alternate_contact_number2: '',
    })
    const [submitDisable, setSubmitDisable] = useState(false);
    const [visible, setVisible] = useState(false)
    const [formValue, setFormValue] = useState({
        address_name: '',
        address_location: '',
        contact_person_name: '',
        contact_number: '',
    });
    const [formValue1, setFormValue1] = useState({
        alternate_contact_number1: '',
        alternate_contact_number2: '',
    });
    const [errorValue, setErrorValue] = useState({
        address_name: '',
        address_location: '',
        contact_person_name: '',
        contact_number: '',
        alternate_contact_number1: '',
        alternate_contact_number2: '',
    });
    const [formTouchValue, setFormTouchValue] = useState({
        address_name: false,
        address_location: false,
        contact_person_name: false,
        contact_number: false,
        alternate_contact_number1: false,
        alternate_contact_number2: false,
    });
    const [map, setMap] = React.useState(null)
    const [defaultLocation, setDefaultLocation] = useState({
        lat: '',
        lng: ''
    })
    const [markerPosition, setMarkerPosition] = useState({
        lat: '',
        lng: ''
    })


    useEffect(() => {
        _officeAddressList();
    }, [])

    useEffect(() => {
        setDefaultLocation((prevFormValue) => {
            return {
                ...prevFormValue, lat: defaultLocation?.lat,
                lng: defaultLocation?.lng,
            };
        });
    }, [formValue])

    /**
    * @author Sarmistha Mondal
    * @Date_Created 24/11/2023
    * @Date_Modified 
    * @function async
    * @functionName _officeAddressList
    * @functionPurpose this function for onboarding Office Address List.
    *
    * @functionParam {}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _officeAddressList = () => {

        Service.apiPostTokenCallRequest(RouteURL.godownAddressList, {}).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.addressList.length > 0) {
                    let result = response.data.addressList.map(({ is_deleted, is_verified, updated, ...rest }) => ({
                        ...rest,
                    }));
                    let res = response.data.addressList;
                    setFormValue(res[0])
                    setId(res[0].id)
                    setFormValue1({
                        alternate_contact_number1: res[0].alternate_contact_number1,
                        alternate_contact_number2: res[0].alternate_contact_number1,
                    })
                    setDefaultLocation((prevFormValue) => {
                        return {
                            ...prevFormValue, lat: Number(res[0]?.address_lat),
                            lng: Number(res[0]?.address_long),
                        };
                    });
                    setMarkerPosition((prevFormValue) => {

                        return {
                            ...prevFormValue, lat: Number(res[0]?.address_lat),
                            lng: Number(res[0]?.address_long),
                        };
                    });
                    setFilteredData(result)
                    _setFilteredData(result)
                }




            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    /* on change input function */
    const formChange = (event) => {
        const { name, value } = event.target;
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
        setFormValue1((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        })
    };


    /* error message function */
    const checkError = (targetName) => {
        // console.log(!optionalTouchFields[targetName]);
        if (formTouchValue[targetName]) {
            // console.log(formValue[targetName]);
            setErrorValue((prevErrorValue) => {

                return {

                    ...prevErrorValue, [targetName]: formValue[targetName] == "" ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` :
                        (formValue[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)

                };


            });
        }


    };

    useEffect(() => {
        if (formValue) {
            checkError("address_name");
            checkError("address_location");
            checkError("contact_person_name");
            checkError("contact_number");
        }
        // if (!!formValue1.alternate_contact_number1 && !!formTouchValue.alternate_contact_number1 ) {
        //     console.log('ok', formValue, formValue1);
        //     checkError("alternate_contact_number1");
        // } else if (!!formValue1.alternate_contact_number2 && !!formTouchValue.alternate_contact_number2) checkError("alternate_contact_number2");
    }, [formValue, formValue1]);

    useEffect(() => { }, [defaultLocation])

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }
    };


    /**
      * @author Sarmistha Mondal
      * @Date_Created 12/12/2023
      * @Date_Modified 
      * @function async
      * @functionName _officeAddressUpdateSubmit
      * @functionPurpose this function for onboarding office address update submit.
      *
      * @functionParam {address_name,address_location,address_lat,address_long,contact_person_name
      * contact_number,alternate_contact_number1,alternate_contact_number2,address_id}
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */

    const _officeAddressUpdateSubmit = (e) => {
        e.preventDefault();
        setFormTouchValue(markAllAsTouched(formTouchValue));
        delete formValue.updated;
        delete formValue.is_deleted;
        delete formValue.is_verified;
        delete formValue.alternate_contact_number1;
        delete formValue.alternate_contact_number2;
        delete formValue.transporter_id;
        delete formValue.status;
        delete formValue.created;
        delete formValue.can_deleted;
        delete formValue.address_type;
        checkError("address_name");
        checkError("address_location");
        checkError("contact_person_name");
        checkError("contact_number");
        if (isFormValid(formValue)) {

            setSubmitDisable(true)
            let params = JSON.stringify({
                address_name: formValue.address_name,
                address_location: formValue.address_location,
                address_lat: String(markerPosition.lat),
                address_long: String(markerPosition.lng),
                contact_person_name: formValue.contact_person_name,
                contact_number: formValue.contact_number,
                alternate_contact_number1: formValue1.alternate_contact_number1 == undefined ? '' : formValue1.alternate_contact_number1,
                alternate_contact_number2: formValue1.alternate_contact_number2 == undefined ? '' : formValue1.alternate_contact_number2,
                address_id: String(formValue.id)
            });

            Service.apiPostTokenCallRequest(RouteURL.godownAddressUpdate, params).then((response) => {

                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                    setInputDisable({
                        address_name: '',
                        address_location: '',
                        contact_person_name: '',
                        contact_number: '',
                        alternate_contact_number1: '',
                        alternate_contact_number2: '',
                    })
                    _officeAddressList();

                } else {

                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setSubmitDisable(false);
                        },
                    });
                }
            }).catch((error) => {
                console.log(error);
                setSubmitDisable(false);
            });
        }
    };

    /* close modal*/
    const closeAddEditModal = () => {
        setVisible(false)
    };

    /*after modal close get api call*/
    const onChangeSomeState = (State) => {
        if (!!State) {
            _officeAddressList();
        }
    };

    /* godown address delete*/
    const _deleteGodown = (e, id) => {
        e.preventDefault();
        setDeleteModal(true)
        setGodownId(id)
    }

    /**
     * @author Sarmistha Mondal
     * @Date_Created 13/03/2024
     * @Date_Modified 
     * @function async
     * @functionName _confirmDeleteGodownAddress
     * @functionPurpose this function for onboarding office godown delete .
     *
     * @functionParam {address_id}
     *
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
    const _confirmDeleteGodownAddress = () => {
        let params = JSON.stringify({
            address_id: String(godownId),
        })
        setDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.godownAddressDelete, params).then(
            (response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

                    _officeAddressList();
                    toast.success(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setDisable(false);
                            setDeleteModal(false)
                        },
                    });

                } else {

                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClose: () => {
                            setDisable(false);
                            setDeleteModal(false)
                        },
                    });
                }
            }).catch((error) => {
                // console.log(error);
                if (error.response.status === 401) {
                    setDisable(false);
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /* for view godown details*/
    const ToggleButton = (openIndex, i, item) => {
        if (openIndex) {
            setFormValue(item);
            setFormValue1(item)
            getAddress(Number(item?.address_lat), Number(item?.address_long))
            setDefaultLocation((prevFormValue) => {
                return {
                    ...prevFormValue, lat: Number(item?.address_lat),
                    lng: Number(item?.address_long),
                };
            });
            setMarkerPosition((prevFormValue) => {
                return {
                    ...prevFormValue, lat: Number(item?.address_lat),
                    lng: Number(item?.address_long),
                };
            });
            setId(item.id);

        }
    };

    /* search godown*/
    const handleSearch = (event) => {
        let value = event.target.value.toLowerCase();
        let result = [];
        if (value != "") {
            result = filteredData.filter((data) => {
                if (
                    checkNull(data.address_name).toLowerCase().search(value) != -1
                ) {
                    return true;
                }
                return false;
            });

            setFilteredData(result);
        } else setFilteredData(_filteredData);
    };


    /* map load*/
    const onLoad = useCallback((map) => {

        mapRef.current = map;
        if (navigator.geolocation) {
            // get the current users location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;

                },
                // if there was an error getting the users location
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );

        }

        if (defaultLocation.lat) {
            const bounds = new window.google.maps.LatLngBounds({
                lat: defaultLocation?.lat,
                lng: defaultLocation?.lng

            });
            console.log(bounds);
            map?.fitBounds(bounds);
            setMap(map)
        }
    }, [])

    /* get address*/
    const getAddress = (lat, lng) => {
        let geocoder = new window.google.maps.Geocoder();
        let latlng = new window.google.maps.LatLng(lat, lng);
        geocoder.geocode({ 'latLng': latlng }, function (results, status) {
            //  && results[0].geometry.location_type == ("ROOFTOP" || "APPROXIMATE")
            if (status == 'OK') {
                const address = results[0].formatted_address;
                for (var i = 0; i < results[0].address_components.length; i++) {
                    for (var j = 0; j < results[0].address_components[i].types.length; j++) {
                        if (results[0].address_components[i].types[j] == "locality") {

                            setFormValue((prevFormValue) => {
                                return {
                                    ...prevFormValue, address_name: results[0]?.address_components[i]?.short_name,
                                    address_location: results[0].formatted_address,
                                };
                            });
                            setMarkerPosition((prevFormValue) => {
                                return {
                                    ...prevFormValue, lat: lat,
                                    lng: lng,
                                };
                            });
                            setDefaultLocation((prevFormValue) => {
                                return {
                                    ...prevFormValue, lat: lat,
                                    lng: lng,
                                };
                            });

                        }
                    }
                }

            }

        });
    }

    /* close modal */
    const onCloseModal = (data) => {
        if (data?.coords) {
            getAddress(data.coords.latitude, data.coords.longitude)
        } else if (data?.location) {
            getAddress(data.location.lat(), data.location.lng())
        } else if (data?.geometry) {
            getAddress(data.geometry.location.lat(), data.geometry.location.lng())
        }
    }




    return (
        <>

            <div className="sideright_menu">
                <div className="shipment_menu_rt">
                    <div className="godown_upper_hed">
                        <div className="row">
                            <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                                <div className="godown_upper_hed_lt">
                                    <h4>My Godown</h4>
                                </div>
                            </div>
                            <div className="col-sm-12 col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                                <div className="godown_upper_hed_rt">
                                    <div className="office_field"><button type="button" onClick={(e) => { e.preventDefault(); setVisible(!visible) }}><i className="fa-solid fa-plus"></i> Add godown </button></div>
                                    <div className="delivery_search"><i className="fa-solid fa-magnifying-glass"></i><input type="search" placeholder="Search For Godown Name..." onChange={(event) => handleSearch(event)} /></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="godwn_dwn">
                        <div className="row">
                            <div className="col-sm-12 col-xxl-4 col-xl-4 col-lg-12 col-md-12">

                                <div className='godown_office_main'>
                                    {filteredData.map((item, key) => {
                                        return (<>
                                            {/* item.isActive == true && */}
                                            <div className={`${Id == item.id ? `godown_office_box office_box_select` : `godown_office_box `}`} onClick={() => { ToggleButton(item.id, key, item); }}>
                                                <div className="godown_office_box_upper">
                                                    <h6 style={{
                                                        color:
                                                            Id == item.id ? ' #FBAA2A' : '#000'
                                                    }}>Godown Office</h6>
                                                    <p>{item.address_name}<br /> {item.address_location}</p>
                                                </div>
                                                <div className="godown_office_box_dwn">
                                                    <p>{item.address_type}</p>
                                                    <p className="mt-3">{item.contact_person_name}<br /><a href="javascript:void(0)" style={{ textDecoration: 'none' }}>{item.contact_number}</a></p>
                                                    <div className="godwn_arrow">
                                                        {
                                                            Id == item.id ?
                                                                <i className="fa-solid fa-angle-left" style={{
                                                                    color:
                                                                        Id == item.id ? ' #FBAA2A' : '#000'
                                                                }} /> :
                                                                <i className="fa-solid fa-angle-right" />}

                                                    </div>
                                                </div>

                                            </div>
                                        </>)
                                    })}
                                </div>
                            </div>
                            <div className="col-sm-12 col-xxl-8 col-xl-8 col-lg-12 col-md-12">
                                <div className="office_address_lt">
                                    <div className="row">
                                        <div className="col-6"><h6>Address Details</h6></div>
                                        <div className="col-6 d-flex flex-row-reverse text-secondary"><i className="fa-solid fa-trash-can" title="Delete"
                                            onClick={(e) => _deleteGodown(e, formValue.id)}
                                        /></div>
                                    </div>


                                    <form >
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="office_field">
                                                    <label htmlFor="staticEmail" >Address Name
                                                    </label>

                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="address_name"
                                                        placeholder="Enter Address Name"
                                                        // onChange={formChange}
                                                        onClick={() => { setopenModal(true); }}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValue.address_name}
                                                        error={errorValue.address_name ? "true" : "false"}
                                                        disabled={inputDisable?.address_name ? false : true}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                    />
                                                    <span className="verify_sec"><a href="javascript:void(0)"><i
                                                        onClick={() => {
                                                            setInputDisable((prev) => {
                                                                return { ...prev, address_name: !inputDisable.address_name };
                                                            })
                                                        }}
                                                        className='fa fa-pencil'></i></a></span>

                                                    {errorValue.address_name && (
                                                        <span className="error">
                                                            {errorValue.address_name}
                                                        </span>
                                                    )}
                                                </div>

                                            </div>
                                            <div className="col-sm-12">
                                                <div className="office_field">
                                                    <label htmlFor="staticEmail" >Address Line 1
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="address_location"
                                                        placeholder="Enter address location"
                                                        onChange={formChange}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValue.address_location}
                                                        error={errorValue.address_location ? "true" : "false"}
                                                        disabled
                                                    // disabled={inputDisable?.address_location ? false : true}
                                                    />
                                                    {/* <span className="verify_sec"><a href="javascript:void(0)"><i
                                                        onClick={() => {
                                                            setInputDisable((prev) => {
                                                                return { ...prev, address_location: !inputDisable.address_location };
                                                            })
                                                        }}
                                                        className='fa fa-pencil'></i></a></span> */}
                                                    {errorValue.address_location && (
                                                        <span className="error">
                                                            {errorValue.address_location}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="office_field">
                                                    <label htmlFor="staticEmail" >Contact Person Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="contact_person_name"
                                                        placeholder="Enter Contact Person Name"
                                                        onChange={formChange}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValue.contact_person_name}
                                                        error={errorValue.contact_person_name ? "true" : "false"}
                                                        disabled={inputDisable?.contact_person_name ? false : true}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                    />
                                                    <span className="verify_sec"><a href="javascript:void(0)"><i
                                                        onClick={() => {
                                                            setInputDisable((prev) => {
                                                                return { ...prev, contact_person_name: !inputDisable.contact_person_name };
                                                            })
                                                        }}
                                                        className='fa fa-pencil'></i></a></span>
                                                    {errorValue.contact_person_name && (
                                                        <span className="error">
                                                            {errorValue.contact_person_name}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="office_field">
                                                    <label htmlFor="staticEmail" >Contact Number  </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="contact_number"
                                                        placeholder="Enter Contact Number"
                                                        maxLength={10}
                                                        onChange={formChange}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValue.contact_number}
                                                        error={errorValue.contact_number ? "true" : "false"}
                                                        disabled={inputDisable?.contact_number ? false : true}
                                                    />
                                                    <span className="verify_sec"><a href="javascript:void(0)"><i
                                                        onClick={() => {
                                                            setInputDisable((prev) => {
                                                                return { ...prev, contact_number: !inputDisable.contact_number };
                                                            })
                                                        }}
                                                        className='fa fa-pencil'></i></a></span>
                                                    {errorValue.contact_number && (
                                                        <span className="error">
                                                            {errorValue.contact_number}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="office_field">
                                                    <label htmlFor="staticEmail" >Alternate Contact Number 1(Optional)
                                                    </label>

                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="alternate_contact_number1"
                                                        placeholder="Alternate Phone Number 1"
                                                        pattern="[0-9]*"
                                                        maxLength={10}
                                                        onChange={formChange}
                                                        onKeyDown={e => checkLength(e)}
                                                        onKeyUp={removeSpecialCharacters}
                                                        onBlur={updateFormTouchValue}
                                                        value={formValue1.alternate_contact_number1}
                                                        error={errorValue.alternate_contact_number1 ? "true" : "false"}
                                                        disabled={inputDisable?.alternate_contact_number1 ? false : true}
                                                    />
                                                    <span className="verify_sec"><a href="javascript:void(0)"><i
                                                        onClick={() => {
                                                            setInputDisable((prev) => {
                                                                return { ...prev, alternate_contact_number1: !inputDisable.alternate_contact_number1 };
                                                            })
                                                        }}
                                                        className='fa fa-pencil'></i></a></span>
                                                    {errorValue.alternate_contact_number1 && (
                                                        <span className="error">
                                                            {errorValue.alternate_contact_number1}
                                                        </span>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="office_field">
                                                    <label htmlFor="staticEmail" >Alternate Contact Number 2(Optional)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="alternate_contact_number2"
                                                        placeholder="Alternate Phone Number 2"
                                                        maxLength={10}
                                                        pattern="[0-9]*"
                                                        onChange={formChange}
                                                        onBlur={updateFormTouchValue}
                                                        onKeyDown={e => checkLength(e)}
                                                        onKeyUp={removeSpecialCharacters}
                                                        value={formValue1.alternate_contact_number2}
                                                        error={errorValue.alternate_contact_number2 ? "true" : "false"}
                                                        disabled={inputDisable?.alternate_contact_number2 ? false : true}
                                                    />
                                                    <span className="verify_sec"><a href="javascript:void(0)"><i
                                                        onClick={() => {
                                                            setInputDisable((prev) => {
                                                                return { ...prev, alternate_contact_number2: !inputDisable.alternate_contact_number2 };
                                                            })
                                                        }}
                                                        className='fa fa-pencil'></i></a></span>
                                                    {errorValue.alternate_contact_number2 && (
                                                        <span className="error">
                                                            {errorValue.alternate_contact_number2}
                                                        </span>
                                                    )}

                                                </div>
                                            </div>


                                        </div>
                                    </form>

                                </div>
                                <div className="mt-4">
                                    <div className="row">

                                        <div className="col-sm-12">
                                            <div className="office_address_rt">
                                                <div className="address_map">
                                                    <h6>Select Address Location</h6>
                                                    <span>Select Address in Map</span>
                                                    {formValue.address_location && <GoogleMap
                                                        onLoad={onLoad}
                                                        center={markerPosition}
                                                        mapContainerStyle={mapStyle}
                                                        options={{
                                                            zoom: 15,
                                                            disableDefaultUI: true,
                                                            mapTypeControl: false,
                                                            draggable: false,
                                                            scaleControl: false,
                                                            scrollwheel: false,
                                                            navigationControl: false,
                                                        }}
                                                    // onUnmount={onUnmount}
                                                    >
                                                        <Marker position={defaultLocation} />
                                                    </GoogleMap>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="office_field mt-4"><button type="button"
                                        disabled={Object.values(inputDisable).some(x => x !== '') == true ? (submitDisable ? true : false) : true}
                                        onClick={_officeAddressUpdateSubmit}

                                        style={{ background: (Object.values(inputDisable).some(x => x !== '') == true ? '#000' : '#C5C4D2') }}>Save</button></div>
                                    {/* <CButton color="dark"
                                                    onClick={() => _deleteGodown(formValue.id)}
                                                ><i className="fa fa-trash" aria-hidden="true"></i></CButton> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={visible}
                onHide={() => setVisible(false)}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                size="xl"

            >
                <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}> <h6 className="hed_txt">Add Godown </h6></Modal.Header>

                </div>
                <AddGodownAddressList CloseAddEditModal={closeAddEditModal}
                    onChangeSomeState={onChangeSomeState} />
                {/* <OnboardingOfficeAddress Type={1} CloseAddEditModal={closeAddEditModal}
                                onChangeSomeState={onChangeSomeState} /> */}

            </Modal>


            {openModal && <OnboardingLocationModal onHide={() => { setopenModal(false) }}
                show={openModal} onCloseModal={onCloseModal} />}


            {/* delete office */}
            {deleteModal && <Modal
                show={deleteModal}
                onHide={() => setDeleteModal(false)}
                backdrop="static"
            > <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}> <h6 className="hed_txt">Delete Godown Address
                    </h6></Modal.Header>
                </div>
                <div className="task_modal_sec">
                    <div className="row">
                        <div className="col">
                            <div className="office_address_lt" >
                                <p>Do you want to delete this addtess?
                                </p>
                                <div className='reco_trans_rt_inner_dwn' style={{ justifyContent: 'center' }}>
                                    <div>
                                        <button type='' value="reject"
                                            onClick={() => setDeleteModal(false)}
                                            style={{ background: '#fff', color: '#000' }}
                                        >Cancel</button></div>
                                    <div><button type='reject' value="reject"
                                        onClick={(e) => _confirmDeleteGodownAddress(e)}
                                        disabled={disable ? true : false}
                                        style={{ background: '#000', color: '#fff' }}
                                    >Delete</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>}
        </>
    );
}
