import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE, REGEX, Constants } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import HomeImg from '../../../assets/images/car.png';

function SubscriptionForShipper() {
    const navigate = useNavigate();
    const [subscriptionList, setsubscriptionList] = useState([]);

    useEffect(() => {
        loadGetsubscriptionListList()
    }, []); 


    /**
  * @author Sarmistha Mondal
  * @Date_Created 14/02/2024
  * @Date_Modified 
  * @function async
  * @functionName loadGetsubscriptionListList
  * @functionPurpose this function gets
  * subscription list
  *
  *  @functionParam {payload object: user_type}
  * 
  * @functionSuccess Success status and message.
  *
  * @functionError {Boolean} error is there.
  * @functionError {String} message  Description message.
  */
    const loadGetsubscriptionListList = () => {
        let params = JSON.stringify({
            user_type: localStorage.getItem('user_type'),
        })
        Service.apiPostCallRequest(RouteURL.subscriptionList, params)
            .then((res) => {
                if (res.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    setsubscriptionList(res.data.subscriptionList);
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 14/02/2024
    * @Date_Modified 
    * @function async
    * @functionName _submitUserSubscription
    * @functionPurpose this function for
    * submit subscription
    *
    *  @functionParam {payload object: user_type}
    * 
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _submitUserSubscription = (subscription_id) => {
        let params = JSON.stringify({
            subscription_id: subscription_id,
        })
        Service.apiPostTokenCallRequest(RouteURL.userSubscription, params)
            .then((response) => {
                if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                    let res = response.data.subscriptionInfo.subscriptionDetails; 
                    navigate(`/payment-details/${res.id}`);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                    window.location.reload();
                }
            });
    }
    
    return (
        <div>
            <section className='shipper_subscription'>
                <div className='container-fluid p-0'>
                    <div className='row m-0'>
                        <div className="col-sm-4 p-0">
                            <div className="select_page_lt">
                                <h2>Lorem ipsum is <br /><span>a dummy text</span></h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <img src={HomeImg} alt="" />
                            </div>
                        </div>
                        <div className='col-sm-8'>
                            <div className='shhipper_sub_rt'>
                                <div className='shipper_sub_txt'>
                                    <h6>Subscription</h6>
                                </div>
                                <div className='shipper_sub_dwn'>
                                    <h5>Choose Your Plan</h5>
                                    <div className="row">
                                        {subscriptionList?.length > 0 && subscriptionList.map(list => (
                                        <div className='col-sm-6'>
                                            <div className='shipper_sub_box'>
                                                    <h6>Rs.{list?.price}/{list?.no_of_year} Year </h6>
                                                    <p><div
                                                        className="sample_note"
                                                        dangerouslySetInnerHTML={{
                                                            __html: list?.description,
                                                        }}
                                                    /></p>
                                                    <a href='' onClick={(e) => { e.preventDefault(); _submitUserSubscription(list.id)}}>Subscribe <i className="fa-solid fa-chevron-right"></i></a>
                                            </div>
                                        </div>
                                        ))}
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SubscriptionForShipper