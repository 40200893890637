/**
 * @file_purpose  page for services
 * @author Sarmistha Mondal
 * @Purpose reusable api service function 
 * @Date_Created 06/11/2023
 * @Date_Modified 
 */

import API from "./API";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const _logout = (e) => {
  localStorage.clear();
  window.location.href = "/";
};
const Service = {

  /**
  * @author Sarmistha Mondal
  * @functionName apiGetCallRequest
  * @functionPurpose this function for get api call.  
  * @functionParam {  url, token}
  * @Date_Created 06/11/2023
  * @Date_Modified 
  */
  apiGetCallRequest: function (url) {
    return new Promise((resolve, reject) => {
      API.get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.code == "ERR_NETWORK") {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (error.response.data.message === "jwt expired") {
            toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            _logout();
          } else if (error.response.data.statusCode === 406) {
            if (localStorage.getItem("user_type") != 'Transporter') window.location.href = "/shipper-subscription";
            else window.location.href = "/subscription"
          } else if (error.response.data.statusCode === 403) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            _logout();
          }
        });
    });
  },

  /**
  * @author Sarmistha Mondal
  * @functionName apiPostCallRequest
  * @functionPurpose this function for post api call.  
  * @functionParam {  url, body}
  * @Date_Created 06/11/2023
  * @Date_Modified 
  */
  apiPostCallRequest: function (url, body = null) {
    return new Promise((resolve, reject) => {
      API.post(`${url}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => resolve(res.data))
        .catch((error) => {

          if (error.code == "ERR_NETWORK") {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (error.response.data.message === "jwt expired") {
            toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            _logout();
          } else if (error.response.data.statusCode === 406) {
            if (localStorage.getItem("user_type") != 'Transporter') window.location.href = "/shipper-subscription";
            else window.location.href = "/subscription"
          } else if (error.response.data.statusCode === 403) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            _logout();
          }
        });
    });
  },

  /**
  * @author Sarmistha Mondal
  * @functionName apiPostTokenCallRequest
  * @functionPurpose this function for post api call.  
  * @functionParam {  url, token,body}
  * @Date_Created 06/11/2023
  * @Date_Modified 
  */
  apiPostTokenCallRequest: function (url, params = null) {
    return new Promise((resolve, reject) => {
      API.post(`${url}`, params, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.code == "ERR_NETWORK") {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (error.response.data.message === "jwt expired") {
            toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            _logout();
          } else if (error.response.data.statusCode === 406) {
            if (localStorage.getItem("user_type") != 'Transporter') window.location.href = "/shipper-subscription";
            else window.location.href = "/subscription";
          } else if (error.response.data.statusCode === 403) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            _logout();
          }
        });
    });
  },

  /**
  * @author Sarmistha Mondal
  * @functionName apiPostCallFormDataRequest
  * @functionPurpose this function for post formdata api call.  
  * @functionParam {  url, token,body}
  * @Date_Created 06/11/2023
  * @Date_Modified 
  */

  apiPostCallFormDataRequest: function (url, body = null) {
    return new Promise((resolve, reject) => {
      API.post(`${url}`, body, {
        headers: {
          "Content-Type": "application/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => resolve(res.data))
        .catch((error) => {
          if (error.code == "ERR_NETWORK") {
            toast.error(error.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (error.response.data.message === "jwt expired") {
            toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            _logout();
          } else if (error.response.data.statusCode === 406) {
            if (localStorage.getItem("user_type") != 'Transporter') window.location.href = "/shipper-subscription";
            else window.location.href = "/subscription";
          } else if (error.response.data.statusCode === 403) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            _logout();
          }
        });
    });
  },
};
export default Service;
