/**
 * @file_purpose  page for registration type choose 
 * @author Sarmistha Mondal
 * @Date_Created 08/11/2023
 * @Date_Modified 
 */
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeImg from "../../assets/images/car.png";
import Truck from "../../assets/images/truck.png";
import Man from "../../assets/images/d1.png";
import SmallTruck from "../../assets/images/d2.png";
import HomeMobCar from "../../assets/images/mob_car.png";

const TypeOfRegistration = () => {
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    localStorage.clear();
    // let keysToRemove = ['token', 'user_type', 'firm_name', 'name', 'first_name', 'MenuName'];
    // keysToRemove.forEach(k =>
    //   localStorage.removeItem(k))
  }, []);

  /* select type */ 
  const typeOfRegistration = (e) => {
    const { value } = e.target;
    setType(value);
    localStorage.setItem("typeOfRegistration", value);
  };

  /* after type choose page navigation */ 
  const goToSignup = () => {
    if (type) navigate("/register", { state: { name: type } });
    else {
      setIsDisabled(true);
      toast.error("Please select type", {
        position: toast.POSITION.BOTTOM_RIGHT,
        onClose: () => {
          setIsDisabled(false);
        },
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-5 col-sm-12 p-0">
            <div className="select_page_lt">
              <h2>
                Lorem ipsum is <br />
                <span>a dummy text</span>
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <img src={HomeImg} alt="" />
            </div>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-7 col-sm-12">
            <div className="select_page_rt">
              <div className="logo_tag_sec">
                <img src={Truck} alt="" />
                <h6>Shipping made easy</h6>
              </div>
              <div className="mobcar">
                <img src={HomeMobCar} alt="" />
              </div>
              <div className="business_type_sec">
                <p>Choose your Business Type</p>
              </div>
              <div className="business_type_inn">
                <div className="business_box_check">
                  <img src={Man} alt="" />
                  <div className="business_type_txt">
                    <h6>Shipper</h6>
                    <p>I want to deliver the goods</p>
                  </div>
                  <div className="radio_sec">
                    <input
                      id="radio-1"
                      className="radio-custom"
                      name="radio-group"
                      value="Shipper"
                      type="radio"
                      onChange={typeOfRegistration}
                    />
                    <label for="radio-1" className="radio-custom-label"></label>
                  </div>
                </div>
                <div className="business_box_check">
                  <img src={SmallTruck} alt="" />
                  <div className="business_type_txt">
                    <h6>Transporter</h6>
                    <p>Ship your goods with us</p>
                  </div>
                  <div className="radio_sec">
                    <input
                      id="radio-2"
                      className="radio-custom"
                      name="radio-group"
                      value="Transporter"
                      type="radio"
                      onChange={typeOfRegistration}
                    />
                    <label for="radio-2" className="radio-custom-label"></label>
                  </div>
                </div>

                <div className="get_startbtn">
                  <button
                    type=""
                    value="get Started"
                    onClick={goToSignup}
                    style={{ background: type == "" ? "#C5C4D2" : "#000" }}
                    disabled={isDisabled ? true : false}
                  >
                    Get Started
                  </button>
                </div>

                <div className="regis_dwn_txt">
                  <p>
                    Already have an Account? <Link to="/login">Sign In Now</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TypeOfRegistration;
