/**
 * @file_purpose  page for constantdata
 * @author Sarmistha Mondal
 * @Purpose common regex, error message and status code .
 * @Date_Created 03/11/2023
 * @Date_Modified 
 */

const REGEX = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  name: /^[a-zA-Z ]{3,}$/,
  username: /^[a-zA-Z0-9]{3,}$/,
  firstName: /^[a-zA-Z '!"/()&*]{3,}$/,
  lastName: /^[a-zA-Z '!"/()&*]{3,}$/,
  phone: /^[0-9]{10}$/,
  emailName: /^[a-zA-Z '!"/()&*0-9]{3,}$/,
  gstno: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  otp: /^[0-9]{4}$/,
  panNo: /^[A-Z]{5}[0-9]{4}[a-zA-Z]{1}$/,
  policy: "",
  number: /^(?=.*[0-9]).{1,}$/,
  lowercase: /^(?=.*[a-z]).{1,}$/,
  uppercase: /^(?=.*[A-Z]).{1,}$/,
  special: /^(?=.*[!*@#\$%^&+=()]).{1,}$/,
  space: /(?=\\S+\$)/,
  contact_number: /^[0-9]{10}$/,
  alternate_contact_number1: /^[0-9]{10}$/,
  alternate_contact_number2: /^[0-9]{10}$/,
  loading_time: /^[0-9]{1,3}$/,
  account_number: /^[0-9]{9,18}$/,
  ifsc_code: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  upi_id: /^[\w.-]+@[\w.-]+$/, // /^[a-zA-Z0-9.-]{2, 256}@[a-zA-Z][a-zA-Z]{2, 64}$/,
  account_holder_name: /^[a-zA-Z ]{3,}$/,
  estyear: /^[0-9]{4}$/,
  aadharNo: /^[0-9]{12}$/,
  vehicle_plate_number: /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/,
  tracking_device_iemi: '',
  otpAdhar: /^[0-9]{6}$/,
  vehicle_category_id: /^[0-9]{1}$/,
  // role_id: /^[0-9]{1}$/,
  // transportername: /^[a-zA-Z '!"/()&* ]{3,}$/,
  // from_location: /^[a-zA-Z ]{3,}$/
};

const ERROR_MESSAGE = {
  usernameRequired: "User Name is required.",
  usernameInvalid: "Please enter a valid user name.",
  nameRequired: "Name is required.",
  nameInvalid: "Please enter a valid name.",
  // phoneRequired: "Phone number is required.",
  phoneInvalid: "Please enter a valid whatsapp number.",
  passwordRequired: "Password is required.",
  // passwordInvalid: "Password must contain at least one number, upper case, lower case, special character & 8 characters.",
  confirmPasswordRequired: "Confirm password is required.",
  confirmPasswordInvalid: `Password and confirm password doesn't match.`,
  firstNameRequired: "First name is required.",
  firstNameInvalid: "Please enter a valid first name.",
  lastNameRequired: "Last name is required.",
  lastNameInvalid: "Please enter a valid last name.",
  emailRequired: "Email is required.",
  emailInvalid: "Please enter a valid email address.",
  transporternameRequired: "Transporter Firm Name is required.",
  transporternameInvalid: "",
  estyearRequired: "Establishment Year is required",
  estyearInvalid: "Please enter a valid establishment year.",
  businesstypeRequired: "Business Type is required",
  phoneRequired: "Whatsapp Number is required",
  gstnoRequired: "Please provide GST",//"GST Number is required",
  gstnoInvalid: "Please enter a valid GST number.",
  otpRequired: 'OTP is required.',
  otpInvalid: 'Please enter a valid OTP.',
  emailOrphoneRequired: 'Please enter your email id ',
  // emailOrphoneInvalid: '',
  panNoRequired: "PAN Number is required",
  panNoInvalid: "Please enter a valid PAN number.",
  policyRequired: "Privacy policy is required",
  policyInvalid: "",
  passwordNumber: "Password must have at least 1 digit",
  passwordLowerCase: "Password must have at least 1 lowercase letter",
  passwordUpperCase: "Password must have at least 1 uppercase letter",
  passwordSpecial: "Password must have at least 1 special character",
  passwordSpace: "Password should not contain any blank space",
  passwordLength: "Password must have 8 to 10 characters",
  officeAddressRequired: "Address is required",
  //-- step 1 --//
  address_nameRequired: "Office address name is required",
  // address_nameInvalid: '',
  address_locationRequired: "Office address location is required",
  contact_person_nameRequired: "Contact person name is required",
  contact_numberRequired: "Contact number is required",
  contact_numberInvalid: "Please enter a valid contact number.",
  alternate_contact_number1Required: "",
  alternate_contact_number1Invalid: "Please enter a valid contact number 1.",
  alternate_contact_number2Required: "",
  alternate_contact_number2Invalid: "Please enter a valid contact number 2.",
  // partial_loading_time_in_hhRequired: "",
  partial_loading_time_in_hhInvalid: "Please enter a valid load time hh",
  // partial_loading_time_in_mmRequired: "",
  partial_loading_time_in_mmInvalid: "Please enter a valid load time mm",
  // full_loading_time_in_hhRequired: "",
  full_loading_time_in_hhInvalid: "Please enter a valid load time hh",
  // full_loading_time_in_mmRequired: "",
  full_loading_time_in_mmInvalid: "Please enter a valid load time mm",
  // step 2
  account_holder_nameRequired: "Bank account holder name is required",
  account_holder_nameInvalid: "Please enter a valid name",
  account_numberRequired: "Bank Account Number is required",
  account_numberInvalid: "Please enter a valid bank account number.",
  ifsc_codeRequired: "IFSC Code is required",
  ifsc_codeInvalid: "Please enter a valid IFSC Code",
  bank_nameRequired: "Bank Name is required",
  bank_nameInvalid: "",
  upi_idRequired: "UPI id is required",
  upi_idInvalid: "Please enter a valid upi id",
  branch_nameRequired: "Please enter a valid branch name",
  branch_nameInvalid: "",

  from_locationRequired: "Please enter from location",
  from_location_cityRequired: "Please select city",
  to_locationRequired: 'Please enter to location',
  to_location_cityRequired: 'Please select city',
  material_typesRequired: 'Please enter marerial type',
  package_lengthRequired: 'Please enter package length',
  package_widthRequired: 'Please enter package width',
  package_heightRequired: 'Please enter package height',
  net_package_weight_in_kgRequired: 'Please enter package net weight',
  expected_delivery_dateRequired: 'Please enter expected delivery date',
  no_of_unitsRequired: 'Please enter no of boxes',
  dimension_unitRequired: 'Please enter units',
  role_idRequired: 'Please select role',
  role_idInvalid: '',
  statusRequired: 'Please select status',
  quote_priceRequired: "Please enter quoted price",
  estimated_delivery_dateRequired: "Please enter estimated delivery date",
  pickup_typeRequired: "Please select pickup type",
  address_of_send_to_usRequired: "Please select pickup location",
  rejection_reasonRequired: "Please select rejection reasons",
  additional_notesRequired: "Please enter additional notes",
  gstOCRRequired: "Please upload GST OCR",
  documentTypeRequired: "Please enter document type",
  aadharNoRequired: "Please enter aadhar no",
  aadharNoInvalid: "Please enter a valid aadhar no",
  shhiperaadharOCRRequired: "Please upload Aadhar OCR",
  shhipergstOCRRequired: "Please upload GST OCR",
  vehicle_plate_numberRequired: "Please enter vehicle no",
  vehicle_plate_numberInvalid: "Please enter a valid vehicle no",
  tracking_device_iemiRequired: "Please enter iemi no",
  tracking_device_iemiInvalid: "",
  msgRequired: "Please enter message",
  booking_dateRequired: "Please enter booking date",
  load_typeRequired: "Please enter load type",
  payment_modeRequired: "Please enter payment mode",
  receiver_nameRequired: "Please enter receiver name",
  receiver_contact_numberRequired: 'Please enter receiver contact number',
  receiver_contact_numberInvalid: 'Please enter a valid receiver contact number',
  receiver_contact_number2Invalid: 'Please enter a valid receiver contact 2 number',
  receiver_contact_number2Required: '',
  receiver_emailRequired: 'Please enter receiver email',
  total_goods_priceRequired: 'Please enter total goods price',
  updated_quote_priceRequired: 'Please enter quotation',
  address_for_pickup_goodsRequired: "Please select delivery location",
  other_rejection_reasonRequired: "Please enter others reason",
  assigned_vehicle_idRequired: 'Please select vehicle',
  assigned_driver_idRequired: 'Please select driver',
  start_dateRequired: 'Please enter loading date',
  unique_idRequired:'Please enter unique id',
  e_way_billRequired:'',
  vehicle_category_idRequired:'Please enter vehicle category'
};
// Required Invalid
const Constants = {
  API_RESPONSE_STATUS_SUCCESS: false,
  API_RESPONSE_STATUS_FAILED: true,
};
export { REGEX, ERROR_MESSAGE, Constants };
