/**
 * @file_purpose  page for registertion
 * @author Sarmistha Mondal
 * @Date_Created 06/11/2023
 * @Date_Modified 22/11/2023
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getErrorMessage,
  markAllAsTouched,
  leftTrimAllValue,
  isFormValid,
  leftTrim, removeSpecialCharacters,
  checkLength
} from "../../Utility/Helper";
import { Constants } from "../../Utility/ConstantData";
import Service from "../../apis/Service";
import RouteURL from "../../apis/RouteURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REGEX, ERROR_MESSAGE } from "../../Utility/ConstantData";
import Modal from "react-bootstrap/Modal";
import VerifyOTP from "./veryfyOTP";
import HomeImg from "../../assets/images/car.png";
import Verified from "../../assets/images/checkmark.png";
import Congratulation from "./congratulation";
import Loader from "react-js-loader";

const Register = (props) => {
  let formData = new FormData();
  const navigate = useNavigate();
  const location = useLocation();
  let typeOfRegistration = location.state.name;
  const [openModalForOTP, setopenModalForOTP] = useState({
    visible: false,
    type: '',
    emailOrPhone: '',
    pageFor: 'signup',
    client_id: ''
  });
  const [isDisabled, setIsDisabled] = useState(false)
  const [is_phone_verified, setIsPhoneVerified] = useState(0);
  const [is_email_verified, setIsEmailVerified] = useState(0);
  const [bType, setbType] = useState('Individual');
  const [is_gst_number_verified, setIs_gst_number_verified] = useState(0);
  const [is_pan_number_verified, setIs_pan_number_verified] = useState(0);
  const [is_aadhar_number_verified, setIs_aadhar_number_verified] = useState(0);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [inputDisable, setInputDisable] = useState(() => {
    if (typeOfRegistration == 'Shipper') {
      if (bType == 'Individual') {
        return ({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          aadharNo: "",
          officeAddress: "",
          password: "",
          confirmPassword: "",
          documentType: "",

        })
      }
      else if (bType == 'Organisation') {
        return ({
          transportername: "",
          phone: "",
          email: "",
          gstno: "",
          password: "",
          confirmPassword: "",
          officeAddress: "",
          documentType: "",
        })
      }
    } else if (typeOfRegistration == 'Transporter') {
      if (bType == 'Individual') {
        return ({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          aadharNo: "",
          panNo: "",
          officeAddress: "",
          password: "",
          confirmPassword: "",
          documentType: "",
          businesstype: "",


        })
      } else if (bType == 'Organisation') {
        return ({
          transportername: "",
          estyear: "",
          businesstype: "",
          phone: "",
          email: "",
          gstno: "",
          officeAddress: "",
          password: "",
          confirmPassword: "",
        })
      }

    };

  });
  const [passwordShown, setPasswordShown] = useState({
    password: false,
    confirmPassword: false,
  });
  const [gstOCR, setGstOcr] = useState();
  const [shhipergstOCR, setShhipergstOCR] = useState();
  const [shhiperaadharOCR, setShhiperaadharOCR] = useState();
  const [upadate, setUpdate] = useState(false);
  const [stateUpdate, setstateUpdate] = useState(false);
  const [terms, setTerms] = useState(false);
  const [congo, setCongo] = useState(false);
  const [loader, setLoader] = useState(0);
  const [validationText, setValidationText] = useState(false);

  const [formValue, setFormValue] =
    useState(() => {
      if (typeOfRegistration == 'Shipper') {
        if (bType == 'Individual') {
          return ({
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            aadharNo: "",
            officeAddress: "",
            password: "",
            confirmPassword: "",
            documentType: "",
            // policy: false

          })
        }
        else if (bType == 'Organisation') {
          return ({
            transportername: "",
            phone: "",
            email: "",
            gstno: "",
            password: "",
            confirmPassword: "",
            officeAddress: "",
            documentType: "",
            // policy: false
          })
        }
      } else if (typeOfRegistration == 'Transporter') {
        if (bType == 'Individual') {
          return ({
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            aadharNo: "",
            panNo: "",
            officeAddress: "",
            password: "",
            confirmPassword: "",
            documentType: "",
            businesstype: "Individual",
            // policy: false


          })
        } else if (bType == 'Organisation') {
          return ({
            transportername: "",
            estyear: "",
            businesstype: "",
            phone: "",
            email: "",
            gstno: "",
            officeAddress: "",
            password: "",
            confirmPassword: "",
            // policy: false
          })
        }
      }

    });

  const [errorValue, setErrorValue] = useState(() => {
    if (typeOfRegistration == 'Shipper') {
      if (bType == 'Individual') {
        return ({
          shhiperaadharOCR: "",
          firstName: "",
          lastName: "",
          businesstype: "",
          phone: "",
          email: "",
          aadharNo: "",
          officeAddress: "",
          password: "",
          confirmPassword: "",
          documentType: "",

        })
      }
      return ({
        shhipergstOCR: "",
        transportername: "",
        phone: "",
        email: "",
        gstno: "",
        password: "",
        confirmPassword: "",
        officeAddress: "",
        documentType: "",
        policy: false,
      })

    } else {
      if (bType == 'Individual') {
        return ({
          shhiperaadharOCR: "",
          firstName: "",
          lastName: "",
          businesstype: "",
          phone: "",
          email: "",
          aadharNo: "",
          panNo: "",
          officeAddress: "",
          password: "",
          confirmPassword: "",
          documentType: "",
          register_type: "",

        })
      } else if (bType == 'Organisation') {
        return ({
          gstOCR: "",
          transportername: "",
          estyear: "",
          businesstype: "",
          phone: "",
          email: "",
          officeAddress: "",
          gstno: "",
          password: "",
          confirmPassword: "",
          register_type: "",
        })
      }

    }
  });

  const [formTouchValue, setFormTouchValue] = useState(() => {
    if (typeOfRegistration == 'Shipper') {
      if (bType == 'Individual') {
        return ({
          shhiperaadharOCR: false,
          firstName: false,
          lastName: false,
          phone: false,
          email: false,
          aadharNo: false,
          officeAddress: false,
          password: false,
          confirmPassword: false,
          documentType: false,

        })
      }
      else if (bType == 'Organisation') {
        return ({
          shhipergstOCR: false,
          transportername: false,
          phone: false,
          email: false,
          gstno: false,
          password: false,
          confirmPassword: false,
          officeAddress: false,
          documentType: false,
        })
      }
    } else if (typeOfRegistration == 'Transporter') {
      if (bType == 'Individual') {
        return ({
          shhiperaadharOCR: false,
          firstName: false,
          lastName: false,
          phone: false,
          email: false,
          aadharNo: false,
          panNo: false,
          officeAddress: false,
          password: false,
          confirmPassword: false,
          documentType: false,

        })
      }
      else if (bType == 'Organisation') {
        return ({
          shhipergstOCR: false,
          transportername: false,
          phone: false,
          email: false,
          gstno: false,
          password: false,
          confirmPassword: false,
          officeAddress: false,
          documentType: false,
        })
      }

    };

  });

  useEffect(() => {

    if (formValue) {

      if ((typeOfRegistration != 'Shipper' && !!gstOCR) || (typeOfRegistration == 'Shipper' && bType == 'Individual' && !!shhiperaadharOCR) || (typeOfRegistration == 'Shipper' && bType == 'Organisation' && !!shhipergstOCR)) {


        if (typeOfRegistration == 'Shipper') {

          if (bType == 'Individual') {

            if (!!formTouchValue.email) checkError("email");
            if (!!formTouchValue.phone) checkError("phone");
            if (!!formTouchValue.aadharNo) checkError("aadharNo");
            if (!!formTouchValue.password) checkError("password");
            if (!!formTouchValue.confirmPassword) checkError("confirmPassword");
            // console.log('11oo', formValue);
            // checkError("firstName");
            // checkError("lastName");              
            // checkError("documentType");
            // checkError("officeAddress");
            // checkError("aadharNo");
            // checkError("phone");
            // checkError("email");
            // checkError("password");
            // checkError("confirmPassword");

          }
          if (bType == 'Organisation') {
            // console.log('12oo', formValue);
            setFormValue((prevValue) => {
              const { firstName, lastName, estyear, aadharNo, ...rest } = prevValue;
              return rest;
            })

            if (!!formTouchValue.email) checkError("email");
            if (!!formTouchValue.phone) checkError("phone");
            if (!!formTouchValue.gstno) checkError("gstno");
            if (!!formTouchValue.password) checkError("password");
            if (!!formTouchValue.confirmPassword) checkError("confirmPassword");
          }
        } else {
          console.log('2oo');

          if (!!formTouchValue.email) checkError("email");
          if (!!formTouchValue.phone) checkError("phone");
          if (!!formTouchValue.gstno) checkError("gstno");
          if (!!formTouchValue.password) checkError("password");
          if (!!formTouchValue.password && !!formTouchValue.confirmPassword) checkError("confirmPassword");

        }
      }


    }


  }, [formValue]);

  useEffect(() => {
    // console.log( location);
    if (location.state.user_type == 'Receiver') {
      localStorage.setItem("email", location.state.email)
      setIsEmailVerified(1)
      setFormValue((prevErrorValue) => {
        return {
          ...prevErrorValue, email: location.state.email,
        }
      })
    }

    if (typeOfRegistration == null || typeOfRegistration == undefined) navigate("/type-of-registration");

    if (window.performance) {
      if (performance.navigation.type == 1) {
        // localStorage.clear();
        let keysToRemove = ['token', 'user_type', 'firm_name', 'name', 'first_name', 'MenuName'];
        keysToRemove.forEach(k =>
          localStorage.removeItem(k))

        // console.log("This page is reloaded");
      } else {
        let keysToRemove = ['token', 'user_type', 'firm_name', 'name', 'first_name', 'MenuName'];
        keysToRemove.forEach(k =>
          localStorage.removeItem(k))
        // console.log("This page is not reloaded");
      }

    }
  }, []);


  useEffect(() => {
  }, [upadate]);

  useEffect(() => {
  }, [stateUpdate]);

  useEffect(() => {
  }, [validationText]);



  /* on change input function */
  const formChange = (event) => {
    const { name, value, checked } = event.target;

    setValidationText(false)
    if (name == 'businesstype') {
      setInputDisable((prevErrorValue) => {
        return {
          ...prevErrorValue, phone: '', aadharNo: '', gstno: '', email: '',
          password: '', confirmPassword: '',
        }
      })
      // console.log(value, errorValue);
      if (value == 'Individual' || value == 'Organisation') {
        setValidationText(true)

      }

      setFormValue((prevFormValue) => {
        return {
          ...prevFormValue, transportername: '',
          estyear: '',
          businesstype: '',
          documentType: '',
          gstno: '',
          officeAddress: '',
          firstName: '',
          lastName: '',
          aadharNo: '', phone: '', email: '',
          password: '', confirmPassword: '', panNo: '',
        }
      });

      setbType(value)
    } else {
      setInputDisable((prevErrorValue) => {
        return {
          ...prevErrorValue, phone: '', aadharNo: '', gstno: '', email: true,
          password: '', confirmPassword: '', panNo: ''
        }
      })

      if (value == 'Individual' || value == 'Organisation') {
        setValidationText(true)
      }

      setFormValue((prevFormValue) => {
        return {
          ...prevFormValue, transportername: '',
          estyear: '',
          businesstype: '',
          documentType: '',
          gstno: '',
          officeAddress: '',
          firstName: '',
          lastName: '',
          aadharNo: '', phone: '', email: location.state.email,
          password: '', confirmPassword: '', panNo: ''
        }
      });

      setbType(value)
      setFormTouchValue((prevFormTouchValue) => {
        return { ...prevFormTouchValue, email: true };
      });
    }
    if (name == 'policy') {
      setFormValue((prevValue) => {
        return { ...prevValue, ['policy']: checked };
      });
    } else {
      setFormValue((prevFormValue) => {
        return { ...prevFormValue, [name]: value };
      });

    }

  };

  /* onKeyDown error message call */
  const updateFormTouchValue = (event) => {
    event.preventDefault()
    const { name, value } = event.target;
    // console.log(value, event);

    if (!formTouchValue[name]) {

      setFormTouchValue((prevFormTouchValue) => {
        return { ...prevFormTouchValue, [name]: true };
      });
      setFormValue((prevFormValue) => {
        return { ...prevFormValue, [name]: leftTrim(value) };
      });
    }


  };

  /* error message function */
  const checkError = (targetName) => {
    // console.log(targetName + '--' + formValue[targetName], 'gst' + formValue.gstno);
    if (formTouchValue[targetName]) {
      // console.log(formTouchValue[targetName] + "--" + targetName);
      // console.log(state.charCount);
      // console.log(formValue.password);
      setErrorValue((prevErrorValue) => {
        if (formValue.password || formValue.confirmPassword) {
          // let formIsValid = true;
          if (!formValue.password) {
            return { ...prevErrorValue, password: ERROR_MESSAGE['passwordRequired'], }
          } else if (formValue.password && !/^(?=.*[0-9]).{1,}$/.test(formValue.password)) {
            return { ...prevErrorValue, password: ERROR_MESSAGE['passwordNumber'] }
          } else if (formValue.password && !/^(?=.*[a-z]).{1,}$/.test(formValue.password)) {
            return { ...prevErrorValue, password: ERROR_MESSAGE['passwordLowerCase'] }
          } else if (formValue.password && !/^(?=.*[A-Z]).{1,}$/.test(formValue.password)) {
            return { ...prevErrorValue, password: ERROR_MESSAGE['passwordUpperCase'] }
          } else if (formValue.password && !/^(?=.*[!*@#\$%^&+=()]).{1,}$/.test(formValue.password)) {
            return { ...prevErrorValue, password: ERROR_MESSAGE['passwordSpecial'] }
          } else if (formValue.password && /(?=\\S+\$)/.test(formValue.password)) {
            return { ...prevErrorValue, password: ERROR_MESSAGE['passwordSpace'] }
          } else if (formValue.password && (formValue.password.length < 8 || formValue.password.length > 10)) {
            return { ...prevErrorValue, password: ERROR_MESSAGE['passwordLength'] }
          } else { prevErrorValue.password = ""; }


          // if (!formValue.password) {
          //   return { ...prevErrorValue, password: ERROR_MESSAGE['passwordRequired'], }
          // } else if ( formValue.password.match(REGEX.number)) {
          //   return { ...prevErrorValue, password: ERROR_MESSAGE['passwordNumber'] }
          // } else if ( formValue.password.match(REGEX.lowercase)) {
          //   return { ...prevErrorValue, password: ERROR_MESSAGE['passwordLowerCase'] }
          // } else if ( formValue.password.match(REGEX.uppercase)) {
          //   return { ...prevErrorValue, password: ERROR_MESSAGE['passwordUpperCase'] }
          // } else if ( formValue.password.match(REGEX.special)) {
          //   return { ...prevErrorValue, password: ERROR_MESSAGE['passwordSpecial'] }
          // } else if ( formValue.password.match(REGEX.space)) {
          //   return { ...prevErrorValue, password: ERROR_MESSAGE['passwordSpace'] }
          // } else if ( (formValue.password.length < 8 || formValue.password.length > 10)) {
          //   return { ...prevErrorValue, password: ERROR_MESSAGE['passwordLength'] }
          // } else { prevErrorValue.password = ""; }

          //  


          if (!formValue.confirmPassword) {
            // formIsValid = false;
            return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['confirmPasswordRequired'] }
          } else if (formValue.confirmPassword !== formValue.password) {
            // formIsValid = false;
            return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['confirmPasswordInvalid'] }
          } else { prevErrorValue.confirmPassword = ""; }
          // else if (!formValue.confirmPassword.match(REGEX.number)) {
          //   return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['passwordNumber'] }
          // } else if (!formValue.confirmPassword.match(REGEX.lowercase)) {
          //   return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['passwordLowerCase'] }
          // } else if (!formValue.confirmPassword.match(REGEX.uppercase)) {
          //   return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['passwordUpperCase'] }
          // } else if (!formValue[targetName].match(REGEX.special)) {
          //   return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['passwordSpecial'] }
          // } else if (formValue.confirmPassword.match(REGEX.space)) {
          //   return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['passwordSpace'] }
          // } else if (formValue.confirmPassword.length < 8) {
          //   return { ...prevErrorValue, confirmPassword: ERROR_MESSAGE['passwordLength'] }
          // }
          // else { prevErrorValue.confirmPassword = ""; }
          // return formIsValid;
        }

        // if (!formValue.panNo) {
        //   return { ...prevErrorValue, panNo: ERROR_MESSAGE['panNo' + 'Required'], }
        //   console.log('pan');
        // }
        return {
          ...prevErrorValue, [targetName]: !formValue[targetName] ?
            `${ERROR_MESSAGE[targetName + 'Required']}` :
            (formValue[targetName].match(REGEX[targetName]) ? '' : `${ERROR_MESSAGE[targetName + 'Invalid']}`)

        };

      });
    }
  };



  /**
   * @author Sarmistha Mondal
   * @Date_Created 16.11.2023
   * @Date_Modified 
   * @function async
   * @functionName _generateEmailForOTP
   * @functionPurpose this function for otp send in email 
   *    
   * @functionParam {payload object: email}
   * 
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
  const _generateEmailForOTP = () => {
    let params = JSON.stringify({
      email: formValue.email,
    })

    document.getElementById("genEmail")?.classList.add("disabled");
    Service.apiPostCallRequest(RouteURL.generateEmailOtp, params).then(
      (response) => {
        // console.log(response);
        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          // setIs_email_verified(1)
          setopenModalForOTP({ visible: true, type: 'email', emailOrPhone: formValue.email, pageFor: 'signup' })
          document.getElementById("genEmail")?.classList.remove("disabled");
          // setopenModal({ visible: true, type: 'email', emailOrPhone: formValue.email, pageFor: 'signup' });
          toast.success(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

        } else {
          // setIs_email_verified(0)
          document.getElementById("genEmail").classList.remove("disabled");
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }).catch((error) => {
        // console.log(error);
        document.getElementById("genEmail").classList.remove("disabled");
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          window.location.reload();
        }
      });
  }

  /**
   * @author Sarmistha Mondal
   * @Date_Created 16.11.2023
   * @Date_Modified 22.02.2024
   * @function async
   * @functionName _generateGstNumber
   * @functionPurpose this function for verify gst   
   *
   * @functionParam {payload object: gst_number}
   * 
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
  const _generateGstNumber = (gst_no) => {
    let params = JSON.stringify({
      // gst_number: formValue.gstno ,
      gst_number: gst_no,
    })
    document.getElementById("gstGen").classList.add("disabled");
    Service.apiPostCallRequest(RouteURL.verifyGstnumber, params).then(
      (response) => {
        // console.log(response);
        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          let res = response.data.ocrDetails;
          // localStorage.setItem("gstno", formValue.gstno)
          // setIs_gst_number_verified(1)
          setopenModalForOTP({ visible: true, type: 'gst', emailOrPhone: gst_no, pageFor: 'signup', client_id: res.client_id })
          document.getElementById("gstGen").classList.remove("disabled");
          setIsDisabled(false);
          toast.success(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoader(0)
        } else {
          setLoader(0)
          setIs_gst_number_verified(0)
          setInputDisable({ gstno: true })
          setInputDisable((prevFormValue) => {
            return {
              ...prevFormValue,
              transportername: false,
              estyear: false,
              businesstype: false,
              gstno: true,
              officeAddress: false,
              phone: true,
              email: true,
              password: true,
              confirmPassword: true,
            };

          });
          setInputDisable((prevValue) => {
            const { transportername, estyear, businesstype, officeAddress, ...rest } = prevValue;
            // console.log(prevValue);
            return {
              ...prevValue,
              gstno: true,
              phone: true,
              email: true,
              password: true,
              confirmPassword: true,
            };
            // return rest;
          })
          document.getElementById("gstGen").classList.remove("disabled");
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }).catch((error) => {
        // console.log(error);
        document.getElementById("gstGen").classList.remove("disabled");
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          window.location.reload();
        }
      });
  }

  /**
   * @author Sarmistha Mondal
   * @Date_Created 13.02.2024
   * @Date_Modified 
   * @function async
   * @functionName _generateAadharNumber
   * @functionPurpose this function for verify aadhar   
   *
   * @functionParam {payload object: aadhar_number}
   * 
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
  const _generateAadharNumber = (aadharNo) => {
    let params = JSON.stringify({
      aadhar_number: aadharNo,
    })
    Service.apiPostCallRequest(RouteURL.verifyAadharnumber, params).then(
      (response) => {
        // console.log(response);
        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          let res = response.data.ocrDetails;
          setopenModalForOTP({ visible: true, type: 'aadhar', emailOrPhone: res.id_number, pageFor: 'signup', client_id: res.client_id })
          setIsDisabled(false);
          toast.success(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoader(0)
        } else {
          setLoader(0)
          setInputDisable((prevFormValue) => {
            console.log(prevFormValue);
            return {
              ...prevFormValue,
              firstName: false,
              lastName: false,
              documentType: false,
              aadharNo: true,
              officeAddress: false,
              phone: true,
              email: true,
              password: true,
              confirmPassword: true,
              panNo: true,
            };

          });
          setInputDisable((prevValue) => {
            const { firstName, lastName, documentType, officeAddress, ...rest } = prevValue;
            // console.log(prevValue);
            return {
              ...prevValue,
              aadharNo: true,
              phone: true,
              email: true,
              password: true,
              confirmPassword: true,
              panNo: true,
            };
            // return rest;
          })
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }).catch((error) => {
        // console.log(error);
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          window.location.reload();
        }
      });
  }

  /**
     * @author Sarmistha Mondal
     * @Date_Created 21.11.2023
     * @Date_Modified 
     * @function async
     * @functionName _generatePanNumber
     * @functionPurpose this function for
     * verify pan
     *
     *  @functionParam {payload object: pan_number}
     * 
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */
  const _generatePanNumber = () => {
    let params = JSON.stringify({
      pan_number: formValue.panNo,
    })
    Service.apiPostCallRequest(RouteURL.verifyPanNumber, params).then(
      (response) => {
        document.getElementById("panGen").classList.remove("disabled");
        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {

          // console.log(response.data.name, formValue.firstName.toUpperCase() + ' ' + formValue.lastName.toUpperCase());
          if (response.data.name == formValue.firstName.toUpperCase() + ' ' + formValue.lastName.toUpperCase()) {
            localStorage.setItem("panNo", formValue.panNo)
            setIs_pan_number_verified(1)
            toast.success(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error('Not matched aadhar and pan name', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }



        } else {
          setIs_pan_number_verified(0)
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }).catch((error) => {
        // console.log(error);
        document.getElementById("panGen").classList.remove("disabled");
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          window.location.reload();
        }
      });
  }

  /**
   * @author Sarmistha Mondal
   * @Date_Created 16.11.2023
   * @Date_Modified 
   * @function async
   * @functionName _generateWhatsappOTP
   * @functionPurpose this function for
   * otp send in whatsapp 
   *
   *  @functionParam {payload object: phone_number}
   * 
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
  const _generateWhatsappOTP = () => {
    let params = JSON.stringify({
      phone_number: formValue.phone,
    })
    document.getElementById("gen_otp").classList.add("disabled");
    Service.apiPostCallRequest(RouteURL.generateWhatsappOtp, params).then(
      (response) => {
        // console.log(response);

        if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          setopenModalForOTP({ visible: true, type: 'phone', emailOrPhone: formValue.phone, pageFor: 'signup', viewOtp: response.data.otp_for_whatsapp_verification })
          document.getElementById("gen_otp").classList.remove("disabled");
          // setopenModal({ visible: true, type: 'phone', emailOrPhone: formValue.phone, pageFor: 'signup' });
          toast.success(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

        } else {

          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          document.getElementById("gen_otp").classList.remove("disabled");
        }
      }).catch((error) => {
        // console.log(error);
        document.getElementById("gen_otp").classList.remove("disabled");
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
          window.location.reload();
        }
      });
  }

  /* Validation Checking */
  const ValidateForm = (e) => {
    e.preventDefault();
    setFormTouchValue(markAllAsTouched(formTouchValue));
    if (typeOfRegistration == 'Transporter' && bType == 'Organisation' && !shhipergstOCR) {
      // console.log('ok1', shhipergstOCR);
      setErrorValue((prevErrorValue) => {
        return { ...prevErrorValue, gstOCR: ERROR_MESSAGE['shhipergstOCRRequired'] }
      })
    } else if (typeOfRegistration == 'Transporter' && bType == 'Individual' && !shhiperaadharOCR) {
      // console.log('00');
      setErrorValue((prevErrorValue) => {
        return { ...prevErrorValue, shhiperaadharOCR: ERROR_MESSAGE['shhiperaadharOCRRequired'] }
      })
    } else if (typeOfRegistration == 'Shipper' && bType == 'Individual' && !shhiperaadharOCR) {

      // console.log('ok2', shhiperaadharOCR);
      setErrorValue((prevErrorValue) => {
        return { ...prevErrorValue, shhiperaadharOCR: ERROR_MESSAGE['shhiperaadharOCRRequired'] }
      })
    } else if (typeOfRegistration == 'Shipper' && bType == 'Organisation' && !shhipergstOCR) {
      // console.log('ok3', shhipergstOCR);
      setErrorValue((prevErrorValue) => {
        return { ...prevErrorValue, shhipergstOCR: ERROR_MESSAGE['gstOCRRequired'] }
      })
    }
    else {
      // console.log(formValue);
      let formIsValid = true;
      if (typeOfRegistration == 'Shipper') {

        if (bType == 'Individual') {
          console.log('11oo', formValue);
          if (!!formTouchValue.email) checkError("email");
          if (!!formTouchValue.phone) checkError("phone");
          if (!!formTouchValue.aadharNo) checkError("aadharNo");
          if (!!formTouchValue.password) checkError("password");
          if (!!formTouchValue.confirmPassword) checkError("confirmPassword");
          formIsValid = false;
        }
        if (bType == 'Organisation') {
          // console.log('12oo', formValue);
          setFormValue((prevValue) => {
            const { firstName, lastName, estyear, aadharNo, ...rest } = prevValue;
            // console.log(prevValue);
            return rest;
          })
          checkError("transportername");
          checkError("officeAddress");
          checkError("gstno");
          checkError("documentType");
          checkError("businesstype");
          checkError("phone");
          checkError("email");
          checkError("password");
          checkError("confirmPassword");
          formIsValid = false;
        }
      } else {
        if (bType == 'Individual') {
          if (!!formTouchValue.email) checkError("email");
          if (!!formTouchValue.phone) checkError("phone");
          if (!!formTouchValue.aadharNo) checkError("aadharNo");
          if (!!formTouchValue.password) checkError("password");
          if (!!formTouchValue.confirmPassword) checkError("confirmPassword");
          checkError("panNo");
          formIsValid = false;
        }
        if (bType == 'Organisation') {
          console.log('2oo');
          setFormValue((prevValue) => {
            const { firstName, lastName, estyear, aadharNo, panNo, ...rest } = prevValue;
            // console.log(prevValue);
            return rest;
          })
          checkError("businesstype");
          checkError("phone");
          checkError("email");
          checkError("password");
          checkError("confirmPassword");
          checkError("officeAddress");
          checkError("transportername");
          checkError("estyear");
          checkError("gstno");
          formIsValid = false;

        }

      }

      // console.log(formValue);

      if (isFormValid(formValue)) {
        if (String(formValue.password) == String(formValue.confirmPassword)) { console.log('ok'); _signup(); }

      } else console.log('no');
      return formIsValid;
    }


  };



  /**
  * @author Sarmistha Mondal
  * @Date_Created 15.11.2023
  * @Date_Modified 
  * @function async
  * @functionName _signup
  * @functionPurpose this function gets
  * Lists of business type
  *
  *  @functionParam {payload object: email,password,first_name,last_name,whatsapp_number
  *   gst_number,is_gst_number_verified, business_type_id, pan_number,is_pan_number_verified}
  * 
  * @functionSuccess Success status and message.
  *
  * @functionError {Boolean} error is there.
  * @functionError {String} message  Description message.
  */
  const _signup = () => {
    let url;
    if (typeOfRegistration == 'Shipper') {
      // console.log('1');
      url = RouteURL.shipperRegister;
      formData.set(`first_name`, formValue.firstName);
      formData.set(`last_name`, formValue.lastName);
      formData.set(`business_type`, bType);
      formData.set(`whatsapp_number`, formValue.phone);
      formData.set(`email`, formValue.email);
      formData.set(`gst_number`, is_gst_number_verified == 1 ? formValue.gstno : "");
      formData.set(`aadhar_number`, is_aadhar_number_verified == 1 ? formValue.aadharNo : "");
      formData.set(`password`, formValue.password);
      formData.set(`is_email_verified`, localStorage.getItem("is_email_verified"));
      formData.set(`is_gst_number_verified`, is_gst_number_verified == 1 ? '1' : '0');
      formData.set(`is_aadhar_number_verified`, is_aadhar_number_verified == 1 ? '1' : '0');
      // formData.set(`is_gst_verified`, is_gst_number_verified == 1 ? '1' : '0');
      formData.set(`office_address`, formValue.officeAddress);
      formData.set(`document_type`, formValue.documentType);
      formData.set(`ocr_file`, formValue.documentType == "Aadhar" ? shhiperaadharOCR : shhipergstOCR);

    } else {
      // console.log('2');
      url = RouteURL.transporterRegister;

      formData.set(`firm_name`, bType == 'Organisation' ? formValue.transportername : '');
      formData.set(`establishment_year`, bType == 'Organisation' ? formValue.estyear : '');
      formData.set(`business_type`, formValue.businesstype);
      formData.set(`whatsapp_number`, formValue.phone);
      formData.set(`email`, formValue.email);
      formData.set(`gst_number`, bType == 'Organisation' ? formValue.gstno : '');
      formData.set(`password`, formValue.password);
      formData.set(`is_email_verified`, localStorage.getItem("is_email_verified"));
      formData.set(`is_gst_verified`, is_gst_number_verified == 1 ? '1' : '0');
      formData.set(`office_address`, formValue.officeAddress);
      formData.set(`aadhar_number`, is_aadhar_number_verified == 1 ? formValue.aadharNo : "");
      formData.set(`pan_number`, formValue.panNo);
      formData.set(`register_type`, bType == 'Organisation' ? 'Organization' : bType);
      formData.set(`first_name`, formValue.firstName);
      formData.set(`last_name`, formValue.lastName);
      formData.set(`ocr_file`, formValue.documentType == "Aadhar" ? shhiperaadharOCR : gstOCR);

    }
    // console.log(url, formValue.policy,);


    if (isFormValid(formValue) && is_email_verified == '1' && (is_gst_number_verified == 1 || is_aadhar_number_verified == 1)) {
      if (((localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1) && ((localStorage.getItem("email") == formValue.email) && is_email_verified == 1)
        && (((localStorage.getItem("gstno") == formValue.gstno) && is_gst_number_verified == 1) || ((localStorage.getItem("aadharNo") == formValue.aadharNo) && is_aadhar_number_verified == 1))) {
        setSubmitDisable(true)
        if (formValue.policy == true || formValue.policy == 'true') {

          Service.apiPostCallFormDataRequest(url, formData).then(
            (response) => {
              setSubmitDisable(false)
              // console.log(response);
              if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                localStorage.setItem('token', response.data.userDetails.token);
                localStorage.setItem('user_type', response.data.userDetails.user_type);
                localStorage.setItem('firm_name', response.data.profileDetails.firm_name);
                localStorage.setItem('name', response.data.profileDetails.name);
                localStorage.setItem('first_name', response.data.profileDetails.first_name + ' ' + response.data.profileDetails.last_name);
                localStorage.setItem('is_receiver', response.data.userDetails.is_receiver)
                localStorage.setItem('Profile_img', response.data.userDetails.profile_image)
                localStorage.setItem('profile_details', JSON.stringify(response.data.profileDetails))
                // localStorage.removeItem("ocr_files");
                if (response.data.is_valid_subscription == false) setCongo(true)

              } else {

                toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            }).catch((error) => {
              setSubmitDisable(false)
              if (error.response.status === 401) {
                localStorage.clear();
                navigate("/login");
                window.location.reload();
              }
            });
        } else {
          toast.error("Accept terms and condition before to process", {
            position: toast.POSITION.BOTTOM_RIGHT,
            onClose: () => {
              setSubmitDisable(false);
            },
          });
        }
      } else { console.log('not veryfy'); }

    } else {
      console.log('else1', is_gst_number_verified, is_aadhar_number_verified, localStorage.getItem("is_email_verified"));
    }

  };

  const onChangeSomeState = (State) => {
    // console.log(State);
    if (!!State) {
      if (openModalForOTP.type == 'email') {
        setIsEmailVerified(State)
      } else if (openModalForOTP.type == 'phone') {
        setIsPhoneVerified(State)

      } else if (openModalForOTP.type == 'gst') {
        setIs_gst_number_verified(State)

      } else if (openModalForOTP.type == 'aadhar') {
        setIs_aadhar_number_verified(State)

      }

      // console.log(State);


    }
  };

  /* password view*/
  const togglePasswordVisibility = (e) => {

    setPasswordShown((prev) => {
      return {
        ...prev, password: passwordShown.password ? false : true,

      };
    });

  };
  /* confirm password view*/
  const toggleConfPasswordVisibility = (e) => {
    setPasswordShown((prev) => {
      return {
        ...prev,
        confirmPassword: passwordShown.confirmPassword ? false : true,
      };
    });
  };

  /* after otp verify data autofill in input field */
  const _otpModalCloseForOTP = (state, res) => {
    // console.log(state, res);
    setopenModalForOTP({ visible: false })
    if (state == 'forGst') {
      localStorage.setItem("gstno", res.gst_number)
      setFormValue((prevFormValue) => {
        return {
          ...prevFormValue,
          transportername: res.firm_name,
          estyear: Number(res.establishment_year),
          businesstype: res.business_type,
          gstno: res.gst_number,
          officeAddress: res.office_address,
          documentType: "GST",
        };

      });
      setInputDisable((prevFormValue) => {
        return {
          ...prevFormValue,
          transportername: false,
          estyear: false,
          businesstype: false,
          gstno: false,
          officeAddress: false,
          phone: true,
          email: true,
          password: true,
          confirmPassword: true,
        };

      });
    } if (state == 'forAadhar') {
      localStorage.setItem("aadharNo", res.aadhar_number)
      setFormValue((prevFormValue) => {
        return {
          ...prevFormValue,
          firstName: res.first_name,
          lastName: res.last_name,
          documentType: "Aadhar",
          aadharNo: res.aadhar_number,
          officeAddress: res.office_address,
        };

      });
      setInputDisable((prevFormValue) => {
        return {
          ...prevFormValue,
          firstName: false,
          lastName: false,
          documentType: false,
          aadharNo: false,
          officeAddress: false,
          phone: true,
          email: true,
          password: true,
          confirmPassword: true,
          panNo: true
        };

      });
    }
  }


  /**
   * @author Sarmistha Mondal
   * @Date_Created 09.02.2024
   * @Date_Modified 
   * @function async
   * @functionName _submitOcrTransporterGst
   * @functionPurpose this function for
   * transpoter ocr upload
   *
   *  @functionParam {payload object: ocr_files}
   * 
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */

  const _submitOcrTransporterGst = (file) => {

    if (file.length > 0) {
      setFileUploadModal(!fileUploadModal)
      formData.set(`ocr_files`, file[0]);
      setLoader(1)
      setIsDisabled(true)
      Service.apiPostCallFormDataRequest(RouteURL.ocrTransporterGst, formData).then(
        (response) => {
          // console.log(response);
          if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
            let res = response.data.ocrDetails;


            _generateGstNumber(res.id_number)

            setErrorValue((prevErrorValue) => {
              return { ...prevErrorValue, gstOCR: '' }
            })


          } else {
            setLoader(0)
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT, onClose: () => {
                setIsDisabled(false);
              },

            });
            setInputDisable((prevFormValue) => {
              return {
                ...prevFormValue,
                transportername: false,
                estyear: false,
                businesstype: false,
                gstno: true,
                officeAddress: false,
                phone: true,
                email: true,
                password: true,
                confirmPassword: true,
              };

            });
          }
        }).catch((error) => {
          // console.log(error);
          setIsDisabled(false);
          if (error.response.status === 401) {
            localStorage.clear();
            navigate("/login");
            window.location.reload();
          }
        });
    }
  }

  /* shipper ocr upload*/
  const _shipperOCRUpload = (e) => {
    setIsDisabled(true)
    if (bType == 'Individual') {
      setShhiperaadharOCR(e.target.files[0])
      _submitOcrShipperAadhar(e.target.files[0])
      e.target.value = ''
    } else {
      setShhipergstOCR(e.target.files[0])
      _submitOcrShipperGst(e.target.files[0])
      e.target.value = ''
    }

  }

  /* transporter ocr upload*/
  const _transporterOCRUpload = (e) => {
    setIsDisabled(true)
    if (bType == 'Individual') {
      setShhiperaadharOCR(e.target.files[0])
      _submitOcrShipperAadhar(e.target.files[0])
      e.target.value = ''
    } else {
      setShhipergstOCR(e.target.files[0])
      _submitOcrTransporterGst(e.target.files)
      e.target.value = ''
    }
  }

  /**
   * @author Sarmistha Mondal
   * @Date_Created 12.02.2024
   * @Date_Modified 
   * @function async
   * @functionName _submitOcrShipperGst
   * @functionPurpose this function for
   * shipper ocr upload
   *
   *  @functionParam {payload object: ocr_files}
   * 
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */

  const _submitOcrShipperGst = (file) => {
    // console.log(file);
    if (file != undefined) {
      setLoader(1)
      formData.set(`ocr_files`, file);
      Service.apiPostCallFormDataRequest(RouteURL.ocrShipperGst, formData).then(
        (response) => {
          // console.log(response);
          if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
            let res = response.data.ocrDetails;
            _generateGstNumber(res.id_number)

            setErrorValue((prevErrorValue) => {
              return { ...prevErrorValue, shhipergstOCR: '' }
            })


          } else {
            setLoader(0)
            setInputDisable({ gstno: true })
            setInputDisable((prevFormValue) => {
              return {
                ...prevFormValue,
                transportername: false,
                estyear: false,
                businesstype: false,
                gstno: true,
                officeAddress: false,
                phone: true,
                email: true,
                password: true,
                confirmPassword: true,
              };

            });
            setInputDisable((prevValue) => {
              const { transportername, estyear, businesstype, officeAddress, ...rest } = prevValue;
              // console.log(prevValue);
              return {
                ...prevValue,
                gstno: true,
                phone: true,
                email: true,
                password: true,
                confirmPassword: true,
              };
              // return rest;
            })
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              onClose: () => {
                setIsDisabled(false);
              },
            });

          }
        }).catch((error) => {
          // console.log(error);
          setIsDisabled(false);
          if (error.response.status === 401) {
            localStorage.clear();
            navigate("/login");
            window.location.reload();
          }
        });
    }
  }


  /**
   * @author Sarmistha Mondal
   * @Date_Created 12.02.2024
   * @Date_Modified 
   * @function async
   * @functionName _submitOcrShipperAadhar
   * @functionPurpose this function for
   * shipper addhar ocr upload
   *
   * @functionParam {payload object: ocr_files}
   * 
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */

  const _submitOcrShipperAadhar = (file) => {
    if (file != undefined) {
      setLoader(1)
      formData.set(`ocr_files`, file);
      Service.apiPostCallFormDataRequest(RouteURL.ocrShipperAadhar, formData).then(
        (response) => {
          console.log(response);
          if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
            let res = response.data.ocrDetails;
            setIsDisabled(false);
            setopenModalForOTP({ visible: true, type: 'aadhar', emailOrPhone: res.id_number, pageFor: 'signup', client_id: res.client_id })
            // _generateAadharNumber(res.id_number)
            setErrorValue((prevErrorValue) => {
              return { ...prevErrorValue, shhiperaadharOCR: '' }
            })

            setLoader(0)
          } else {
            console.log('not ok');
            setLoader(0)
            // console.log(loader)
            // console.log(loader, inputDisable);
            setInputDisable({ aadharNo: true })
            setInputDisable((prevFormValue) => {
              console.log(prevFormValue);
              return {
                ...prevFormValue,
                firstName: false,
                lastName: false,
                documentType: false,
                aadharNo: true,
                officeAddress: false,
                phone: true,
                email: true,
                password: true,
                confirmPassword: true,
                panNo: true,
              };

            });
            setInputDisable((prevValue) => {
              const { firstName, lastName, documentType, officeAddress, ...rest } = prevValue;
              // console.log(prevValue);
              return {
                ...prevValue,
                aadharNo: true,
                panNo: true,
                phone: true,
                email: true,
                password: true,
                confirmPassword: true,
              };
              // return rest;
            })
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              onClose: () => {
                setIsDisabled(false);
              },
            });

          }
        }).catch((error) => {
          // console.log(error);
          setIsDisabled(false);
          if (error.response.status === 401) {
            localStorage.clear();
            navigate("/login");
            window.location.reload();
          }
        });
    }
  }

  /**
     * @author Sarmistha Mondal
     * @Date_Created 10.06.2024
     * @Date_Modified 
     * @function async
     * @functionName _submitOcrTransporterPan
     * @functionPurpose this function for
     * shipper addhar ocr upload
     *
     *  @functionParam {payload object: ocr_files}
     * 
     * @functionSuccess Success status and message.
     *
     * @functionError {Boolean} error is there.
     * @functionError {String} message  Description message.
     */

  const _submitOcrTransporterPan = (file) => {
    if (file != undefined) {
      setLoader(1)
      formData.set(`ocr_files`, file);
      Service.apiPostCallFormDataRequest(RouteURL.ocrShipperAadhar, formData).then(
        (response) => {
          console.log(response);
          if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
            let res = response.data.ocrDetails;
            setIsDisabled(false);
            setopenModalForOTP({ visible: true, type: 'aadhar', emailOrPhone: res.id_number, pageFor: 'signup', client_id: res.client_id })
            // _generateAadharNumber(res.id_number)
            setErrorValue((prevErrorValue) => {
              return { ...prevErrorValue, shhiperaadharOCR: '' }
            })

            setLoader(0)
          } else {
            console.log('not ok');
            setLoader(0)
            console.log(loader)
            console.log(loader, inputDisable);
            setInputDisable({ aadharNo: true })
            setInputDisable((prevFormValue) => {
              console.log(prevFormValue);
              return {
                ...prevFormValue,
                firstName: false,
                lastName: false,
                documentType: false,
                aadharNo: true,
                officeAddress: false,
                phone: true,
                email: true,
                password: true,
                confirmPassword: true,
              };

            });
            setInputDisable((prevValue) => {
              const { firstName, lastName, documentType, officeAddress, ...rest } = prevValue;
              // console.log(prevValue);
              return {
                ...prevValue,
                aadharNo: true,
                phone: true,
                email: true,
                password: true,
                confirmPassword: true,
              };
              // return rest;
            })
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              onClose: () => {

                setIsDisabled(false);
              },
            });
          }
        }).catch((error) => {
          // console.log(error);
          setIsDisabled(false);
          if (error.response.status === 401) {
            localStorage.clear();
            navigate("/login");
            window.location.reload();
          }
        });
    }
  }

  return (
    <>
      <ToastContainer />
      <section className="signup_sec">
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-5 col-sm-12 p-0">
              <div className="select_page_lt">
                <h2>Lorem ipsum is <br /><span>a dummy text</span></h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <img src={HomeImg} alt="" />
              </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-7 col-sm-12">
              <div className="create_acct_sec">
                <h4>Create Account</h4>
                <h6>Get Started with Your {typeOfRegistration} Details</h6>

                {typeOfRegistration == 'Transporter' ?
                  <>

                    <div className="shipper_create">
                      <div className="row">
                        <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-4 col-md-12">
                          <div className="shipper_business_type">
                            <p>Business Type</p>
                            <div className="radio_sec">
                              <input id="radio-1" className="radio-custom" type="radio" value="Individual" name="businesstype" checked={bType == 'Individual' ? true : false}
                                onChange={formChange} disabled={loader == 1 ? true : false} />
                              <label for="radio-1" className="radio-custom-label" style={{ color: "#000" }}>Individual</label>
                            </div>
                            <div className="radio_sec">
                              <input id="radio-2" className="radio-custom" name="businesstype" type="radio" value="Organisation" onChange={formChange} checked={bType == 'Organisation' ? true : false} disabled={loader == 1 ? true : false} />
                              <label for="radio-2" className="radio-custom-label">Organisation</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-xxl-7 col-xl-7 col-lg-8 col-md-12">
                          <div className="shipper_create_rt">
                            <p>Upload to Autofill The Details Below </p>
                            <div className="uploadbtn">
                              <div className="choose_file d-flex align-items-center justify-content-center">
                                <div className="upload-btn-wrapper">
                                  <label for="banner_image">
                                    <button className="btn-custom" >
                                      <input type="file" accept=".jpg,.jpeg,.png,.pdf" onChange={_transporterOCRUpload} disabled={isDisabled ? true : false} />
                                      <i className="fa-solid fa-upload"></i> Upload {bType == 'Individual' ? 'Aadhar' : 'GST'} Document
                                    </button>
                                  </label>
                                  {loader == 1 ? <Loader type="spinner-default" bgColor={'white'} color={'white'} size={30} /> : ""}
                                </div>
                              </div>
                            </div>

                          </div>

                          {bType == 'Individual' && !shhiperaadharOCR && errorValue.shhiperaadharOCR && (
                            <span className="error" style={{ alignContent: 'center' }}>
                              {errorValue.shhiperaadharOCR}
                            </span>
                          )}
                          {bType == 'Organisation' && !shhipergstOCR && errorValue.gstOCR && (
                            <span className="error" style={{ alignContent: 'center' }}>
                              {errorValue.gstOCR}
                            </span>
                          )}

                          {/* {console.log(shhipergstOCR, shhiperaadharOCR)} */}
                        </div>
                      </div>

                    </div>


                  </> :
                  <div className="shipper_create">
                    <div className="row">
                      <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-4 col-md-12">
                        <div className="shipper_business_type">
                          <p>Business Type</p>
                          <div className="radio_sec">
                            <input id="radio-1" className="radio-custom" type="radio" value="Individual" name="businesstype" checked={bType == 'Individual' ? true : false}
                              onChange={formChange} disabled={loader == 1 ? true : false} />
                            <label for="radio-1" className="radio-custom-label" style={{ color: "#000" }}>Individual</label>
                          </div>
                          <div className="radio_sec">
                            <input id="radio-2" className="radio-custom" name="businesstype" type="radio" value="Organisation" onChange={formChange} checked={bType == 'Organisation' ? true : false} disabled={loader == 1 ? true : false} />
                            <label for="radio-2" className="radio-custom-label">Organisation</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-xxl-7 col-xl-7 col-lg-8 col-md-12">
                        <div className="shipper_create_rt">
                          <p>Upload to Autofill The Details Below </p>
                          <div className="uploadbtn">
                            <div className="choose_file d-flex align-items-center justify-content-center">
                              <div className="upload-btn-wrapper">
                                <label for="banner_image">
                                  <button className="btn-custom" >
                                    <input type="file" accept=".jpg,.jpeg,.png,.pdf" onChange={_shipperOCRUpload} disabled={isDisabled ? true : false} />
                                    <i className="fa-solid fa-upload"></i> Upload {bType == 'Individual' ? 'Aadhar' : 'GST'} Document
                                  </button>
                                </label>
                                {loader == 1 ? <Loader type="spinner-default" bgColor={'white'} color={'white'} size={30} /> : ""}
                              </div>
                            </div>
                          </div>

                        </div>
                        {bType == 'Individual' && errorValue.shhiperaadharOCR && (
                          <span className="error" style={{ alignContent: 'center' }}>
                            {errorValue.shhiperaadharOCR}
                          </span>
                        )}
                        {bType == 'Organisation' && errorValue.shhipergstOCR && (
                          <span className="error" style={{ alignContent: 'center' }}>
                            {errorValue.shhipergstOCR}
                          </span>
                        )}
                      </div>
                    </div>

                  </div>}

                <form
                  onSubmit={(e) => ValidateForm(e)}
                  name="signin_form"
                >
                  <div className="row">
                    {(typeOfRegistration == 'Transporter' && bType == 'Organisation') &&
                      <>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="create_sub">
                            <label style={{ color: !formValue.transportername ? '#C5C4D2' : '#000' }}>Transporter Firm Name</label>
                            <input type="text" name="transportername" placeholder="Transporter Firm Name" onChange={formChange}
                              onKeyDown={updateFormTouchValue}
                              value={formValue.transportername}
                              error={errorValue.transportername ? "true" : "false"}
                              disabled={inputDisable.transportername ? false : true}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="create_sub">
                            <label style={{ color: !formValue.estyear ? '#C5C4D2' : '#000' }}>Establishment Year</label>
                            <input type="text" placeholder="Firm Establishment Year" maxLength="4" name="estyear"
                              onChange={formChange}
                              onKeyDown={updateFormTouchValue}
                              value={formValue.estyear}
                              error={errorValue.estyear ? "true" : "false"}
                              disabled={inputDisable.estyear ? false : true} />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="create_sub">
                            <label style={{ color: !formValue.businesstype ? '#C5C4D2' : '#000' }}>Business Type</label>
                            <input type="text" placeholder="Organization" name="businesstype"
                              // onChange={formChange}
                              // onKeyDown={updateFormTouchValue}
                              value={formValue.businesstype}
                              error={errorValue.businesstype ? "true" : "false"}
                              disabled={inputDisable.businesstype ? false : true} />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="create_sub">
                            <label style={{ color: !formValue.gstno ? '#C5C4D2' : '#000' }}>GST Number</label>
                            <input type="text" placeholder={`${inputDisable.gstno == true ? 'Enter ' : ''}` + `Firm's GST Number`} name="gstno"
                              maxLength={15}
                              minLength={15}
                              onChange={(e) => {
                                setFormValue((prevFormValue) => {
                                  return { ...prevFormValue, 'gstno': e.target.value };
                                })
                              }}
                              // onChange={formChange}
                              onKeyDown={updateFormTouchValue}
                              style={{ borderColor: inputDisable.gstno == true ? (!formValue.gstno ? '#000' : '#C5C4D2') : '' }}
                              value={formValue.gstno ? formValue.gstno.toUpperCase() : ""}
                              error={errorValue.gstno ? "true" : "false"}
                              disabled={inputDisable.gstno ? false : true} />
                            <span className="verify_sec" id="gstGen"
                              style={{ color: formValue.gstno ? ((localStorage.getItem("gstno") != formValue.gstno) || is_gst_number_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => {
                                if (formValue['gstno'].match(REGEX['gstno'])) _generateGstNumber(formValue.gstno)
                              }}>
                              {(localStorage.getItem("gstno") == formValue.gstno) && is_gst_number_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("gstno") == formValue.gstno) && is_gst_number_verified == 1 ? 'verified' : 'verify'}
                            </span>
                          </div>
                          {errorValue.gstno && (
                            <span className="error">
                              {errorValue.gstno}
                            </span>
                          )}
                        </div>

                        <div className="col-sm-6">
                          <div className="create_sub">
                            <label style={{ color: !formValue.officeAddress ? '#C5C4D2' : '#000' }}>Office Address</label>
                            <input type="text" placeholder={`Office Address`} name="officeAddress" onChange={formChange}
                              onKeyDown={updateFormTouchValue}
                              value={formValue.officeAddress}
                              error={errorValue.officeAddress ? "true" : "false"}
                              disabled={inputDisable.officeAddress ? false : true} />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="create_sub">
                            <label style={{ color: !formValue.email ? '#C5C4D2' : '#000' }}>Email Id </label>
                            <input type="email"
                              placeholder={`${inputDisable.email == true ? 'Enter ' : ''}` + `Your Email Id`}
                              // onChange={formChange}
                              onChange={(e) => setFormValue((prevFormValue) => {
                                return { ...prevFormValue, 'email': e.target.value };
                              })}
                              name="email"
                              onkeyDown={updateFormTouchValue}
                              value={formValue.email}
                              error={errorValue.email ? "true" : "false"}
                              disabled={inputDisable.email ? false : true}
                            />

                            <span className="verify_sec "
                              style={{ color: formValue.email ? ((localStorage.getItem("email") != formValue.email) || is_email_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => {
                                if (formValue['email'].match(REGEX['email'])) _generateEmailForOTP()
                              }}
                            >

                              {(localStorage.getItem("email") == formValue.email) && is_email_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("email") == formValue.email) && is_email_verified == 1 ? 'verified' : 'verify'}
                            </span>
                          </div>
                          {errorValue.email && (
                            <span className="error">
                              {errorValue.email}
                            </span>
                          )}
                        </div>

                        <div className="col-sm-6">
                          <div className="create_sub">
                            <label style={{ color: !formValue.phone ? '#C5C4D2' : '#000' }}>WhatsApp Number</label>
                            <input type="number"
                              placeholder={`${inputDisable.phone == true ? 'Enter ' : ''}` + `Your WhatsApp Number`}
                              name="phone"
                              maxLength={10}
                              pattern="[0-9]*"
                              // onChange={formChange}
                              onChange={(e) => setFormValue((prevFormValue) => {
                                return { ...prevFormValue, 'phone': e.target.value };
                              })}
                              onkeydown={updateFormTouchValue}
                              onKeyDown={e => checkLength(e)}
                              onKeyUp={removeSpecialCharacters}
                              value={formValue.phone}
                              error={errorValue.phone ? "true" : "false"}
                            // disabled={inputDisable.phone ? false : true} 
                            />

                            <span className="verify_sec" id="gen_otp"
                              style={{ color: formValue.phone ? ((localStorage.getItem("phone") != formValue.phone) || is_phone_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => {
                                if (formValue['phone'].match(REGEX['phone'])) _generateWhatsappOTP()
                              }}
                            >
                              {(localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1 ? 'verified' : 'verify'}
                            </span>
                          </div>
                          {errorValue.phone && (
                            <span className="error">
                              {errorValue.phone}
                            </span>
                          )}
                        </div>
                      </>
                    }

                    {/* {typeOfRegistration == 'Shipper' && */}
                    <>
                      {((typeOfRegistration == 'Shipper' || typeOfRegistration == 'Transporter') && bType == 'Individual') &&
                        <>
                          <div className="col-md-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.firstName ? '#C5C4D2' : '#000' }} >First Name
                              </label>
                              <input type="text"
                                name="firstName"
                                placeholder="First Name"
                                onChange={formChange}
                                onKeyDown={updateFormTouchValue}
                                value={formValue.firstName}
                                error={errorValue.firstName ? "true" : "false"}
                                disabled={inputDisable.firstName ? false : true} />

                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.lastName ? '#C5C4D2' : '#000' }} >Last Name
                              </label>
                              <input type="text"
                                name="lastName"
                                placeholder="Last Name"
                                onChange={formChange}
                                onKeyDown={updateFormTouchValue}
                                value={formValue.lastName}
                                error={errorValue.lastName ? "true" : "false"}
                                disabled={inputDisable.lastName ? false : true} />
                            </div>
                          </div>

                          {typeOfRegistration == 'Transporter' &&
                            <div className="col-sm-6">
                              <div className="create_sub">
                                <label style={{ color: !formValue.businesstype ? '#C5C4D2' : '#000' }}>Business Type</label>
                                <input type="text" placeholder="Business Type" name="businesstype"
                                  // onChange={formChange}
                                  // onKeyDown={updateFormTouchValue}
                                  value={formValue.businesstype}
                                  error={errorValue.businesstype ? "true" : "false"}
                                  disabled={inputDisable.businesstype ? false : true} />
                              </div>
                            </div>}

                          <div className="col-md-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.gstno ? '#C5C4D2' : '#000' }} >Document Type
                              </label>
                              <input type="text"
                                name="documentType"
                                placeholder="Document Type"
                                onChange={formChange}
                                onKeyDown={updateFormTouchValue}
                                value={formValue.documentType}
                                error={errorValue.documentType ? "true" : "false"}
                                disabled={inputDisable.documentType ? false : true} />

                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.email ? '#C5C4D2' : '#000' }}>Email Id</label>
                              <input type="email"
                                placeholder={`${inputDisable.email == true ? 'Enter ' : ''}` + `Your Email Id`}
                                // onChange={formChange} 
                                onChange={(e) => setFormValue((prevFormValue) => {
                                  return { ...prevFormValue, 'email': e.target.value };
                                })}
                                name="email"
                                onkeyDown={updateFormTouchValue}
                                value={formValue.email}
                                error={errorValue.email ? "true" : "false"}
                                // disabled={inputDisable.email ? false : true} 
                                disabled={location.state.user_type == 'Receiver' ? true : (inputDisable.email ? false : true)}
                              />

                              <span className="verify_sec " id="genEmail"
                                style={{ color: formValue.email ? ((localStorage.getItem("email") != formValue.email) || is_email_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  if (formValue['email'].match(REGEX['email'])) _generateEmailForOTP()
                                }}
                              >
                                {(localStorage.getItem("email") == formValue.email) && is_email_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("email") == formValue.email) && is_email_verified == 1 ? 'verified' : 'verify'}
                              </span>
                            </div>
                            {errorValue.email && (
                              <span className="error">
                                {errorValue.email}
                              </span>
                            )}
                          </div>

                          <div className="col-sm-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.officeAddress ? '#C5C4D2' : '#000' }}>Address</label>
                              <input type="text" placeholder="Address" name="officeAddress" onChange={formChange}
                                onKeyDown={updateFormTouchValue}
                                value={formValue.officeAddress}
                                error={errorValue.officeAddress ? "true" : "false"}
                                disabled={inputDisable.officeAddress ? false : true} />

                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.phone ? '#C5C4D2' : '#000' }}>WhatsApp Number</label>
                              <input type="number"
                                placeholder={`${inputDisable.phone == true ? 'Enter ' : ''}` + `Your WhatsApp Number`}
                                name="phone"
                                //  onChange={formChange}
                                onChange={(e) => setFormValue((prevFormValue) => {
                                  return { ...prevFormValue, 'phone': e.target.value };
                                })}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false;
                              }}
                                maxLength={10}
                                pattern="[0-9]*"
                                onkeydown={updateFormTouchValue}
                                onKeyDown={e => checkLength(e)}
                                onKeyUp={removeSpecialCharacters}
                                value={formValue.phone}
                                error={errorValue.phone ? "true" : "false"}
                                disabled={inputDisable.phone ? false : true}
                              />
                              <span className="verify_sec" id="gen_otp"
                                style={{ color: formValue.phone ? ((localStorage.getItem("phone") != formValue.phone) || is_phone_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  if (formValue['phone'].match(REGEX['phone'])) _generateWhatsappOTP()
                                }}
                              >
                                {(localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1 ? 'verified' : 'verify'}
                              </span>
                            </div>
                            {errorValue.phone && (
                              <span className="error">
                                {errorValue.phone}
                              </span>
                            )}
                          </div>

                          <div className="col-sm-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.aadharNo ? '#C5C4D2' : '#000' }}>Aadhar Number</label>
                              <input type="number" placeholder={`${inputDisable.aadharNo == true ? 'Enter ' : ''}` + `Aadhar Number`} name="aadharNo"
                                maxLength={12}
                                pattern="[0-9]*"
                                onkeydown={updateFormTouchValue}
                                onKeyDown={e => checkLength(e)}
                                onKeyUp={removeSpecialCharacters}
                                // onChange={formChange}
                                onChange={(e) => setFormValue((prevFormValue) => {
                                  return { ...prevFormValue, 'aadharNo': e.target.value };
                                })}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false;
                              }}
                                style={{ borderColor: inputDisable.aadharNo == true ? (!formValue.aadharNo ? '#000' : '#C5C4D2') : '' }}

                                value={formValue.aadharNo ? formValue.aadharNo : ""}
                                error={errorValue.aadharNo ? "true" : "false"}
                                disabled={inputDisable.aadharNo ? false : true} />

                              {/* {inputDisable} */}
                              <span className="verify_sec "
                                style={{ color: formValue.aadharNo ? ((localStorage.getItem("aadharNo") != formValue.aadharNo) || is_aadhar_number_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  if (formValue['aadharNo'].match(REGEX['aadharNo'])) _generateAadharNumber(formValue.aadharNo)
                                }}>
                                {(localStorage.getItem("aadharNo") == formValue.aadharNo) && is_aadhar_number_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("aadharNo") == formValue.aadharNo) && is_aadhar_number_verified == 1 ? 'verified' : 'verify'}

                              </span>
                            </div>
                            {errorValue.aadharNo && (
                              <span className="error">
                                {errorValue.aadharNo}
                              </span>
                            )}
                          </div>

                          {typeOfRegistration == 'Transporter' &&
                            <div className="col-sm-6">
                              <div className="create_sub">
                                <label style={{ color: !formValue.panNo ? '#C5C4D2' : '#000' }}>Pan Number</label>
                                <input type="text" placeholder={`${inputDisable.panNo == true ? 'Enter ' : ''}` + `Pan Number`} name="panNo"
                                  maxLength={10}
                                  minLength={10}
                                  onChange={(e) => {
                                    setFormValue((prevFormValue) => {
                                      return { ...prevFormValue, 'panNo': e.target.value };
                                    })
                                  }}
                                onPaste={(e) => {
                                  e.preventDefault()
                                  return false;
                                }}
                                  onkeydown={updateFormTouchValue}
                                  value={formValue.panNo ? formValue.panNo.toUpperCase() : ""}
                                  error={errorValue.panNo ? "true" : "false"}
                                  disabled={inputDisable.panNo ? false : true}
                                />

                                <span className="verify_sec" id="panGen"
                                  style={{ color: formValue.panNo ? ((localStorage.getItem("panNo") != formValue.panNo) || is_pan_number_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                  onClick={() => {
                                    // if (formValue['panNo'].match(REGEX['panNo']))
                                    _generatePanNumber(formValue.panNo)
                                  }}>
                                  {(localStorage.getItem("panNo") == formValue.panNo) && is_pan_number_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("panNo") == formValue.panNo) && is_pan_number_verified == 1 ? 'verified' : 'verify'}

                                </span>
                              </div>
                              {errorValue.panNo && (
                                <span className="error">
                                  {errorValue.panNo}
                                </span>
                              )}
                            </div>
                          }
                        </>}

                      {(typeOfRegistration == 'Shipper' && bType == 'Organisation') &&
                        <>
                          <div className="col-md-6"><div className="create_sub">
                            <label style={{ color: !formValue.transportername ? '#C5C4D2' : '#000' }} >Firm Name
                            </label>
                            <input type="text"
                              name="transportername"
                              placeholder="Firm Name"
                              onChange={formChange}
                              onKeyDown={updateFormTouchValue}
                              value={formValue.transportername}
                              error={errorValue.transportername ? "true" : "false"}
                              disabled={inputDisable.transportername ? false : true} />
                          </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.officeAddress ? '#C5C4D2' : '#000' }}>Address</label>
                              <input type="text" placeholder="Address" name="officeAddress" onChange={formChange}
                                onKeyDown={updateFormTouchValue}
                                value={formValue.officeAddress}
                                error={errorValue.officeAddress ? "true" : "false"}
                                disabled={inputDisable.officeAddress ? false : true} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.gstno ? '#C5C4D2' : '#000' }} >Document Type
                              </label>
                              <input type="text"
                                name="documentType"
                                placeholder="Document Type"
                                onChange={formChange}
                                onKeyDown={updateFormTouchValue}
                                value={formValue.documentType}
                                error={errorValue.documentType ? "true" : "false"}
                                disabled={inputDisable.documentType ? false : true} />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.email ? '#C5C4D2' : '#000' }}>Email Id </label>
                              <input type="email"
                                placeholder={`${inputDisable.email == true ? 'Enter ' : ''}` + `Your Email Id`}
                                // onChange={formChange} 
                                onChange={(e) => setFormValue((prevFormValue) => {
                                  return { ...prevFormValue, 'email': e.target.value };
                                })}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false;
                              }}
                                name="email"
                                onkeyDown={updateFormTouchValue}
                                value={formValue.email}
                                error={errorValue.email ? "true" : "false"}
                                disabled={location.state.user_type == 'Receiver' ? true : (inputDisable.email ? false : true)}
                              // disabled={inputDisable.email ? false : true} 
                              />

                              <span className="verify_sec " id="genEmail"
                                style={{ color: formValue.email ? ((localStorage.getItem("email") != formValue.email) || is_email_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  if (formValue['email'].match(REGEX['email'])) _generateEmailForOTP()
                                }}
                              >
                                {(localStorage.getItem("email") == formValue.email) && is_email_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("email") == formValue.email) && is_email_verified == 1 ? 'verified' : 'verify'}
                              </span>
                            </div>
                            {errorValue.email && (
                              <span className="error">
                                {errorValue.email}
                              </span>
                            )}
                          </div>

                          <div className="col-sm-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.gstno ? '#C5C4D2' : '#000' }}>GST Number</label>
                              <input type="text" placeholder={`${inputDisable.gstno == true ? 'Enter ' : ''}` + `Firm's GST Number`}
                                name="gstno"
                                maxLength={15}
                                minLength={15}
                                // onChange={formChange}
                                onChange={(e) => {
                                  setFormValue((prevFormValue) => {
                                    return { ...prevFormValue, 'gstno': e.target.value };
                                  })
                                }}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false;
                              }}
                                style={{ borderColor: inputDisable.gstno == true ? (!formValue.gstno ? '#000' : '#C5C4D2') : '' }}

                                onkeydown={updateFormTouchValue}
                                value={formValue.gstno ? formValue.gstno.toUpperCase() : ""}
                                error={errorValue.gstno ? "true" : "false"}
                                disabled={inputDisable.gstno ? false : true} />

                              <span className="verify_sec" id="gstGen"
                                style={{ color: formValue.gstno ? ((localStorage.getItem("gstno") != formValue.gstno) || is_gst_number_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  if (formValue['gstno'].match(REGEX['gstno'])) _generateGstNumber(formValue.gstno)
                                }}>
                                {(localStorage.getItem("gstno") == formValue.gstno) && is_gst_number_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("gstno") == formValue.gstno) && is_gst_number_verified == 1 ? 'verified' : 'verify'}

                              </span>
                            </div>
                            {errorValue.gstno && (
                              <span className="error">
                                {errorValue.gstno}
                              </span>
                            )}
                          </div>

                          <div className="col-sm-6">
                            <div className="create_sub">
                              <label style={{ color: !formValue.phone ? '#C5C4D2' : '#000' }}>WhatsApp Number</label>
                              <input type="number"
                                placeholder={`${inputDisable.phone == true ? 'Enter ' : ''}` + `Your WhatsApp Number`}
                                name="phone"
                                maxLength={10}
                                pattern="[0-9]*"
                                // onChange={formChange}
                                onChange={(e) => setFormValue((prevFormValue) => {
                                  return { ...prevFormValue, 'phone': e.target.value };
                                })}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false;
                              }}
                                onkeydown={updateFormTouchValue}
                                onKeyDown={e => checkLength(e)}
                                onKeyUp={removeSpecialCharacters}
                                value={formValue.phone}
                                error={errorValue.phone ? "true" : "false"}
                                disabled={inputDisable.phone ? false : true}
                              />
                              <span className="verify_sec" id="gen_otp"
                                style={{ color: formValue.phone ? ((localStorage.getItem("phone") != formValue.phone) || is_phone_verified == 0 ? '#EB2F06' : '#2ED573') : '#928FA9', textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => {
                                  if (formValue['phone'].match(REGEX['phone'])) _generateWhatsappOTP()
                                }}
                              >
                                {(localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1 && <img src={Verified} alt="" />}{(localStorage.getItem("phone") == formValue.phone) && is_phone_verified == 1 ? 'verified' : 'verify'}
                              </span>
                            </div>
                            {errorValue.phone && (
                              <span className="error">
                                {errorValue.phone}
                              </span>
                            )}
                          </div>
                        </>
                      }

                    </>
                    {/* } */}

                    <div className="col-sm-6">
                      <div className="create_sub">
                        <label style={{ color: !formValue?.password ? '#C5C4D2' : '#000' }}>Password</label>
                        <input type={passwordShown?.password == false ? "password" : "text"}
                          placeholder={`${inputDisable?.password == true ? 'Enter ' : ''}` + ` Password`}
                          // onChange={(e) => { formChange(e); setstateUpdate(!stateUpdate) }}
                          onChange={(e) => {
                            setFormValue((prevFormValue) => {
                              return { ...prevFormValue, 'password': e.target.value };
                            }); setstateUpdate(!stateUpdate)
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false;
                          }}
                          name="password"
                          minLength={8}
                          maxLength={10}
                          onKeyUp={updateFormTouchValue}
                          value={formValue?.password}
                          error={errorValue?.password ? "true" : "false"}
                          disabled={inputDisable?.password ? false : true}
                          autoComplete="off" />

                        <span className="verify_sec" ><i onClick={togglePasswordVisibility} id="password" className={passwordShown?.password ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'}></i></span>
                      </div>

                      {errorValue?.password && (
                        <span className="error">
                          {errorValue.password}
                        </span>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="create_sub">
                        <label style={{ color: !formValue?.confirmPassword ? '#C5C4D2' : '#000' }}>Confirm Password</label>
                        <input type={passwordShown?.confirmPassword == false ? "password" : "text"}
                          placeholder={`${inputDisable?.confirmPassword == true ? 'Enter Your ' : ''}` + `Confirm Password`}
                          minLength={8}
                          maxLength={10}
                          // onChange={formChange}
                          onChange={(e) => {
                            setFormValue((prevFormValue) => {
                              return { ...prevFormValue, 'confirmPassword': e.target.value };
                            })
                          }}
                          onPaste={(e) => {
                            e.preventDefault()
                            return false;
                          }}
                          name="confirmPassword"
                          onKeyUp={updateFormTouchValue}
                          value={formValue?.confirmPassword}
                          error={errorValue?.confirmPassword ? "true" : "false"}
                          disabled={inputDisable?.confirmPassword ? false : true}
                          autoComplete="off" />

                        <span className="verify_sec"><i onClick={toggleConfPasswordVisibility} id="confirmPassword" className={passwordShown.confirmPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'}></i></span>
                      </div>

                      {errorValue.confirmPassword && (
                        <span className="error">
                          {errorValue.confirmPassword}
                        </span>
                      )}
                    </div>

                    <div className="col-sm-6">
                      <div className="create_sub">

                        <label className="check_term" htmlFor="text-input">
                          <input id="cb1" type="checkbox" name="policy"
                            onChange={(e) => {
                              setFormValue((prevFormValue) => {
                                return { ...prevFormValue, 'policy': e.target.checked };
                              }); setUpdate(!upadate)
                            }}
                            // onChange={(e) => { formChange(e); setUpdate(!upadate) }}
                            checked={formValue?.policy}
                          /> I have read & agree to all the <span style={{ color: "#000", fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); setTerms(!terms) }}>Terms and Condition </span></label>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="create_sub text-center">
                        <div className="login_btn">

                          <button type="submit" value="get Started" disabled={(loader == 1 || submitDisable) ? true : false}
                            style={{ background: '#000' }}
                          // style={{ background: (formValue != undefined && (Object.values(formValue).every(x => x != '') == false) || ((is_phone_verified == 0 || is_email_verified == 0) && (is_gst_number_verified == 0 || is_aadhar_number_verified == 0)) ? '#C5C4D2' : '#000') }}
                          >Sign up</button>
                        </div>
                      </div>
                    </div>

                    <div className="regis_dwn_txt sign_dwntxt">
                      <p className="text-center">
                        Already have an Account?  <span style={{ color: "#FBAA2A", fontWeight: 600, textDecoration: 'underline', cursor: "pointer" }} onClick={() => navigate("/login")}>Sign In Now</span>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {openModalForOTP.visible == true ? <VerifyOTP otpFor={openModalForOTP} show={openModalForOTP.visible} onHide={() => setopenModalForOTP({ visible: !openModalForOTP.visible })} otpModalCloseForOTP={_otpModalCloseForOTP} onChangeSomeState={onChangeSomeState} /> : ""}

      <Modal
        show={terms}
        onHide={() => setTerms(!terms)}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="forgot_sec">
          <Modal.Header closeButton closeVariant={'white'}>
            <h6 className="hed_txt">Terms and Condition </h6>
          </Modal.Header>
          <div className="task_modal_sec">
            <section className="forgot_inn fotgot_pad">
              <span>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              </span>
              <div className="create_sub">

                <label className="check_term" htmlFor="text-input">
                  <input id="cb1" type="checkbox" name="policy"
                    onChange={(e) => {
                      setFormValue((prevFormValue) => {
                        return { ...prevFormValue, 'policy': e.target.checked };
                      }); setUpdate(!upadate)
                    }}
                    // onChange={(e) => { formChange(e); setUpdate(!upadate) }}
                    defaultChecked={Boolean(formValue?.policy)}
                  /> I have read & agree to all the <span style={{ color: "#000", fontWeight: 600, }} >Terms and Condition </span></label>

              </div>
            </section>
          </div>
        </div>
      </Modal>

      <Congratulation show={congo} onHide={() => { setCongo(false) }} />
    </>
  );
};

export default Register;
