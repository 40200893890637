/**
 * @file_purpose  page for showing transporter out for delivery list
 * @author Sarmistha Mondal
 * @Date_Created 15/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import {
    CButton,
    CCard,
    CCol,
    CCollapse,
    CRow,
    CDataTable, CCardBody
} from "@coreui/react";
import { Constants } from "../../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../../apis/Service";
import RouteURL from "../../../../apis/RouteURL";
import PaymentDetailsModal from "../../commonModal/paymentDetailsModal";
import SmallTruck from "../../../../assets/images/sm_truck.png";
import VehicleDoc from "../../../../assets/images/document.png";
import Slider from "react-slick";
import UpdateAcceptsRejectQuotationModal from "../../commonModal/updateAcceptRejectQuatetion";
import VerifyOTPForStopDelivery from "../../commonModal/otpForEndDelivery"

export default function OutForDeliveryPage(props) {
    const [search, setSearch] = useState(props.bookingRequestId);
    const [filteredData, setFilteredData] = useState([]);
    const [visible, setVisible] = useState()
    const [accordion, setAccordion] = useState(0)
    const [cancelModal, setcancelModal] = useState();
    const [startStopModal, setStartStopModal] = useState();
    const [CancelmodalValue, setCancelModalValue] = useState({
        type: "",
        shipment_request_id: "",
        quote_price: "", payment_method: "", invoice_document: "",
        order_id: "",
        type: "",
        request_id: "",
        shipper_code: ""
    });
    const fields = [
        { key: 'request_id', _classes: "", }, { key: 'shipper_code', _classes: "", }, { key: 'shipper_name', _classes: "", },
        { key: 'from_city', _classes: "", }, { key: 'to_city', _classes: "", },
        { key: 'quote_price', _classes: "", },
        { key: 'delivery_status', _classes: "", },
        { key: 'status', label: "Status", _classes: "", },

        {
            key: "action",
            label: "Action",
            _classes: "",
            sorter: false,
            filter: false,
        },
        {
            key: "droparrow",
            label: "",
            sorter: false,
            filter: false,
            _classes: "",
        },
    ];


    useEffect(() => {
        _transporterOutForDeliveryList();
    }, [search])

    useEffect(() => {
        setSearch(props.bookingRequestId)
    }, [props?.bookingRequestId])

    /**
    * @author Sarmistha Mondal
    * @Date_Created 15/04/2024
    * @Date_Modified 
    * @function async
    * @functionName _transporterOutForDeliveryList
    * @functionPurpose this function for transporter out for delivery List.
    *
    * @functionParam {filter, page_no}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _transporterOutForDeliveryList = () => {

        Service.apiPostTokenCallRequest(RouteURL.transporterOutfordeliveryRequest, { filter: search, page_no: 999999 }).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                if (response.data.outForDeliveryShipmentRequest.length > 0) {
                    setFilteredData(response.data.outForDeliveryShipmentRequest)
                } else setFilteredData([])

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };

    const toggleDetails = (index, id,) => {
        setAccordion(id)
    }

    const settings = {
        // dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }


    /*close modal*/
    const closeAddEditModal = () => {
        setcancelModal(false)
        setVisible(false)
        setStartStopModal(false)

    };

    /*after modal close get api call*/
    const onChangeSomeState = (State) => {
        if (!!State) {
            _transporterOutForDeliveryList();
        }
    };

    /* payment modal open*/
    const paymentDetails = (e, type, request_id, quote_price, payment_method, invoice_document) => {
        e.preventDefault();
        setVisible(!visible)
        setCancelModalValue({
            type: type,
            shipment_request_id: request_id,
            quote_price: quote_price,
            payment_method: payment_method,
            invoice_document: invoice_document
        })
    }

    /* start stop deliver modal*/ 
    const _startStopDelivery = (e, order_id, type, request_id, shipper_code) => {
        e.preventDefault();
        setStartStopModal(!startStopModal)
        setCancelModalValue({
            order_id: order_id,
            type: type,
            request_id: request_id,
            shipper_code: shipper_code
        })
    }
    
    return (
        <>

            <form>
                <div className="shipments_sec">
                    <div className="">
                        <div className="row">
                            <div className="col-sm-12">

                                <div className="shipment_bottom_panel">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="shipment_search">
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                                <input type="search" placeholder="Search for Req Id" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="shipment_track_txt">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="godwn_dwn">
                                        <div className="row">
                                            <div className="col">
                                                <div className="office_address_lt shipping_dwn_pad">
                                                    <div className="shipper_table shipping_pagination">

                                                        <CDataTable
                                                            items={filteredData}
                                                            fields={fields}
                                                            itemsPerPage={10}
                                                            itemsPerPageSelect
                                                            pagination={{
                                                                className: "next_arrow",
                                                                firstButton: <i class="fa-solid fa-angle-left"></i>,
                                                                previousButton: <i class="fa-solid fa-angles-left"></i>,
                                                                nextButton: <i class="fa-solid fa-angles-right"></i>,
                                                                lastButton: <i class="fa-solid fa-angle-right"></i>,
                                                                size: "",
                                                                dots: false
                                                            }}
                                                            scopedSlots={{
                                                                request_id: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: '#000' }}>
                                                                            {item.shipper_transporter_details.request_id != null ? item.shipper_transporter_details.request_id : '--'}
                                                                        </td>)
                                                                },
                                                                shipper_code: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.shipper_transporter_details.shipper_code != null ? item.shipper_transporter_details.shipper_code : '--'}
                                                                        </td>)
                                                                },
                                                                shipper_name: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: "#FBAA2A" }}>
                                                                            {item.shipper_transporter_details.shipper_name != null ? item.shipper_transporter_details.shipper_name : '--'}
                                                                        </td>)
                                                                },
                                                                from_city: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.from_city != null ? item.location_details.from_city : '--'}
                                                                        </td>)
                                                                },
                                                                to_city: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.to_city != null ? item.location_details.to_city : '--'}
                                                                        </td>)
                                                                },
                                                                request_date: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.booking_date != null ? item.location_details.booking_date : '--'}
                                                                        </td>)
                                                                },
                                                                status: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: '#1E90FF' }}>
                                                                            <b><svg id="courier" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 7.516 9.937">
                                                                                <path id="Path_9427" data-name="Path 9427" d="M181.413,0h-1.572a.875.875,0,0,0-.873.873V1.4h-.446a.291.291,0,0,0,0,.582h.453a1.66,1.66,0,0,0,3.313-.146V.873A.875.875,0,0,0,181.413,0Zm0,0" transform="translate(-174.771 0)" fill="#1e90ff" fill-rule="evenodd" />
                                                                                <path id="Path_9428" data-name="Path 9428" d="M5.177,210.083a1.672,1.672,0,0,0-.555.406l-1.565,1.6H.873a.873.873,0,0,0,0,1.747H3.318a1,1,0,0,0,.734-.269l.26-.262v2.2a.3.3,0,0,0,.233.291H7.224a.291.291,0,0,0,.291-.291V211.6a1.661,1.661,0,0,0-2.338-1.513Zm0,0" transform="translate(0.001 -205.861)" fill="#1e90ff" fill-rule="evenodd" />
                                                                                <path id="Path_9429" data-name="Path 9429" d="M26.518,156.655h2.1a.291.291,0,0,0,.291-.291v-2.077a.291.291,0,0,0-.291-.291h-.757v.5a.291.291,0,0,1-.582,0V154h-.757a.291.291,0,0,0-.291.291v2.077a.291.291,0,0,0,.291.291Zm0,0" transform="translate(-25.718 -151.007)" fill="#1e90ff" fill-rule="evenodd" />
                                                                            </svg> Out For Delivery</b>

                                                                        </td>)
                                                                },
                                                                estimated_delivery_date: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.location_details.estimated_delivery_date != null ? item.location_details.estimated_delivery_date : '--'}
                                                                        </td>)
                                                                },
                                                                assigned_driver: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.vehicle_details.assigned_driver != null ? item.vehicle_details.assigned_driver : ''}
                                                                        </td>)
                                                                },
                                                                assigned_vehicle: (item, index) => {
                                                                    return (
                                                                        <td>
                                                                            {item.vehicle_details.assigned_vehicle != null ? item.vehicle_details.assigned_vehicle : ''}
                                                                        </td>)
                                                                },
                                                                quote_price: (item, index) => {
                                                                    return (
                                                                        <td style={{ color: '#000' }}>
                                                                            {item.quote_price != null ? ('₹ ' + item.quote_price) : '--'}
                                                                        </td>)
                                                                },
                                                                droparrow: (item, index) => {
                                                                    return (
                                                                        <td className="py-2 history_sec">

                                                                            {accordion !== item.order_id ?
                                                                                <CButton
                                                                                    onClick={() => { toggleDetails(index, item.order_id,) }}
                                                                                >
                                                                                    <i className="fa fa-angle-down" aria-hidden="true"></i>

                                                                                </CButton>
                                                                                :
                                                                                <CButton
                                                                                    onClick={() => { toggleDetails(index,) }}
                                                                                >
                                                                                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                                                                                </CButton>
                                                                            }

                                                                        </td>

                                                                    );
                                                                },
                                                                action: (item, index) => {
                                                                    return (

                                                                        <td className="action_chk">

                                                                            <i className="fa-solid fa-truck-moving" title="End Delivery" onClick={(e) => _startStopDelivery(e, item.order_id, 'stop', item.shipper_transporter_details
                                                                                .request_id, item.shipper_transporter_details.shipper_code)} style={{ cursor: 'pointer' }}
                                                                            />

                                                                        </td>


                                                                    );
                                                                },
                                                                'details':
                                                                    (item, index) => {
                                                                        return (
                                                                            <CCollapse show={item.order_id === accordion}>
                                                                                <div className="order_tracking_sec">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-4">
                                                                                            <div className="order_step_inn">
                                                                                                <div className="order-track">
                                                                                                    <div className="order-track-step">
                                                                                                        <div className="order-track-status">
                                                                                                            <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                                                                                            <span className="order-track-status-line"></span>
                                                                                                        </div>
                                                                                                        <div className="order-track-text">
                                                                                                            <span className="order-track-text-sub">Booking Date - {item?.location_details.booking_date}</span>
                                                                                                            <p className="order-track-text-stat">{item?.location_details.from_location}</p>
                                                                                                            <span className="kilometer">{item?.location_details?.distance}</span>
                                                                                                            <span className="kilometer">{item?.location_details?.time_duration}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="order-track-step">
                                                                                                        <div className="order-track-status">
                                                                                                            <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                                                                                            <span className="order-track-status-line"></span>
                                                                                                        </div>
                                                                                                        <div className="order-track-text">
                                                                                                            <span className="order-track-text-sub">Est. Delivery date - {item.location_details.estimated_delivery_date != null ? item.location_details.estimated_delivery_date : ' _____'}</span>
                                                                                                            <p className="order-track-text-stat">{item?.location_details.to_location}</p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3 pt-4">
                                                                                            <div className="row">
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6>
                                                                                                            Assigned Vehicle
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">
                                                                                                            <span>
                                                                                                                {item?.vehicle_details?.assigned_vehicle}
                                                                                                                <p style={{ color: '#1E90FF' }}>{item?.vehicle_details?.vehicle_assigned}</p>
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6>
                                                                                                            Assigned Driver
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">
                                                                                                            {(item.vehicle_details.assigned_driver != null ? item.vehicle_details.assigned_driver : '--')}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6> Load Type
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">
                                                                                                            {item?.shipper_transporter_details?.load_type}  </p>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6>
                                                                                                            {(item.vehicle_details.assigned_driver != null ? 'Vehicle Departure' : '')}
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">
                                                                                                            {(item.vehicle_details.assigned_driver != null ? (item.vehicle_details.vehicle_departure != null ?
                                                                                                                <p style={{ color: '#1E90FF' }}>{item?.vehicle_details?.vehicle_departure}</p>
                                                                                                                : '--')
                                                                                                                : '')}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6>
                                                                                                            {(item.vehicle_details.assigned_driver != null ? 'Delivery Status' : '')}
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">
                                                                                                            {(item.delivery_status != null ? item.delivery_status
                                                                                                                : '')}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn" >
                                                                                                        <h6 > Payment Status
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">  {item?.shipper_transporter_details?.payment_status == 'Pending' ? <p style={{ color: '#EB2F06' }}>Pending</p> : <>
                                                                                                            {item?.shipper_transporter_details?.payment_status} <p style={{ color: '#1E90FF' }} onClick={(e) => {
                                                                                                                paymentDetails(e, 'tranporter', item.shipper_transporter_details
                                                                                                                    .request_id, item.quote_price, item.shipper_transporter_details.payment_method, item.document_details
                                                                                                                    .invoice_document
                                                                                                                )
                                                                                                            }}>View Details</p>
                                                                                                        </>}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6>
                                                                                                            Shipper Doc
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">
                                                                                                            {(item.document_details?.shipper_document.length != 0 ?
                                                                                                                <div className="tran_doc_img">
                                                                                                                    <a href={`${item.document_details?.shipper_document[0]}`} target="_blank" ><img src={VehicleDoc} alt="" height={40} width={40} /></a></div> : '--')}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-6">
                                                                                                    <div className="productmaterial_inn">
                                                                                                        <h6>
                                                                                                            Transporter Docs
                                                                                                        </h6>
                                                                                                        <p className="productmaterial_inn_color">
                                                                                                            {(item.document_details?.transporter_document.length != 0 ? <div className="tran_doc_img">   {item.document_details?.transporter_document.map((item, key) => {
                                                                                                                return (
                                                                                                                    <a href={`${item}`} target="_blank" key={key}>
                                                                                                                        <img src={VehicleDoc} alt="" height={40} width={40} />
                                                                                                                    </a>
                                                                                                                )
                                                                                                            })}</div> : '--')}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-5">

                                                                                            <div className="dimen_slide" >

                                                                                                <div className="slider-container">
                                                                                                    <Slider {...settings}>
                                                                                                        {item?.material_type.length > 0 && item?.material_type.map((data, key) => {
                                                                                                            return (
                                                                                                                <div key={key}>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-6 pt-4">
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 6.017 6">
                                                                                                                                            <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                                            <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                                            <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                                                        </svg> Dimensions (L x W x H)
                                                                                                                                        </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.product_dimension}</p>
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="10.141" height="10" viewBox="0 0 5.141 6">
                                                                                                                                            <g id="scale" transform="translate(-36.645)">
                                                                                                                                                <g id="Group_8799" data-name="Group 8799" transform="translate(36.645)">
                                                                                                                                                    <g id="Group_8798" data-name="Group 8798">
                                                                                                                                                        <path id="Path_9414" data-name="Path 9414" d="M40.915,1.059A2.128,2.128,0,0,0,41.761.27.179.179,0,0,0,41.608,0H36.824a.179.179,0,0,0-.154.27,2.127,2.127,0,0,0,.847.789,2.531,2.531,0,0,0,1.018.282V1.8H37.69a.627.627,0,0,0-.621.55L36.708,5.3a.626.626,0,0,0,.621.7H41.1a.626.626,0,0,0,.621-.7L41.363,2.35a.627.627,0,0,0-.621-.55H39.9V1.341A2.532,2.532,0,0,0,40.915,1.059ZM40.681,3.9a1.465,1.465,0,1,1-1.465-1.465A1.466,1.466,0,0,1,40.681,3.9Z" transform="translate(-36.645)" fill="#928fa9" />
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                                <g id="Group_8801" data-name="Group 8801" transform="translate(38.1 2.781)">
                                                                                                                                                    <g id="Group_8800" data-name="Group 8800">
                                                                                                                                                        <path id="Path_9415" data-name="Path 9415" d="M162.628,238.308a1.115,1.115,0,1,0,1.115,1.115A1.117,1.117,0,0,0,162.628,238.308Zm.127,1.243-.433.433a.18.18,0,1,1-.255-.255l.433-.433a.18.18,0,0,1,.255.255Z" transform="translate(-161.513 -238.308)" fill="#928fa9" />
                                                                                                                                                    </g>
                                                                                                                                                </g>
                                                                                                                                            </g>
                                                                                                                                        </svg> Weight
                                                                                                                                        </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.package_weight}</p>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="col-sm-12">
                                                                                                                                    <div className="productmaterial_inn">
                                                                                                                                        <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                                                                            <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                                            <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                                            <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                                                        </svg> No of boxes
                                                                                                                                        </h6>
                                                                                                                                        <p className="productmaterial_inn_color">{data.no_of_units}</p>

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-6">
                                                                                                                            <div className="productmaterial_image">
                                                                                                                                <h6>Product Image</h6>
                                                                                                                                <div className="productmaterial_image_box">

                                                                                                                                    <div className="p_box">
                                                                                                                                        {data?.images.length > 0 && data?.images.map((img) => {
                                                                                                                                            return (
                                                                                                                                                <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                                                                                            )
                                                                                                                                        })}

                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-12">
                                                                                                                            <div className="productmaterial_inn">
                                                                                                                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 5.737 6">
                                                                                                                                    <g id="Group_29516" data-name="Group 29516" transform="translate(-803 -366)">
                                                                                                                                        <path id="Path_9494" data-name="Path 9494" d="M26.667,1.6,29.308.463,28.214.014a.19.19,0,0,0-.144,0L25.444,1.093Z" transform="translate(777.726 366)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9495" data-name="Path 9495" d="M172.654,56.125l-2.641,1.132.984.4,2.7-1.108Z" transform="translate(634.871 310.54)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9496" data-name="Path 9496" d="M13.76,122.008l-1.069-.439v.831a.19.19,0,0,1-.379,0v-.987l-1.231-.506v3.339a.19.19,0,0,0,.118.175l2.561,1.052Z" transform="translate(791.919 246.525)" fill="#928fa9" />
                                                                                                                                        <path id="Path_9497" data-name="Path 9497" d="M269.069,122.008v3.466l2.561-1.052a.19.19,0,0,0,.118-.175v-3.339Z" transform="translate(536.989 246.525)" fill="#928fa9" />
                                                                                                                                    </g>
                                                                                                                                </svg> Material
                                                                                                                                </h6>
                                                                                                                                <p className="productmaterial_inn_color">{data.material_type
                                                                                                                                }</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}

                                                                                                    </Slider>
                                                                                                    <span className="slider_number_count"><span style={{ color: "#fbaa2a" }}></span></span>

                                                                                                </div>                                                                           </div>



                                                                                            <div className="material_sec">
                                                                                                <div className="row">

                                                                                                    {/* <div className="col-sm-6">
                                                                                                        <div className="productmaterial_inn">
                                                                                                            <h6><svg id="square-measument" xmlns="http://www.w3.org/2000/svg" width="6.017" height="6" viewBox="0 0 6.017 6">
                                                                                                                <path id="Path_9416" data-name="Path 9416" d="M4.3,116.048H.3a.3.3,0,0,0-.3.3v4a.3.3,0,0,0,.3.3h4a.3.3,0,0,0,.3-.3v-4A.3.3,0,0,0,4.3,116.048Zm-.3,4H.6v-3.4H4Z" transform="translate(0 -114.647)" fill="#928fa9" />
                                                                                                                <path id="Path_9417" data-name="Path 9417" d="M397.341,78.265h-.325v-3.49h.325a.059.059,0,0,0,.042-.1l-.525-.524a.06.06,0,0,0-.084,0l-.525.524a.059.059,0,0,0,.042.1h.325v3.49h-.325a.059.059,0,0,0-.042.1l.525.524a.06.06,0,0,0,.084,0l.525-.524a.059.059,0,0,0-.042-.1Z" transform="translate(-391.384 -73.232)" fill="#928fa9" />
                                                                                                                <path id="Path_9418" data-name="Path 9418" d="M27,1.828a.059.059,0,0,0,.1-.042V1.461h3.49v.325a.059.059,0,0,0,.1.042l.524-.525a.06.06,0,0,0,0-.084L30.688.693a.059.059,0,0,0-.1.042v.325H27.1V.735A.059.059,0,0,0,27,.693l-.524.525a.06.06,0,0,0,0,.084Z" transform="translate(-26.13 -0.676)" fill="#928fa9" />
                                                                                                            </svg> Last Quote Date & Time
                                                                                                            </h6>
                                                                                                            <p>{item.last_accepted_date_time}</p>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="material_sec">
                                                                                                <div className="row">

                                                                                                    <div className="col-sm-6">

                                                                                                    </div>


                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </CCollapse>
                                                                        )
                                                                    }
                                                            }}

                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                {/* calcel modal */}
                {cancelModal == true && <UpdateAcceptsRejectQuotationModal _allData={CancelmodalValue}
                    CloseAddEditModal={closeAddEditModal} show={cancelModal} onHide={() => setcancelModal(false)}
                    onChangeSomeState={onChangeSomeState} />}

                {/* payment details */}
                {visible && <PaymentDetailsModal _allData={CancelmodalValue} CloseAddEditModal={closeAddEditModal} show={visible} onHide={() => setVisible(false)} onChangeSomeState={onChangeSomeState} />}


                {/* delivery stsrt stop modal */}
                {startStopModal && <VerifyOTPForStopDelivery show={startStopModal} onHide={() => setStartStopModal(false)}
                    CloseAddEditModal={closeAddEditModal} onChangeSomeState={onChangeSomeState} _allData={CancelmodalValue} />}


            </form >

        </>
    );
}




