/**
 * @file_purpose  page for showing new shipment add
 * @author Sarmistha Mondal
 * @Date_Created 07/12/2023
 * @Date_Modified 
 */

import React, { useState, useEffect, useRef, useCallback, Component, useLayoutEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import TataBg from "../../../assets/images/tat_bg.png"
import Select from 'react-select';
import { Constants, ERROR_MESSAGE, REGEX } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
  markAllAsTouched,
  leftTrimAllValue,
  isFormValid,
  leftTrim, checkLength, 
} from "../../../Utility/Helper";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import LocationModal from "./locationModal";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import moment from "moment";
import { VariableSizeList as List } from "react-window";


const mapStyle = {
  height: '300px',
  width: '100%'
}

function NewShipmentBooking() {

  const inputRef = useRef(null);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.GOOGLE_MAPS_API_KEY,
    inputAutocompleteValue: "IN",
    options: {
      componentRestrictions: 'in',
    },
  });
  const navigate = useNavigate();
  let formData = new FormData();
  const [submitDisable, setSubmitDisable] = useState(false);
  const [citylist, setCitylist] = useState([]);
  const [_materialList, setMaterialList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [formValue, setFormValue] = useState({
    load_type: '', payment_mode: '', booking_date: '',
  });
  const [formTouchValue, setFormTouchValue] = useState({
    from_location: false,
    from_location_city: false,
    to_location: false,
    to_location_city: false,
    booking_date: false,
    load_type: false,
    payment_mode: false,
    vehicle_category_id: false
  });
  const [errorValue, setErrorValue] = useState({
    from_location: '',
    from_location_city: '',
    to_location: '',
    to_location_city: '',
    booking_date: '',
    load_type: '',
    payment_mode: '',
    vehicle_category_id: ''
  });
  const [goodsValues, setGoodsValues] = useState([{ material_type: "", package_length: "", package_width: "", package_height: "", net_package_weight_in_kg: "", net_package_weight_in_ton: "", net_package_weight_in_gm: "", no_of_units: "", dimension_unit: "", goods_images: [], images: [] }])

  const [errorgoodsValues, setErrorGoodsValues] = useState([{ material_type: "", package_length: "", package_width: "", package_height: "", net_package_weight_in_kg: "", net_package_weight_in_ton: "", net_package_weight_in_gm: "", no_of_units: "", dimension_unit: "", goods_images: "", }])

  const [formTouchgoodsValues, setFormTouchGoodsValues] = useState([{ material_type: "", package_length: "", package_width: "", package_height: "", net_package_weight_in_kg: "", net_package_weight_in_ton: "", net_package_weight_in_gm: "", no_of_units: "", dimension_unit: "", goods_images: [], }])

  const [map, setMap] = React.useState(null)
  const [toMarkerPosition, setToMarkerPosition] = useState({
    lat: '',
    lng: ''
  })
  const [formMarkerPosition, setFormMarkerPosition] = useState({
    lat: '',
    lng: ''
  })
  const [defaultLocation, setDefaultLocation] = useState({
    lat: '',
    lng: ''
  })

  const [center, setCenter] = useState({
    lat: '',
    lng: '',
  });
  const [vehicle_category_id, setvehicle_category_id] = useState({ id: '' })
  const [openModal, setopenModal] = useState(false)
  const [from_location, setFrom_location] = useState({
    longName: "", sortName: ""
  })
  const [to_location, setTo_location] = useState({
    longName: "", sortName: ""
  })
  const [type, setType] = useState('');
  const [textChange, setTextChange] = useState(false);
  const selectedFIles = [];

  useEffect(() => {
    mapPermition();
    setLoader(true)
    _CityList();
    _MaterialList();
    loadVehicleCategoryList()
  }, []);


  useEffect(() => { }, [toMarkerPosition, formMarkerPosition]);

  useEffect(() => {
    if (formValue) {
      if (!from_location.longName) checkError("from_location");
      if (!from_location.sortName) checkError("from_location_city");
      if (!to_location.longName) checkError("to_location");
      if (!to_location.sortName) checkError("to_location_city");


      checkError("booking_date"); checkError("load_type"); checkError("payment_mode");
      if (formValue.load_type == 'full' && vehicle_category_id.id == '') checkError("vehicle_category_id");

    }


    else if (goodsValues[0].no_of_units) {
      var flag = 0
      goodsValues.forEach((Value, Key) => {
        let onChangeValue = [...errorgoodsValues];

        if (!Value.net_package_weight_in_ton || !Value.net_package_weight_in_kg || !Value.net_package_weight_in_gm) {
          onChangeValue[Key].net_package_weight_in_ton = "Please enter net package weight";
          setErrorGoodsValues(onChangeValue)
          flag++

        } else {
          onChangeValue[Key].net_package_weight_in_ton = "";
          setErrorGoodsValues(onChangeValue)
          flag++
        }

        if (!Value.package_height || !Value.package_length || !Value.package_width || !Value.dimension_unit) {
          onChangeValue[Key].package_height = "Please enter package dimension";
          setErrorGoodsValues(onChangeValue)
          flag++

        } else {
          onChangeValue[Key].package_height = "";
          setErrorGoodsValues(onChangeValue)
          flag++
        }

        if (!Value.no_of_units) {
          onChangeValue[Key].no_of_units = "Please enter number of boxes";
          setErrorGoodsValues(onChangeValue)
          flag++

        } else {
          onChangeValue[Key].no_of_units = "";
          setErrorGoodsValues(onChangeValue)
          flag++
        }

        if (!Value.material_type) {
          onChangeValue[Key].material_type = "Please enter Material Type";
          setErrorGoodsValues(onChangeValue)
          flag++
        } else {
          onChangeValue[Key].material_type = "";
          setErrorGoodsValues(onChangeValue)
          flag++
        }

        if (Value.goods_images.length == 0) {
          onChangeValue[Key].goods_images = "Please add vehicle image ";
          setErrorGoodsValues(onChangeValue)
          flag++
        } else {
          onChangeValue[Key].goods_images = "";
          setErrorGoodsValues(onChangeValue)
          flag++
        }
      })
    }
  }, [formValue, goodsValues]);

  /**
      * @author Sarmistha Mondal
      * @Date_Created 13/06/2024
      * @Date_Modified
      * @function async
      * @functionName loadVehicleCategoryList
      * @functionPurpose this function gets the vehicle category.
      *
      * @functionSuccess Success status and message.
      *
      * @functionError {Boolean} error is there.
      * @functionError {String} message  Description message.
      */

  const loadVehicleCategoryList = () => {
    Service.apiPostTokenCallRequest(RouteURL.getVehicleCategory, {})
      .then((res) => {
        if (res.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
          let _res = res.data;
          var arrCategory = [{ value: '', label: 'Select Option', color: '#0052CC', },]
          _res.map((item, index) =>
            arrCategory.push({
              value: item.vehicle_category_id,
              label: item.vehicle_category,
            })
          );
          setFilteredData(arrCategory);

        } else {
          toast.error(res.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
  }

  /**
    * @author Sarmistha Mondal
    * @Date_Created 07/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _CityList
    * @functionPurpose this function for city list
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */

  const _CityList = () => {
    Service.apiPostTokenCallRequest(RouteURL.cityList, {}).then((response) => {
      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        let res = response.data.cityList;
        var newArray = [{ value: '', label: 'Select Option', color: '#0052CC', },]
        res.map((item, index) => (
          newArray.push({
            value: item.city_id,
            label: item.city_name
          })
        ))

        setCitylist(newArray)

      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

  };

  /**
    * @author Sarmistha Mondal
    * @Date_Created 07/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _MaterialList
    * @functionPurpose this function for material list.
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
  const _MaterialList = () => {

    Service.apiPostTokenCallRequest(RouteURL.materialList, {}).then((response) => {
      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        let res = response.data.materialList;
        var arrMaterial = [{ value: '', label: 'Select Option', color: '#0052CC', },]
        res.map((item, index) =>
          arrMaterial.push({
            value: item.material_id,
            label: item.hsn_code + ' - ' + item.material_name,
          })
        );
        setMaterialList(arrMaterial);
        setLoader(false)
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

  };


  /* select category*/
  const handleChangeCategory = (e) => {
    setvehicle_category_id((prevFormValue) => {
      return { ...prevFormValue, id: e.value };
    });
    setErrorValue((err) => {
      return { ...err, vehicle_category_id: '' }
    })
  };

  /* on change input function */
  const formChange = (event) => {
    const { name, value } = event.target;
    if (value == 'partial') setvehicle_category_id((prevFormValue) => { return { ...prevFormValue, id: "", } })
    setFormValue((prevFormValue) => {
      return { ...prevFormValue, [name]: leftTrim(value) };
    });

  };

  /* onBlur error message call */
  const updateFormTouchValue = (event) => {
    const { name, value } = event.target;
    if (!formTouchValue[name]) {
      setFormTouchValue((prevFormTouchValue) => {
        return { ...prevFormTouchValue, [name]: true };
      });
      setFormValue((prevFormValue) => {
        return { ...prevFormValue, [name]: leftTrim(value) };
      });
    }
  };

  /* error message function */
  const checkError = (targetName) => {
    if (formTouchValue[targetName]) {
      setErrorValue((prevErrorValue) => {
        return {
          ...prevErrorValue,
          [targetName]: !formValue[targetName] ? ERROR_MESSAGE[targetName + 'Required'] : "",
        };
      });
    }
  };

  /* on change input function for goods value*/
  const updateFormTouchGoodsValue = (event, key) => {
    const { name, value } = event.target;
    let onChangeValue = [...goodsValues];
    let touchValue = [...formTouchgoodsValues];
    onChangeValue[key][name] = value;
    touchValue[key][name] = true;

    if (!formTouchgoodsValues[key][name]) {
      setFormTouchGoodsValues(touchValue)
      setGoodsValues(onChangeValue)
    }

  };

  /* Validation Checking */
  const validForm = (e) => {
    e.preventDefault();

    setFormTouchValue(markAllAsTouched(formTouchValue));
    setFormValue(leftTrimAllValue(formValue));
    if (!from_location.longName) checkError("from_location");
    if (!from_location.sortName) checkError("from_location_city");
    if (!to_location.longName) checkError("to_location");
    if (!to_location.sortName) checkError("to_location_city");

    checkError("booking_date"); checkError("load_type"); checkError("payment_mode");
    if (formValue.load_type == 'full' && vehicle_category_id.id == '') checkError("vehicle_category_id");

    var flag = 0
    goodsValues.forEach((Value, Key) => {
      let onChangeValue = [...errorgoodsValues];

      if (!Value.net_package_weight_in_ton || !Value.net_package_weight_in_kg || !Value.net_package_weight_in_gm) {
        onChangeValue[Key].net_package_weight_in_ton = "Please enter net package weight";
        setErrorGoodsValues(onChangeValue)
        flag++

      } else {
        onChangeValue[Key].net_package_weight_in_ton = "";
        setErrorGoodsValues(onChangeValue)
        flag++
      }

      if (!Value.package_height || !Value.package_length || !Value.package_width || !Value.dimension_unit) {
        onChangeValue[Key].package_height = "Please enter package dimension";
        setErrorGoodsValues(onChangeValue)
        flag++

      } else {
        onChangeValue[Key].package_height = "";
        setErrorGoodsValues(onChangeValue)
        flag++
      }

      if (!Value.no_of_units) {
        onChangeValue[Key].no_of_units = "Please enter number of boxes";
        setErrorGoodsValues(onChangeValue)
        flag++

      } else {
        onChangeValue[Key].no_of_units = "";
        setErrorGoodsValues(onChangeValue)
        flag++
      }
      if (!Value.material_type) {
        onChangeValue[Key].material_type = "Please enter Material Type";
        setErrorGoodsValues(onChangeValue)
        flag++
      } else {
        onChangeValue[Key].material_type = "";
        setErrorGoodsValues(onChangeValue)
        flag++
      }

      if (Value.goods_images.length == 0) {
        onChangeValue[Key].goods_images = "Please add vehicle image ";
        setErrorGoodsValues(onChangeValue)
        flag++
      } else {
        onChangeValue[Key].goods_images = "";
        setErrorGoodsValues(onChangeValue)
        flag++
      }

    })


    let allValueCheck = goodsValues.map(({ goods_images, images, ...rest }) => ({
      ...rest,
    })).map((Value, Key) => {
      return (Object.values(Value).every(x => x != ''))
    }).every(y => y == true)

    let _allValueGoods_imagesCheck = goodsValues.map(x => { return x.goods_images.length > 0 }).every(y => y == true)

    if (isFormValid(formValue) && _allValueGoods_imagesCheck && allValueCheck) {

      _SubmitNewShipment()
    }
  }

  /**
   * @author Sarmistha Mondal
   * @Date_Created 07/12/2023
   * @Date_Modified 
   * @function async
   * @functionName _SubmitNewShipment
   * @functionPurpose this function for create new shipment.
   *
   * @functionParam {from_location,from_location_city,from_location_lat,from_location_long,to_location,to_location_city,
   * to_location_lat,to_location_long,material_details,booking_date,payment_mode,load_type,vehicle_category_id,insurance_link,additional_comments,goods_images}
   *
   * @functionSuccess Success status and message.
   *
   * @functionError {Boolean} error is there.
   * @functionError {String} message  Description message.
   */
  const _SubmitNewShipment = () => {

    formData.append(`from_location`, from_location.longName);
    formData.append(`from_location_city`, from_location.sortName);
    formData.append(`from_location_lat`, formMarkerPosition.lat);
    formData.append(`from_location_long`, formMarkerPosition.lng);
    formData.append(`to_location`, to_location.longName);
    formData.append(`to_location_city`, to_location.sortName);
    formData.append(`to_location_lat`, toMarkerPosition.lat);
    formData.append(`to_location_long`, toMarkerPosition.lng);
    formData.append(`material_details`, JSON.stringify(goodsValues.map(({ goods_images, images, ...rest }) => ({
      ...rest,
    }))))
    formData.append(`booking_date`, formValue.booking_date);
    formData.append(`payment_mode`, formValue.payment_mode);
    formData.append(`load_type`, formValue.load_type);
    formData.append(`vehicle_category_id`, formValue.load_type == 'full' ? vehicle_category_id.id : '');
    formData.append(`insurance_link`, formValue.insurance_link);
    formData.append(`additional_comments`, formValue.additional_comments);
    for (let i = 0; i < goodsValues.length; i++) {
      for (let j = 0; j < goodsValues[i]?.goods_images?.length; j++) {
        formData.append(`goods_images[${i}]`, goodsValues[i]?.goods_images[j]);

      }

    }

    setSubmitDisable(true)
    Service.apiPostCallFormDataRequest(RouteURL.shipmentBookings, formData).then((response) => {

      if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
        toast.success(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setSubmitDisable(false)
        navigate(`/shipment-booking/${response.data.bookingId.id}/recommend`);
      } else {
        setSubmitDisable(false)
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }).catch((error) => {
      setSubmitDisable(false)

    });
  }



  /* dynamic add more fields*/
  const addFormFields = () => {
    setGoodsValues((
      [...goodsValues, { material_type: "", package_length: "", package_width: "", package_height: "", net_package_weight_in_kg: "", net_package_weight_in_ton: "", net_package_weight_in_gm: "", no_of_units: "", dimension_unit: "", goods_images: [], images: [] }]
    ))
    setErrorGoodsValues((
      [...errorgoodsValues, { material_type: "", package_length: "", package_width: "", package_height: "", net_package_weight_in_kg: "", net_package_weight_in_ton: "", net_package_weight_in_gm: "", no_of_units: "", dimension_unit: "", }]
    ))

    setFormTouchGoodsValues((
      [...formTouchgoodsValues, { material_type: false, package_length: false, package_width: false, package_height: false, net_package_weight_in_kg: false, net_package_weight_in_ton: false, net_package_weight_in_gm: false, no_of_units: false, dimension_unit: false, }]
    ))
    setTextChange(true)
  }

  /* select material types*/
  const handleChangeMaterialTypes = (i, e) => {
    let onChangeValue = [...goodsValues];
    onChangeValue[i]['material_type'] = (e?.value == null ? '' : e.value);
    setGoodsValues(onChangeValue);
  };

  /* select unit*/
  const handleChangeDimension_unit = (i, e) => {
    let onChangeValue = [...goodsValues];
    onChangeValue[i]['dimension_unit'] = (e?.value == null ? '' : leftTrim(e.value));
    setGoodsValues(onChangeValue);
  };

  /* on change input function */
  const handleChange = (i, e) => {
    const { name, value } = e.target;
    let onChangeValue = [...goodsValues];
    onChangeValue[i][name] = value;
    setGoodsValues(onChangeValue);
  }

  /* goods image upload*/
  const handleMultipleImages = (e, i) => {
    let files = e.target.files[0];
    const newArray = [...goodsValues];
    newArray[i].goods_images.push(files)
    setGoodsValues(newArray)
    if (newArray[i].goods_images.length <= 4) {
      const targetFilesObject = [...newArray[i].goods_images]
      targetFilesObject.map((file) => {
        return selectedFIles.push(URL.createObjectURL(file))
      })
    }
    newArray[i].images = selectedFIles;
    setGoodsValues(newArray);
  }

  /* remove img*/
  const removeImg = (e, index, name, key) => {
    e.preventDefault();
    const newArray = [...goodsValues];
    newArray[index].images.splice(key, 1)
    newArray[index].goods_images.splice(key, 1)
    inputRef.current.value = "";
    setGoodsValues(newArray);
  }

  /* delete dynamic input fields*/
  const handleDeleteInput = (e, index) => {
    e.preventDefault()
    const newArray = [...goodsValues];
    newArray.splice(index, 1);
    setGoodsValues(newArray);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY
  })

  /* map load*/
  const onLoad = useCallback((map) => {
    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setDefaultLocation((prevFormValue) => {
            return {
              ...prevFormValue, lat: Number(latitude),
              lng: Number(longitude),
            };
          });
          setToMarkerPosition((prevFormValue) => {
            return {
              ...prevFormValue, lat: Number(latitude),
              lng: Number(longitude),
            };
          });
          setFormMarkerPosition((prevFormValue) => {
            return {
              ...prevFormValue, lat: Number(latitude),
              lng: Number(longitude),
            };
          });
          setCenter((prevFormValue) => {
            return {
              ...prevFormValue, lat: Number(latitude),
              lng: Number(longitude),
            };
          });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );

    }

    if (defaultLocation.lat) {
      const bounds = new window.google.maps.LatLngBounds({
        lat: defaultLocation.lat,
        lng: defaultLocation.lng

      });

      map.fitBounds(bounds);
      setMap(map)
    }


  }, [])

  /* map permition*/
  const mapPermition = () => {
    if (navigator.geolocation) {

      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
          } else if (result.state === "prompt") {
          } else if (result.state === "denied") {

            toast.success('Geolocation location permission ' + result.state, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    } else {



      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, { enableHighAccuracy: true });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }

    }
  }

  const successCallback = (position) => {
  };

  const errorCallback = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
      default:
        console.log("An unknown error occurred.");
        break;
    }
  };

  const onUnmount = useCallback(() => {
    setMap(null)
  }, [])



  /* map modal close*/
  const onCloseModal = (data) => {
    console.log(data);
    if (data?.location) {
      getAddress(data.location.lat(), data.location.lng())
    } else if (data?.coords) {
      getAddress(data.coords.latitude, data.coords.longitude)
    } else if (data?.geometry) {
      getAddress(data.geometry.location.lat(), data.geometry.location.lng())

    } else {
      for (var i = 0; i < data?.address_components.length; i++) {
        for (var j = 0; j < data?.address_components[i].types.length; j++) {
          if (data?.address_components[i].types[j] == "locality") {
            console.log(data.address_components[i].long_name)
            if (type == 'from_location') {
              setFrom_location({
                longName: data.formatted_address,
                sortName: data.address_components[i].short_name
              })
            } else {
              setTo_location({
                longName: data.formatted_address,
                sortName: data.address_components[i].short_name
              })
            }

          }
        }
      }
    }
  }


  /* select address from map*/
  const getAddress = (lat, lng) => {
    let geocoder = new window.google.maps.Geocoder();
    let latlng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ 'latLng': latlng }, function (results, status) {
      console.log(results, type);
      //  && results[0].geometry.location_type == ("ROOFTOP" || "APPROXIMATE")
      if (status == 'OK') {
        console.log(results, type);
        const address = results[0].formatted_address;
        for (var i = 0; i < results[0].address_components.length; i++) {
          for (var j = 0; j < results[0].address_components[i].types.length; j++) {
            if (results[0].address_components[i].types[j] == "locality") {

              if (type == 'from_location') {
                setFrom_location({
                  longName: results[0].formatted_address,
                  sortName: results[0].address_components[i].short_name
                })
                setFormMarkerPosition((prevFormValue) => {
                  return {
                    ...prevFormValue, lat: Number(lat),
                    lng: Number(lng),
                  };
                });
                setDefaultLocation((prevFormValue) => {
                  return {
                    ...prevFormValue, lat: Number(lat),
                    lng: Number(lng),
                  };
                });

              } else {
                console.log(lat, lng, results[0]);
                setTo_location({
                  longName: results[0].formatted_address,
                  sortName: results[0].address_components[i].short_name
                })
                setToMarkerPosition((prevFormValue) => {
                  return {
                    ...prevFormValue, lat: lat,
                    lng: lng,
                  };
                });
                setCenter((prevFormValue) => {
                  return {
                    ...prevFormValue, lat: Number(lat),
                    lng: Number(lng),
                  };
                });
              }


            }
          }
        }

      }

    });
  }

  useEffect(() => {
  }, [toMarkerPosition, formMarkerPosition])



  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 2].focus();
        event.preventDefault();
      }
    });
  }, []);



  return (
    <>

      {loader == false ?
        <div className="sideright_menu">
          <div className="new_shipment_rt">
            <div className='row'>
              <div className="col-sm-12">
                <div className='booking_hed_txt'>
                  <h4><i class="fa fa-arrow-left" aria-hidden="true" onClick={() => navigate(`/shipment-list`)} style={{ cursor: 'pointer' }} /> Shipment Booking </h4>
                  {/* {textChange == true ? 'Bookings' :'Booking'} */}
                  <p>Book Your Shipment</p>
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='booking_part_lt'>
                  <div className='location_details'>
                    <h6>Location Details</h6>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <div className="office_field">
                          <label>From Location</label>
                          <input type="text" placeholder="Select your source location"
                            name="from_location"
                            onClick={() => { setopenModal(true); setType('from_location') }}
                            value={from_location.longName}
                            error={errorValue.from_location ? "true" : "false"} />
                          {errorValue.from_location && (
                            <span className="error">
                              {errorValue.from_location}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='col-sm-3'>
                        <div className="office_field">
                          <label>From City</label>
                          <input type="text" placeholder="Select city"
                            name="from_location"
                            value={from_location.sortName}
                            error={errorValue.from_location_city ? "true" : "false"} />
                          {errorValue.from_location_city && (
                            <span className="error">
                              {errorValue.from_location_city}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className="office_field">
                          <label>Select Address in Map</label>
                          <div className='map_sec'>
                            {
                              (isLoaded) ? (
                                <GoogleMap
                                  onLoad={onLoad}
                                  center={defaultLocation}
                                  mapContainerStyle={mapStyle}
                                  onClick={() => { setopenModal(true); setType('from_location') }}
                                  options={{
                                    zoom: 15,
                                    disableDefaultUI: true,
                                    mapTypeControl: false,
                                    draggable: false,
                                    scaleControl: false,
                                    scrollwheel: false,
                                    navigationControl: false,
                                  }}
                                >
                                  <Marker position={formMarkerPosition} />
                                </GoogleMap>

                              ) : <></>
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <div className="office_field">
                          <label>Destination Location</label>
                          <input type="text" placeholder="Select your destination location" name="to_location"
                            id="to_location"
                            value={to_location.longName}
                            onClick={() => { setopenModal(true); setType('to_location') }}
                            error={errorValue.to_location ? "true" : "false"} />
                          {errorValue.to_location && (
                            <span className="error">
                              {errorValue.to_location}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='col-sm-3'>
                        <div className="office_field">
                          <label>To City</label>

                          <input type="text" placeholder="Select city"
                            name="to_location_city"
                            value={to_location.sortName}
                            error={errorValue.to_location_city ? "true" : "false"} />
                          {errorValue.to_location_city && (
                            <span className="error">
                              {errorValue.to_location_city}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='col-sm-12'>
                        <div className="office_field">
                          <label>Select Address in Map</label>
                          <div className='map_sec'>
                            {
                              (isLoaded) ? (
                                <GoogleMap
                                  onLoad={onLoad}
                                  center={center}
                                  mapContainerStyle={mapStyle}
                                  onClick={() => { setopenModal(true); setType('to_location') }}
                                  options={{
                                    zoom: 15,
                                    disableDefaultUI: true,
                                    mapTypeControl: false,
                                    draggable: false,
                                    scaleControl: false,
                                    scrollwheel: false,
                                    navigationControl: false,
                                    streetViewControl: false,
                                  }}
                                >
                                  <Marker position={toMarkerPosition} />
                                </GoogleMap>
                              ) : <></>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className='booking_part_lt mt-3' >
                  <div className='location_details mt-3'>
                    <h6>Goods Details</h6>


                    {goodsValues.map((element, index) => (
                      <div className="addmore_row">
                        {
                          index ?

                            <div className="" style={{ display: 'flex', justifyContent: "flex-end", margin: 10, fontSize: 20 }}
                              onClick={(e) => handleDeleteInput(e, index)}>
                              <i className="fa-solid fa-trash-can" style={{ color: "#FBAA2A" }} /></div>
                            : null
                        }
                        <div className='row' key={index}>
                          <div className='col-sm-6'>
                            <div className="office_field">
                              <label>Material Type / HSN code</label>
                              <Select className="dropdown"
                                name="material_type"
                                placeholder="Select Option"
                                components={{ MenuList }}
                                options={_materialList}
                                isSearchable
                                isClearable
                                onChange={(e) => handleChangeMaterialTypes(index, e)}
                                aria-errormessage={errorgoodsValues[index]?.material_type ? "true" : "false"} />

                              {errorgoodsValues[index]?.material_type && (
                                <span className="error">
                                  {errorgoodsValues[index]?.material_type}
                                </span>
                              )}

                            </div>
                          </div>
                          <div className='col-sm-6'>
                            <div className="office_field">
                              <label>Net Package Weight</label>
                              <div className="weight_sec">
                                <div className='kilo_sec'>
                                  <input type="number" placeholder="ton" name="net_package_weight_in_ton" value={element.net_package_weight_in_ton}
                                    onKeyPress={event => {
                                      if (event.keyCode == 9) {
                                        var wow = document.getElementsByName('net_package_weight_in_kg');
                                        wow[0].focus();
                                      }
                                    }}
                                    onChange={(e) => handleChange(index, e)}
                                    onBlur={(e) => updateFormTouchGoodsValue(e, index)}                                    
                                    onKeyDown={e => checkLength(e)}
                                    error={errorgoodsValues[index]?.net_package_weight_in_ton ? "true" : "false"}
                                  /></div>
                                <div className='kilo_sec'>
                                  <input type="number" placeholder="kg" name="net_package_weight_in_kg" value={element.net_package_weight_in_kg} onChange={(e) => handleChange(index, e)}
                                    onKeyPress={event => {
                                      if (event.keyCode == 9) {
                                        var wow = document.getElementsByName('net_package_weight_in_gm');
                                        wow[0].focus();
                                      }
                                    }}
                                    onBlur={(e) => updateFormTouchGoodsValue(e, index)}
                                    maxLength={3}
                                    onKeyDown={e => checkLength(e)}
                                    error={errorgoodsValues[index]?.net_package_weight_in_kg ? "true" : "false"} />
                                </div>
                                <div className='kilo_sec'><input type="number" placeholder="gm" name="net_package_weight_in_gm" value={element.net_package_weight_in_gm} onChange={(e) => handleChange(index, e)}
                                  onKeyPress={event => {
                                    if (event.keyCode == 9) {
                                      var wow = document.getElementsByName('package_length');
                                      wow[0].focus();
                                    }
                                  }}
                                  onBlur={(e) => updateFormTouchGoodsValue(e, index)}
                                  maxLength={3}
                                  onKeyDown={e => checkLength(e)}
                                  error={errorgoodsValues[index]?.net_package_weight_in_gm ? "true" : "false"}
                                /></div>
                              </div>

                              {(errorgoodsValues[index]?.net_package_weight_in_ton) && (
                                <span className="error">
                                  {errorgoodsValues[index]?.net_package_weight_in_ton}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-sm-6'>
                            <div className="office_field">
                              <label>Package Dimension</label>
                              <div className='dimension_sec'>
                                <div className='kilo_sec'>
                                  <input type="number" placeholder="L" name="package_length" value={element.package_length}
                                    maxLength={3}
                                    onChange={(e) => handleChange(index, e)}
                                    onKeyDown={e => checkLength(e)}
                                    onKeyPress={event => {
                                      if (event.keyCode == 9) {
                                        var wow = document.getElementsByName('package_width');
                                        wow[0].focus();
                                      }
                                    }}
                                    error={errorgoodsValues[index]?.package_length ? "true" : "false"}
                                  />

                                </div>
                                <div className='kilo_sec'>
                                  <input type="number" placeholder="W" name="package_width" value={element.package_width}
                                    maxLength={3}
                                    onChange={(e) => handleChange(index, e)}
                                    onKeyDown={e => checkLength(e)}
                                    onBlur={(e) => updateFormTouchGoodsValue(e, index)}
                                    onKeyPress={event => {
                                      if (event.keyCode == 9) {
                                        var wow = document.getElementsByName('package_height');
                                        wow[0].focus();
                                      }
                                    }}
                                    error={errorgoodsValues[index]?.package_width ? "true" : "false"}
                                  />

                                </div>
                                <div className='kilo_sec'>
                                  <input type="number" placeholder="H" name="package_height" value={element.package_height}
                                    maxLength={3}
                                    onChange={(e) => handleChange(index, e)}
                                    onKeyDown={e => checkLength(e)}
                                    onBlur={(e) => updateFormTouchGoodsValue(e, index)}
                                    onKeyPress={event => {
                                      if (event.keyCode == 9) {
                                        var wow = document.getElementsByName('dimension_unit');
                                        wow[0].focus();
                                      }
                                    }}
                                    error={errorgoodsValues[index]?.package_height ? "true" : "false"}
                                  />

                                </div>

                                <div className='kilo_sec'>
                                  <Select
                                    className="dropdown"
                                    name="dimension_unit"
                                    placeholder="Select"
                                    options={[{ value: 'cm', label: 'cm' }, { value: 'inch', label: 'inch' }, { value: 'ft', label: 'ft' },
                                    ]}
                                    onChange={(e) => handleChangeDimension_unit(index, e)}
                                    onKeyPress={event => {
                                      if (event.keyCode == 9) {
                                        var wow = document.getElementsByName('no_of_units');
                                        wow[0].focus();
                                      }
                                    }}
                                    aria-errormessage={errorgoodsValues[index]?.package_height ? "true" : "false"}
                                  />
                                </div>
                              </div>
                              {(errorgoodsValues[index]?.package_height) && (
                                <span className="error">
                                  {errorgoodsValues[index]?.package_height}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='col-sm-6'>
                            <div className="office_field">
                              <label>Number of boxes</label>
                              <input type="number" placeholder="Enter number of boxes" name="no_of_units"
                                value={element.no_of_units}
                                onChange={(e) => handleChange(index, e)}
                                onBlur={(e) => updateFormTouchGoodsValue(e, index)}
                                error={errorgoodsValues[index]?.no_of_units ? "true" : "false"} />
                              {errorgoodsValues[index]?.no_of_units && (
                                <span className="error">
                                  {errorgoodsValues[index]?.no_of_units}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <div className='booking_part_rt'>
                              <div className="vehicle_rt_box">
                                <div className="vehicle_txt">
                                  <h6>Add Goods Image <span><a href="javascript:void(0)" style={{ cursor: 'default' }}> Total image: &nbsp;{element.goods_images?.length}</a></span></h6>
                                </div>
                                <div className="row">

                                  {element.images.map((url, key) => {
                                    return (<div className="col-sm-3">
                                      <div className="tata_moto_sec">
                                        <span onClick={(e) => removeImg(e, index, url, key)}><i className="fa-regular fa-trash-can"></i></span>
                                        <img src={url} alt="" />
                                      </div>
                                    </div>)
                                  })}
                                  {element.goods_images?.length < 4 &&
                                    <div className="col-sm-2">
                                      <div className="tata_moto_sec">
                                        <input type="file" accept=".jpg,.jpeg,.png" ref={inputRef}
                                          name="goods_images"
                                          onChange={(e) => handleMultipleImages(e, index)}
                                          error={errorgoodsValues[index]?.goods_images ? "true" : "false"}
                                        />
                                        <img src={TataBg} alt="" />
                                      </div>
                                    </div>
                                  }

                                </div>
                                {errorgoodsValues[index]?.goods_images && (
                                  <span className="error">
                                    {errorgoodsValues[index]?.goods_images}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>

                    ))}
                  </div>

                  <div className='addmore_goods'>
                    <div className='addmore_btn'>
                      <button type='button' value="submit" onClick={addFormFields}><i className="fa-solid fa-plus"></i> Add More Goods</button>
                    </div>
                    <div className='addmore_rt'> <p>Book Multiple Goods In A Single Shipment </p></div>
                  </div>
                </div>



                <div className="booking_part_lt mt-3">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="office_field">
                        <label>Booking Date</label>
                        <input type="date" placeholder="Booking Date" name="booking_date"
                          value={formValue.booking_date}
                          onChange={formChange}
                          onBlur={updateFormTouchValue}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onKeyDown={(e) => e.preventDefault()}
                          error={errorValue.booking_date ? "true" : "false"}
                        />
                        {errorValue.booking_date && (
                          <span className="error">
                            {errorValue.booking_date}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='col-sm-6'></div>
                    <div className='col-sm-12'>
                      <div className="office_field">
                        <p>Payment Mode</p>
                      </div>
                      <div className="office_radio">
                        <div className="radio_sec">
                          <input id="radio-1" className="radio-custom" name="payment_mode" type="radio" value="shipper" onChange={formChange}
                            onBlur={updateFormTouchValue}
                            error={errorValue.payment_mode ? "true" : "false"} />
                          <label for="radio-1" className="radio-custom-label" style={{ color: "#000" }}>By Shipper</label>
                        </div>
                        <div className="radio_sec">
                          <input id="radio-2" className="radio-custom" name="payment_mode" type="radio" value="receiver" onChange={formChange}
                            onBlur={updateFormTouchValue}
                            error={errorValue.payment_mode ? "true" : "false"} />
                          <label for="radio-2" className="radio-custom-label">By Reciever</label>
                        </div>

                      </div>
                      {errorValue.payment_mode && (
                        <span className="error">
                          {errorValue.payment_mode}
                        </span>
                      )}
                    </div>
                    <div className='col-sm-12'>
                      <div className="row" >
                        <div className="col-sm-6" >
                          <div className="office_field">
                            <p>Load Type</p>
                          </div>
                          <div className="office_radio">
                            <div className="radio_sec">
                              <input id="radio-3" className="radio-custom" name="load_type" type="radio" value="partial" onChange={formChange} onBlur={updateFormTouchValue}
                                error={errorValue.load_type ? "true" : "false"} />
                              <label for="radio-3" className="radio-custom-label" style={{ color: "#000" }}>Partial Load</label>
                            </div>
                            <div className="radio_sec">
                              <input id="radio-4" className="radio-custom" name="load_type" type="radio" value="full" onChange={formChange} onBlur={updateFormTouchValue}
                                error={errorValue.load_type ? "true" : "false"} />
                              <label for="radio-4" className="radio-custom-label">Full Load</label>
                            </div>

                          </div>{errorValue.load_type && (
                            <span className="error">
                              {errorValue.load_type}
                            </span>
                          )}
                        </div>

                        {formValue.load_type == 'full' &&
                          <div className="col-sm-6">
                            <div className="office_field">
                              <label>Vehicle Category</label>
                              <Select
                                className="dropdown"
                                name="vehicle_category_id"
                                placeholder="Select Option"
                                value={filteredData.filter((x) => x.value == vehicle_category_id.id)}
                                isSearchable={false}
                                options={filteredData}
                                onChange={(e) => handleChangeCategory(e)}
                              />
                            </div>
                            {errorValue.vehicle_category_id && (
                              <span className="error">
                                {errorValue?.vehicle_category_id}
                              </span>
                            )}

                          </div>}
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className="office_field">
                        <label>Additional Notes</label>
                        <input type="text" placeholder="Add Note" name="additional_comments"
                          onChange={formChange} value={formValue.additional_comments} />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className="office_field">
                        <label>Add Insurance Link</label>
                        <input type="text" placeholder="Insurance Link" name="insurance_link" value={formValue.insurance_link} onChange={formChange} />
                      </div>
                    </div>
                    <div className="save_btn mt-3">
                      <button type="submit" value="Verify & Save"
                        disabled={submitDisable ? true : false}
                        onClick={validForm}
                      >Confirm & Submit Request</button>
                    </div>
                  </div>
                </div>

              </div>
              <div className='col-sm-4'>

              </div>
            </div>
          </div>
        </div>
        : ''}
      {openModal && <LocationModal onHide={() => { setopenModal(false) }}
        show={openModal} onCloseModal={onCloseModal} />}

    </>
  )
}

export default NewShipmentBooking;


/* custom menu list*/
const MenuList = (props) => {
  const listRef = useRef();
  const sizeMap = useRef({});
  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  }, []);
  const getItemSize = index => sizeMap.current[index] || 50;
  const [windowWidth] = useWindowResize();

  return <List
    ref={listRef}
    className="List"
    height={350}
    itemCount={props.children.length}
    itemSize={(index) => getItemSize(index)}
    itemData={props.children}
  >
    {({ data, index, style }) => (
      <div style={style}>
        <Row
          data={data}
          index={index}
          setSize={setSize}
          windowWidth={windowWidth}
        />
      </div>
    )}

  </List>
}

/* calculate row size and width*/
const Row = ({ data, index, setSize, windowWidth }) => {
  const rowRef = useRef();
  const isEven = index % 2 === 0;

  useEffect(() => {
    setSize(index, rowRef.current.getBoundingClientRect().height);
  }, [setSize, index, windowWidth]);

  return (
    <div
      ref={rowRef}
      style={{
        backgroundColor: isEven ? "rgba(0, 0, 255, .1)" : "transparent"
      }}
    >
      {data[index]}
    </div>
  );
};

/* dynamic width */
export const useWindowResize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};
