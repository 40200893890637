/**
 * @file_purpose  page for showing shipment quotes request accept reject  modal
 * @author Sarmistha Mondal
 * @Date_Created 09/04/2024
 * @Date_Modified 
 */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Constants, ERROR_MESSAGE } from "../../../Utility/ConstantData";
import { ToastContainer, toast } from "react-toastify";
import Service from "../../../apis/Service";
import RouteURL from "../../../apis/RouteURL";
import {
    getErrorMessage,
    markAllAsTouched,
    leftTrimAllValue,
    isFormValid,
    leftTrim, checkLength
} from "../../../Utility/Helper";
import Modal from "react-bootstrap/Modal";
import Dimension from "../../../assets/images/square-measument.png";
import Select from 'react-select';
import SmallTruck from "../../../assets/images/sm_truck.png"
import Slider from "react-slick";

export default function QuotesRequestAcceptsRejectModal(props) {
    const navigate = useNavigate();
    let shipment_booking_id = props._allData.shipmentBookingId;
    let shipment_quote_id = props._allData.shipmentQuoteId;
    let Type = props._allData.type;
    let Payload = props._allData.payload;
    const [reasonList, setReasonList] = useState([]);
    const [submitDisable, setSubmitDisable] = useState(false);
    const [productDetailsShow, setProductDetailsShow] = useState(false);
    const [formValue, setFormValue] = useState({
        receiver_name: '',
        receiver_contact_number: '',
        total_goods_price: '',
        receiver_email: '',
    });
    const [optionalFields, setOptionalFields] = useState({
        additional_notes: "",
        receiver_contact_number2: "",
    });
    const [formTouchValue, setFormTouchValue] = useState({
        receiver_name: false,
        receiver_contact_number: false,
        receiver_contact_number2: false,
        total_goods_price: false,
        receiver_email: false
    });
    const [errorValue, setErrorValue] = useState({
        receiver_name: '',
        receiver_contact_number: '',
        receiver_contact_number2: '',
        total_goods_price: '',
        receiver_email: ''
    });

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        touchMove: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const [rejectionFormValue, setRejectionFormValue] = useState({ rejection_reason: "", additional_notes: "" });
    const [rejectionFormTouchValue, setRejectionFormTouchValue] = useState({ rejection_reason: false, additional_notes: false });
    const [rejectionErrorValue, setRejectionErrorValue] = useState({
        rejection_reason: "", additional_notes: "",
    });
    const [buttonVisible, setButtonVisible] = useState(0)
    useEffect(() => {
        if (Type == 'reject') _shipmentRejectionReasonsList()
    }, [])


    /**
       * @author Sarmistha Mondal
       * @Date_Created 19/12/2023
       * @Date_Modified 
       * @function async
       * @functionName _shipmentRejectionReasonsList
       * @functionPurpose this function for shipment rejection reasons list.
       *
       * @functionParam {}
       *
       * @functionSuccess Success status and message.
       *
       * @functionError {Boolean} error is there.
       * @functionError {String} message  Description message.
       */
    const _shipmentRejectionReasonsList = () => {

        Service.apiPostTokenCallRequest(RouteURL.rejectionReasonsList, {}).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                let res = response.data.reasonList;
                var newArray = []
                res.map((item, index) => (
                    newArray.push({
                        value: item.id,
                        label: item.reason
                    })
                ))
                setReasonList(newArray)

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        });

    };



    useEffect(() => {
        checkError("rejection_reason");
        checkError("additional_notes");
    }, [rejectionFormValue])


    /* on change input function */
    const formChange = (event) => {
        const { name, value } = event.target;
        setFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
        setOptionalFields((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });

    };

    /* on change input function */
    const rejectionFormChange = (event) => {
        const { name, value } = event.target;
        setRejectionFormValue((prevFormValue) => {
            return { ...prevFormValue, [name]: leftTrim(value) };
        });
    }

    /* onBlur error message call */
    const updateFormTouchValue = (event) => {
        const { name, value } = event.target;
        if (!formTouchValue[name]) {
            setFormTouchValue((prevFormTouchValue) => {
                return { ...prevFormTouchValue, [name]: true };
            });
            setFormValue((prevFormValue) => {
                return { ...prevFormValue, [name]: leftTrim(value) };
            });
        }

    };

    /* error message function */
    const checkError = (targetName) => {
        if (formTouchValue[targetName]) {
            setErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !formValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` : ""

                };
            });
        }

        if (rejectionFormTouchValue[targetName]) {
            setRejectionErrorValue((prevErrorValue) => {
                return {
                    ...prevErrorValue, [targetName]: !rejectionFormValue[targetName] ?
                        `${ERROR_MESSAGE[targetName + 'Required']}` : ""

                };
            });
        }
    };

    /* Validation Checking */
    const validation = (e) => {
        e.preventDefault();
        setFormTouchValue(markAllAsTouched(formTouchValue));

        checkError("receiver_name");
        checkError("receiver_contact_number");
        checkError("total_goods_price");
        checkError("receiver_email");
        if (isFormValid(formValue)) {
            _QuotesAcceptRequestSubmit()
        }
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 10/04/2024
    * @Date_Modified 
    * @function async
    * @functionName _QuotesAcceptRequestSubmit
    * @functionPurpose this function for transport accept quotetion request.
    *
    * @functionParam {shipment_booking_id,shipment_quote_id,additional_notes,receiver_name
    * receiver_email,receiver_contact_number,receiver_contact_number2,total_goods_price}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _QuotesAcceptRequestSubmit = () => {
        let params = JSON.stringify({
            shipment_booking_id: String(shipment_booking_id),
            shipment_quote_id: String(shipment_quote_id),
            receiver_name: formValue.receiver_name,
            receiver_email: formValue.receiver_email,
            receiver_contact_number: formValue.receiver_contact_number,
            total_goods_price: formValue.total_goods_price,
            receiver_contact_number2: optionalFields.receiver_contact_number2,
            additional_notes: optionalFields.additional_notes,
        });

        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.shippmentQuoteAccept, params).then((response) => {

            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                navigate('/shipment-list')
                setSubmitDisable(false)
            } else {

                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });
            }
        });
    }

    /* Validation Checking */
    const rejectValidation = (e) => {
        e.preventDefault();
        setRejectionFormTouchValue(markAllAsTouched(rejectionFormTouchValue));
        checkError("rejection_reason"); checkError("additional_notes");
        if (isFormValid(rejectionFormValue)) {
            _shippmentRejectRequestSubmit()
        }
    }

    /**
    * @author Sarmistha Mondal
    * @Date_Created 18/12/2023
    * @Date_Modified 
    * @function async
    * @functionName _shippmentRejectRequestSubmit
    * @functionPurpose this function for transport reject request.
    *
    * @functionParam {shipment_booking_id,shipment_quote_id,rejection_reason,additional_notes}
    *
    * @functionSuccess Success status and message.
    *
    * @functionError {Boolean} error is there.
    * @functionError {String} message  Description message.
    */
    const _shippmentRejectRequestSubmit = () => {
        let params = JSON.stringify({
            shipment_booking_id: String(shipment_booking_id),
            shipment_quote_id: String(shipment_quote_id),
            rejection_reason: rejectionFormValue.rejection_reason,
            additional_notes: rejectionFormValue.additional_notes,
        });
        setSubmitDisable(true)
        Service.apiPostTokenCallRequest(RouteURL.shippmentQuoteReject, params).then((response) => {
            if (response.err === Constants.API_RESPONSE_STATUS_SUCCESS) {
                props.CloseAddEditModal();
                navigate('/shipment-list')
                setSubmitDisable(false)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    onClose: () => {
                        setSubmitDisable(false);
                    },
                });

            }
        });
    }

    const reasonSelect = (e) => {
        setRejectionFormValue((prevFormValue) => {
            return { ...prevFormValue, rejection_reason: String(e.value) };
        });
    }

    /* slider navigation*/
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;

        setButtonVisible(props.currentSlide + 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
        );
    }

    /* slider navigation*/
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        setButtonVisible(props.currentSlide - 1)
        return (
            <div
                className={className}
                style={{ ...style, display: "block", }}
                onClick={onClick}
            >
                <i className="fa-solid fa-chevron-left"></i>
            </div>
        );
    }

    return (
        <>
            < Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="forgot_sec">
                    <Modal.Header closeButton closeVariant={'white'}>
                        <h6 className="hed_txt">{Type == 'accept' ? 'Accept' : 'Reject'} Request</h6>
                    </Modal.Header>

                    <section className="reject_inner">
                        <div className="reject_req_sec">
                            <div className="reject_hed"><h4>{Payload?.shipment_details?.request_id} <br /><span style={{ color: "#FBAA2A" }}>{Payload?.shipment_details?.shipper_code}</span></h4></div>
                            {/* <div className="reject_person"><p>{Payload?.shipment_details?.shipper_name}</p></div> */}
                        </div>
                        <div className="reject_track">
                            <div className="order-track-step">
                                <div className="order-track-status">
                                    <span className="order-track-status-dot" style={{ background: "#7068E2" }}><i className="fa-solid fa-location-dot"></i></span>
                                    <span className="order-track-status-line"></span>
                                </div>
                                <div className="order-track-text">
                                    <span className="order-track-text-sub">Booking Date - {Payload?.location_details?.booking_date}</span>
                                    <p className="order-track-text-stat">{Payload?.location_details?.from_location}</p>
                                    <span className="kilometer">{Payload?.location_details?.distance}</span>
                                    <span className="kilometer">{Payload?.location_details?.time_duration}</span>
                                </div>
                            </div>
                            <div className="order-track-step">
                                <div className="order-track-status">
                                    <span className="order-track-status-dot" style={{ background: "#2ED573" }}><i className="fa-solid fa-arrow-right"></i></span>
                                    <span className="order-track-status-line"></span>
                                </div>
                                <div className="order-track-text">
                                    <span className="order-track-text-sub">Est. Delivery date -
                                        {Payload?.quoteList[0]?.quoteDetails
                                            .estimated_delivery_date != null ? Payload?.quoteList[0]?.quoteDetails
                                            .estimated_delivery_date : ' _____'}

                                    </span>
                                    <p className="order-track-text-stat">{Payload?.location_details?.to_location}</p>
                                </div>
                            </div>

                        </div>
                        <div className="material_show_dot">
                            <span className="dot_bluclick">
                                <i className="fa-solid fa-ellipsis" onClick={() => setProductDetailsShow(!productDetailsShow)} />
                                {productDetailsShow == true &&
                                    <>
                                        <div className="dot_bluclick_inner shipaccept">
                                            <span><i className="fa-solid fa-xmark" onClick={() => setProductDetailsShow(false)}></i></span>
                                            <Slider {...settings} >
                                                {Payload?.material_typeDetails.length > 0 && Payload?.material_typeDetails.map((item) => {
                                                    return (
                                                        <div className="row d-flex" >

                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><img src={Dimension} alt="" /> Material</p>
                                                                    <h6>{item.material_type}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><img src={Dimension} alt="" /> Weight</p>
                                                                    <h6>{item.package_weight}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><img src={Dimension} alt="" /> Dimensions</p>
                                                                    <h6>{item.product_dimension}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p><img src={Dimension} alt="" /> Number of Boxes</p>
                                                                    <h6>{item.no_of_units}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p>Payment Mode</p>
                                                                    <h6>{Payload?.shipment_details?.payment_mode?.charAt(0).toUpperCase() + Payload?.shipment_details?.payment_mode?.slice(1)}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <p>Loading Type</p>
                                                                    <h6>{Payload?.shipment_details?.load_type} </h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="material1_sec">
                                                                    <h6>Product Image</h6>
                                                                    <div className="productmaterial_image_box">

                                                                        {item?.images.length > 0 && item?.images.map((img) => {
                                                                            return (<div className="p_box">
                                                                                <img src={img ? img : SmallTruck} alt="" height={100} width={100} />
                                                                            </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })}
                                            </Slider>
                                            <span className="slider_number_count">{buttonVisible}/<span style={{ color: "#fbaa2a" }}>{Payload?.material_typeDetails.length}</span></span>
                                        </div>

                                    </>

                                }


                            </span>
                        </div>
                        <div className="Reject_field">
                            <div className="row">
                                {Type == 'accept' &&
                                    <>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="office_field">
                                                <label>Receiver Name</label>
                                                <input type="text" placeholder="Enter Receiver Name" name="receiver_name"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    value={formValue.receiver_name}
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}
                                                    error={errorValue.receiver_name ? "true" : "false"} />
                                                {errorValue.receiver_name && (
                                                    <span className="error">
                                                        {errorValue.receiver_name}
                                                    </span>
                                                )}

                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="office_field">
                                                <label>Receiver Contact Number</label>
                                                <input type="number"
                                                    name="receiver_contact_number"
                                                    placeholder="Enter Receiver Contact Number"
                                                    maxLength={10}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    onKeyDown={e => {
                                                        checkLength(e);
                                                        // if (formValue.receiver_contact_number.length == 10) {
                                                        //     setErrorValue((prevErrorValue) => {
                                                        //         return {
                                                        //             ...prevErrorValue, receiver_contact_number: ""
                                                        //         };
                                                        //     });
                                                        // } else setErrorValue((prevErrorValue) => {
                                                        //     return {
                                                        //         ...prevErrorValue, receiver_contact_number: `${ERROR_MESSAGE['receiver_contact_numberInvalid']}`
                                                        //     };
                                                        // })
                                                    }}
                                                    value={formValue.receiver_contact_number}
                                                    error={errorValue.receiver_contact_number ? "true" : "false"} />
                                                {errorValue.receiver_contact_number && (
                                                    <span className="error">
                                                        {errorValue.receiver_contact_number}
                                                    </span>
                                                )}

                                            </div>
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="office_field">
                                                <label>Receiver Contact Number 2</label>
                                                <input type="number"
                                                    name="receiver_contact_number2"
                                                    placeholder="Enter Receiver Contact Number 2"
                                                    maxLength={10}
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    onKeyDown={e => {
                                                        checkLength(e);
                                                        // if (optionalFields.receiver_contact_number2.length < 10) {
                                                        //     setErrorValue((prevErrorValue) => {
                                                        //         return {
                                                        //             ...prevErrorValue, receiver_contact_number2: `${ERROR_MESSAGE['receiver_contact_number2Invalid']}`
                                                        //         };
                                                        //     });
                                                        // } else setErrorValue((prevErrorValue) => {
                                                        //     return {
                                                        //         ...prevErrorValue, receiver_contact_number2: ''
                                                        //     };
                                                        // });
                                                    }}
                                                    value={optionalFields.receiver_contact_number2}
                                                    error={errorValue.receiver_contact_number2 ? "true" : "false"} />
                                            </div>
                                            {/* {errorValue.receiver_contact_number2 && (
                                                <span className="error">
                                                    {errorValue.receiver_contact_number2}
                                                </span>
                                            )} */}
                                        </div>
                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="office_field">
                                                <label>Receiver Email</label>
                                                <input type="email"
                                                    name="receiver_email"
                                                    placeholder="Enter Receiver Email"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    value={formValue.receiver_email}
                                                    error={errorValue.receiver_email ? "true" : "false"} />
                                            </div>
                                            {errorValue.receiver_email && (
                                                <span className="error">
                                                    {errorValue.receiver_email}
                                                </span>
                                            )}
                                        </div>


                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="office_field">
                                                <label> Total Good's Price</label>
                                                <input type="number"
                                                    name="total_goods_price"
                                                    placeholder="Enter Total Good's Price"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    value={formValue.total_goods_price}
                                                    error={errorValue.total_goods_price ? "true" : "false"} />
                                                {errorValue.total_goods_price && (
                                                    <span className="error">
                                                        {errorValue.total_goods_price}
                                                    </span>
                                                )}
                                            </div>
                                        </div>


                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                            <div className="office_field">
                                                <label>Additional Notes</label>
                                                <input type="text"
                                                    name="additional_notes"
                                                    placeholder="Enter Additional Notes"
                                                    onChange={formChange}
                                                    onBlur={updateFormTouchValue}
                                                    value={optionalFields.additional_notes}
                                                    onKeyDown={event => {
                                                        if (event.keyCode == 9) {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            return false;
                                                        }
                                                    }}

                                                />

                                            </div>
                                        </div>

                                        <div className="col-sm-4 offset-sm-4">
                                            <div className="login_btn"><button type="submit" value="get Started" style={{ background: '#000' }}
                                                disabled={submitDisable ? true : false}
                                                onClick={validation}
                                            >Confirm </button></div>
                                        </div>
                                    </>

                                }
                                {Type == 'reject' &&
                                    <>

                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Rejection Reason</label>
                                                <Select
                                                    onChange={reasonSelect}
                                                    options={reasonList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                />

                                                {rejectionErrorValue.rejection_reason && (
                                                    <span className="error">
                                                        {rejectionErrorValue.rejection_reason}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="office_field">
                                                <label>Additional Notes</label>
                                                <input type="text" placeholder="Enter Note"
                                                    name="additional_notes"
                                                    onChange={rejectionFormChange}
                                                    value={rejectionFormValue.additional_notes}
                                                    error={errorValue.additional_notes ? "true" : "false"}

                                                    onKeyDown={event => {
                                                        if (event.keyCode == 9) {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            return false;

                                                        }
                                                    }} />

                                                {rejectionErrorValue.additional_notes && (
                                                    <span className="error">
                                                        {rejectionErrorValue.additional_notes}
                                                    </span>
                                                )}
                                            </div>
                                        </div>


                                        <div className="col-sm-4 offset-sm-4">
                                            <div className="login_btn"><button type="submit" value="get Started" style={{ background: '#000' }} onClick={rejectValidation} disabled={submitDisable ? true : false}>Confirm & Reject</button></div>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>

                    </section>

                </div>
            </Modal>
        </>
    )
}